// funcionalidades.jsx

import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { NavbarModelagemcompleta } from './NavbarModelagemcompleta.jsx'
import logoTopoModelagemCompleta from '../Usuarios/login/logo_topo.jpg'
import logoModaNegocios360 from '../Usuarios/login/4.png'
import nomeModaNegocios360 from '../Usuarios/login/6.png'

import { funcionalidadesData } from './funcionalidadesData.js' // Importe os dados

function Funcionalidades() {
  const navigate = useNavigate()
  const location = useLocation()
  const hostname = window.location.hostname
  const pathname = location.pathname

  const isModaNegocios360 =
    (hostname && hostname.includes('moda.negocios360')) ||
    (pathname && pathname.includes('moda.negocios360'))
  const logoTopo = isModaNegocios360 ? logoModaNegocios360 : logoTopoModelagemCompleta

  // Definindo alturas com base na condição
  const logoTopoHeight = isModaNegocios360 ? '55px' : '60px'
  const logoTopoWidth = isModaNegocios360 ? '65px' : '70px'
  const titleHeight = isModaNegocios360 ? '60px' : undefined

  // Estado para controlar quais módulos estão expandidos
  const [modulosExpandidos, setModulosExpandidos] = useState({})

  // Função para alternar a expansão de um módulo
  const toggleExpandirModulo = index => {
    setModulosExpandidos(prev => ({
      ...prev,
      [index]: !prev[index]
    }))
  }

  return (
    <div className="funcionalidades-wrapper">
      <NavbarModelagemcompleta
        setShowAlertMessage={() => {}}
        setAlertMessage={() => {}}
        logoTopo={logoTopo}
        logoTopoHeight={logoTopoHeight}
        logoTopoWidth={logoTopoWidth}
        title={
          isModaNegocios360 ? (
            <img src={nomeModaNegocios360} alt="Moda Negócios 360" style={{ height: '150px' }} />
          ) : (
            'Modelagem Completa'
          )
        }
        titleHeight={titleHeight}
      />

      <div className="funcionalidades-container">
        <header className="funcionalidades-header">
          <h1>Funcionalidades</h1>
          <p>
            Conheça todas as funcionalidades que oferecemos para otimizar o seu negócio de moda.
          </p>
        </header>

        <section className="funcionalidades-content">
          {funcionalidadesData.map((item, index) => (
            <div key={index} className="funcionalidade-card">
              <div className="funcionalidade-header">
                <h2 className="funcionalidade-modulo">{item.modulo}</h2>
                <button
                  className="btn-toggle"
                  onClick={() => toggleExpandirModulo(index)}
                  aria-expanded={modulosExpandidos[index] ? 'true' : 'false'}
                  aria-controls={`funcionalidade-lista-${index}`}
                >
                  {modulosExpandidos[index] ? '▲' : '▼'}
                </button>
              </div>
              <p className="funcionalidade-descricao">{item.descricao}</p>

              {/* Accordion: Lista de Funcionalidades */}
              {modulosExpandidos[index] && (
                <ul className="funcionalidade-lista" id={`funcionalidade-lista-${index}`}>
                  {item.funcionalidades.map((func, idx) => (
                    <li key={idx}>{func}</li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </section>

        <footer className="funcionalidades-footer">
          <button className="btn-voltar-home" onClick={() => navigate('/home')}>
            Voltar para Home
          </button>
        </footer>
      </div>
    </div>
  )
}

export default Funcionalidades
