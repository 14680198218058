//comissao-display.jsx
import { useEffect, useState, useContext } from 'react'
import { Table } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { CustomField } from '../../../../../../src/components/CustomField/custom-field'
import { calcularFreteParaAmericanas } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/calculo-precoPF.js'
import { AuthContext } from '../../../../../../src/context/AuthContext'
export const ProfitMarginCalculator = ({
  produtoSelecionado,
  marketplacesWithComissoes,
  selectedMarketplaces,
  calcularFreteComDescontoMagalu,
  calcularFreteComDescontoMercadoLivre,
  valorFretePorPeso,
  descontoAtualFreteMagalu,
  descontoAtualFreteMercadoLivre,
  regrasPesoFreteMagalu,
  regrasPesoFreteMercadoLivre,
  peso,
  setValorFreteMagalu,
  setValorFreteMercadoLivre,
  setValorFreteAmazon,
  setValorFreteAmericanas,
  setValorFreteViaVarejo,
  valorFreteMagalu,
  valorFreteMercadoLivre,
  valorFreteAmazon,
  valorFreteAmericanas,
  valorFreteViaVarejo,
  marketplaceColors,
  setDynamicStateMargem,
  stateMargem,
  setDynamicStatePrecoVendaSugerido,
  statePrecoVendaSugerido,
  setDynamicStateLucro,
  stateLucro,
  regrasPorGrupoAmericanas,
  regrasPorGrupoViaVarejo,
  ultimoImposto
}) => {
  const [selectedMarketplacesData, setSelectedMarketplacesData] = useState([])

  // Validação das props dentro do componente
  ProfitMarginCalculator.propTypes = {
    produtoSelecionado: PropTypes.object,
    marketplacesWithComissoes: PropTypes.array,
    selectedMarketplaces: PropTypes.object,
    calcularFreteComDescontoMagalu: PropTypes.func,
    calcularFreteComDescontoMercadoLivre: PropTypes.func,
    valorFretePorPeso: PropTypes.func,
    descontoAtualFreteMagalu: PropTypes.number,
    descontoAtualFreteMercadoLivre: PropTypes.number,
    regrasPesoFreteMagalu: PropTypes.array,
    regrasPesoFreteMercadoLivre: PropTypes.array,
    peso: PropTypes.number,
    setValorFreteMagalu: PropTypes.func,
    setValorFreteMercadoLivre: PropTypes.func,
    setValorFreteAmazon: PropTypes.func,
    setValorFreteAmericanas: PropTypes.func,
    setValorFreteViaVarejo: PropTypes.func,
    valorFreteMagalu: PropTypes.number,
    valorFreteMercadoLivre: PropTypes.number,
    valorFreteAmazon: PropTypes.number,
    valorFreteAmericanas: PropTypes.number,
    valorFreteViaVarejo: PropTypes.number,
    marketplaceColors: PropTypes.object,
    setDynamicStateMargem: PropTypes.func,
    stateMargem: PropTypes.object,
    setDynamicStatePrecoVendaSugerido: PropTypes.func,
    statePrecoVendaSugerido: PropTypes.object,
    setDynamicStateLucro: PropTypes.func,
    stateLucro: PropTypes.object,
    regrasPorGrupoAmericanas: PropTypes.array,
    regrasPorGrupoViaVarejo: PropTypes.array,
    ultimoImposto: PropTypes.number
  }
  const { RotaBase, tenantId, assinaturaStatus } = useContext(AuthContext)

  function addColorsToMarketplaces(marketplaces, colors) {
    if (!Array.isArray(marketplaces)) {
      console.error('marketplaces is not an array', marketplaces)
      return []
    }
    return marketplaces?.map(marketplace => ({
      ...marketplace,
      cor: colors[marketplace.nome] || '#000000' // Default to black if no color match
    }))
  }

  const marketplacesColored = addColorsToMarketplaces(marketplacesWithComissoes, marketplaceColors)
  const select = marketplacesColored.filter(marketplace => selectedMarketplaces[marketplace._id])
  const addFixoParameter = select => {
    if (Array.isArray(select)) {
      return select.map(item => {
        const nome = item.nome.toLowerCase()
        let fixo = ''

        if (nome === 'magalu' || nome === 'shein') {
          fixo = 5
        } else if (nome === 'shopee') {
          fixo = 3
        }

        return { ...item, fixo }
      })
    } else {
      const item = select
      const nome = item.nome.toLowerCase()
      let fixo = ''

      if (nome === 'magalu' || nome === 'shein') {
        fixo = 5
      } else if (nome === 'shopee') {
        fixo = 3
      }

      return { ...item, fixo }
    }
  }
  useEffect(() => {
    const updatedSelect = addFixoParameter(select)

    setSelectedMarketplacesData(updatedSelect)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplacesWithComissoes, selectedMarketplaces, marketplaceColors])

  function calcularFreteParaMagalu(
    selectedMarketplacesData,
    peso,
    descontoAtualFreteMagalu,
    regrasPesoFreteMagalu
  ) {
    const marketplaceMagalu = selectedMarketplacesData.find(
      marketplace => marketplace.nome === 'Magalu'
    )

    if (!marketplaceMagalu) {
      return undefined
    }

    const freteCalculado = calcularFreteComDescontoMagalu(
      regrasPesoFreteMagalu,
      peso,
      descontoAtualFreteMagalu
    )
    return freteCalculado
  }
  function calcularFreteParaMercadoLivre(
    selectedMarketplacesData,
    peso,
    descontoAtualFreteMercadoLivre,
    regrasPesoFreteMercadoLivre,
    precoVendaBruto
  ) {
    const marketplaceMercadoLivre = selectedMarketplacesData.find(
      marketplace => marketplace.nome === 'Mercado Livre'
    )

    if (!marketplaceMercadoLivre) {
      return undefined
    }

    const freteCalculado = calcularFreteComDescontoMercadoLivre(
      regrasPesoFreteMercadoLivre,
      peso,
      descontoAtualFreteMercadoLivre,
      precoVendaBruto
    )
    return freteCalculado
  }

  //------PARA O CAMPO MARGEM--------------------------------------------------------------

  function handleMargemChange(marketplaceId, event) {
    let novaMargem = event.target.value
    // troque , por . em novaMargem
    novaMargem = novaMargem.replace(',', '.')
    setDynamicStateMargem({
      ...stateMargem,
      [marketplaceId]: novaMargem
    })
  }

  function handleMargemBlur(marketplaceId) {
    let novaMargem = parseFloat(stateMargem[marketplaceId])

    let marketplace = marketplacesWithComissoes.find(m => m._id === marketplaceId)
    marketplace = addFixoParameter(marketplace)
    function getComissaoByMarketplaceId(marketplacesWithComissoes, marketplaceId) {
      const marketplace = marketplacesWithComissoes.find(m => m._id === marketplaceId)
      return marketplace ? marketplace.comissao : null
    }

    const comissaoPercentual = getComissaoByMarketplaceId(marketplacesWithComissoes, marketplaceId)

    if (!isNaN(novaMargem) && novaMargem >= 0) {
      const custo = parseFloat(produtoSelecionado?.custo || 0)
      if (custo && !isNaN(comissaoPercentual)) {
        let frete = 0
        let valorComissao = 0
        let valorLucro = 0
        let precoVendaComFrete = 1 // Inicia com 1
        let incremento = 1 // Inicialmente, incrementa por 1
        let found = false

        while (!found && precoVendaComFrete < 300) {
          // limite arbitrário para evitar loops infinitos
          if (marketplace.nome === 'Amazon') {
            frete = valorFretePorPeso(regrasPesoFreteMagalu, peso, precoVendaComFrete || 0)
            valorComissao = precoVendaComFrete * (comissaoPercentual / 100)
            valorLucro =
              precoVendaComFrete -
              custo -
              valorComissao -
              frete -
              (marketplace.fixo || 0) -
              precoVendaComFrete * (ultimoImposto / 100)
          } else if (marketplace.nome === 'Magalu') {
            frete = calcularFreteParaMagalu(
              selectedMarketplacesData,
              peso,
              descontoAtualFreteMagalu,
              regrasPesoFreteMagalu
            )
            valorComissao = precoVendaComFrete * (comissaoPercentual / 100)
            valorLucro =
              precoVendaComFrete -
              custo -
              valorComissao -
              frete -
              (marketplace.fixo || 0) -
              precoVendaComFrete * (ultimoImposto / 100)
          } else if (marketplace.nome === 'Mercado Livre') {
            frete = calcularFreteParaMercadoLivre(
              selectedMarketplacesData,
              peso,
              descontoAtualFreteMercadoLivre,
              regrasPesoFreteMercadoLivre,
              precoVendaComFrete
            )
            valorComissao = precoVendaComFrete * (comissaoPercentual / 100)
            valorLucro =
              precoVendaComFrete - custo - valorComissao - frete - (marketplace.fixo || 0)
          } else if (marketplace.nome === 'Americanas') {
            frete = calcularFreteParaAmericanas(peso, precoVendaComFrete, regrasPorGrupoAmericanas)
            valorComissao = precoVendaComFrete * (comissaoPercentual / 100)
            valorLucro =
              precoVendaComFrete - custo - valorComissao - frete - (marketplace.fixo || 0)
          } else if (marketplace.nome === 'Via Varejo') {
            frete = calcularFreteParaAmericanas(peso, precoVendaComFrete, regrasPorGrupoViaVarejo)
            valorComissao = precoVendaComFrete * (comissaoPercentual / 100)
            valorLucro =
              precoVendaComFrete - custo - valorComissao - frete - (marketplace.fixo || 0)
          }

          if (Math.abs((valorLucro / precoVendaComFrete) * 100 - novaMargem) < 0.03) {
            setDynamicStateMargem({
              ...stateMargem,
              [marketplaceId]: novaMargem
            })

            setDynamicStateLucro({
              ...stateLucro,
              [marketplaceId]: valorLucro.toFixed(2)
            })

            setDynamicStatePrecoVendaSugerido({
              ...statePrecoVendaSugerido,
              [marketplaceId]: precoVendaComFrete.toFixed(2)
            })

            if (marketplace.nome === 'Amazon') {
              setValorFreteAmazon(frete)
            } else if (marketplace.nome === 'Magalu') {
              setValorFreteMagalu(frete)
            } else if (marketplace.nome === 'Mercado Livre') {
              setValorFreteMercadoLivre(frete)
            } else if (marketplace.nome === 'Americanas') {
              setValorFreteAmericanas(frete)
            } else if (marketplace.nome === 'Via Varejo') {
              setValorFreteViaVarejo(frete)
            }

            found = true
          } else {
            if ((valorLucro / precoVendaComFrete) * 100 > novaMargem) {
              if (incremento === 1) {
                precoVendaComFrete -= 1
                incremento = 0.1
              } else {
                incremento = 0.1
              }
            }
            precoVendaComFrete += incremento
          }
        }
      }
    }
  }

  //-------PARA O CAMPO VENDA SUGERIDA-----------------------------------
  function handlePrecoVendaChange(marketplaceId, event) {
    let novoPrecoVenda = event.target.value
    // troque , por . em novoPrecoVenda
    novoPrecoVenda = novoPrecoVenda.replace(',', '.')
    setDynamicStatePrecoVendaSugerido({
      ...statePrecoVendaSugerido,
      [marketplaceId]: novoPrecoVenda
    })
  }
  //--------------------PARA O CAMPO VENDA SUGERIDA-----------------------------------

  function handlePrecoVendaBlur(marketplaceId) {
    let precoVendaComFrete = parseFloat(statePrecoVendaSugerido[marketplaceId])

    let marketplace = marketplacesWithComissoes.find(m => m._id === marketplaceId)
    marketplace = addFixoParameter(marketplace)
    function getComissaoByMarketplaceId(marketplacesWithComissoes, marketplaceId) {
      const marketplace = marketplacesWithComissoes.find(m => m._id === marketplaceId)
      return marketplace ? marketplace.comissao : null
    }

    const comissaoPercentual = getComissaoByMarketplaceId(marketplacesWithComissoes, marketplaceId)

    if (!isNaN(precoVendaComFrete) && precoVendaComFrete >= 0) {
      const custo = parseFloat(produtoSelecionado?.custo || 0)

      if (custo && !isNaN(comissaoPercentual)) {
        let frete = 0
        let valorComissao = 0
        let valorLucro = 0
        let porcentagemDeMargem = 0
        if (marketplace.nome === 'Amazon') {
          frete = valorFretePorPeso(regrasPesoFreteMagalu, peso, precoVendaComFrete || 0)
          valorComissao = precoVendaComFrete * (comissaoPercentual / 100)

          valorLucro =
            precoVendaComFrete -
            custo -
            valorComissao -
            frete -
            (marketplace.fixo || 0) -
            precoVendaComFrete * (ultimoImposto / 100)

          porcentagemDeMargem = (valorLucro / precoVendaComFrete) * 100
        } else if (marketplace.nome === 'Magalu') {
          frete = calcularFreteParaMagalu(
            selectedMarketplacesData,
            peso,
            descontoAtualFreteMagalu,
            regrasPesoFreteMagalu
          )
          valorComissao = precoVendaComFrete * (comissaoPercentual / 100)
          valorLucro =
            precoVendaComFrete -
            custo -
            valorComissao -
            frete -
            (marketplace.fixo || 0) -
            precoVendaComFrete * (ultimoImposto / 100)

          porcentagemDeMargem = (valorLucro / precoVendaComFrete) * 100
        } else if (marketplace.nome === 'Mercado Livre') {
          frete = calcularFreteParaMercadoLivre(
            selectedMarketplacesData,
            peso,
            descontoAtualFreteMercadoLivre,
            regrasPesoFreteMercadoLivre,
            precoVendaComFrete
          )
          valorComissao = precoVendaComFrete * (comissaoPercentual / 100)
          valorLucro =
            precoVendaComFrete -
            custo -
            valorComissao -
            frete -
            (marketplace.fixo || 0) -
            precoVendaComFrete * (ultimoImposto / 100)
          porcentagemDeMargem = (valorLucro / precoVendaComFrete) * 100
        } else if (marketplace.nome === 'Americanas') {
          frete = calcularFreteParaAmericanas(peso, precoVendaComFrete, regrasPorGrupoAmericanas)
          valorComissao = precoVendaComFrete * (comissaoPercentual / 100)
          valorLucro =
            precoVendaComFrete -
            custo -
            valorComissao -
            frete -
            (marketplace.fixo || 0) -
            precoVendaComFrete * (ultimoImposto / 100)
          porcentagemDeMargem = (valorLucro / precoVendaComFrete) * 100
        } else if (marketplace.nome === 'Via Varejo') {
          frete = calcularFreteParaAmericanas(peso, precoVendaComFrete, regrasPorGrupoViaVarejo)
          valorComissao = precoVendaComFrete * (comissaoPercentual / 100)
          valorLucro =
            precoVendaComFrete -
            custo -
            valorComissao -
            frete -
            (marketplace.fixo || 0) -
            precoVendaComFrete * (ultimoImposto / 100)
          porcentagemDeMargem = (valorLucro / precoVendaComFrete) * 100
        }

        setDynamicStateMargem({
          ...stateMargem,
          [marketplaceId]: porcentagemDeMargem.toFixed(2)
        })

        setDynamicStateLucro({
          ...stateLucro,
          [marketplaceId]: valorLucro.toFixed(2)
        })

        setDynamicStatePrecoVendaSugerido({
          ...statePrecoVendaSugerido,
          [marketplaceId]: precoVendaComFrete.toFixed(2)
        })

        if (marketplace.nome === 'Amazon') {
          setValorFreteAmazon(frete)
        } else if (marketplace.nome === 'Magalu') {
          setValorFreteMagalu(frete)
        } else if (marketplace.nome === 'Mercado Livre') {
          setValorFreteMercadoLivre(frete)
        } else if (marketplace.nome === 'Americanas') {
          setValorFreteAmericanas(frete)
        } else if (marketplace.nome === 'Via Varejo') {
          setValorFreteViaVarejo(frete)
        }
      }
    }
  }

  //--------------------PARA O CAMPO LUCRO-----------------------------------
  // Atualiza o estado do lucro no onChange
  function handleLucroChange(marketplaceId, event) {
    let novoLucro = event.target.value
    // troque , por . em novoLucro
    novoLucro = novoLucro.replace(',', '.')
    setDynamicStateLucro({
      ...stateLucro,
      [marketplaceId]: novoLucro
    })
  }

  // Calcula o preço de venda sugerido a partir do lucro informado

  function handleLucroBlur(marketplaceId) {
    let novoLucro = parseFloat(stateLucro[marketplaceId])

    novoLucro = novoLucro.toString().replace(',', '.')
    novoLucro = parseFloat(novoLucro)

    let marketplace = marketplacesWithComissoes.find(m => m._id === marketplaceId)
    marketplace = addFixoParameter(marketplace)
    function getComissaoByMarketplaceId(marketplacesWithComissoes, marketplaceId) {
      const marketplace = marketplacesWithComissoes.find(m => m._id === marketplaceId)
      return marketplace ? marketplace.comissao : null
    }

    const comissaoPercentual = getComissaoByMarketplaceId(marketplacesWithComissoes, marketplaceId)

    if (!isNaN(novoLucro) && novoLucro >= 0) {
      const custo = parseFloat(produtoSelecionado?.custo || 0)
      if (custo && !isNaN(comissaoPercentual)) {
        let precoVendaComFrete = 1 // Inicia com 1
        let incremento = 1 // Inicialmente, incrementa por 1
        let found = false

        while (!found && precoVendaComFrete < 300) {
          // limite arbitrário para evitar loops infinitos
          let frete = 0
          let valorComissao = precoVendaComFrete * (comissaoPercentual / 100)
          let valorLucro = 0

          if (marketplace.nome === 'Amazon') {
            frete = valorFretePorPeso(regrasPesoFreteMagalu, peso, precoVendaComFrete || 0)
            valorLucro =
              precoVendaComFrete -
              custo -
              valorComissao -
              frete -
              (marketplace.fixo || 0) -
              precoVendaComFrete * (ultimoImposto / 100)
          } else if (marketplace.nome === 'Magalu') {
            frete = calcularFreteParaMagalu(
              selectedMarketplacesData,
              peso,
              descontoAtualFreteMagalu,
              regrasPesoFreteMagalu
            )
            valorLucro =
              precoVendaComFrete -
              custo -
              valorComissao -
              frete -
              (marketplace.fixo || 0) -
              precoVendaComFrete * (ultimoImposto / 100)
          } else if (marketplace.nome === 'Mercado Livre') {
            frete = calcularFreteParaMercadoLivre(
              selectedMarketplacesData,
              peso,
              descontoAtualFreteMercadoLivre,
              regrasPesoFreteMercadoLivre,
              precoVendaComFrete
            )
            valorLucro =
              precoVendaComFrete -
              custo -
              valorComissao -
              frete -
              (marketplace.fixo || 0) -
              precoVendaComFrete * (ultimoImposto / 100)
          } else if (marketplace.nome === 'Americanas') {
            frete = calcularFreteParaAmericanas(peso, precoVendaComFrete, regrasPorGrupoAmericanas)
            valorLucro =
              precoVendaComFrete -
              custo -
              valorComissao -
              frete -
              (marketplace.fixo || 0) -
              precoVendaComFrete * (ultimoImposto / 100)
          } else if (marketplace.nome === 'Via Varejo') {
            frete = calcularFreteParaAmericanas(peso, precoVendaComFrete, regrasPorGrupoViaVarejo)
            valorLucro =
              precoVendaComFrete -
              custo -
              valorComissao -
              frete -
              (marketplace.fixo || 0) -
              precoVendaComFrete * (ultimoImposto / 100)
          }

          if (Math.abs(valorLucro - novoLucro) < 0.03) {
            let porcentagemDeMargem = (valorLucro / precoVendaComFrete) * 100

            setDynamicStateMargem({
              ...stateMargem,
              [marketplaceId]: porcentagemDeMargem.toFixed(2)
            })

            setDynamicStateLucro({
              ...stateLucro,
              [marketplaceId]: valorLucro.toFixed(2)
            })

            setDynamicStatePrecoVendaSugerido({
              ...statePrecoVendaSugerido,
              [marketplaceId]: precoVendaComFrete.toFixed(2)
            })

            if (marketplace.nome === 'Amazon') {
              setValorFreteAmazon(frete)
            } else if (marketplace.nome === 'Magalu') {
              setValorFreteMagalu(frete)
            } else if (marketplace.nome === 'Mercado Livre') {
              setValorFreteMercadoLivre(frete)
            } else if (marketplace.nome === 'Americanas') {
              setValorFreteAmericanas(frete)
            } else if (marketplace.nome === 'Via Varejo') {
              setValorFreteViaVarejo(frete)
            }

            found = true
          } else {
            if (valorLucro > novoLucro) {
              if (incremento === 1) {
                precoVendaComFrete -= 1
                incremento = 0.1
              } else {
                incremento = 0.1
              }
            }
            precoVendaComFrete += incremento
          }
        }
      }
    }
  }

  //---------------------------------------------------------------------------------------------------
  return (
    <div className="comissao-display-container" style={{ width: '62vw', left: '8vw' }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ width: '25vw' }}>Marketplace</th>
            <th>
              Margem %
              {assinaturaStatus !== 'ativa' && (
                <div style={{ color: 'red', fontSize: '0.8em' }}>
                  Assine o Plano para ter acesso.
                </div>
              )}
            </th>
            <th>
              Venda Sugerida
              {assinaturaStatus !== 'ativa' && (
                <div style={{ color: 'red', fontSize: '0.8em' }}>
                  Assine o Plano para ter acesso.
                </div>
              )}
            </th>
            <th>
              Lucro
              {assinaturaStatus !== 'ativa' && (
                <div style={{ color: 'red', fontSize: '0.8em' }}>
                  Assine o Plano para ter acesso.
                </div>
              )}
            </th>
            <th>Comissão</th>
            <th>Fixo</th>
            <th>Frete</th>
            <th>Imposto</th>
          </tr>
        </thead>
        <tbody>
          {selectedMarketplacesData.map(marketplace => {
            const freteDisplay =
              marketplace.nome === 'Magalu'
                ? valorFreteMagalu
                : marketplace.nome === 'Amazon'
                ? valorFreteAmazon
                : marketplace.nome === 'Mercado Livre'
                ? valorFreteMercadoLivre
                : marketplace.nome === 'Americanas'
                ? valorFreteAmericanas
                : marketplace.nome === 'Via Varejo'
                ? valorFreteViaVarejo
                : marketplace.frete || ''

            return (
              <tr key={marketplace._id}>
                <td className="comissao-texto" style={{ color: marketplace.cor }}>
                  {marketplace.nome}
                </td>
                <td>
                  <CustomField
                    value={stateMargem[marketplace._id] || ''}
                    onChange={e => handleMargemChange(marketplace._id, e)}
                    onBlur={() => handleMargemBlur(marketplace._id)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault() // Opcional: Previne ação padrão do Enter, se necessário.
                        handleMargemBlur(marketplace._id)
                      }
                    }}
                    onClear={() =>
                      setDynamicStateMargem({
                        ...stateMargem,
                        [marketplace._id]: ''
                      })
                    }
                    width="10vw"
                    editable={{
                      isEditable: assinaturaStatus === 'ativa',
                      alertMessage: 'Assine o Plano para ter acesso.'
                    }}
                  />
                </td>
                <td>
                  {' '}
                  <CustomField
                    value={statePrecoVendaSugerido[marketplace._id]}
                    onChange={e => handlePrecoVendaChange(marketplace._id, e)}
                    onBlur={() => handlePrecoVendaBlur(marketplace._id)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault() // Opcional: Previne ação padrão do Enter, se necessário.
                        handlePrecoVendaBlur(marketplace._id)
                      }
                    }}
                    onClear={() =>
                      setDynamicStatePrecoVendaSugerido({
                        ...statePrecoVendaSugerido,
                        [marketplace._id]: ''
                      })
                    }
                    width="10vw"
                    editable={{
                      isEditable: assinaturaStatus === 'ativa',
                      alertMessage: 'Assine o Plano para ter acesso.'
                    }}
                  />
                </td>
                <td>
                  <CustomField
                    value={stateLucro[marketplace._id] || ''}
                    onChange={e => handleLucroChange(marketplace._id, e)}
                    onBlur={() => handleLucroBlur(marketplace._id)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault() // Opcional: Previne ação padrão do Enter, se necessário.
                        handleLucroBlur(marketplace._id)
                      }
                    }}
                    onClear={() =>
                      setDynamicStateLucro({
                        ...stateLucro,
                        [marketplace._id]: ''
                      })
                    }
                    width="10vw"
                    editable={{
                      isEditable: assinaturaStatus === 'ativa',
                      alertMessage: 'Assine o Plano para ter acesso.'
                    }}
                  />
                </td>
                <td className="comissao-texto" style={{ color: marketplace.cor }}>
                  {marketplace.comissao}%
                </td>
                <td className="comissao-texto" style={{ color: marketplace.cor }}>
                  {marketplace?.fixo}
                </td>
                <td className="comissao-texto" style={{ color: marketplace.cor }}>
                  {freteDisplay}
                </td>
                <td className="comissao-texto" style={{ color: marketplace.cor }}>
                  {ultimoImposto}%
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default ProfitMarginCalculator
