import { fetchData } from '../../../../../src/backend/model'
import { config } from '../../../../../src/backend/configConexaoGeral'
export const carregarUltimoImposto = async (tenantId, nomeImposto, setUltimoImposto) => {
  try {
    const dados = {
      tenantId: tenantId,
      nome: nomeImposto,
      requisicao: 'carregarImpostoAtual'
    }

    let url = config.host + '/impostoPF'

    const response = await fetchData(url, dados)

    if (response.success && response.data) {
      setUltimoImposto(response.data.valor)
    } else {
      setUltimoImposto('Nenhum imposto cadastrado.')
    }
  } catch (error) {
    console.error('Erro ao carregar o último imposto:', error)
    setUltimoImposto('Erro ao carregar o imposto.')
  }
}
