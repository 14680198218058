import { useState, useContext } from 'react'
import ModuleConfirmationDialog from './ModuleConfirmationDialog'
import { AuthContext } from '../../../../src/context/AuthContext'
import { config } from '../../../backend/configConexaoGeral'
import { fetchData } from '../../../backend/model'
import fs from 'fs'

const CadastroModulo = () => {
  const [nomeModulo, setNomeModulo] = useState('')
  const [descricaoModulo, setDescricaoModulo] = useState('')
  const [precoModulo, setPrecoModulo] = useState('')
  const [erro, setErro] = useState('')
  const [sucesso, setSucesso] = useState('')
  const [showConfirmation, setShowConfirmation] = useState(false)
  const { RotaBase } = useContext(AuthContext)

  // Função para criar a pasta

  const handleOpenConfirmation = e => {
    e.preventDefault() // Previne a recarga da página
    setErro('') // Limpa mensagens de erro anteriores
    setShowConfirmation(true)
  }

  const handleCloseConfirmation = () => {
    setShowConfirmation(false)
    setErro('')
    setSucesso('')
  }

  const handleConfirmModule = async () => {
    // Limpar mensagens de erro e sucesso anteriores
    setErro('')
    setSucesso('')

    // URL base para envio de dados
    const url = `${config.host}/modulos`

    // Dados base para envio
    const dadosBase = {
      RotaBase,
      nomeModulo: nomeModulo.toLowerCase(),
      descricaoModulo,
      precoModulo: parseFloat(precoModulo)
    }

    try {
      // Etapas do fluxo com as respectivas requisições
      const etapas = [
        {
          requisicao: 'cadastrarNovoModulo',
          dados: { ...dadosBase }
        },
        {
          requisicao: 'criarPastaModulo',
          dados: { nomeModulo: nomeModulo.toUpperCase() }
        },
        {
          requisicao: 'adicionarModuloAoAppModelagemCompleta',
          dados: { nomeModulo: nomeModulo }
        },
        {
          requisicao: 'AdicionarRotaCadastroCliente',
          dados: { nomeModulo: nomeModulo }
        },
        {
          requisicao: 'AdicionarRotaPesquisaEdicaoCliente',
          dados: { nomeModulo: nomeModulo }
        }
      ]

      // Processar cada etapa sequencialmente
      for (const etapa of etapas) {
        const response = await fetchData(url, {
          requisicao: etapa.requisicao,
          ...etapa.dados
        })

        if (!response || !response.sucesso) {
          throw new Error(response.message || `Erro na etapa: ${etapa.requisicao}`)
        }

        // Exibir mensagem de sucesso retornada pelo response
        setSucesso(response.message)
        await new Promise(resolve => setTimeout(resolve, 3000)) // Pausa de 3 segundos
      }

      // Limpar campos após sucesso
      setSucesso('')
      setNomeModulo('')
      setDescricaoModulo('')
      setPrecoModulo('')
      setShowConfirmation(false)
    } catch (error) {
      console.error('Erro no processo:', error)
      setErro(error.message || 'Erro inesperado. Verifique sua conexão.')

      // Exibir mensagem de erro por 3 segundos
      setTimeout(() => {
        setErro('')
        setShowConfirmation(false)
      }, 3000)
    }
  }

  return (
    <div
      className="cadastro-modulo-container"
      style={{
        maxWidth: '400px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        position: 'relative'
      }}
    >
      {showConfirmation && (
        <ModuleConfirmationDialog
          nomeModulo={nomeModulo}
          descricaoModulo={descricaoModulo}
          precoModulo={precoModulo}
          onConfirm={handleConfirmModule}
          onCancel={handleCloseConfirmation}
          sucesso={sucesso}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 10
          }}
        />
      )}

      {/* Ocultar o formulário quando o diálogo de confirmação estiver visível */}
      {!showConfirmation && (
        <>
          <h2 style={{ marginTop: '50px' }}>Cadastrar Novo Módulo</h2>
          <form onSubmit={handleOpenConfirmation}>
            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="nomeModulo">Nome do Módulo:</label>
              <input
                type="text"
                id="nomeModulo"
                value={nomeModulo}
                onChange={e => setNomeModulo(e.target.value)}
                placeholder="Digite o nome do módulo"
                style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                required
              />
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="descricaoModulo">Descrição (Opcional):</label>
              <textarea
                id="descricaoModulo"
                value={descricaoModulo}
                onChange={e => setDescricaoModulo(e.target.value)}
                placeholder="Descreva brevemente o módulo"
                style={{ width: '100%', padding: '8px', marginTop: '5px', minHeight: '100px' }}
              />
            </div>

            <div style={{ marginBottom: '15px' }}>
              <label htmlFor="precoModulo">Preço Mensal:</label>
              <input
                type="number"
                id="precoModulo"
                value={precoModulo}
                onChange={e => setPrecoModulo(e.target.value)}
                placeholder="Digite o preço do módulo"
                min="0"
                step="0.01"
                style={{ width: '100%', padding: '8px', marginTop: '5px' }}
                // required
              />
            </div>

            {erro && (
              <div
                style={{
                  color: 'red',
                  marginBottom: '15px',
                  padding: '10px',
                  backgroundColor: '#ffebee',
                  borderRadius: '4px'
                }}
              >
                {erro}
              </div>
            )}

            <button
              type="submit"
              style={{
                width: '100%',
                padding: '10px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer'
              }}
            >
              Cadastrar Módulo
            </button>
          </form>
        </>
      )}
    </div>
  )
}

export default CadastroModulo
