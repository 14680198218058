import { useEffect, useState, useContext } from 'react'
import { FormGroup, Row, Col, Button, Alert } from 'react-bootstrap'
import sheetsConfig from '../../../../../src/config/sheetsConfig'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { fetchData } from '../../../../../src/backend/model'
import { config } from '../../../../../src/backend/configConexaoGeral'
import {
  ShowAlertCPF,
  ShowAlertCep,
  ShowAlertEmail,
  ShowAlertTel,
  ShowAlertCNPJ
} from './cadastro-alerts'
import {
  SelectSexo,
  SelectTipo,
  SelectCategoria,
  ImputName,
  SobrenomeImput,
  NomeEmpresaInput,
  InputCPForCNPJ,
  TelefoneImput,
  EmailImput,
  CepImput,
  EnderecoImput,
  NumeroImput,
  ComplementoImput,
  BairroImput,
  MunicipiosSelect,
  EstadosSelect,
  filterMunicipiosByCidade,
  filterMunicipiosByUF
} from './cadastro-fields.jsx'
import { useTodosMunicipiosBrasil } from '../../../../utils/municipios.js'
import { getUfValueByName } from '../../../../utils/address-utils'
import { AuthContext } from '../../../../../src/context/AuthContext'
import Banner from '../../../pages/CadastroGeral/Banner'
import AlertMessage from '../../../pages/PLOTAGEM/AlertMessage'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { SalvarAssociacaoClienteServico } from '../../../../../src/components/pages/AssociacaoClienteServico/associacao-cliente-servico'
import { handleForm } from '../../../../../src/components/pages/CadastroGeral/cadastro/cadastro-fields.js'
import {
  getAllUfs,
  lookupAddress,
  lookupCep,
  checkEnderecoExists,
  verificaBairroNaCidade,
  getUfNameByValue
} from '../../../../utils/address-utils.js'
import nlp from 'compromise'

function CadastroGeral({
  showCategory = true,
  isPlotagem = false,
  isCorte = false,
  isModelagem = false,
  isCadastroGeral = false,
  isEditing = false,
  isVisualizando = false,
  tipoPessoaProp = '',
  ...extraParams
}) {
  CadastroGeral.propTypes = {
    showCategory: PropTypes.bool,
    isPlotagem: PropTypes.bool,
    isCorte: PropTypes.bool,
    isModelagem: PropTypes.bool,
    isCadastroGeral: PropTypes.bool,
    isEditing: PropTypes.bool,
    isVisualizando: PropTypes.bool,
    tipoPessoaProp: PropTypes.string
  }
  const navigate = useNavigate()
  const location = useLocation()
  const todosMunicipiosBrasil = useTodosMunicipiosBrasil()
  const { clienteId } = useParams()
  const [nome, setNome] = useState('')
  const [sobrenome, setSobrenome] = useState('')
  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [isValidCpf, setisValidCpf] = useState(false)
  const [tipoPessoa, setTipoPessoa] = useState('')
  const [categoria, setCategoria] = useState('')
  const [sexo, setSexo] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')
  const [originalEmail, setOriginalEmail] = useState('')
  const [isValidEmail, setisValidEmail] = useState(false)
  const [cep, setCep] = useState('')
  const [isValidCep, setisValidCep] = useState(false)
  const [endereco, setEndereco] = useState('')
  const [numero, setNumero] = useState('')
  const [complemento, setComplemento] = useState('')
  const [bairro, setBairro] = useState('')
  const [cidade, setCidade] = useState('')
  const [uf, setUf] = useState('')
  const [ufs, setUfs] = useState([])
  const [isBlurred, setisBlurred] = useState(false)
  const [showAlert_cpf, setShowAlertCPF] = useState(false)
  const [showAlert_cep, setShowAlertCep] = useState(false)
  const [showAlert_email, setShowAlertEmail] = useState(false)
  const [showAlert_tel, setShowAlertTel] = useState(false)
  const [buscarCepAtivo, setBuscarCepAtivo] = useState(false)
  const [Municipios, setMunicipios] = useState([])
  const [codigoUf, setCodigoUf] = useState('')
  const [todosMunicipios, setTodosMunicipios] = useState([])
  const [isValidTel, setisValidTel] = useState(false)
  const [MunicipiosParaOSelectSource, setMunicipiosParaOSelectSource] = useState([])
  const [title, setTitle] = useState('')
  const [pegarCep, setpegarCep] = useState(false)
  const [cidadeSelecionada, setCidadeSelecionada] = useState('')
  const [searchType, setsearchType] = useState('')
  const [CepUpdateCounter, setCepUpdateCounter] = useState(0)
  const [selectedSector, setSelectedSector] = useState('')
  const [isValidCnpj, setisValidCnpj] = useState(false)
  const [showAlert_cnpj, setShowAlertCNPJ] = useState(false)
  const [nomeEmpresa, setNomeEmpresa] = useState('')
  const [clienteEditando, setClienteEditando] = useState(null)

  const [clienteData, setClienteData] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [showAlertGravando, setShowAlertGravando] = useState(false)

  const [AddressUpdateStatus, setAddressUpdateStatus] = useState(false)
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [tipoPessoa2, setTipoPessoa2] = useState('')
  const [showAlertGravandoState, setShowAlertGravandoState] = useState(false)
  const dispatch = useDispatch()

  const handleCloseAlert = () => {
    setShowAlert(false)
  }
  const handleTipoPessoaChange = e => {
    setTipoPessoa2(e.target.value)
  }

  useEffect(() => {
    if (todosMunicipiosBrasil.length > 0) {
      setTodosMunicipios(todosMunicipiosBrasil)
      const ufs = getAllUfs()

      setUfs(ufs)
    }
  }, [todosMunicipiosBrasil])

  useEffect(() => {
    if (tipoPessoaProp) {
      setTipoPessoa(tipoPessoaProp)
    }
  }, [tipoPessoaProp])

  useEffect(() => {
    async function setAddress(data) {
      console.log('data:', data)
      setpegarCep(true)
      setEndereco(data.logradouro)
      setBairro(data.bairro)
      setUf(getUfNameByValue(data.uf))
      setCidade({ cidade: data.localidade, source: 'function' })
      setCidadeSelecionada(data.localidade)
      const municipiosDaCidade = filterMunicipiosByCidade(todosMunicipios, data.localidade, ufs)

      setMunicipios(municipiosDaCidade)
      setTitle(data.localidade)
      setpegarCep(false)
    }

    async function fetchData() {
      if (isValidCep && cep.length === 9 && searchType === 'cep') {
        const addressPromise = lookupAddress(cep)
        addressPromise
          .then(data => setAddress(data))
          .catch(error => console.error('erro aqui ', error))
      }
    }

    fetchData()
  }, [cep, isValidCep, searchType])

  useEffect(() => {
    const fetchCep = async () => {
      try {
        if (searchType === 'address' && endereco !== '' && cidade.cidade !== '' && uf !== '') {
          const data = await lookupCep(getUfValueByName(uf), cidade.cidade, endereco, setpegarCep)
          if (!data.erro) {
            setCep(data[0].cep)
            setBairro(data[0].bairro)
            setisValidCep(true)
          }
        }
      } catch (error) {
        setisValidCep(false)
        setCep('')
        console.error(`Erro ao buscar CEP: ${error}`)
      }
    }

    if (endereco !== '' && cidade && cidade.cidade !== '' && uf !== '') {
      fetchCep()
    }
  }, [endereco, cidade, uf, searchType])

  useEffect(() => {
    async function handleMunicipioChange(cidade) {
      const enderecoExists = await checkEnderecoExists(uf, cidade.cidade, endereco)
      if (!enderecoExists) {
        setEndereco('')
        setCep('')
      } else {
        if (cep !== '') {
          const cepExists = await checkEnderecoExists(uf, cidade.cidade, cep)
          if (!cepExists) {
            setCep('')
          }
        }
      }
      if (bairro !== '') {
        const bairroExiste = await verificaBairroNaCidade(uf, cidade.cidade, bairro)
        if (!bairroExiste) {
          setBairro('')
        }
      }
    }

    if (cidade && cidade.source === 'user') handleMunicipioChange(cidade)
  }, [cidade])

  useEffect(() => {
    if (isPlotagem) {
      setCategoria('Plotagem')
      setSelectedSector('plotagem')
    }
  }, [isPlotagem])

  useEffect(() => {
    if (isCorte) {
      setCategoria('Corte')
      setSelectedSector('cortes')
    }
  }, [isCorte])

  useEffect(() => {
    if (isModelagem) {
      setCategoria('Modelagem')
      setSelectedSector('modelagem')
    }
  }, [isModelagem])

  useEffect(() => {
    if (isCadastroGeral) {
      setSelectedSector('cadastroClienteG')
    }
  }, [isCadastroGeral])

  useEffect(() => {
    if (isEditing && clienteEditando) {
      setNome(clienteEditando.cliente.Nome || '')
      setSobrenome(clienteEditando.cliente.Sobrenome || '')
      setCpf(clienteEditando.cliente.CPF || '')
      setCnpj(clienteEditando.cliente.CNPJ || '')
      setTipoPessoa(clienteEditando.cliente.Tipo_Pessoa || '')
      setCategoria(clienteEditando.cliente.Categoria_Cliente || '')
      setSexo(clienteEditando.cliente.Sexo || '')
      setTelefone(clienteEditando.cliente.Tel || '')
      setEmail(clienteEditando.cliente.Email || '')
      setOriginalEmail(clienteEditando.cliente.Email || '')
      setCep(clienteEditando.cliente.Cep || '')
      setEndereco(clienteEditando.cliente.Endereco || '')
      setNumero(clienteEditando.cliente.Numero || '')
      setComplemento(clienteEditando.cliente.Complemento || '')
      setBairro(clienteEditando.cliente.Bairro || '')

      setCidade({
        value: clienteEditando.cliente.Cidade || '',
        label: clienteEditando.cliente.Cidade || '',
        source: 'initial'
      }) // Certifique-se que a cidade está no formato correto
      setUf(getUfNameByValue(clienteEditando.cliente.UF) || '')
      setNomeEmpresa(clienteEditando.cliente.Empresa || '')
    }
  }, [clienteEditando])

  // Controle da visualização

  useEffect(() => {
    if (isVisualizando && clienteEditando) {
      setNome(clienteEditando.cliente.Nome || '')
      setSobrenome(clienteEditando.cliente.Sobrenome || '')
      setCpf(clienteEditando.cliente.CPF || '')
      setCnpj(clienteEditando.cliente.CNPJ || '')
      setTipoPessoa(clienteEditando.cliente.Tipo_Pessoa || '')
      setCategoria(clienteEditando.cliente.Categoria_Cliente || '')
      setSexo(clienteEditando.cliente.Sexo || '')
      setTelefone(clienteEditando.cliente.Tel || '')
      setEmail(clienteEditando.cliente.Email || '')
      setCep(clienteEditando.cliente.Cep || '')
      setEndereco(clienteEditando.cliente.Endereco || '')
      setNumero(clienteEditando.cliente.Numero || '')
      setComplemento(clienteEditando.cliente.Complemento || '')
      setBairro(clienteEditando.cliente.Bairro || '')
      setCidade({
        value: clienteEditando.cliente.Cidade || '',
        label: clienteEditando.cliente.Cidade || '',
        source: 'initial'
      })
      setUf(getUfNameByValue(clienteEditando.cliente.UF) || '')
      setNomeEmpresa(clienteEditando.cliente.Empresa || '')
    }
  }, [isVisualizando, clienteEditando])

  // Função para converter a estrutura dos municípios para ser compatível com o Select
  function convertMunicipiosForSelect(municipios) {
    return municipios.map(municipio => ({
      label: municipio.nome, // 'label' será o nome do município
      value: municipio.codigo_ibge // 'value' será o código IBGE do município
    }))
  }

  useEffect(() => {
    if (uf && todosMunicipiosBrasil.length > 0 && isEditing) {
      const todosufs = getAllUfs()

      const municipiosDoEstado = filterMunicipiosByUF(
        todosMunicipiosBrasil,
        clienteEditando.cliente.UF,
        todosufs
      )

      const municipiosParaSelect = convertMunicipiosForSelect(municipiosDoEstado)

      setMunicipios(municipiosParaSelect)
      setMunicipiosParaOSelectSource(municipiosParaSelect)
    }
  }, [uf, todosMunicipiosBrasil, clienteEditando, ufs])

  useEffect(() => {
    if (!isEditing) {
      setMunicipiosParaOSelectSource(() => {
        const nomesMunicipios = Municipios.map(municipio => ({
          label: municipio.nome,
          value: municipio.codigo_ibge
        }))
        return nomesMunicipios
      })
      setTitle('Escolha a cidade')
    }
  }, [Municipios, cidadeSelecionada])

  async function CarregarClienteDoServico() {
    const dados = {
      rota_base: RotaBase,
      tenantId: tenantId,
      id: clienteId,
      requisicao: 'CarregarClienteDoServico'
    }

    try {
      const url = config.host + '/cadastroGeral'
      const response = await fetchData(url, dados)
      if (response.success) {
        console.log(response.message)
        return response
      } else {
        console.error('Erro:', response.message)
        return null
      }
    } catch (error) {
      console.error('Erro ao chamar a função no servidor:', error)
      return null
    }
  }

  useEffect(() => {
    if (isEditing || isVisualizando) {
      const fetchCliente = async () => {
        const cliente = await CarregarClienteDoServico()

        setClienteEditando(cliente)
      }
      fetchCliente()
    }
  }, [isEditing, isVisualizando])

  useEffect(() => {
    if (uf && todosMunicipiosBrasil.length > 0 && !isEditing) {
      const municipiosDoEstado = filterMunicipiosByCidade(todosMunicipiosBrasil, uf, ufs)

      // const municipiosParaSelect = convertMunicipiosForSelect(municipiosDoEstado)

      setMunicipios(municipiosDoEstado)
      setMunicipiosParaOSelectSource(municipiosDoEstado)
    }
  }, [uf, todosMunicipiosBrasil])

  const handleSubmit = async e => {
    e.preventDefault()
    setShowAlertGravandoState(true)
    let rota = 'cadastroGeral'
    if (isEditing) {
      rota = 'funcoesGenericas'
    }

    const retorno = await handleForm(
      isEditing,
      {
        Nome: nome,
        Sobrenome: sobrenome,
        Empresa: nomeEmpresa,
        CPF: cpf,
        CNPJ: cnpj,
        Tipo_Pessoa: tipoPessoa,
        Categoria_Cliente: categoria,
        Sexo: sexo,
        Tel: telefone,
        Email: email,
        Cep: cep,
        Endereco: endereco,
        Numero: numero,
        Complemento: complemento,
        Bairro: bairro,
        Cidade: cidade,
        UF: uf ? getUfValueByName(uf) : null,
        Data: new Date(),
        tenantId: tenantId,
        rota_base: RotaBase
      },
      clienteId,
      clienteData,
      null,
      null,
      {},
      sheetsConfig[selectedSector].sheetUrl,
      sheetsConfig[selectedSector].tabs.clientes,
      'enviar',
      '',
      { rota: rota }
    )

    if (!isEditing) {
      await salvarAssociacaoServicoCliente({
        isPlotagem,
        isCorte,
        isModelagem,
        retorno,
        isAssocieParaTodos: true,
        tenantId
      })
    } else {
      const params = new URLSearchParams(location.search)
      const pesquisa = params.get('pesquisa')

      //voltar para pagina de ediçao do cliente
      setTimeout(() => {
        navigate(`/corte-pesquisa-e-edicao-cliente?pesquisa=${pesquisa}`)
      }, 2000)
    }

    setShowAlertGravandoState(false)
    setShowAlert(true)

    setTimeout(() => {
      setShowAlert(false)
      setNome('')
      setSobrenome('')
      setBairro('')
      setNumero('')
      setCep('')
      setCpf('')
      setTelefone('')
      setEmail('')
      setComplemento('')
      setEndereco('')
      setCategoria('')
      setTipoPessoa('')
      setCidade('')
      setUf('')
    }, 2000)
  }
  const activeParams = Object.entries(extraParams).filter(([key, value]) => value)
  // function formatParameterName(param) {
  //   if (!param.startsWith('is')) {
  //     return param.toUpperCase() // Se não começar com "is", retorna apenas em maiúsculas
  //   }
  //   const formatted = param
  //     .slice(2) // Remove o prefixo "is"
  //     .replace(/([A-Z])/g, ' $1') // Adiciona espaço antes de cada letra maiúscula
  //     .trim() // Remove espaços desnecessários no início ou fim
  //   return formatted.toUpperCase() // Converte para maiúsculas
  // }
  function formatParameterName(param) {
    if (!param.startsWith('is')) {
      return param.toUpperCase() // Caso não comece com "is", retorna como está
    }

    // Remove o prefixo "is"
    const coreString = param.slice(2).toLowerCase()
    console.log('coreString:', coreString)
    // Usa Compromise para tokenizar
    const doc = nlp(coreString)
    console.log('doc:', doc)
    const tokens = doc.terms().out('array') // Divide o texto em palavras
    console.log('tokens:', tokens)
    // Formata cada palavra para Capital Case
    const formatted = tokens.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') // Junta com espaços
    console.log('formatted:', formatted)
    return formatted
  }
  return (
    <div className="d-flex align-items-center form-container" style={{ height: '100vh' }}>
      <div className="text-center mx-auto" style={{ maxWidth: '2000px' }}>
        {activeParams.map(([key]) => (
          <div key={key} className="title-container">
            <Banner title={formatParameterName(key)} />
          </div>
        ))}
        {isPlotagem && (
          <div className="title-container">
            <Banner title="PLOTAGEM" />
          </div>
        )}
        {isCorte && (
          <div className="title-container">
            <Banner title="CORTE" />
          </div>
        )}
        {isModelagem && (
          <div className="title-container">
            <Banner title="MODELAGEM" />
          </div>
        )}
        {isCadastroGeral && (
          <div className="title-container">
            <Banner title="CADASTRO GERAL" />
          </div>
        )}
        <h2 style={{ marginBottom: '3rem' }}>
          {isVisualizando
            ? 'VISUALIZANDO CADASTRO'
            : showCategory
            ? 'NOVO CADASTRO GERAL'
            : 'CADASTRO CLIENTE'}
          {isEditing && (
            <>
              {' - '}
              <span style={{ color: '#F25C05' }}>Modo de Edição</span>
            </>
          )}
        </h2>

        <ShowAlertCPF
          message="Por favor, insira um número de CPF válido"
          showAlert_cpf={showAlert_cpf}
          isBlurred={isBlurred}
          setShowAlertCPF={setShowAlertCPF}
        />
        <ShowAlertCNPJ
          message="Por favor, insira um número de CNPJ válido"
          showAlert_cnpj={showAlert_cnpj}
          isBlurred={isBlurred}
          setShowAlertCNPJ={setShowAlertCNPJ}
        />
        <ShowAlertCep
          message="Por favor, insira um número de CEP válido"
          showAlert_cep={showAlert_cep}
          isBlurred={isBlurred}
          setShowAlertCep={setShowAlertCep}
        />
        <ShowAlertEmail
          message="Por favor, insira um endereço de email válido!"
          showAlert_email={showAlert_email}
          isBlurred={isBlurred}
          setShowAlertEmail={setShowAlertEmail}
        />
        <ShowAlertTel
          message="Por favor, insira um número de telefone válido"
          showAlert_tel={showAlert_tel}
          isBlurred={isBlurred}
          setShowAlertTel={setShowAlertTel}
        />
        {showAlertGravandoState && (
          <AlertMessage
            message={isEditing ? 'Editando dados' : 'Gravando dados'}
            size="x-large"
            customHeight={100}
            variant1="primary"
            variant2="light"
            variantDefault="success"
            isBlinking={false}
          />
        )}
        {showAlert && (
          <Alert variant="success" onClose={handleCloseAlert} dismissible>
            <Alert.Heading>
              {isEditing ? 'Cadastro editado com sucesso!' : 'Cadastro realizado com sucesso!'}
            </Alert.Heading>
          </Alert>
        )}

        <FormGroup>
          <Row>
            <Col md={4} className="mb-3">
              <SelectTipo
                initialValue={clienteEditando && clienteEditando.cliente.Tipo_Pessoa}
                tipoPessoa={tipoPessoa}
                setTipoPessoa={setTipoPessoa}
                disabled={isVisualizando}
              />
            </Col>

            {showCategory && (
              <Col md={4} className="mb-3">
                <SelectCategoria
                  initialValue={clienteEditando && clienteEditando.cliente.Categoria_Cliente}
                  categoria={categoria}
                  setCategoria={setCategoria}
                  disabled={isVisualizando}
                />
              </Col>
            )}
            {tipoPessoa === 'Pessoa Física' && (
              <Col md={3} className="mb-3">
                <SelectSexo
                  initialValue={clienteEditando && clienteEditando.cliente.Sexo}
                  sexo={sexo}
                  setSexo={setSexo}
                  disabled={isVisualizando}
                />
              </Col>
            )}
          </Row>

          <Row>
            {tipoPessoa === 'Pessoa Física' && (
              <>
                <Col md={4} className="mb-3">
                  <ImputName
                    initialValue={clienteEditando && clienteEditando.cliente.Nome}
                    nome={nome}
                    setNome={setNome}
                    setSobrenome={setSobrenome}
                    setSexo={setSexo}
                    disabled={isVisualizando}
                  />
                </Col>
                <Col md={5} style={{ maxWidth: '349px' }} className="mb-3">
                  <SobrenomeImput
                    initialValue={clienteEditando && clienteEditando.cliente.Sobrenome}
                    sobrenome={sobrenome}
                    setSobrenome={setSobrenome}
                    disabled={isVisualizando}
                  />
                </Col>
                <Col md={3} style={{ maxWidth: '173px' }} className="mb-3">
                  <InputCPForCNPJ
                    initialValue={
                      clienteEditando &&
                      (clienteEditando.cliente.Tipo_Pessoa === 'Pessoa Física'
                        ? clienteEditando.cliente.CNPJ
                        : clienteEditando.cliente.CPF)
                    }
                    cpf={cpf}
                    setCpf={setCpf}
                    cnpj={cnpj}
                    setCnpj={setCnpj}
                    isValidCpf={isValidCpf}
                    setisValidCpf={setisValidCpf}
                    isValidCnpj={isValidCnpj}
                    setisValidCnpj={setisValidCnpj}
                    isBlurred={isBlurred}
                    setisBlurred={setisBlurred}
                    setShowAlertCPF={setShowAlertCPF}
                    showAlert_cpf={showAlert_cpf}
                    setShowAlertCNPJ={setShowAlertCNPJ}
                    showAlert_cnpj={showAlert_cnpj}
                    tipoPessoa={tipoPessoa}
                  />
                </Col>
              </>
            )}

            {tipoPessoa === 'Pessoa Jurídica' && (
              <>
                <Col md={6} className="mb-3">
                  <NomeEmpresaInput
                    initialValue={clienteEditando && clienteEditando.cliente.Empresa}
                    nomeEmpresa={nomeEmpresa}
                    setNomeEmpresa={setNomeEmpresa}
                  />
                </Col>

                <Col md={3} style={{ maxWidth: '173px' }} className="mb-3">
                  <InputCPForCNPJ
                    initialValue={
                      clienteEditando &&
                      (clienteEditando.cliente.Tipo_Pessoa === 'Pessoa Jurídica'
                        ? clienteEditando.cliente.CNPJ
                        : clienteEditando.cliente.CPF)
                    }
                    cpf={cpf}
                    setCpf={setCpf}
                    cnpj={cnpj}
                    setCnpj={setCnpj}
                    isValidCpf={isValidCpf}
                    setisValidCpf={setisValidCpf}
                    isValidCnpj={isValidCnpj}
                    setisValidCnpj={setisValidCnpj}
                    isBlurred={isBlurred}
                    setisBlurred={setisBlurred}
                    setShowAlertCPF={setShowAlertCPF}
                    showAlert_cpf={showAlert_cpf}
                    setShowAlertCNPJ={setShowAlertCNPJ}
                    showAlert_cnpj={showAlert_cnpj}
                    tipoPessoa={tipoPessoa}
                  />
                </Col>
              </>
            )}
          </Row>

          <Row>
            <Col md={4} className="mb-3">
              <TelefoneImput
                initialValue={clienteEditando && clienteEditando.cliente.Tel}
                telefone={telefone}
                setTelefone={setTelefone}
                isValidTel={isValidTel}
                setisValidTel={setisValidTel}
                setisBlurred={setisBlurred}
                setShowAlertTel={setShowAlertTel}
                disabled={isVisualizando}
              />
            </Col>
            <Col md={7} className="mb-3">
              <EmailImput
                initialValue={clienteEditando && clienteEditando.cliente.Email}
                email={email}
                setEmail={setEmail}
                isValidEmail={isValidEmail}
                setisValidEmail={setisValidEmail}
                setisBlurred={setisBlurred}
                setShowAlertEmail={setShowAlertEmail}
                originalEmail={originalEmail}
                setOriginalEmail={setOriginalEmail}
                disabled={isVisualizando}
              />
            </Col>
          </Row>

          <Row>
            <Col md={2} className="mb-3" style={{ display: 'flex' }}>
              <CepImput
                initialValue={clienteEditando && clienteEditando.cliente.Cep}
                cep={cep}
                setCep={setCep}
                isValidCep={isValidCep}
                setisValidCep={setisValidCep}
                setShowAlertCep={setShowAlertCep}
                showAlert_cep={showAlert_cep}
                setisBlurred={setisBlurred}
                isBlurred={isBlurred}
                setpegarCep={setpegarCep}
                setCepUpdateCounter={setCepUpdateCounter}
                setAddressUpdateStatus={setAddressUpdateStatus}
                setsearchType={setsearchType}
                searchType={searchType}
                disabled={isVisualizando}
              />
            </Col>
          </Row>

          <Row>
            <Col md={5} className="mb-3">
              <EnderecoImput
                initialValue={clienteEditando && clienteEditando.cliente.Endereco}
                endereco={endereco}
                setEndereco={setEndereco}
                setUf={setUf}
                setCep={setCep}
                setpegarCep={setpegarCep}
                setAddressUpdateStatus={setAddressUpdateStatus}
                setsearchType={setsearchType}
                disabled={isVisualizando}
              />
            </Col>
            <Col md={2} className="mb-3">
              <NumeroImput
                initialValue={clienteEditando && clienteEditando.cliente.Numero}
                numero={numero}
                setNumero={setNumero}
                disabled={isVisualizando}
              />
            </Col>
            <Col md={4} className="mb-3">
              <ComplementoImput
                initialValue={clienteEditando && clienteEditando.cliente.Complemento}
                complemento={complemento}
                setComplemento={setComplemento}
                disabled={isVisualizando}
              />
            </Col>
          </Row>

          <Row>
            <Col md={3} className="mb-3">
              <BairroImput
                initialValue={clienteEditando && clienteEditando.cliente.Bairro}
                bairro={bairro}
                setBairro={setBairro}
                disabled={isVisualizando}
              />
            </Col>
            <Col md={4} className="mb-3">
              <EstadosSelect
                initialValue={clienteEditando && clienteEditando.cliente.UF}
                uf={uf}
                setUf={setUf}
                ufs={ufs}
                Municipios={Municipios}
                setMunicipios={setMunicipios}
                setTitle={setTitle}
                todosMunicipios={todosMunicipios}
                cidade={cidade}
                setpegarCep={setpegarCep}
                setCidadeSelecionada={setCidadeSelecionada}
                disabled={isVisualizando}
              />
            </Col>
            <Col md={5} className="mb-3">
              <MunicipiosSelect
                setCidadeSelecionada={setCidadeSelecionada}
                initialValue={clienteEditando && clienteEditando.cliente.Cidade}
                Municipios={Municipios}
                title={title}
                setTitle={setTitle}
                cidadeState={cidade}
                MunicipiosParaOSelectSource={MunicipiosParaOSelectSource}
                setMunicipiosParaOSelectSource={setMunicipiosParaOSelectSource}
                cidadeSelecionada={cidadeSelecionada}
                setCidade={setCidade}
                pegarCep={pegarCep}
                disabled={isVisualizando}
              />
            </Col>
          </Row>

          {!isVisualizando && (
            <Row>
              <Col md={12} className="mb-3">
                <Button
                  variant="success"
                  type="submit"
                  style={{ width: '40%' }}
                  onClick={handleSubmit}
                >
                  {isEditing ? 'Salvar Alterações' : 'Cadastrar'}
                </Button>
              </Col>
            </Row>
          )}
        </FormGroup>
      </div>
    </div>
  )
}

export default CadastroGeral

async function salvarAssociacaoServicoCliente({
  isPlotagem,
  isCorte,
  isModelagem,
  retorno,
  isAssocieParaTodos,
  tenantId
}) {
  let id_servico

  if (isAssocieParaTodos) {
    id_servico = [1, 3, 4]
  } else {
    if (isPlotagem) {
      id_servico = 4
    }
    if (isCorte) {
      id_servico = 3
    }
    if (isModelagem) {
      id_servico = 1
    }
  }

  let cadastroDados
  console.log('retorno:', retorno)
  if (Array.isArray(id_servico)) {
    cadastroDados = id_servico.map(id => ({
      ID_Cliente: retorno.mongodbIds,
      id_servico: id,
      ativo: true
    }))
  } else if (id_servico) {
    cadastroDados = [
      {
        ID_Cliente: retorno.mongodbIds,
        id_servico: id_servico,
        ativo: true
      }
    ]
  } else {
    cadastroDados = []
  }

  await SalvarAssociacaoClienteServico(false, cadastroDados, {
    rota: 'cadastroGeral',
    tenantId
  })
}
