// edicao-produto
import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Button, FormGroup } from 'react-bootstrap'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import CustomTable from '../../../../../../src/components/CustomTable/custom-table' // Certifique-se de importar corretamente o CustomTable
import { fetchData } from '../../../../../../src/backend/model'
import { AuthContext } from '/src/context/AuthContext'
import { config } from '/src/backend/configConexaoGeral'

const EdicaoProduto = () => {
  const [dadosEdicao, setDadosEdicao] = useState({})
  const [updatedData, setUpdatedData] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const { tenantId } = useContext(AuthContext)
  const {
    data: produtos,
    isLoading: isLoadingProdutos,
    refetch: refetchProdutos,
    isError: isErrorCarregaProdutos
  } = useQuery(
    'produtos',
    () => CarregarProdutos(tenantId), // Sua função para buscar categorias
    {
      refetchOnWindowFocus: false
    }
  )
  useEffect(() => {
    setUpdatedData(produtos)
  }, [produtos])

  // Definir cabeçalhos da tabela e outras configurações
  const headerCells = [
    { label: 'Sku', dataKey: 'sku', width: '30%', edit: true },
    { label: 'Descrição', dataKey: 'descricao', width: '40%', edit: true },
    { label: 'Gramas', dataKey: 'gramas', width: '15%', edit: true },
    { label: 'Custo', dataKey: 'custo', width: '15%', edit: true }
  ]

  return (
    <div style={{ position: 'relative' }}>
      <div
        className="d-flex flex-column align-items-center form-container"
        style={{ marginTop: '30px' }}
      >
        <Banner title="Gerenciamento de Produto" color="#2e8b57" width="32%" fontSize={50} />
      </div>

      <FormGroup
        style={{
          maxWidth: '1000px',
          margin: '-10px auto 0 auto',
          paddingTop: '-10px'
        }}
      >
        <CustomTable
          headerCells={headerCells}
          data={produtos}
          updatedData={updatedData}
          setUpdatedData={setUpdatedData}
          dadosEdicao={dadosEdicao}
          setDadosEdicao={setDadosEdicao}
          isDeleteEdit={{
            configKeySheet: 'produtoPF',
            exclusaoConfig: {
              tab: 'cadastro_produtoPF',
              invalidateQueries: 'produtos'
            },
            deletaDoBanco: true
          }}
          width="970px"
          tableHeight="750px"
        />
      </FormGroup>
    </div>
  )
}

export default EdicaoProduto
//--------------------------------------------------------------------------------------------
export async function CarregarProdutos(tenantId) {
  try {
    let dados = {
      requisicao: 'CarregarProdutosPF',
      tenantId: tenantId
    }
    let url = config.host + '/produtoPF'

    let response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao carregar produtos:', error)
    // Trate o erro conforme necessário
  }
}
