import { DraggableContainer } from '/src/components/DraggableContainer/draggable-container'
import { Button } from 'react-bootstrap'
import AlertMessage from '/src/components/pages/PLOTAGEM/AlertMessage'
export const ButtonWithAlerts = ({
  showAlertGravando,
  showAlert,
  components,
  handleSubmit,
  buttonPosition,
  handleCloseAlert,
  draggableId,
  variant = 'success',
  buttonLabel = 'Cadastrar',
  width,
  message = 'Gravando',
  size = 'alert-small',
  className = '',
  componentType,
  positionConfig
}) => {
  if (showAlertGravando) {
    return (
      <AlertMessage
        message={message}
        size={size}
        customHeight={70}
        customWidth={170}
        variant1="warning"
        variant2="light"
        isBlinking={true}
      />
    )
  }

  if (showAlert) {
    return (
      <AlertMessage
        message="Sucesso!"
        showDots={false}
        size="alert-small"
        customHeight={70}
        customWidth={170}
        top={buttonPosition?.y}
        left={buttonPosition?.x}
        variantDefault="success"
        onClose={handleCloseAlert}
        isBlinking={false}
      />
    )
  }

  // Se o draggableId foi fornecido, retorne o Button envolvido pelo DraggableContainer
  if (draggableId) {
    return (
      <DraggableContainer
        id={draggableId}
        components={components}
        width={width}
        type={componentType}
        positionConfig={positionConfig}
      >
        <Button
          type="submit"
          className={'form-control btn-success btnSubmit ' + className}
          style={{
            marginTop: '30px',
            width: width ? width : 'auto'
          }}
          onClick={handleSubmit}
        >
          {buttonLabel}
        </Button>
      </DraggableContainer>
    )
  }

  // Se não, apenas retorne o Button
  return (
    <Button
      type="submit"
      className="form-control btn-success btnSubmit"
      style={{
        width: width ? width : 'auto'
      }}
      variant={variant}
      onClick={handleSubmit}
    >
      {buttonLabel}
    </Button>
  )
}
