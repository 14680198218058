// planos-e-precos-moda.jsx

import { useNavigate, useLocation } from 'react-router-dom'
import { NavbarModelagemcompleta } from './NavbarModelagemcompleta.jsx'
import logoTopoModelagemCompleta from '../Usuarios/login/logo_topo.jpg'
import logoModaNegocios360 from '../Usuarios/login/4.png'
import nomeModaNegocios360 from '../Usuarios/login/6.png'
import { useState } from 'react'
function PlanosEPrecosModa() {
  const navigate = useNavigate()
  const location = useLocation()
  const hostname = window.location.hostname
  const pathname = location.pathname

  const isModaNegocios360 =
    (hostname && hostname.includes('moda.negocios360')) ||
    (pathname && pathname.includes('moda.negocios360'))
  const logoTopo = isModaNegocios360 ? logoModaNegocios360 : logoTopoModelagemCompleta

  // Definindo alturas com base na condição
  const logoTopoHeight = isModaNegocios360 ? '55px' : '60px'
  const logoTopoWidth = isModaNegocios360 ? '65px' : '70px'
  const titleHeight = isModaNegocios360 ? '60px' : undefined

  // Dados dos planos
  const planos = [
    {
      nome: 'Módulo de Corte',
      precoMensal: 129.9,
      precoTrimestral: parseFloat((129.9 * 3 * 0.9).toFixed(2)), // 10% de desconto
      precoSemestral: parseFloat((129.9 * 6 * 0.85).toFixed(2)), // 15% de desconto
      descricao: 'Gerencie todas as etapas do processo de corte com eficiência e precisão.',
      beneficios: [
        'Cadastro de Clientes e Funcionários',
        'Gerenciamento de Cortes e Pontualidade',
        'Controle Financeiro e Cadastro de Pagamentos',
        'Relatórios Detalhados de Cortes e Eficiência'
      ],
      funcionalidadesCompletas: [
        'Backup automático de dados',
        'Suporte prioritário',
        'Acesso multiusuário com diferentes níveis de permissão',
        'Relatórios avançados de desempenho',
        'Dashboard interativo para monitoramento de desempenho'
      ]
    },
    {
      nome: 'Módulo de Modelagem',
      precoMensal: 59.9,
      precoTrimestral: parseFloat((59.9 * 3 * 0.9).toFixed(2)),
      precoSemestral: parseFloat((59.9 * 6 * 0.85).toFixed(2)),
      descricao: 'Ferramentas avançadas para cadastro e acompanhamento de pedidos de modelagem.',
      beneficios: [
        'Cadastro de Pedidos de Modelagem',
        'Gerenciamento de Valores e Clientes',
        'Integração com Outros Módulos',
        'Ajuste de Layout dos Componentes'
      ],
      funcionalidadesCompletas: [
        'Suporte prioritário',
        'Backup automático de dados',
        'Relatórios avançados de desempenho',
        'Gestão de versões de modelos',
        'Controle de revisões e aprovações',
        'Acesso multiusuário com diferentes níveis de permissão'
      ]
    },
    {
      nome: 'Módulo de Plotagem',
      precoMensal: 59.9,
      precoTrimestral: parseFloat((59.9 * 3 * 0.9).toFixed(2)),
      precoSemestral: parseFloat((59.9 * 6 * 0.85).toFixed(2)),
      descricao: 'Gerencie serviços de plotagem com controle financeiro completo.',
      beneficios: [
        'Cadastro e Gerenciamento de Plotagens',
        'Resumo Financeiro dos Serviços de Plotagem',
        'Integração com Módulos Financeiros',
        'Controle de Valores Pendentes e Pagos'
      ],
      funcionalidadesCompletas: [
        'Suporte prioritário',
        'Backup automático de dados',
        'Monitoramento em tempo real das plotagens',
        'Relatórios de eficiência e produtividade',
        'Notificações de status de plotagem',
        'Configuração de perfis de usuário personalizados',
        'Acesso multiusuário com diferentes níveis de permissão'
      ]
    }
  ]

  // Estado para controlar quais planos estão expandidos
  const [planosExpandidos, setPlanosExpandidos] = useState({})

  // Função para alternar a expansão de um plano
  const toggleExpandirPlano = index => {
    setPlanosExpandidos(prev => ({
      ...prev,
      [index]: !prev[index]
    }))
  }

  return (
    <div className="planos-e-precos-wrapper">
      <NavbarModelagemcompleta
        setShowAlertMessage={() => {}}
        setAlertMessage={() => {}}
        logoTopo={logoTopo}
        logoTopoHeight={logoTopoHeight}
        logoTopoWidth={logoTopoWidth}
        title={
          isModaNegocios360 ? (
            <img src={nomeModaNegocios360} alt="Moda Negócios 360" style={{ height: '150px' }} />
          ) : (
            'Modelagem Completa'
          )
        }
        titleHeight={titleHeight}
      />

      <div className="planos-e-precos-container">
        <header className="planos-e-precos-header">
          <h1>Planos sob consulta</h1>
          <p>
            Escolha o plano que melhor se adapta às necessidades do seu negócio. Oferecemos
            flexibilidade com opções de assinaturas mensais, trimestrais e semestrais.
          </p>
        </header>

        <section className="planos-container">
          {planos.map((plano, index) => (
            <div key={index} className="plano-card">
              <h2 className="plano-nome">{plano.nome}</h2>
              <p className="plano-descricao">{plano.descricao}</p>
              <ul className="plano-beneficios">
                {plano.beneficios.map((beneficio, idx) => (
                  <li key={idx}>{beneficio}</li>
                ))}
              </ul>

              {/* Botão para expandir funcionalidades completas */}
              <button className="btn-e-mais" onClick={() => toggleExpandirPlano(index)}>
                {planosExpandidos[index] ? 'Ver Menos' : 'Ver Mais'}
              </button>

              {/* Se o plano estiver expandido, mostrar as funcionalidades completas */}
              {planosExpandidos[index] && (
                <ul className="plano-funcionalidades-completas">
                  {plano.funcionalidadesCompletas.map((func, idx) => (
                    <li key={idx}>{func}</li>
                  ))}
                </ul>
              )}

              <div className="plano-precos">
                <div className="preco-item">
                  {/* <span className="preco-titulo">Mensal</span>
                  <span className="preco-valor">R${plano.precoMensal.toFixed(2)}</span> */}
                </div>
                <div className="preco-item">
                  {/* <span className="preco-titulo">Trimestral</span>
                  <span className="preco-valor">R${plano.precoTrimestral.toFixed(2)}</span>
                  <span className="preco-desconto">10% de desconto</span> */}
                </div>
                <div className="preco-item">
                  {/* <span className="preco-titulo">Semestral</span>
                  <span className="preco-valor">R${plano.precoSemestral.toFixed(2)}</span>
                  <span className="preco-desconto">15% de desconto</span> */}
                </div>
              </div>
              <button className="btn-assinar" onClick={() => navigate('/cadastro-tenant')}>
                Cadastre-se para Assinar Agora
              </button>
            </div>
          ))}
        </section>

        <footer className="planos-e-precos-footer">
          <button className="btn-voltar" onClick={() => navigate('/home')}>
            Voltar para Home
          </button>
        </footer>
      </div>
    </div>
  )
}

export default PlanosEPrecosModa
