// saibaMais.jsx

import { useNavigate, useLocation } from 'react-router-dom'

import { NavbarModelagemcompleta } from './NavbarModelagemcompleta.jsx'
import logoTopoModelagemCompleta from '../Usuarios/login/logo_topo.jpg'
import logoModaNegocios360 from '../Usuarios/login/4.png'
import nomeModaNegocios360 from '../Usuarios/login/6.png'
function SaibaMais() {
  const navigate = useNavigate()

  const location = useLocation()
  const hostname = window.location.hostname
  const pathname = location.pathname

  const isModaNegocios360 =
    (hostname && hostname.includes('moda.negocios360')) ||
    (pathname && pathname.includes('moda.negocios360'))
  const logoTopo = isModaNegocios360 ? logoModaNegocios360 : logoTopoModelagemCompleta
  console.log('isModaNegocios360', isModaNegocios360)
  // Definindo alturas com base na condição
  const logoTopoHeight = isModaNegocios360 ? '55px' : '60px' // Ajuste os valores conforme necessário
  const logoTopoWidth = isModaNegocios360 ? '65px' : '70px' // Ajuste os valores conforme necessário
  const titleHeight = isModaNegocios360 ? '60px' : undefined // Mantém a altura somente se for uma imagem
  return (
    <div className="saiba-mais-wrapper">
      <NavbarModelagemcompleta
        setShowAlertMessage={() => {}}
        setAlertMessage={() => {}}
        logoTopo={logoTopo}
        logoTopoHeight={logoTopoHeight}
        logoTopoWidth={logoTopoWidth}
        title={
          isModaNegocios360 ? (
            <img src={nomeModaNegocios360} alt="Moda Negócios 360" style={{ height: '150px' }} />
          ) : (
            'Modelagem Completa'
          )
        }
        titleHeight={titleHeight}
      />
      <div className="saiba-mais-container-moda">
        <header className="saiba-mais-header-moda">
          <h1>Saiba Mais sobre moda.negocios360</h1>
          <p>
            Moda.negocios360 é um serviço que oferece soluções inovadoras para a indústria da moda,
            ajudando estilistas e designers a transformar conceitos em peças de alta qualidade.
            Nosso foco está em garantir precisão e qualidade através dos nossos serviços de
            modelagem, corte, e plotagem sob medida.
          </p>
        </header>

        <section className="saiba-mais-content-moda">
          <h2 className="content-heading">Resumo dos Módulos</h2>

          {/* Módulo de Corte */}
          <section className="modulo-section-moda">
            <div className="modulo-icon"></div>
            <h3>Módulo de Corte</h3>
            <p>
              O módulo de corte permite gerenciar todas as etapas do processo de corte, desde o
              cadastro de novos cortes até o gerenciamento de presença dos funcionários.
              Funcionalidades incluem o cadastro de clientes e funcionários, gerenciamento de
              cortes, controle de pontualidade, e relatórios financeiros.
            </p>
            <ul className="modulo-list-moda">
              <li>Cadastro de Clientes e Funcionários</li>
              <li>Gerenciamento de Cortes e Pontualidade</li>
              <li>Controle Financeiro e Cadastro de Pagamentos</li>
              <li>Relatórios Detalhados de Cortes e Eficiência</li>
            </ul>
          </section>

          {/* Módulo de Modelagem */}
          <section className="modulo-section-moda">
            <div className="modulo-icon"></div>
            <h3>Módulo de Modelagem</h3>
            <p>
              O módulo de modelagem oferece ferramentas para o cadastro e acompanhamento de pedidos
              de modelagem. Possibilita a integração com outros módulos, ajuste do layout dos
              componentes, e controle detalhado dos pedidos e valores envolvidos.
            </p>
            <ul className="modulo-list-moda">
              <li>Cadastro de Pedidos de Modelagem</li>
              <li>Gerenciamento de Valores e Clientes</li>
              <li>Integração com Outros Módulos</li>
              <li>Ajuste de Layout dos Componentes</li>
            </ul>
          </section>

          {/* Módulo de Plotagem */}
          <section className="modulo-section-moda">
            <div className="modulo-icon"></div>
            <h3>Módulo de Plotagem</h3>
            <p>
              O módulo de plotagem permite gerenciar serviços de plotagem, desde a seleção do
              cliente até a definição dos valores. É possível integrar o módulo com outros sistemas,
              garantindo um controle financeiro completo dos pedidos de plotagem.
            </p>
            <ul className="modulo-list-moda">
              <li>Cadastro e Gerenciamento de Plotagens</li>
              <li>Resumo Financeiro dos Serviços de Plotagem</li>
              <li>Integração com Módulos Financeiros</li>
              <li>Controle de Valores Pendentes e Pagos</li>
            </ul>
          </section>

          <h2 className="content-heading">Planos sob consulta</h2>
          <div className="planos-precos-section">
            <p>
              Oferecemos planos flexíveis para atender às suas necessidades. Benefícios exclusivos
              para assinantes que incluem acesso ilimitado a serviços e suporte especializado.
            </p>
            {/* <p>
              Oferecemos planos flexíveis para atender às suas necessidades. Nossos planos começam a
              partir de <strong>R$59,90</strong> por mês, com benefícios exclusivos para assinantes
              que incluem acesso ilimitado a serviços e suporte especializado.
            </p> */}
            <button className="btn-planos-moda" onClick={() => navigate('/planos-e-precos-moda')}>
              Conheça nossos Planos
            </button>
          </div>
        </section>

        <footer className="saiba-mais-footer-moda">
          <button className="btn-voltar-moda" onClick={() => navigate('/home')}>
            Voltar para Home
          </button>
        </footer>
      </div>
    </div>
  )
}

export default SaibaMais
