import { Alert } from 'react-bootstrap'
import React, { useState, useEffect } from 'react'
export const ShowAlertCPF = ({
  showAlert_cpf,
  message,
  isBlurred,
  setShowAlertCPF,
  isCPFObrigatorio = false
}) => {
  // Fechar o alerta automaticamente após 5 segundos
  useEffect(() => {
    let timer
    if (showAlert_cpf && (isBlurred || isCPFObrigatorio)) {
      timer = setTimeout(() => {
        setShowAlertCPF(false)
      }, 2000) // 5000 milissegundos = 5 segundos
    }

    // Limpar o temporizador se o componente for desmontado ou se o alerta for fechado manualmente
    return () => clearTimeout(timer)
  }, [showAlert_cpf, setShowAlertCPF, isBlurred, isCPFObrigatorio])

  if (showAlert_cpf && (isBlurred || isCPFObrigatorio)) {
    return (
      <Alert variant="danger" onClose={() => setShowAlertCPF(false)} dismissible>
        {message}
      </Alert>
    )
  }

  return null
}
//------------------------------------------
export const ShowAlertCNPJ = ({ showAlert_cnpj, message, isBlurred, setShowAlertCNPJ }) => {
  if (showAlert_cnpj && isBlurred) {
    return (
      <Alert variant="danger" onClose={() => setShowAlertCNPJ(false)} dismissible>
        {message}
      </Alert>
    )
  }
  return null
}
//------------------------------------------
export const ShowAlertCep = ({
  showAlert_cep,
  message,
  isBlurred,
  setShowAlertCep,
  isCepObrigatorio = false
}) => {
  // Fechar o alerta automaticamente após 2 segundos
  useEffect(() => {
    let timer
    if (showAlert_cep && (isBlurred || isCepObrigatorio)) {
      timer = setTimeout(() => {
        setShowAlertCep(false)
      }, 2000) // 2000 milissegundos = 2 segundos
    }

    // Limpar o temporizador se o componente for desmontado ou se o alerta for fechado manualmente
    return () => clearTimeout(timer)
  }, [showAlert_cep, setShowAlertCep, isBlurred, isCepObrigatorio])

  if (showAlert_cep && (isBlurred || isCepObrigatorio)) {
    return (
      <Alert variant="danger" onClose={() => setShowAlertCep(false)} dismissible>
        {message}
      </Alert>
    )
  }

  return null
}
//------------------------------------------
export const ShowAlertEmail = ({
  showAlert_email,
  isBlurred,
  message,
  setShowAlertEmail,
  isEmailObrigatorio = false
}) => {
  // Fechar o alerta automaticamente após 2 segundos
  useEffect(() => {
    let timer
    if (showAlert_email && (isBlurred || isEmailObrigatorio)) {
      timer = setTimeout(() => {
        setShowAlertEmail(false)
      }, 2000) // 2000 milissegundos = 2 segundos
    }

    // Limpar o temporizador se o componente for desmontado ou se o alerta for fechado manualmente
    return () => clearTimeout(timer)
  }, [showAlert_email, setShowAlertEmail, isBlurred, isEmailObrigatorio])

  if (showAlert_email && (isBlurred || isEmailObrigatorio)) {
    return (
      <Alert variant="danger" onClose={() => setShowAlertEmail(false)} dismissible>
        {message}
      </Alert>
    )
  }

  return null
}

export const ShowAlertTel = ({
  showAlert_tel,
  message,
  isBlurred,
  setShowAlertTel,
  isTelefoneObrigatorio = false
}) => {
  // Fechar o alerta automaticamente após 2 segundos
  useEffect(() => {
    let timer
    if (showAlert_tel && (isTelefoneObrigatorio || isBlurred)) {
      timer = setTimeout(() => {
        setShowAlertTel(false)
      }, 2000) // 2000 milissegundos = 2 segundos
    }

    // Limpar o temporizador se o componente for desmontado ou se o alerta for fechado manualmente
    return () => clearTimeout(timer)
  }, [showAlert_tel, setShowAlertTel, isBlurred, isTelefoneObrigatorio])

  if (showAlert_tel && (isTelefoneObrigatorio || isBlurred)) {
    return (
      <Alert variant="danger" onClose={() => setShowAlertTel(false)} dismissible>
        {message}
      </Alert>
    )
  }

  return null
}
