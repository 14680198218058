export const setTipoPessoa = tipoPessoa => ({
  type: 'SET_TIPO_PESSOA',
  payload: tipoPessoa
})

export const setShowAlertGravando = showAlertGravando => ({
  type: 'SET_SHOW_ALERT_GRAVANDO',
  payload: showAlertGravando
})

export const setShowAlert = showAlert => ({
  type: 'SET_SHOW_ALERT',
  payload: showAlert
})
