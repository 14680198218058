//import moment from 'moment'
import dayjs from 'dayjs'
import { DraggableContainer } from '../../../src/components/DraggableContainer/draggable-container'
import Banner from '../../../src/components/pages/CadastroGeral/Banner'
import { Form, Table, Card, Button } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import AlertMessage from '../../../src/components/pages/PLOTAGEM/AlertMessage'
import { deletarRegistro } from '../../../src/backend/model'
import { ConfirmationModal } from '../../../src/components/DraggableContainer/ConfirmationModal/confirmation-modal'
import { SalvarEdicao } from '../../../src/backend/model'

import { useQueryClient } from 'react-query'

import Select from 'react-select'

function CustomTable({
  cabecalho,
  IntemsTravadosNaPesquisa,
  editableTotals,
  draggableId,
  components,
  isDeleteEdit,
  isDarBaixa,
  data,
  setData,
  title,
  headerCells,
  fontSizeTitle,
  dadosEdicao,
  setDadosEdicao,
  width,
  updatedData,
  setUpdatedData,
  todasColunasSheet,
  invalidateQueries,
  botaoDetalhes = false,
  onDetalhesClick,
  classEfeito3dMouseOver,
  onlyDeleteButton = false,
  tableHeight = '400px',
  positionConfig,
  showBorder = true,
  marginLeftCard = '0px',
  marginLeftTitle = '0px',
  marginTop = '0px'
}) {
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [editandoGrau, setEditandoGrau] = useState(null)
  const [idParaDeletar, setIdParaDeletar] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showAlertDeletando, set_showAlertDeletando] = useState(false)
  const [showAlertSucesso, set_showAlertSucesso] = useState(false)
  const [editing, setEditing] = useState(null)
  const [dataEntregue, set_dataEntregue] = useState('')
  const queryClient = useQueryClient()

  function cancelarEdicao() {
    setEditandoGrau(null)
    setDadosEdicao({ grau: '' })
  }

  const pegaData = (dados, idProcurado) => {
    const objetoEncontrado = dados.find(obj => obj.id === idProcurado)
    return objetoEncontrado ? objetoEncontrado.data : null
  }
  function tratarDadosComConfiguracao(dados, configuracoes) {
    // Cria uma cópia do objeto de dados para evitar mutações
    let dadosTratados = { ...dados }

    // Itera sobre as configurações para aplicar as substituições necessárias

    configuracoes.forEach(config => {
      if (config.replace && dadosTratados.hasOwnProperty(config.dataKey)) {
        // Aplica o replace com base no que está especificado no objeto de configuração
        // Assume que o valor de replace é uma string com dois caracteres onde o primeiro deve ser substituído pelo segundo

        const [charToReplace, newChar] = config.replace.split(' ')

        if (typeof dadosTratados[config.dataKey] === 'string') {
          dadosTratados[config.dataKey] = dadosTratados[config.dataKey].replace(
            charToReplace,
            newChar
          )
        }
      }
    })

    return dadosTratados
  }

  async function salvarEdicao() {
    // Salvar os dados editados

    setAlertMessage('Editando')
    setShowAlert(true)
    // await onSave(dadosEdicao);
    //const campo_id = isNumeric(id_registro) ? 'id' : '_id';
    // let id = dadosEdicao.id || dadosEdicao._id
    // const data = pegaData(updatedData, id)

    console.log('dadosEdicao antes:', dadosEdicao)
    console.log('data:', data)
    //dadosEdicao = { ...dadosEdicao, data }

    dadosEdicao = tratarDadosComConfiguracao(dadosEdicao, headerCells)
    console.log('dadosEdicao depois:', dadosEdicao)
    await SalvarEdicao(
      {
        todasColunasSheet: todasColunasSheet,
        configKeySheet:
          isDeleteEdit && isDeleteEdit.configKeySheet
            ? isDeleteEdit.configKeySheet
            : isDarBaixa.configKeySheet,
        tab:
          isDeleteEdit && isDeleteEdit.exclusaoConfig && isDeleteEdit.exclusaoConfig.tab
            ? isDeleteEdit.exclusaoConfig.tab
            : isDarBaixa.salvarConfig.tab,

        cadastroDados: dadosEdicao,
        clienteId: dadosEdicao.id || dadosEdicao._id
      },
      { rota: 'funcoesGenericas' }
    )
      .then(() => {
        queryClient.invalidateQueries(invalidateQueries)
      })
      .catch(error => {
        console.error('Erro ao salvar edição:', error)
      })

    setShowAlert(false)

    cancelarEdicao()
    isDeleteEdit
    setEditandoGrau(null)
  }
  //--------------------------------------------------------
  async function DeleteRegistro(id_registro, deletaDoBanco, callback) {
    set_showAlertDeletando(true)
    set_showAlertSucesso(false)

    // Primeira chamada para deletar registro da tabela principal
    if (deletaDoBanco) {
      await deletarRegistro({
        id: id_registro,
        sheetConfigKey: isDeleteEdit.configKeySheet,
        tab: isDeleteEdit.exclusaoConfig.tab,
        requisicao: 'deletar',
        rota: 'funcoesGenericas'
      })
    }

    if (isDeleteEdit.exclusaoConfig && isDeleteEdit.exclusaoConfig.tabDetalhes) {
      isDeleteEdit.exclusaoConfig.setExcluirDetalhesFlag(prev => !prev)
    }

    set_showAlertDeletando(false)
    set_showAlertSucesso(true)

    const campoParaDeletar = isNumeric(id_registro) ? 'id' : '_id'
    setTimeout(() => {
      set_showAlertDeletando(false)
      setShowDeleteModal(false)
      set_showAlertSucesso(false)

      setUpdatedData &&
        setUpdatedData(updatedData.filter(item => item[campoParaDeletar] !== id_registro))

      setData && setData(updatedData.filter(item => item[campoParaDeletar] !== id_registro))
    }, 2000)

    if (callback) {
      callback()
    }
    // queryClient.invalidateQueries(invalidateQueries);
  }
  //--------------------------------------------------------
  useEffect(() => {
    if (
      isDeleteEdit &&
      isDeleteEdit.exclusaoConfig &&
      isDeleteEdit.exclusaoConfig.ExcluirDetalhesFlag === true
    ) {
      queryClient.invalidateQueries(isDeleteEdit.exclusaoConfig.invalidateQueries)
    }
  }, [
    isDeleteEdit &&
      isDeleteEdit.exclusaoConfig &&
      isDeleteEdit.exclusaoConfig.ExcluirDetalhesFlag === true
  ])

  const handleEditChange = e => {
    let { name, value } = e.target

    setDadosEdicao(prevState => {
      const newState = { ...prevState, [name]: value }

      return newState
    })
  }

  useEffect(() => {
    if (setUpdatedData && !IntemsTravadosNaPesquisa) {
      setUpdatedData(data)
    }
  }, [data])

  const dataKeys = headerCells.map(cell => cell.dataKey)

  const iniciarEdicao = (row, index) => {
    setEditandoGrau(index)

    setDadosEdicao(row)
  }

  let colunas = []
  if (IntemsTravadosNaPesquisa) {
    colunas = ['id', 'data', 'categoria', 'grau', 'descricao', 'preco']
  }

  const tableContent = (
    <div className="" style={{ width: width, height: tableHeight, marginTop: marginTop }}>
      <ConfirmationModal
        show={showDeleteModal}
        botaoComAlerta={true}
        buttonWithAlertsProps={{
          showAlertGravando: showAlertDeletando,
          showAlert: showAlertSucesso, // responsavel pela exibiçao da menssagem de sucesso
          buttonLabel: 'Sim',
          width: '70',
          handleSubmit: () =>
            DeleteRegistro(idParaDeletar, isDeleteEdit.deletaDoBanco, () => {
              // atualiza data aqui
            }),
          message: 'Deletando Registro'
        }}
        onHide={() => {
          setShowDeleteModal(false)
          // setShowButtonWithAlerts(false); // resetar para o padrão após fechar o modal
        }}
        title="Confirmação"
        message={
          <div>
            Deletar registro{' '}
            <span
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: '1.2em'
              }}
            >
              {dadosEdicao && dadosEdicao.grau}
            </span>
          </div>
        }
        confirmLabel="Sim"
        cancelLabel="Não"
      ></ConfirmationModal>

      <div style={{ width: width, marginLeft: marginLeftTitle }}>
        <Banner
          fontSize={fontSizeTitle}
          title={title}
          classEfeito3dMouseOver={classEfeito3dMouseOver}
        />
      </div>
      <Card
        style={{
          width: width,
          height: tableHeight,
          marginLeft: marginLeftCard,
          overflowY: 'auto',
          overflowX: 'hidden',
          border: showBorder ? '1px solid #dee2e6' : 'none'
        }}
      >
        <Card.Body
          style={{
            width: '100%',
            height: tableHeight,
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <Form>
            {cabecalho && (
              <div className="cabecalho">
                <span className="titulo">{cabecalho.titulo} </span>
                <span
                  className="valor"
                  style={{
                    perspective: '1000px',
                    transform: 'rotateX(5deg) rotateY(10deg)',
                    boxShadow: '5px 10px 10px rgba(0,0,0,0.3)'
                  }}
                  //  onMouseOver={handleMouseOver}
                  // onFocus={handleMouseOver} // Added onFocus event handler
                >
                  {cabecalho.valor}
                </span>
              </div>
            )}
            <Table
              className="CustomTable-table table-striped"
              style={{ width: width, height: tableHeight }}
            >
              <thead className="CustomTable_thead">
                <tr>
                  {headerCells.map((cell, index) => {
                    const adjustedWidth = `${parseFloat(cell.width)}%`

                    return (
                      <th key={index} className="centered-label" style={{ width: cell.width }}>
                        {cell.label}
                      </th>
                    )
                  })}

                  {(isDeleteEdit || isDarBaixa) && (
                    <th className="centered-label" style={{ width: '80px' }}>
                      AÇÕES
                    </th>
                  )}
                </tr>
              </thead>
              <tbody
              // style={{
              //   overflowY: 'auto',
              //   display: 'block',

              //   width: '100%',
              //   minHeight: '100px'
              // }}
              >
                {updatedData &&
                  updatedData.map((row, index) => (
                    <tr key={index}>
                      {editandoGrau === index ? (
                        <>
                          {/* ----------------Parte da ediçao------------------------  */}
                          {headerCells.map((cell, keyIndex) => {
                            // Se estamos editando a célula "Entregue"
                            if (cell.edit && cell.dataKey === 'entregue') {
                              // Added console.log to monitor the value of row.id
                              return (
                                <td key={keyIndex} style={{ width: cell.width }}>
                                  <Form.Group controlId="data_corte">
                                    <Form.Control
                                      type="date"
                                      name="entregue"
                                      value={dadosEdicao.entregue}
                                      onChange={handleEditChange}
                                      style={{ width: '100%' }}
                                      className="editable-field"
                                    />
                                  </Form.Group>
                                </td>
                              )
                            }
                            // Criando uma variável para armazenar o valor que será renderizado dentro do <td>
                            let renderedValue
                            // se o campo e um campo do tipo select e é editavel
                            if (cell.fetchFunction && cell.edit !== false) {
                              return (
                                <td key={keyIndex} style={{ width: cell.width }}>
                                  <CellSelect
                                    cell={cell}
                                    row={row}
                                    dadosEdicao={dadosEdicao}
                                    setDadosEdicao={setDadosEdicao}
                                  />
                                </td>
                              )
                            } else if (cell.edit) {
                              return (
                                <td key={keyIndex} style={{ width: cell.width }}>
                                  <Form.Control
                                    type="text"
                                    name={cell.dataKey}
                                    value={dadosEdicao[cell.dataKey] || ''}
                                    onChange={handleEditChange}
                                  />
                                </td>
                              )
                            } else if (cell.edit == false) {
                              if (cell.fetchFunction && cell.isFormatMoeda) {
                                renderedValue = Number(
                                  cell.fetchFunction(
                                    cell.dados,
                                    row[cell.dataKey],
                                    cell.dadoRetorno,
                                    cell.idDado
                                  )
                                ).toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL'
                                })
                              } else if (cell.fetchFunction && cell.isFormatDate) {
                                renderedValue = cell.fetchFunction(row[cell.dataKey])
                              } else if (cell.fetchFunction) {
                                renderedValue = cell.fetchFunction(
                                  cell.dados,
                                  row[cell.dataKey],
                                  cell.dadoRetorno,
                                  cell.idDado
                                )
                              } else if (cell.isCalculedField) {
                                let valor1 = extractValor(row, cell.isCalculedField.field1, cell)
                                let valor2 = extractValor(row, cell.isCalculedField.field2, cell)
                                renderedValue = calculaResultado(valor1, valor2, cell)
                              } else {
                                renderedValue = row[cell.dataKey]
                              }
                            }

                            return (
                              <td key={keyIndex} style={{ width: cell.width }}>
                                {renderedValue}
                              </td>
                            )
                          })}
                        </>
                      ) : (
                        <>
                          {headerCells.map((cell, keyIndex) => {
                            // Criando uma variável para armazenar o valor que será renderizado dentro do <td>
                            let renderedValue

                            if (cell.fetchFunction && cell.isFormatMoeda) {
                              renderedValue = Number(
                                cell.fetchFunction(
                                  cell.dados,
                                  row[cell.dataKey],
                                  cell.dadoRetorno,
                                  cell.idDado
                                )
                              ).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                              })
                            } else if (cell.fetchFunction && cell.isFormatDate) {
                              renderedValue = cell.fetchFunction(
                                row[cell.dataKey] // Valor a ser buscado
                              )
                            } else if (cell.fetchFunction) {
                              renderedValue = cell.fetchFunction(
                                cell.dados,
                                row[cell.dataKey], // Valor a ser buscado
                                cell.dadoRetorno,
                                cell.idDado
                              )
                            } else {
                              if (cell.isFormatMoeda) {
                                renderedValue = Number(row[cell.dataKey]).toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL'
                                })
                              } else {
                                renderedValue = row[cell.dataKey]
                              }
                              if (cell.isCalculedField) {
                                let valor1 = extractValor(row, cell.isCalculedField.field1, cell)
                                let valor2 = extractValor(row, cell.isCalculedField.field2, cell)
                                renderedValue = calculaResultado(valor1, valor2, cell)
                              }
                            }
                            let backgroundColor
                            if (cell.dataKey === 'dias') {
                              backgroundColor = determinaCorDeFundo(Number(renderedValue))
                            }
                            return (
                              <td
                                key={keyIndex}
                                style={{
                                  width: cell.width,
                                  backgroundColor: backgroundColor || 'transparent' // Aplique a cor de fundo, ou transparente se não houver cor de fundo
                                }}
                              >
                                {renderedValue}
                              </td>
                            )
                          })}
                        </>
                      )}
                      {isDeleteEdit && (
                        <td className="action-cell">
                          {renderActions(
                            row,
                            index,
                            alertMessage,
                            salvarEdicao,
                            setDadosEdicao,
                            setEditandoGrau,
                            editandoGrau,
                            showAlert,
                            cancelarEdicao,
                            setIdParaDeletar,
                            setShowDeleteModal,
                            DeleteRegistro,
                            botaoDetalhes,
                            onDetalhesClick,
                            iniciarEdicao,
                            onlyDeleteButton
                          )}
                        </td>
                      )}

                      {isDarBaixa && (
                        <td style={{ width: '17%' }}>
                          {renderDarBaixaButton(
                            row,
                            index,
                            alertMessage,
                            salvarEdicao,
                            setDadosEdicao,
                            setEditandoGrau,
                            editandoGrau,
                            showAlert,
                            cancelarEdicao,
                            setIdParaDeletar,
                            setShowDeleteModal,
                            DeleteRegistro,
                            botaoDetalhes,
                            onDetalhesClick,
                            iniciarEdicao,
                            onlyDeleteButton
                          )}
                        </td>
                      )}
                    </tr>
                  ))}

                {data &&
                  IntemsTravadosNaPesquisa &&
                  data.map((row, index) => {
                    if (!IntemsTravadosNaPesquisa.includes(row[0])) {
                      return null // Não renderiza a linha se o valor não estiver contido
                    }
                    // Calcula a largura total para a linha
                    const totalWidth =
                      headerCells.reduce((sum, cell) => {
                        const widthValue = parseInt(cell.width, 10) // Extrai o valor numérico da string de largura
                        return sum + widthValue
                      }, 0) + '%' // Converte o total de volta para uma string de porcentagem
                    return (
                      <tr key={index} style={{ width: totalWidth }}>
                        {headerCells.map((cell, keyIndex) => {
                          let value
                          switch (cell.dataKey) {
                            case 'modelagem':
                              value = row[colunas.indexOf('descricao')]
                              break
                            case 'preco':
                              value = Number(row[colunas.indexOf('preco')]).toLocaleString(
                                'pt-BR',
                                {
                                  style: 'currency',
                                  currency: 'BRL'
                                }
                              )
                              break
                            case 'unidades':
                              value = editableTotals[row[0]] || ''
                              break
                            case 'total':
                              value = (
                                Number(row[colunas.indexOf('preco')]) *
                                Number(editableTotals[row[0]])
                              ).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                              })
                              break

                            default:
                              value = ''
                              break
                          }
                          return (
                            <td key={keyIndex} style={{ width: cell.width }}>
                              {IntemsTravadosNaPesquisa.includes(row[0]) && <>{value}</>}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
              </tbody>
            </Table>
          </Form>
        </Card.Body>
      </Card>
    </div>
  )
  // Se draggableId e components forem fornecidos, use DraggableContainer
  if (draggableId && components) {
    return (
      <DraggableContainer
        id={draggableId}
        components={components}
        width={width}
        positionConfig={positionConfig}
      >
        {tableContent}
      </DraggableContainer>
    )
  }

  // Caso contrário, apenas retorne o conteúdo da tabela
  return tableContent
}

export default CustomTable
//----------------------------------------------------------------------
function determinaCorDeFundo(valorDias) {
  if (valorDias === 0) {
    return 'yellow'
  } else if (valorDias < 0) {
    return 'red'
  } else {
    return '' // Cor de fundo padrão ou nenhuma cor
  }
}
//----------------------------------------------------------------------

const formatToDisplayDate = isoDate => {
  const [year, month, day] = isoDate.split('-')
  return `${day}/${month}/${year}`
}

//----------------------------------------------------------------------
function CellSelect({ cell, row, dadosEdicao, setDadosEdicao }) {
  // Verifica se cell.dados está definido e é um array
  const options = Array.isArray(cell?.dados) ? cell.dados : []

  // Inicializa o valor selecionado baseado na linha atual e nas opções disponíveis

  const initialOption = options.find(option => {
    return String(option.value) === String(row[cell.dataKey])
  })

  const [selectedOption, setSelectedOption] = useState(initialOption)

  useEffect(() => {
    if (selectedOption) {
      setDadosEdicao(prevDados => ({
        ...prevDados,
        [cell.dataKey]: selectedOption.value
      }))
    } else {
      // Se a opção for limpa (por causa de isClearable=true)
      setDadosEdicao(prevDados => ({
        ...prevDados,
        [cell.dataKey]: null
      }))
    }
  }, [selectedOption, setDadosEdicao, cell.dataKey])

  return (
    <Select
      placeholder={cell.label}
      options={options}
      isClearable={true}
      value={selectedOption}
      onChange={setSelectedOption}
    />
  )
}
function renderActions(
  row,
  index,
  alertMessage,
  salvarEdicao,
  setDadosEdicao,
  setEditandoGrau,
  editandoGrau,
  showAlert,
  cancelarEdicao,
  setIdParaDeletar,
  setShowDeleteModal,
  DeleteRegistro,
  botaoDetalhes,
  onDetalhesClick,
  iniciarEdicao,
  onlyDeleteButton
) {
  if (onlyDeleteButton) {
    return (
      <Button
        variant="danger"
        onClick={() => {
          setIdParaDeletar(row._id ? row._id : row.id)
          setDadosEdicao({ id: row._id ? row._id : row.id, grau: row.grau })
          setShowDeleteModal(true)
        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        Excluir
      </Button>
    )
  }
  if (editandoGrau === index) {
    if (!showAlert) {
      return (
        <>
          <Button
            variant="success"
            onClick={salvarEdicao}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            Salvar
          </Button>{' '}
          <Button
            variant="secondary"
            onClick={cancelarEdicao}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            Cancelar
          </Button>
        </>
      )
    } else {
      return (
        <AlertMessage
          message={alertMessage}
          size="medium"
          customHeight={40}
          customWidth={160}
          top={'0px'}
          variant1="primary"
          variant2="light"
          variantDefault="success"
          isBlinking={false}
        />
      )
    }
  } else {
    return (
      <div className="action-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="primary"
          onClick={() => iniciarEdicao(row, index)}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          style={{ marginRight: '10px' }}
        >
          Editar
        </Button>{' '}
        <Button
          variant="danger"
          onClick={() => {
            setIdParaDeletar(row._id ? row._id : row.id)
            setDadosEdicao({ id: row._id ? row._id : row.id, grau: row.grau })
            setShowDeleteModal(true)
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          Excluir
        </Button>{' '}
        {botaoDetalhes && ( // Renderiza o botão "Detalhes" apenas se botaoDetalhes for verdadeiro
          <DetalhesButton
            onDetalhesClick={onDetalhesClick}
            row={row}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          />
        )}
      </div>
    )
  }
}

export const handleMouseOver = e => {
  e.currentTarget.style.transform = 'perspective(1000px) rotateX(5deg) rotateY(10deg)'

  e.currentTarget.style.boxShadow = '5px 10px 10px rgba(0,0,0,0.3)'
}

export const handleMouseOut = e => {
  e.currentTarget.style.transform = 'perspective(1000px) rotateX(0) rotateY(0)'

  e.currentTarget.style.boxShadow = 'none'
}
//----------------------------------------------------------------------------

function renderDarBaixaButton(
  row,
  rowIndex,
  alertMessage,
  salvarEdicao,
  setDadosEdicao,
  setEditandoGrau,
  editandoGrau,
  showAlert,
  cancelarEdicao,
  setIdParaDeletar,
  setShowDeleteModal,
  DeleteRegistro,
  botaoDetalhes,
  onDetalhesClick,
  iniciarEdicao,
  onlyDeleteButton
) {
  if (editandoGrau === rowIndex) {
    return renderActions(
      row,
      rowIndex,
      alertMessage,
      salvarEdicao,
      setDadosEdicao,
      setEditandoGrau,
      editandoGrau,
      showAlert,
      cancelarEdicao,
      setIdParaDeletar,
      setShowDeleteModal,
      DeleteRegistro,
      botaoDetalhes,
      onDetalhesClick,
      onlyDeleteButton
    )
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Button
          style={{ width: '60%', marginRight: 10 }} // Adicionei marginRight: 10 aqui
          variant="success"
          onClick={() => iniciarEdicao(row, rowIndex, setEditandoGrau, setDadosEdicao)}
        >
          Dar Baixa
        </Button>
        {botaoDetalhes && (
          <DetalhesButton
            style={{ width: '38%' }}
            onDetalhesClick={onDetalhesClick}
            row={row}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          />
        )}
      </div>
    </>
  )
}
//-----------------------------------------------------------------------------------
export const DetalhesButton = ({ onDetalhesClick, row, handleMouseOver, handleMouseOut }) => {
  return (
    <Button
      variant="light"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{
        background: 'white',
        marginRight: '5px',
        border: '1px solid gray',
        borderRadius: '16px'
      }}
      onClick={() => {
        console.log('DetalhesButton row:', row)
        onDetalhesClick({ rowId: row.id, clienteId: row.id_cliente })
      }}
    >
      Detalhes
    </Button>
  )
}

// function calculaResultado(valor1, valor2, cell) {
//   if (cell.isCalculedField.operator === '-' && cell.isCalculedField.field1.dataKey === 'prevista') {
//     const data1 = dayjs(valor1)
//     const data2 = dayjs(valor2)
//     if (!valor1 || !valor2) return null
//     const diferenca = data1.diff(data2, 'day') // Calcula a diferença em dias
//     return diferenca
//   }

//   let resultado = 0
//   valor1 = Number(valor1)
//   valor2 = Number(valor2)

//   switch (cell.isCalculedField.operator) {
//     case '+':
//       resultado = valor1 + valor2
//       break
//     case '-':
//       resultado = valor1 - valor2
//       break
//     case '*':
//       resultado = valor1 * valor2
//       break
//     case '/':
//       resultado = valor1 / valor2
//       break
//     default:
//       resultado = 0
//   }

//   if (cell.isFormatMoeda) {
//     return Number(resultado).toLocaleString('pt-BR', {
//       style: 'currency',
//       currency: 'BRL'
//     })
//   } else return resultado
// }
function calculaResultado(valor1, valor2, cell) {
  if (cell.isCalculedField.operator === '-' && cell.isCalculedField.field1.dataKey === 'prevista') {
    const data1 = new Date(valor1)
    const data2 = new Date(valor2)
    if (!valor1 || !valor2) return null
    const diferenca = Math.floor((data1 - data2) / (1000 * 60 * 60 * 24)) // Calcula a diferença em dias
    return diferenca
  }

  let resultado = 0
  valor1 = Number(valor1)
  valor2 = Number(valor2)

  switch (cell.isCalculedField.operator) {
    case '+':
      resultado = valor1 + valor2
      break
    case '-':
      resultado = valor1 - valor2
      break
    case '*':
      resultado = valor1 * valor2
      break
    case '/':
      resultado = valor1 / valor2
      break
    default:
      resultado = 0
  }

  if (cell.isFormatMoeda) {
    return Number(resultado).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })
  } else return resultado
}

//----------------------------------------------------------------------

function extractValor(row, field, cell) {
  let valor, retorno
  if (field.dataKey === 'data_atual') {
    const currentIsoDate = new Date().toISOString()
    return currentIsoDate
  }
  if (field.dataKey === 'prevista') {
    let dataprevista = row['prevista']
    return dataprevista
  }

  if (field.fetchFunction) {
    valor = field.fetchFunction(field.dados, row[field.dataKey], field.dadoRetorno, field.idDado)
  } else {
    valor = row[field.dataKey]
  }

  return Number(valor)
}

//----------------------------------------------------------------------
function isNumeric(value) {
  return /^-?\d+$/.test(value)
}
