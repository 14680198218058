import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../../../src/context/AuthContext.jsx'
import { fetchData } from '../../../../src/backend/model.js'
import { config } from '../../../../src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../../src/utils/form-utils.jsx'
import { Container, Spinner, Form, Button, Table } from 'react-bootstrap'
import Banner from '../../../../src/components/pages/CadastroGeral/Banner'

const GerenciamentoSetores = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [setores, setSetores] = useState([])
  const [funcionarios, setFuncionarios] = useState([])
  const [novoSetor, setNovoSetor] = useState('')
  const [funcionarioId, setFuncionarioId] = useState('')
  const [setorSelecionado, setSetorSelecionado] = useState('')
  const [loading, setLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('') // 'success' or 'danger'

  useEffect(() => {
    const fetchSetores = async () => {
      setLoading(true)
      try {
        const url = `${config.host}/gerenciarSetores`
        const setoresResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarTodosOsSetores'
        })

        setSetores(setoresResponse.setores || [])
        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar setores:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar setores')
        setShowAlert(true)
        setLoading(false)
      }
    }

    const fetchFuncionarios = async () => {
      setLoading(true)
      try {
        const url = `${config.host}/gerenciarSetores`

        const funcionariosResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarFuncionarios'
        })

        setFuncionarios(funcionariosResponse || [])
        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar funcionários:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar funcionários')
        setShowAlert(true)
        setLoading(false)
      }
    }

    fetchSetores()
    console.log('fetchFuncionarios')
    fetchFuncionarios()
  }, [RotaBase, tenantId])

  const handleCriarSetor = async () => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarSetores`
      const criarSetorResponse = await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        setor: novoSetor,
        requisicao: 'criarSetor'
      })
      setSetores(prevSetores => [...prevSetores, { setor: novoSetor }])

      setNovoSetor('')
      setAlertVariant('success')
      setAlertMessage('Setor criado com sucesso!')
      setShowAlert(true)
      setLoading(false)
    } catch (error) {
      console.error('Erro ao criar setor:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao criar setor')
      setShowAlert(true)
      setLoading(false)
    }
  }

  const handleAdicionarFuncionarioAoSetor = async () => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarSetores`
      await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        setor: setorSelecionado,
        funcionarioId,
        requisicao: 'adicionarFuncionarioAoSetor'
      })
      setAlertVariant('success')
      setAlertMessage('Funcionário adicionado ao setor com sucesso!')
      setShowAlert(true)
      setLoading(false)
    } catch (error) {
      console.error('Erro ao adicionar funcionário ao setor:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao adicionar funcionário ao setor')
      setShowAlert(true)
      setLoading(false)
    }
  }

  const handleRemoverFuncionarioDoSetor = async (funcionarioId, setor) => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarSetores`
      await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        setor,
        funcionarioId,
        requisicao: 'removerFuncionarioDoSetor'
      })
      setAlertVariant('success')
      setAlertMessage('Funcionário removido do setor com sucesso!')
      setShowAlert(true)
      setLoading(false)
    } catch (error) {
      console.error('Erro ao remover funcionário do setor:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao remover funcionário do setor')
      setShowAlert(true)
      setLoading(false)
    }
  }

  const handleExcluirSetor = async setor => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarSetores`
      await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        setor,
        requisicao: 'excluirSetor'
      })
      setSetores(prevSetores => prevSetores.filter(s => s.setor !== setor))
      setAlertVariant('success')
      setAlertMessage('Setor excluído com sucesso!')
      setShowAlert(true)
      setLoading(false)
    } catch (error) {
      console.error('Erro ao excluir setor:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao excluir setor')
      setShowAlert(true)
      setLoading(false)
    }
  }

  return (
    <Container
      fluid
      className="p-4"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div style={{ width: '100%', maxWidth: '600px' }}>
        <Banner
          title="Gerenciamento de Setores"
          width="100%"
          fontSize={47}
          classEfeito3dMouseOver=""
        />
        <Form.Group className="mb-3">
          <h4>Criar Novo Setor</h4>
          <Form.Control
            type="text"
            placeholder="Nome do novo setor"
            value={novoSetor}
            onChange={e => setNovoSetor(e.target.value)}
          />
          <Button variant="primary" className="mt-2" onClick={handleCriarSetor}>
            Criar Setor
          </Button>
        </Form.Group>

        <Form.Group className="mb-3">
          <h4>Adicionar Funcionário ao Setor</h4>
          <Form.Control
            as="select"
            value={setorSelecionado}
            onChange={e => setSetorSelecionado(e.target.value)}
          >
            <option value="">Selecione um setor</option>
            {setores.map(setor => (
              <option key={setor.setor} value={setor.setor}>
                {setor.setor}
              </option>
            ))}
          </Form.Control>
          <Form.Control
            as="select"
            value={funcionarioId}
            onChange={e => setFuncionarioId(e.target.value)}
            className="mt-2"
          >
            <option value="">Selecione um funcionário</option>
            {funcionarios.map(funcionario => (
              <option key={funcionario._id} value={funcionario._id}>
                {funcionario.Nome}
              </option>
            ))}
          </Form.Control>
          <Button variant="primary" className="mt-2" onClick={handleAdicionarFuncionarioAoSetor}>
            Adicionar Funcionário
          </Button>
        </Form.Group>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Setor</th>
              <th>Funcionários</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {setores.map(setor => (
              <tr key={setor._id}>
                <td style={{ width: '175px' }}>
                  {setor.setor}
                  <Button
                    variant="outline-secondary" // Alterado para um estilo mais discreto
                    size="sm"
                    onClick={() => handleExcluirSetor(setor.setor)}
                    style={{
                      marginLeft: '10px', // Mantém o espaço à esquerda
                      borderColor: '#ccc', // Cor de borda mais suave
                      color: '#666', // Texto em um tom de cinza mais claro
                      backgroundColor: 'transparent' // Fundo transparente para suavidade
                    }}
                  >
                    Excluir
                  </Button>
                </td>
                <td>
                  {setor.funcionarios && setor.funcionarios.length > 0
                    ? setor.funcionarios.map(func => func.Nome).join(', ')
                    : 'Nenhum funcionário'}
                </td>
                <td>
                  {setor.funcionarios &&
                    setor.funcionarios.length > 0 &&
                    setor.funcionarios.map(func => (
                      <Button
                        key={func._id}
                        variant="outline-secondary" // Alterado para um estilo mais discreto
                        size="sm"
                        onClick={() => handleRemoverFuncionarioDoSetor(func._id, setor.setor)}
                        style={{
                          marginRight: '5px',
                          marginTop: '5px',
                          borderColor: '#ccc', // Cor de borda mais suave
                          color: '#666', // Texto em um tom de cinza mais claro
                          backgroundColor: 'transparent' // Fundo transparente para suavidade
                        }}
                      >
                        Remover {func.Nome}
                      </Button>
                    ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {loading && <Spinner animation="border" />}

      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
    </Container>
  )
}

export default GerenciamentoSetores
