// CadastroRegrasDescontoFrete
import React, { useState } from 'react'
import { Button, FormGroup, Row, Col } from 'react-bootstrap'
import Banner from '/src/components/pages/CadastroGeral/Banner'
import { fetchData } from '/src/backend/model'
import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import { CustomField } from '/src/components/CustomField/custom-field'
import Select from 'react-select'
import { config } from '../../../../../../src/backend/configConexaoGeral'

const CadastroRegrasDescontoFrete = () => {
  const [regraDescontoFrete, setRegraDescontoFrete] = useState({
    limite_inferior: '',
    limite_superior: '',
    limite_unico: '',
    desconto: '',
    tipo_comparacao: '' // 'menor', 'entre', 'maior'
  })
  // ...restante do estado e funções
  const [showAlert, setShowAlert] = useState(false)
  const [corAlerta, setCorAlerta] = useState('success')
  const [alertMessage, setAlertMessage] = useState('')
  const validarCampos = () => {
    // Verifica se algum dos campos está vazio
    const camposVazios = Object.values(regraDescontoFrete).some(value => value === '')
    if (camposVazios) {
      setAlertMessage('Todos os campos são obrigatórios.')
      setCorAlerta('danger')
      setShowAlert(true)
      return false // Indica que a validação falhou
    }
    return true // Indica que a validação foi bem-sucedida
  }

  function handleClear(fieldName) {
    // Atualiza o estado para limpar o campo específico
    setRegraDescontoFrete(prevState => ({
      ...prevState,
      [fieldName]: '' // Limpa o campo específico com base no nome fornecido
    }))

    // Chama a função handleChange com um evento simulado para o campo específico
    handleChange({ target: { name: fieldName, value: '' } })
  }
  const handleSubmit = async event => {
    event.preventDefault()

    // if (!validarCampos()) {
    //   return; // Se a validação falhar, não continua com o envio
    // }
    let dados = {
      ...regraDescontoFrete,
      requisicao: 'GravarRegraDescontoFrete'
    }
    let url = config.host + '/regraDescontoFrete'

    try {
      let response = await fetchData(url, dados)

      if (response.message === 'Gravado') {
        setRegraDescontoFrete({
          limite_inferior: '',
          limite_superior: '',
          limite_unico: '',
          desconto: '',
          tipo_comparacao: ''
        })

        setShowAlert(true)
        setCorAlerta('success')
        setAlertMessage('Regra de desconto de frete cadastrada com sucesso')
      } else {
        setShowAlert(true)
        setCorAlerta('danger')
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao tentar cadastrar a regra desconto frete. Tente novamente.')
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    setRegraDescontoFrete({
      ...regraDescontoFrete,
      [name]: value
    })
  }

  // ...restante das funções

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px'
        }}
      >
        {' '}
        {/* Ajuste a altura conforme necessário */}
        <div style={{ width: '60%' }}>
          {' '}
          {/* Ajuste a largura conforme necessário */}
          <Banner title="Cadastro de Regras de Desconto no Frete" color="#2e8b57" fontSize={60} />
        </div>
      </div>

      <div
        className="d-flex justify-content-center"
        style={{ height: '100vh', position: 'relative', marginTop: '20px' }} // Ajuste o valor de marginTop conforme necessário
      >
        <FormGroup className="mx-auto" style={{ maxWidth: '500px' }}>
          {showAlert && (
            <ShowAlertGeneric
              showAlert={showAlert}
              message={alertMessage}
              setShowAlert={setShowAlert}
              variant={corAlerta}
              className="show-alert-genericPF"
              fontSize="1.5rem"
              dismissible={false}
              isBlurred={true}
            />
          )}
          <form onSubmit={handleSubmit} className="form-fields-container">
            <Row>
              <Col md={10} className="mb-5">
                <CustomField
                  label="Limite Inferior"
                  placeholder="Digite o Limite Inferior"
                  name="limite_inferior"
                  value={regraDescontoFrete.limite_inferior}
                  onChange={e =>
                    handleChange({
                      target: {
                        name: 'limite_inferior',
                        value: e.target.value
                      }
                    })
                  }
                  className="Efeito3dMouseOver-class"
                  onClear={() => handleClear('limite_inferior')}
                />
              </Col>
            </Row>
            <Row>
              <Col md={10} className="mb-5">
                <CustomField
                  label="Limite Superior"
                  placeholder="Digite o Limite Superior"
                  name="limite_superior"
                  value={regraDescontoFrete.limite_superior}
                  onChange={e =>
                    handleChange({
                      target: {
                        name: 'limite_superior',
                        value: e.target.value
                      }
                    })
                  }
                  onClear={() => handleClear('limite_superior')}
                  className="Efeito3dMouseOver-class"
                />
              </Col>
            </Row>
            <Row>
              <Col md={10} className="mb-5">
                <CustomField
                  label="Limite Único"
                  placeholder="Digite o Limite Único"
                  name="limite_unico"
                  value={regraDescontoFrete.limite_unico}
                  onChange={e =>
                    handleChange({
                      target: { name: 'limite_unico', value: e.target.value }
                    })
                  }
                  onClear={() => handleClear('limite_unico')}
                  className="Efeito3dMouseOver-class"
                />
              </Col>
            </Row>
            <Row>
              <Col md={10} className="mb-5">
                <CustomField
                  label="Desconto"
                  placeholder="Digite o valor do Desconto"
                  name="desconto"
                  value={regraDescontoFrete.desconto}
                  onChange={e =>
                    handleChange({
                      target: { name: 'desconto', value: e.target.value }
                    })
                  }
                  onClear={() => handleClear('desconto')}
                  className="Efeito3dMouseOver-class"
                />
              </Col>
            </Row>
            <Select
              placeholder="Selecione o Tipo de Comparação"
              name="tipo_comparacao"
              value={
                regraDescontoFrete.tipo_comparacao
                  ? {
                      value: regraDescontoFrete.tipo_comparacao,
                      label:
                        regraDescontoFrete.tipo_comparacao.charAt(0).toUpperCase() +
                        regraDescontoFrete.tipo_comparacao.slice(1)
                    }
                  : null
              }
              onChange={selectedOption =>
                setRegraDescontoFrete(prevState => ({
                  ...prevState,
                  tipo_comparacao: selectedOption ? selectedOption.value : ''
                }))
              }
              options={[
                { value: 'menor', label: 'Menor que' },
                { value: 'entre', label: 'Entre' },
                { value: 'maior', label: 'Maior que' }
              ]}
              isClearable
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
            />
            <Button type="submit" className="btn btn-success" style={{ marginTop: '20px' }}>
              Cadastrar Regra de desconto de frete
            </Button>
          </form>
        </FormGroup>
      </div>
    </div>
  )
}

export default CadastroRegrasDescontoFrete
