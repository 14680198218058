// cadastro-marketplace preco-facil-market
import React, { useState, useContext } from 'react'
import { Button, FormGroup, Row, Col, FormCheck } from 'react-bootstrap'
import Banner from '/src/components/pages/CadastroGeral/Banner'
import { fetchData } from '/src/backend/model'
import { config } from '/src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import { AuthContext } from '/src/context/AuthContext'

const CadastroMarketplace = () => {
  const [marketplace, setMarketplace] = useState({
    nome: '',
    statusAtivo: true // Status ativo por padrão
  })
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [corAlerta, setCorAlerta] = useState('danger')
  const { tenantId } = useContext(AuthContext)
  const handleSubmit = async event => {
    event.preventDefault()

    let dados = {
      nome: marketplace.nome,
      statusAtivo: marketplace.statusAtivo,
      requisicao: 'GravarMarketplacePF'
    }
    let url = config.host + '/marketplacePF'

    try {
      let response = await fetchData(url, dados)

      if (response.marketplace) {
        setMarketplace({ nome: '', statusAtivo: true })
        setShowAlert(true)
        setCorAlerta('success')
        setAlertMessage('Marketplace cadastrado com sucesso')
      } else {
        setShowAlert(true)
        setCorAlerta('danger')
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao tentar cadastrar o marketplace. Tente novamente.')
    }
  }

  const handleChange = e => {
    const { name, value, checked } = e.target
    setMarketplace({
      ...marketplace,
      [name]: name === 'statusAtivo' ? checked : value
    })
  }

  return (
    <div
      className="d-flex align-items-center form-containerPF"
      style={{ height: '100vh', position: 'relative' }}
    >
      <div className="text-center mx-auto mx-autoPF" style={{ maxWidth: '2000px' }}>
        <Banner title="Cadastro de Marketplace" color="#2e8b57" fontSize={60} />

        <FormGroup style={{ maxWidth: '500px', margin: 'auto', paddingTop: '20px' }}>
          {showAlert && (
            <ShowAlertGeneric
              showAlert={showAlert}
              message={alertMessage}
              isBlurred={true}
              setShowAlert={setShowAlert}
              variant={corAlerta}
              className="show-alert-genericPF"
              fontSize="1.5rem"
              dismissible={false}
            />
          )}
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={12} className="mb-3">
                <input
                  type="text"
                  name="nome"
                  className="form-controlPF"
                  placeholder="Nome do Marketplace"
                  onChange={handleChange}
                  value={marketplace.nome}
                  autocomplete="off"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <FormCheck
                  type="checkbox"
                  name="statusAtivo"
                  label="Status Ativo"
                  onChange={handleChange}
                  checked={marketplace.statusAtivo}
                  className="form-check-labelPF"
                />
              </Col>
            </Row>
            <Button type="submit" className="btn btn-successPF" style={{ marginTop: '20px' }}>
              Cadastrar Marketplace
            </Button>
          </form>
        </FormGroup>
      </div>
    </div>
  )
}

export default CadastroMarketplace
