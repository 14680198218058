import { useDrag, useDrop } from 'react-dnd'
import { useRef, useCallback } from 'react'
import { enqueue } from '/src/utils/promiseQueueManager'
import sheetsConfig from '/src/config/sheetsConfig'
import { useState, useEffect } from 'react'
import { AuthContext } from '/src/context/AuthContext'
import { useContext } from 'react'
import { enviar, requisitar } from '/src/backend/model'
import { SalvarEdicao, SelectFromTablePorMultiplosIds } from '/src/backend/model'
import PropTypes from 'prop-types'
//import { id, ro, te } from 'date-fns/locale'
const ItemTypes = {
  COMPONENT: 'datePicker'
}
export const DraggableComponent = ({
  id,
  children,
  position,
  setPosition,
  width,
  flex,
  zIndex,
  type
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.COMPONENT,
    item: { id, position, type },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  })
  const style = {
    transform: `translate(${position.x}px, ${position.y}px)`,
    opacity: isDragging ? 0.5 : 1,
    width: width ? width : 'auto',
    position: 'absolute',
    zIndex: zIndex // Added zIndex property
    // flex: flex ? flex : 'auto',
  }

  return (
    <div ref={drag} style={style}>
      {children}
    </div>
  )
}
//--------------------------------------------------------------------------------

export const DraggableContainer = ({ onFocus, width, type, ...props }) => {
  DraggableContainer.propTypes = {
    components: PropTypes.array.isRequired,
    positionConfig: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    zIndex: PropTypes.number,
    children: PropTypes.node,
    onFocus: PropTypes.func
  }

  if (!props.components) return

  // Procurar o componente pela id
  let component = props.components.find(comp => comp.id === props.id)
  //
  // Verificar se a posição é [0, 0] e usar positionConfig se for o caso
  // Usar a posição do componente ou a posição de fallback do positionConfig, se disponível
  // Garantir que a chave seja uma string
  const positionConfigId = props.id.toString()

  // Usar a posição do componente ou a posição de fallback do positionConfig, se disponível
  let position
  if (component?.position?.x === 0 && component?.position?.y === 0 && props.positionConfig) {
    position =
      props.positionConfig[positionConfigId] !== undefined
        ? props.positionConfig[positionConfigId]
        : { x: 0, y: 0 }
  } else {
    position =
      component?.position ||
      (props.positionConfig[positionConfigId] !== undefined
        ? props.positionConfig[positionConfigId]
        : { x: 0, y: 0 })
  }

  // Verificar se há posição, caso contrário, retornar
  if (!position) return null

  // Se o componente não existir, criá-lo com a posição do positionConfig
  if (!component || (component.position.x === 0 && component.position.y === 0)) {
    component = { id: props.id, position }

    //setComponents(prevComponents => [...prevComponents, component])
  }

  // const setPosition = newPosition =>
  //   setComponents(
  //     props.components.map(component =>
  //       component.id !== props.id ? component : { ...component, position: newPosition }
  //     )
  //   )

  return (
    <DraggableComponent
      id={props.id}
      position={position}
      //  setPosition={setPosition}
      width={width}
      zIndex={props.zIndex}
      type={type}
    >
      <div {...{ onFocus: props.onFocus }}>{props.children}</div>
    </DraggableComponent>
  )
}

//--------------------------------------------------------------------------------------------------
export const DroppableArea = ({ children, onDrop }) => {
  const ref = useRef(null)

  const [, drop] = useDrop({
    accept: ItemTypes.COMPONENT,
    drop: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset()
      const newLeft = Math.round(item.position.x + delta.x)
      const newTop = Math.round(item.position.y + delta.y)
      onDrop(item.id, newLeft, newTop)
    }
  })

  drop(ref)

  return (
    <div ref={ref} style={{ height: '100vh', width: '100vw' }} className="DroppableArea">
      {children}
    </div>
  )
}

//--------------------------------------------------------------------------------------------
function NomeFormulario(idFormulario) {
  if (idFormulario == 1) return 'CADASTRO PLOTAGEM'
  if (idFormulario == 2) return 'CADASTRO CORTE'
  if (idFormulario == 3) return 'FINANCEIRO'
  if (idFormulario == 4) return 'MODELAGEM'
  if (idFormulario == 5) return 'GRAU DE DIFICULDADE'
  if (idFormulario == 6) return 'CADASTRO PREÇO MODELAGEM'
  if (idFormulario == 7) return 'EDITAR MODELAGEM'
  if (idFormulario == 8) return 'FINALIZAR MODELAGEM'
  if (idFormulario == 9) return 'CADASTRO DE PLOTAGEM'
  if (idFormulario == 10) return 'CADASTRO DE IPS LIBERADOS'
}

/// nova versao -----------------------------------------------------------------------------------
const useOnDrop = (
  setComponents, // Esta função atualiza o estado dos componentes.
  components, // Este é o estado atual dos componentes.
  setButtonPosition, // Esta função atualiza a posição do botão, se necessário.
  idFormulario, // Este é o ID do formulário atual, usado para identificação ao salvar.
  RotaBase,
  tenantId,
  type
) => {
  // Defina a função que será chamada quando um item for solto.

  const onDrop = useCallback(
    async (id, x, y, type) => {
      const draggedComponent = components.find(comp => comp.id === id)
      // Verifique se o componente sendo arrastado é um botão

      const isButton = type === 'button' // Acesse o type diretamente aqui
      // Atualize o estado com as novas posições.
      setComponents(prevComponents =>
        prevComponents.map(comp => (comp.id === id ? { ...comp, position: { x, y } } : comp))
      )

      // Se for o botão, atualize a posição do botão também.
      if (id === 1) {
        setButtonPosition({ x, y })
      }

      // Preparar os dados para salvar.
      const dadosPosicao = {
        data: new Date(),
        idUser: '1',
        idFormulario: idFormulario,
        formulario: NomeFormulario(idFormulario),
        x: x,
        y: y,
        idComponent: id,
        rota_base: RotaBase,
        tenantId: tenantId
      }

      // Tente salvar a nova posição usando a função SalvarEdicao.
      try {
        // Aqui você substituiria por uma chamada real para salvar os dados.
        // O código está comentado para evitar execução, pois é um exemplo.
        await SalvarEdicao(
          {
            todasColunasSheet: sheetsConfig.cortes.fields.configPosicionamento,
            configKeySheet: 'cortes',
            tab: 'configPosicionamento',
            cadastroDados: dadosPosicao,
            clienteId: dadosPosicao.idUser,
            formatDate: () => dadosPosicao.data,
            isEditing: false,
            requisicao: 'insertOrUpdateRecord',

            campos: {
              TodosCampos: sheetsConfig.cortes.fields.configPosicionamento,
              CamposRetorno: sheetsConfig.cortes.fields.configPosicionamento
            }
          },
          {
            sheet_dos_dados: 'configPosicionamento',
            idsObj: { id_C: 1, id_D: idFormulario, id_H: id },
            id_pk: 0, // chave primária como 0 porque será gerada pelo evento onEdit da própria planilha
            rota: 'configPosicionamento'
          }
        )
      } catch (error) {
        // Caso ocorra algum erro, imprima no console.
        console.error('Erro ao salvar posição do componente:', error)
      }
    },
    [setComponents, setButtonPosition, idFormulario]
  )

  return onDrop
}
export { useOnDrop }
//--------------------------------------------------------------------------------------------------
export function updateComponentPositions(posicoes, setComponents, components) {
  if (!posicoes || !setComponents || !components) return
  const updatedComponents = components.map(component => {
    // Buscar a posição correspondente nos dados do banco de dados.
    const posicao = posicoes.find(posicao => posicao.idComponent === component.id)

    if (posicao) {
      // Se encontrou uma posição correspondente, atualizar a posição do componente.
      // Converta as posições x e y para números.
      const x = parseInt(posicao.x, 10)
      const y = parseInt(posicao.y, 10)

      return { ...component, position: { x, y } }
    } else {
      // Se não encontrou uma posição correspondente, manter a posição original do componente.
      return component
    }
  })

  // Atualizar o estado dos componentes com os componentes atualizados.
  setComponents(updatedComponents) // essa é a linha horiginal
}
//-------------------------------------------------------------------------
export const createComponentList = numComponents => {
  return Array.from({ length: numComponents }).map((_, index) => ({
    id: index + 1,
    position: { x: 0, y: 0 }
  }))
}
//----------------------------------------------------------------------------------------------
export const useFetchData = (idFormulario, RotaBase, tenantId) => {
  const [positionsFromDB, setPositionsFromDB] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    let isSubscribed = true
    setIsLoading(true)

    const fetchData = async () => {
      try {
        const dadosExtra = {
          sheet_dos_dados: 'configPosicionamento',
          idsObj: {
            id_C: 1,
            id_D: idFormulario,
            id_J: RotaBase,
            id_I: tenantId
          },
          isFrontendCall: true, // isFrontendCall é para indicar que a funcao esta sendo chamado pelo lado client, porque ela tambem é chamada pelo lado servidor, e quando é pelo lado servidor o tipo do return dela é diferente
          selectedSector: 'cortes',
          funcaoDeRetorno: SelectFromTablePorMultiplosIdsDados,
          campos: {
            TodosCampos: sheetsConfig.cortes.fields.configPosicionamento,
            CamposRetorno: sheetsConfig.cortes.fields.configPosicionamento
          },
          rota: 'funcoesGenericas'
        }
        const posicoes = await SelectFromTablePorMultiplosIdsDados({
          dadosExtra
        })
        if (isSubscribed) {
          setPositionsFromDB(posicoes)
        }
      } catch (e) {
        if (isSubscribed) {
          setError(e)
        }
      } finally {
        if (isSubscribed) {
          setIsLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      isSubscribed = false
    }
  }, [idFormulario, RotaBase, tenantId])

  return { positionsFromDB, isLoading, error }
}

//----------------------------------------------------------------------------------------------
export const useArrastar = (idFormulario, numComponents) => {
  const [positionsFromDB, setPositionsFromDB] = useState([])
  const [carregouPosicoes, setCarregouPosicoes] = useState(false)
  // const [components, setComponents] = useState([]); // Adicione o estado inicial se necessário
  const [buttonPosition, setButtonPosition] = useState({}) // Adicione o estado inicial se necessário
  const [components, setComponents] = useState(createComponentList(numComponents))
  const { RotaBase, tenantId } = useContext(AuthContext)
  function setPositionFromData(posicoes, setButtonPosition) {
    // Encontrar o objeto onde idComponent é igual a 1

    const componentData = posicoes.find(posicao => posicao.idComponent === 1)

    if (componentData) {
      // Criar um novo objeto com as propriedades x e y
      const newPosition = {
        x: componentData.x,
        y: componentData.y
      }

      // Passar o novo objeto para setButtonPosition
      setButtonPosition(newPosition)
    } else {
      console.log('Componente com idComponent 1 não encontrado.')
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const dados = {
        pagina1: 'configPosicionamento', // Nome da coleção no MongoDB
        query: {
          idUser: 1,
          idFormulario: idFormulario,
          rota_base: RotaBase,
          tenantId: tenantId
        }
      }

      const posicoes = await requisitar(dados)
      setPositionFromData(posicoes, setButtonPosition)

      setPositionsFromDB(posicoes)
    }

    if (!carregouPosicoes) {
      fetchData()
      setCarregouPosicoes(true)
    }
  }, [carregouPosicoes])

  useEffect(() => {
    // Aqui você pode precisar importar `updateComponentPositions`
    // se ele não estiver neste arquivo
    updateComponentPositions(positionsFromDB, setComponents, components)
  }, [positionsFromDB])

  const handleDrop = useOnDrop(
    setComponents,
    components,
    setButtonPosition,
    idFormulario,
    RotaBase,
    tenantId
  )

  return {
    handleDrop,
    components, // se você precisar acessá-lo fora deste hook
    buttonPosition // se você precisar acessá-lo fora deste hook
    // e quaisquer outras funções ou estados que você queira expor
  }
}
//------------------------------------------------------------------------------------------------
export function SelectFromTablePorMultiplosIdsDados(dadosretorno) {
  const PositionsFormData = dadosretorno?.result

  if (!PositionsFormData) {
    return []
  }

  const PositionsForm = PositionsFormData.map(posicao => {
    return {
      idComponent: posicao.idComponent,
      x: posicao.x,
      y: posicao.y
    }
  })

  return PositionsForm
}
