import diacritics from 'diacritics'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
export function ResultSearch(inputText, todos_dados) {
  if (!todos_dados) return []
  const searchInput = diacritics.remove(inputText).toLowerCase().trim()
  return todos_dados.filter(item => {
    // Convertendo campos numéricos para string e verificando se eles contêm o texto de entrada
    const descricaoMatch =
      item.descricao && diacritics.remove(item.descricao).toLowerCase().includes(searchInput)
    const skuMatch = item.sku && diacritics.remove(item.sku).toLowerCase().includes(searchInput)
    const gramasMatch = item.gramas.toString().includes(searchInput) // `gramas` como número, apenas convertendo para string
    const custoMatch = item.custo.toString().includes(searchInput) // `custo` como número, apenas convertendo para string

    return descricaoMatch || skuMatch || gramasMatch || custoMatch
  })
}
//----------------------------------------------------------------
export function addColorsToMarketplaces(marketplaces, colors) {
  if (!Array.isArray(marketplaces)) {
    console.error('marketplaces is not an array', marketplaces)
    return []
  }
  return marketplaces.map(marketplace => ({
    ...marketplace,
    cor: colors[marketplace.nome] || '#000000' // Usa preto como cor padrão se não encontrar correspondência
  }))
}
//----------------------------------------------------------------
export function mergeComissoesWithMarketplaces(marketplaces, comissoes, marketplaceColors) {
  // Cria um mapa de comissões para um acesso mais rápido
  if (!Array.isArray(marketplaces)) {
    console.error('marketplaces is not an array', marketplaces)
    return []
  }
  if (!Array.isArray(comissoes)) {
    console.error('comissoes is not an array', comissoes)
    return marketplaces?.map(marketplace => ({
      ...marketplace,
      comissao: ''
    }))
  }

  marketplaces = addColorsToMarketplaces(marketplaces, marketplaceColors)

  const comissoesMap = comissoes.reduce((acc, comissao) => {
    acc[comissao.marketplaceId] = comissao.comissao
    return acc
  }, {})

  // Mapeia os marketplaces para incluir a comissão de acordo com o _id
  return marketplaces?.map(marketplace => ({
    ...marketplace,
    comissao: comissoesMap[marketplace._id] || '' // Se não encontrar, coloca um valor padrão
  }))
}
//----------------------------------------------------------------
export async function CarregarComissoesProduto(categoriaId) {
  try {
    let dados = {
      requisicao: 'CarregarComissoesProduto',
      categoriaId: categoriaId
    }
    let url = config.host + '/produtoPF'

    let response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao carregar produtos:', error)
    // Trate o erro conforme necessário
  }
}
//----------------------------------------------------------------
export function ComissaoMarketplace(comissoes, marketplace) {
  // Encontrar a comissão que corresponde ao marketplace fornecido

  const comissaoEncontrada = comissoes.find(comissao => comissao.marketplaceId === marketplace._id)

  // Retorna o valor da comissão se uma correspondência foi encontrada, caso contrário retorna null ou algum valor padrão
  return comissaoEncontrada ? comissaoEncontrada.comissao : null
}
//----------------------------------------------------------------
export async function CarregarRegrasPorGrupoAmVv(grupoNum, marketplaceId) {
  try {
    let dados = {
      requisicao: 'CarregarRegrasPorGrupoAmVv',
      grupoNum: grupoNum,
      marketplaceId: marketplaceId
    }
    let url = config.host + '/regraDescontoFrete'

    let response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao carregar regras por grupo:', error)
    // Trate o erro conforme necessário
  }
}

export function calcularFreteParaAmericanas(peso, precoVendaBruto, regrasPesoFreteAmericanas) {
  let faixaPrecoCorreta = null
  let faixaPesoCorreta = null

  for (const regra of regrasPesoFreteAmericanas) {
    if (
      precoVendaBruto >= regra.faixa_preco.min &&
      (regra.faixa_preco.max === null || precoVendaBruto <= regra.faixa_preco.max)
    ) {
      faixaPrecoCorreta = regra
      faixaPesoCorreta = regra.regras.find(r => peso >= r.peso.min && peso <= r.peso.max)
      if (faixaPesoCorreta) {
        break
      }
    }
  }

  if (!faixaPrecoCorreta) {
    throw new Error('Nenhuma faixa de preço encontrada para o preço de venda bruto atual.')
  }

  if (!faixaPesoCorreta) {
    throw new Error('Nenhuma faixa de peso encontrada para o peso atual.')
  }

  return faixaPesoCorreta.valor_frete
}
