// edicao-categoria
import React, { useState, useEffect, useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Button, FormGroup } from "react-bootstrap";
import Banner from "../../../../../../src/components/pages/CadastroGeral/Banner";
import CustomTable from "../../../../../../src/components/CustomTable/custom-table"; // Certifique-se de importar corretamente o CustomTable
import { fetchData } from "../../../../../../src/backend/model";
import { CarregarCategorias } from "/PRECO_FACIL_MARKET/src/components/pages/Produtos/CadastroProduto/cadastro-produto";
import { AuthContext } from "/src/context/AuthContext";
const EdicaoCategoria = () => {
  const [dadosEdicao, setDadosEdicao] = useState({});
  const [updatedData, setUpdatedData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const { tenantId } = useContext(AuthContext);
  const {
    data: categorias,
    isLoading: isLoadingCategorias,
    refetch: refetchCategorias,
    isError: isErrorCarregaCategorias,
  } = useQuery(
    "categorias",
    () => CarregarCategorias(tenantId), // Sua função para buscar categorias
    {
      enabled: !!tenantId,
      refetchOnWindowFocus: false,
    }
  );
  useEffect(() => {
    setUpdatedData(categorias);
  }, [categorias]);

  // Definir cabeçalhos da tabela e outras configurações
  const headerCells = [
    { label: "Nome", dataKey: "nome", width: "30%", edit: true },
    { label: "Descrição", dataKey: "descricao", width: "70%" },
    // Outras colunas conforme necessário
  ];

  return (
    <div style={{ position: "relative" }}>
      <div
        className="d-flex flex-column align-items-center form-container"
        style={{ marginTop: "30px" }}>
        <Banner
          title="Gerenciamento de Categoria"
          color="#2e8b57"
          width="42%"
          fontSize={60}
        />
      </div>

      <FormGroup
        style={{
          maxWidth: "1000px",
          margin: "-10px auto 0 auto",
          paddingTop: "-10px",
        }}>
        <CustomTable
          headerCells={headerCells}
          data={categorias}
          updatedData={updatedData}
          setUpdatedData={setUpdatedData}
          dadosEdicao={dadosEdicao}
          setDadosEdicao={setDadosEdicao}
          isDeleteEdit={{
            configKeySheet: "categoriaPF",
            exclusaoConfig: {
              tab: "categoriaPF",
              invalidateQueries: "categorias",
            },
            deletaDoBanco: true,
          }}
          width="970px"
          tableHeight="750px"
        />
      </FormGroup>
    </div>
  );
};

export default EdicaoCategoria;
