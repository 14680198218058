// src/pages/PasswordRecovery/PasswordRecovery.js
import { useState } from 'react'
import { fetchData } from '../../../../../src/backend/model.js'
import { config } from '../../../../../src/backend/configConexaoGeral'
import Banner from '../../../../../src/components/pages/CadastroGeral/Banner.jsx'
import { Link } from 'react-router-dom'
import { ShowAlertGeneric } from '../../../../../src/utils/form-utils.jsx'
import logoTopoModelagemCompleta from '../../../../../src/components/pages/Usuarios/login/logo_topo.jpg' // Ajuste o caminho conforme necessário
import logoCentroModelagemCompleta from '../../../../../src/components/pages/Home/logo_centro.jpg' // Ajuste o caminho conforme necessário
import logoTopoModaNegocios360 from '../../../../../src/components/pages/Usuarios/login/4.png'
import logoCentroModaNegocios360 from '../../../../../src/components/pages/Usuarios/login/1.png'
import { useContext } from 'react'
import { AuthContext } from '../../../../../src/context/AuthContext'
const PasswordRecovery = () => {
  const [email, setEmail] = useState('')
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')
  const { RotaBase } = useContext(AuthContext)
  const hostname = window.location.hostname
  const pathname = window.location.pathname
  const handleSubmit = async event => {
    event.preventDefault()

    try {
      const url = `${config.host}/tenants`
      let frontendUrl = 'https://moda.negocios360.com.br'
      let sistema = 'moda.negocios360'
      if (hostname && !hostname.includes('moda.negocios360')) {
        frontendUrl = 'https://www.modelagemcompleta.com.br'
        sistema = 'modelagemcompleta'
      }
      const dados = {
        email: email.trim(),
        rota_base: RotaBase,
        requisicao: 'recuperar_senha',
        frontendUrl: frontendUrl,
        sistema: sistema
      }

      const response = await fetchData(url, dados)

      if (response && response.success) {
        setSuccessMessage('Um e-mail de recuperação foi enviado para o seu endereço.')
        setAlertMessage('')
        setAlertVariant('success')
      }
    } catch (error) {
      console.error('Erro ao tentar recuperar a senha:', error)
      setAlertMessage(error.message)
      setAlertVariant('danger')
      setShowAlertMessage(true)
    }
  }

  const isModaNegocios360 =
    (hostname && hostname.includes('moda.negocios360')) ||
    (pathname && pathname.includes('moda.negocios360'))
  const logoTopo = isModaNegocios360 ? logoTopoModaNegocios360 : logoTopoModelagemCompleta
  const logoCentro = isModaNegocios360 ? logoCentroModaNegocios360 : logoCentroModelagemCompleta

  const title = isModaNegocios360
    ? 'Recuperação de Senha - Moda Negócios 360'
    : 'Recuperação de Senha - Modelagem Completa'
  const description = isModaNegocios360
    ? 'Recupere sua senha no sistema Moda Negócios360'
    : 'Recupere sua senha no sistema Modelagem Completa'

  return (
    <div className="password-recovery-wrapper">
      <header className="password-recovery-header">
        <Link to="/">
          <img src={logoTopo} alt="Logo" className="password-recovery-logo-topo" />
        </Link>
        <Link to="/fale-conosco" className="password-recovery-contact-link">
          Fale conosco
        </Link>
      </header>

      <ShowAlertGeneric
        showAlert={showAlertMessage}
        message={alertMessage}
        isBlurred={true}
        setShowAlert={setShowAlertMessage}
        variant={alertVariant}
        timeOut={90000}
      />
      {successMessage && (
        <div className="password-recovery-success-message" role="alert">
          {successMessage}
        </div>
      )}
      <div className="password-recovery-top-menu">
        <Link to="/">Home</Link> &gt; Recuperar Senha
      </div>
      <Banner
        title={title}
        className="password-recovery-banner-container"
        fontFamily="Josefin Sans"
        width="100%"
        fontSize="3.5rem"
        classEfeito3dMouseOver=""
      />
      <div className="password-recovery-main-container">
        <div className="password-recovery-container">
          <form onSubmit={handleSubmit} className="password-recovery-form">
            <div className="password-recovery-intro-text">
              Insira seu e-mail para recuperar sua senha.
            </div>
            <div className="password-recovery-form-group">
              <label htmlFor="email" className="password-recovery-form-label">
                E-mail
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="password-recovery-input"
                required
              />
            </div>
            <div className="password-recovery-button-container">
              <button type="submit" className="password-recovery-button">
                Enviar
              </button>
            </div>
            <div className="password-recovery-text-links">
              <Link to="/login" className="password-recovery-link-text">
                Voltar para o login
              </Link>
            </div>
          </form>
        </div>
        <div className="password-recovery-info-container">
          <div className="password-recovery-banner-centro">
            <img src={logoCentro} alt="Logo" className="password-recovery-logo-centro" />
            <p className="password-recovery-intro-text">{description}</p>
            <p className="password-recovery-form-label">Gerencie sua empresa de qualquer lugar</p>
          </div>
        </div>
      </div>
      <div id="loading" className="password-recovery-loader-container">
        <div className="password-recovery-loader"></div>
      </div>
    </div>
  )
}

export default PasswordRecovery
