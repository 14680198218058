import { useState, useEffect, useContext } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { fetchData } from '../../../../../../../src/backend/model'
import { config } from '../../../../../../../src/backend/configConexaoGeral'
import { AuthContext } from '../../../../../../../src/context/AuthContext'
import logoAmericanas from '../logoAmericanas.png'
import logoViaVarejo from '../logoViaVarejo.jpeg'
import Banner from '../../../../../../../src/components/pages/CadastroGeral/Banner'
import { carregaIdMarketplace } from '../../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraMagalu/regra-magalu.jsx'
import { ShowAlertGeneric } from '../../../../../../../src/utils/form-utils.jsx'
import { useParams } from 'react-router-dom'
const SelecionarGrupoVendedor = () => {
  const { marketplace } = useParams()
  const [grupos, setGrupos] = useState([])
  const [grupoSelecionado, setGrupoSelecionado] = useState(null)
  const { tenantId } = useContext(AuthContext)
  const [idMarketplace, setIdMarketplace] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [corAlerta, setCorAlerta] = useState('danger')

  useEffect(() => {
    const carregarGrupos = async () => {
      try {
        const url = config.host + '/regraDescontoFrete'
        const dados = { requisicao: 'CarregarGruposAmVv' }
        const response = await fetchData(url, dados)

        setGrupos(response)
      } catch (error) {
        console.error('Erro ao carregar grupos:', error)
      }
    }
    carregarGrupos()
  }, [])

  useEffect(() => {
    async function carregarDados() {
      let idMarketplace
      if (marketplace === 'americanas') {
        idMarketplace = await carregaIdMarketplace('Americanas')
      } else if (marketplace === 'viavarejo') {
        idMarketplace = await carregaIdMarketplace('Via Varejo')
      } else {
        console.error('Marketplace inválido:', marketplace)
      }

      setIdMarketplace(idMarketplace[0]._id)
    }
    carregarDados()
  }, [marketplace])

  useEffect(() => {
    const carregarConfiguracao = async () => {
      try {
        const url = config.host + '/regraDescontoFrete'
        const dados = {
          tenantId: tenantId,
          marketplaceId: idMarketplace,
          requisicao: 'CarregarConfigAmVv'
        }
        const response = await fetchData(url, dados)

        if (response) {
          setGrupoSelecionado(response.grupoNum)
        }
      } catch (error) {
        setGrupoSelecionado(null)
        console.error('Erro ao carregar configuração:', error)
      }
    }

    if (tenantId && idMarketplace) {
      carregarConfiguracao()
    }
  }, [tenantId, idMarketplace])

  const handleChange = async e => {
    const grupo = Number(e.target.value)
    setGrupoSelecionado(grupo)

    try {
      const dados = {
        tenantId: tenantId,
        grupoNum: grupo,
        marketplaceId: idMarketplace,
        requisicao: 'GravarConfigAmVv'
      }
      const url = config.host + '/regraDescontoFrete'

      await fetchData(url, dados)
      setShowAlert(true)
      setCorAlerta('success')
      setAlertMessage('Grupo Configurado com sucesso!')
    } catch (error) {
      console.error('Erro ao gravar grupo:', error)
      alert('Erro ao gravar grupo.')
    }
  }

  return (
    <div
      className="d-flex align-items-center form-container"
      style={{ height: '100vh', position: 'relative', paddingTop: '16px', overflow: 'auto' }}
    >
      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          isBlurred={true}
          setShowAlert={setShowAlert}
          variant={corAlerta}
          className="show-alert-genericPF"
          fontSize="1.5rem"
          dismissible={false}
        />
      )}
      <div className="mx-auto" style={{ maxWidth: '1100px', padding: '16px' }}>
        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src={
                marketplace === 'americanas'
                  ? logoAmericanas
                  : marketplace === 'viavarejo'
                  ? logoViaVarejo
                  : ''
              }
              alt="Logo"
              className="logo-americanas"
              style={{ width: '153px', height: '69px' }}
            />
            <div style={{ marginLeft: '16px' }}>
              <Banner title="Configuração de Grupo" color="#2e8b57" fontSize={50} />
            </div>
          </div>
        </div>

        <h5 style={{ textAlign: 'center' }}>Selecione o Grupo</h5>
        <Form style={{ textAlign: 'center' }}>
          <Form.Group as={Row} style={{ justifyContent: 'center' }}>
            <Col sm={12} style={{ maxWidth: '300px' }}>
              {grupos.map(grupo => (
                <div
                  key={grupo}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    justifyContent: 'center'
                  }}
                >
                  <Form.Check
                    type="radio"
                    label={`Grupo ${grupo}`}
                    name="grupo"
                    value={grupo}
                    onChange={handleChange}
                    checked={grupoSelecionado === grupo}
                    style={{ marginRight: '10px' }}
                  />
                </div>
              ))}
            </Col>
          </Form.Group>
        </Form>
      </div>
    </div>
  )
}

export default SelecionarGrupoVendedor
