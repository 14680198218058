import React from 'react' // Principal import do React
//import { useState } from 'react' // Se precisar usar state dentro do componente

const ModuleConfirmationDialog = ({
  nomeModulo,
  descricaoModulo,
  precoModulo,
  onConfirm,
  onCancel,
  style,
  sucesso
}) => {
  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
      style={style}
    >
      <div className="relative w-auto max-w-md mx-auto my-6">
        <div className="relative flex flex-col w-full bg-white border border-gray-200 rounded-lg shadow-lg outline-none focus:outline-none">
          {/* Header */}
          <div className="flex items-start justify-between p-4 border-b border-gray-200 rounded-t">
            <h4 className="text-xl font-semibold text-gray-700">Confirmar Cadastro de Módulo</h4>
            {sucesso && (
              <div
                style={{
                  width: '100%',
                  padding: '16px',
                  backgroundColor: sucesso.includes('Já existe') ? '#dc3545' : '#28a745', // Verde vibrante
                  color: '#ffffff', // Texto branco
                  border: `1px solid ${sucesso.includes('Já existe') ? '#c82333' : '#218838'}`, // Borda verde escura
                  borderRadius: '8px', // Bordas arredondadas
                  textAlign: 'center', // Centraliza o texto
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra sutil
                  marginBottom: '20px', // Espaçamento inferior
                  transition: 'background-color 0.3s ease' // Transição suave
                }}
              >
                {sucesso}
              </div>
            )}
          </div>

          {/* Body */}
          <div className="relative flex-auto p-6">
            <p className="mb-4 text-sm text-gray-500 text-center px-4">
              Por favor, revise os detalhes antes de confirmar:
            </p>
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 space-y-2">
              <div className="flex justify-between">
                <span className="font-medium text-gray-700">Nome do Módulo:</span>
                <span className="font-semibold text-gray-900">{nomeModulo}</span>
              </div>

              {descricaoModulo && (
                <div className="flex justify-between">
                  <span className="font-medium text-gray-700">Descrição:</span>
                  <span className="max-w-[200px] truncate text-gray-900">{descricaoModulo}</span>
                </div>
              )}

              <div className="flex justify-between">
                <span className="font-medium text-gray-700">Preço Mensal:</span>
                <span className="font-semibold text-green-600">
                  {precoModulo ? `R$ ${parseFloat(precoModulo).toFixed(2)}` : ''}
                </span>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="flex flex-col p-6 border-t border-gray-200 rounded-b space-y-4">
            <div className="flex justify-end">
              <button
                className="px-6 py-2 text-sm font-bold uppercase rounded focus:outline-none mr-4"
                type="button"
                onClick={onCancel}
                style={{
                  backgroundColor: '#FF6347', // Tomate
                  color: '#FFFFFF', // Texto branco
                  marginBottom: '20px',
                  transition: 'background-color 0.3s'
                }}
                onMouseEnter={e => (e.target.style.backgroundColor = '#FF4500')} // OrangeRed
                onMouseLeave={e => (e.target.style.backgroundColor = '#FF6347')}
              >
                Cancelar
              </button>
              <button
                className="px-6 py-2 text-sm font-bold uppercase rounded focus:outline-none"
                type="button"
                onClick={onConfirm}
                style={{
                  backgroundColor: '#1E90FF', // DodgerBlue
                  color: '#FFFFFF', // Texto branco
                  transition: 'background-color 0.3s'
                }}
                onMouseEnter={e => (e.target.style.backgroundColor = '#1C86EE')} // DodgerBlue mais escuro
                onMouseLeave={e => (e.target.style.backgroundColor = '#1E90FF')}
              >
                Confirmar Cadastro
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModuleConfirmationDialog
