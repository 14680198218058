// src/components/ModuleDescription.jsx

import { useParams } from 'react-router-dom'
import CorteDescription from './CorteDescription'
import ModelagemDescription from './ModelagemDescription'
import PlotagemDescription from './PlotagemDescription'
const ModuleDescription = () => {
  const { moduleName } = useParams()

  if (!moduleName) {
    return <div>Selecione um módulo para ver a descrição.</div>
  }

  return (
    <div>
      {moduleName.toLowerCase() === 'corte' && (
        <CorteDescription /> // Renderiza o subcomponente específico
      )}
      {moduleName.toLowerCase() === 'modelagem' && (
        <ModelagemDescription /> // Renderiza o subcomponente específico
      )}
      {moduleName.toLowerCase() === 'plotagem' && (
        <PlotagemDescription /> // Renderiza o subcomponente específico
      )}
    </div>
  )
}

export default ModuleDescription
