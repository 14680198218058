const sheetUrl =
  'https://docs.google.com/spreadsheets/d/1l9EzWdWT40-Ow9A-fGPmQKxGvwW0nV5Q8Bi1tcAvpRg/edit' // link da planilha geral de cadastro de clientes e serviços
const cadastroClienteGeral = 'Cad_G_Cliente'
const fieldsCadastroClienteGeral = [
  'id',
  'data',
  'Nome',
  'Empresa',
  'Tel',
  'Sobrenome',
  'CPF',
  'Tipo_Pessoa',
  'Sexo',
  'Email',
  'Cep',
  'Endereco',
  'Numero',
  'Complemento',
  'Bairro',
  'Cidade',
  'UF',
  'CNPJ',
  'Categoria_Cliente'
]
const configPosicionamento = [
  'id',
  'data',
  'idUser',
  'idFormulario',
  'formulario',
  'x',
  'y',
  'idComponent'
]
const sheetsConfig = {
  // nome do setor
  plotagem: {
    sheetUrl: sheetUrl, // link da planilha
    tabs: {
      // sao os nomes das abas envolvidas no setor plotagem
      clientes: cadastroClienteGeral,
      servicos: 'plotagens',
      servicos_hist: 'plotagens_hist',
      itens_plotagens: 'itens_plotagens',
      itens_plotagens_hist: 'itens_plotagens_hist'
      // Outras abas específicas do setor de plotagem
    },
    fields: {
      clientes: fieldsCadastroClienteGeral,
      servicos: ['id', 'data', 'CLIENTE', 'METROS', 'VALOR', 'PAGO', 'APAGAR', 'idCliente']
    }
  },
  cortes: {
    sheetUrl: sheetUrl,
    tabs: {
      clientes: cadastroClienteGeral,
      servicos: 'Corte_Geral',
      servicos_hist: 'Corte_Geral_hist',
      configPosicionamento: 'configPosicionamento'
      // Outras abas específicas do setor de cortes
    },
    fields: {
      clientes: fieldsCadastroClienteGeral,
      servicos: [
        'id',
        'data',
        'entregar',
        'data_corte',
        'cliente',
        'valor_do_corte',
        'pago',
        'a_receber',
        'tecido',
        'lar_tecido',
        'total_ref',
        'folhas',
        't_grade',
        'total_pecas',
        'id_cliente',
        'id_tecido',
        'total_rolos',
        'descricao',
        'embalagem'
      ],
      configPosicionamento: configPosicionamento
    }
  },
  modelagem: {
    sheetUrl: sheetUrl,
    tabs: {
      clientes: cadastroClienteGeral,
      configPosicionamento: 'configPosicionamento',
      grau_dificuldade_mod: 'grau_dificuldade_mod',
      cadastro_preco_molde: 'cadastro_preco_molde',
      editar_preco_molde: 'cadastro_preco_molde',
      cad_serv_modelagem: 'cad_serv_modelagem',
      cad_serv_modelagem_hist: 'cad_serv_modelagem_hist',
      itens_cad_serv_modelagem: 'itens_cad_serv_modelagem',
      itens_cad_serv_modelagem_hist: 'itens_cad_serv_modelagem_hist'
    },
    fields: {
      clientes: fieldsCadastroClienteGeral,

      configPosicionamento: configPosicionamento,
      grau_dificuldade_mod: ['id', 'grau', 'data'],
      cadastro_preco_molde: ['id', 'data', 'id_categoria', 'id_grau', 'descricao', 'preco'],
      cad_serv_modelagem: ['id', 'data', 'prevista', 'entregue', 'id_cliente', 'sinal'],
      itens_cad_serv_modelagem: [
        'id',
        'id_serv_mod',
        'id_modelagem',
        'valor',
        'pago',
        'a_pagar',
        'unidades'
      ]
    }
  },
  produtos: {
    sheetUrl: sheetUrl,
    tabs: {
      produtos: 'produtos',
      configPosicionamento: 'configPosicionamento'
      // Outras abas específicas do setor de cortes
    },
    fields: {
      produtos: ['id_produto', 'data', 'nome', 'descricao', 'id_categoria'],
      configPosicionamento: configPosicionamento
    }
  },
  cadastroClienteG: {
    sheetUrl: sheetUrl,
    tabs: {
      clientes: cadastroClienteGeral
    },
    fields: {
      clientes: fieldsCadastroClienteGeral
    }
  },
  cadastroServico: {
    sheetUrl: sheetUrl,
    tabs: {
      servicos: 'servicos'
    },
    fields: {
      servicos: ['id_servico', 'Data', 'Nome_Serviço', 'Descrição_Serviço', 'Ativo']
    }
  },
  cadastroCategoria: {
    sheetUrl: sheetUrl,
    tabs: {
      categorias: 'categorias'
    }
  },
  cadastroServicoCategoria: {
    sheetUrl: sheetUrl,
    tabs: {
      servico_categorias: 'servicos_categorias'
    }
  },
  cadastroClienteServico: {
    sheetUrl: sheetUrl,
    tabs: {
      clientes_servicos: 'clientes_servicos'
    },
    fields: {
      clientes_servicos: ['id', 'Data', 'ID_Cliente', 'id_servico', 'Ativo']
    }
  },
  log_de_atividades: {
    sheetUrl: sheetUrl,
    tabs: {
      log_de_atividades: 'log_de_atividades'
    },
    fields: {
      log_de_atividades: ['id', 'data', 'hora', 'operacao', 'status']
    }
  },
  pagamentos: {
    sheetUrl: sheetUrl,
    tabs: {
      pagamentos: 'pagamentos'
    },
    fields: {
      pagamentos: ['id', 'data', 'hora', 'idcliente', 'valor', 'status']
    }
  },
  ips_liberados: {
    sheetUrl: sheetUrl,
    tabs: {
      ips_liberados: 'ips_liberados'
    },
    fields: {
      ips_liberados: ['id', 'ip_liberado']
    }
  },
  categoriaPF: {
    tabs: {
      categoriaPF: 'categoriaPF'
    },
    fields: {
      categoriaPF: ['nome', 'descricao']
    }
  },
  produtoPF: {
    tabs: {
      cadastro_produtoPF: 'cadastro_produtoPF'
    },
    fields: {
      cadastro_produtoPF: ['sku', 'descricao', 'gramas', 'custo']
    }
  },
  marketplacePF: {
    tabs: {
      marketplacePF: 'marketplacePF'
    },
    fields: {
      marketplacePF: ['nome', 'status_ativo']
    }
  }

  // Outros setores
}

export default sheetsConfig
