// //cadastro-produto

import { useState, useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Button, FormGroup, Row, Col, Modal } from 'react-bootstrap'
import Select from 'react-select'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '/src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../../../../src/utils/form-utils.jsx'
import CadastroCategoria from '/PRECO_FACIL_MARKET/src/components/pages/Categorias/CadastroCategoria/cadastro-categoria'
import { AuthContext } from '/src/context/AuthContext'
import { FaPlus } from 'react-icons/fa'
const CadastroProdutoPF = () => {
  const [produtoPF, setProdutoPF] = useState({
    sku: '',
    descricao: '',
    gramas: '',
    custo: '',
    categoriaId: ''
  })
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [corAlerta, setCorAlerta] = useState('danger')
  const { tenantId } = useContext(AuthContext)
  const [showCategoriaModal, setShowCategoriaModal] = useState(false)
  const [categorias, setCategorias] = useState([])
  const [isLoadingCategorias, setIsLoadingCategorias] = useState(false)
  const toggleCategoriaModal = () => setShowCategoriaModal(!showCategoriaModal)
  useEffect(() => {
    if (tenantId) {
      // Garantir que tenantId está definido

      const fetchCategorias = async () => {
        setIsLoadingCategorias(true) // Começa o carregamento
        try {
          const categoriasCarregadas = await CarregarCategorias(tenantId)
          setCategorias(categoriasCarregadas || []) // Salva as categorias no estado
        } catch (error) {
          console.error('Erro ao carregar categorias:', error)
        }
        setIsLoadingCategorias(false) // Finaliza o carregamento
      }

      fetchCategorias()
    }
  }, [tenantId])

  const handleChangeCategoria = selectedOption => {
    setProdutoPF(prevState => ({
      ...prevState,
      categoriaId: selectedOption ? selectedOption.value : ''
    }))
  }

  const handleChange = e => {
    setProdutoPF({
      ...produtoPF,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    let dados = {
      ...produtoPF,
      categoriaId: produtoPF.categoriaId,
      tenantId: tenantId,
      requisicao: 'GravarProdutoPF'
    }
    let url = config.host + '/produtoPF'

    try {
      let response = await fetchData(url, dados)
      if (response.produto) {
        setProdutoPF({
          sku: '',
          descricao: '',
          gramas: '',
          custo: '',
          categoria: null
        })
        setShowAlert(true)
        setCorAlerta('success')
        setAlertMessage('Produto Gravado Com Sucesso')
      } else {
        setShowAlert(true)
        setCorAlerta('danger')
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao tentar gravar o produto. Tente novamente.')
    }
  }

  // Opções para o react-select
  const categoriaOptions = categorias
    ? categorias.map(categoria => ({
        value: categoria._id,
        label: categoria.nome
      }))
    : []

  return (
    <div
      className="d-flex align-items-center form-container"
      style={{ height: '100vh', position: 'relative' }}
    >
      <div className="text-center mx-auto" style={{ maxWidth: '2000px' }}>
        <Banner title="Cadastro de Produto" color="#2e8b57" />

        <FormGroup style={{ maxWidth: '1100px', margin: 'auto', paddingTop: '20px' }}>
          {showAlert && (
            <ShowAlertGeneric
              showAlert={showAlert}
              message={alertMessage}
              isBlurred={true}
              setShowAlert={setShowAlert}
              variant={corAlerta}
            />
          )}
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={6} className="mb-3">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    value={categoriaOptions.find(option => option.value === produtoPF.categoriaId)}
                    onChange={handleChangeCategoria}
                    options={categoriaOptions}
                    isLoading={isLoadingCategorias}
                    isClearable
                    isSearchable
                    placeholder="Selecione ou pesquise a Categoria"
                    styles={{
                      container: base => ({
                        ...base,
                        width: 350,
                        marginRight: '8px'
                      })
                    }}
                  />
                  <FaPlus
                    onClick={toggleCategoriaModal}
                    size={20}
                    color="green"
                    style={{ cursor: 'pointer' }}
                    title="Adicionar Categoria"
                  />

                  {/* Modal para o cadastro de categoria */}
                  <Modal show={showCategoriaModal} onHide={toggleCategoriaModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>Cadastrar Nova Categoria</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <CadastroCategoria fontSize={40} classEfeito3dMouseOver={''} />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={toggleCategoriaModal}>
                        Fechar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Col>
              <Col md={6} className="mb-3 pl-md-4">
                <input
                  type="text"
                  name="sku"
                  className="form-control"
                  placeholder="SKU do Produto"
                  onChange={handleChange}
                  value={produtoPF.sku}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4} className="mb-3">
                <input
                  type="text"
                  name="descricao"
                  className="form-control"
                  placeholder="Descrição do Produto"
                  onChange={handleChange}
                  value={produtoPF.descricao}
                />
              </Col>
              <Col md={4} className="mb-3">
                <input
                  type="number"
                  name="gramas"
                  className="form-control"
                  placeholder="Gramas"
                  onChange={handleChange}
                  value={produtoPF.gramas}
                />
              </Col>
              <Col md={4} className="mb-3">
                <input
                  type="number"
                  name="custo"
                  className="form-control"
                  placeholder="Custo"
                  onChange={handleChange}
                  value={produtoPF.custo}
                />
              </Col>
            </Row>
            <Button type="submit" className="btn btn-success" style={{ marginTop: '20px' }}>
              Cadastrar
            </Button>
          </form>
        </FormGroup>
      </div>
    </div>
  )
}

export default CadastroProdutoPF

export async function CarregarCategorias(tenantId) {
  try {
    let dados = {
      requisicao: 'CarregarCategoriasPF',
      tenantId: tenantId
    }
    let url = config.host + '/categoriaPF'

    let response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao carregar categorias:', error)
    // Trate o erro conforme necessário
  }
}

// import React, { useState, useEffect } from 'react';
// import { useQuery, useQueryClient } from 'react-query';
// import { Button, FormGroup, Row, Col } from 'react-bootstrap';
// import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner';
// import { fetchData } from '../../../../../../src/backend/model';
// import { config } from '/src/backend/configConexaoGeral';
// import AlertMessage from '../../../../../../src/components/pages/CadastroPlotagem/AlertMessage';
// import CadastroGeralPE from '../../../../../../src/components/pages/CadastroGeral/cadastro-geral-pesquisa-e-edicao';
// import CustomTable from '../../../../../../src/components/CustomTable/custom-table';
// import SmartTable from '../../../../../../src/components/SmartTable/smartTable';
// import SmartTableDetails from '../../../../../../src/components/SmartTable/smartTableDetails';
// import { ShowAlertGeneric } from '../../../../../../src/utils/form-utils';
// import Select from 'react-select';
// import { AuthContext } from '/src/context/AuthContext';
// import { useContext } from 'react';

// const CadastroProdutoPF = () => {
//   const [produtoPF, setProdutoPF] = useState({
//     sku: '',
//     descricao: '',
//     gramas: '',
//     custo: '',
//   });

//   const [showAlert, setShowAlert] = useState(false);
//   const [alertMessage, setAlertMessage] = useState('');
//   const [largura, setLargura] = useState();
//   const [altura, setAltura] = useState();
//   const [sizeAlert, setSizeAlert] = useState();
//   //const [categorias, setCategorias] = useState([]);
//   const [dados, setDados] = useState([]);
//   const [editableTotals, setEditableTotals] = useState([]);
//   const [IntemsTravadosNaPesquisa, setIntemsTravadosNaPesquisa] = useState([]);
//   const [ItensSelecionadosParaGravar, setItensSelecionadosParaGravar] =
//     useState([]);
//   const [selectedItemsSmartTable, setSelectedItemsSmartTable] = useState([]);
//   const [corAlerta, setCorAlerta] = useState('danger');
//   const { tenantId } = useContext(AuthContext);
//   const {
//     data: categorias,
//     isLoading: isLoadingCategorias,
//     refetch: refetchCategorias,
//     isError: isErrorCarregaCategorias,
//   } = useQuery(
//     'categorias',
//     () => CarregarCategorias(tenantId), // Sua função para buscar categorias
//     {
//       refetchOnWindowFocus: false,
//     }
//   );

//   function mensagem(mensagem, altura, largura, size) {
//     setAltura(altura);
//     setLargura(largura);
//     setAlertMessage(mensagem);
//     setSizeAlert(size);
//   }

//   const handleChange = (e) => {
//     setProdutoPF({
//       ...produtoPF,
//       [e.target.name]: e.target.value,
//     });
//   };
//   //----------------------------------------------
//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     let dados = {
//       sku: produtoPF.nome,
//       descricao: produtoPF.descricao,
//       gramas: produtoPF.gramas,
//       custo: produtoPF.custo,
//       requisicao: 'GravarProdutoPF',
//     };
//     let url = config.host + '/produtoPF';

//     try {
//       let response = await fetchData(url, dados);

//       if (response.produto) {
//         // Se o produto foi gravado com sucesso
//         handleSubmitRelacionamento(response.produto._id);
//         setProdutoPF({ sku: '', descricao: '', gramas: '', custo: '' });
//         setShowAlert(true);
//         setCorAlerta('success');
//         setAlertMessage('Produto Gravado Com Sucesso');
//       } else {
//         // Tratamento para resposta 409
//         setShowAlert(true);
//         setCorAlerta('danger');
//         setAlertMessage(response.message || 'Erro ao processar a requisição.');
//       }
//     } catch (error) {
//       // Tratamento de erros de rede ou falha na resposta
//       console.error('Erro na função fetchData', error);
//       setShowAlert(true);
//       setCorAlerta('danger');
//       setAlertMessage('Erro ao tentar gravar o produto. Tente novamente.');
//     }
//   };
//   //----------------------------------------------
//   const handleSubmitRelacionamento = async (idproduto) => {
//     const relacionamentos = selectedItemsSmartTable.map((categoriaId) => ({
//       produtoId: idproduto, // Substitua por como você obtém o ID do produto
//       categoriaId: categoriaId,
//       requisicao: 'GravarRelacionamentoProdutoCategoria',
//     }));

//     try {
//       // Enviar cada relacionamento individualmente
//       // Isso pode ser otimizado dependendo da sua API no servidor
//       for (const relacionamento of relacionamentos) {
//         let url = config.host + '/produtoPF';
//         await fetchData(url, relacionamento);
//       }
//       // Trate a resposta conforme necessário
//       // Por exemplo, mostrar uma mensagem de sucesso
//     } catch (error) {
//       // Trate erros de envio aqui
//       console.error('Erro ao gravar relacionamentos:', error);
//       // Mostrar uma mensagem de erro, se necessário
//     }
//   };

//   const handleCloseAlert = () => {
//     setShowAlert(false);
//   };
//   const handleSelectedItemsSmartTable = (selectedItems) => {
//     setSelectedItemsSmartTable(selectedItems);
//   };

//   const customTableData = selectedItemsSmartTable
//     .map((itemId) => categorias.find((categoria) => categoria._id === itemId))
//     .filter((item) => item !== undefined);

//   return (
//     <div
//       className='d-flex align-items-center form-container'
//       style={{ height: '100vh', position: 'relative' }}
//     >
//       <div className='text-center mx-auto' style={{ maxWidth: '2000px' }}>
//         <Banner title='Cadastro de Produto' color='#2e8b57' />
//         <h1 style={{ marginTop: '110px' }}>
//           Cadastro de Produto - preço facil market
//         </h1>
//         <FormGroup
//           style={{ maxWidth: '1100px', margin: 'auto', paddingTop: '20px' }}
//         >
//           {showAlert && (
//             <ShowAlertGeneric
//               showAlert={showAlert}
//               message={alertMessage}
//               isBlurred={true} // ou algum outro critério que deseja usar para blur
//               setShowAlert={setShowAlert}
//               variant={corAlerta} // ou qualquer outra variante que deseja usar
//             />
//           )}
//           <form onSubmit={handleSubmit}>
//             <Row>
//               <Col md={6} className='mb-3'>
//                 <div style={{ height: '300px', width: '500px' }}>
//                   <SmartTable
//                     data={categorias}
//                     camposLabels={[
//                       { field: 'nome', label: 'Nome' },
//                       { field: 'descricao', label: 'Descrição' },
//                     ]}
//                     fixedHeight='300px'
//                     tituloProps={{
//                       title: 'Categorias disponíveis',
//                       color: '#2e8b57',
//                       fontSize: 30,
//                       // ... outras props que você deseja passar para o Banner ...
//                     }}
//                     onSelectedItemsChange={handleSelectedItemsSmartTable}
//                   />
//                 </div>
//               </Col>
//               <Col md={6} className='mb-3 pl-md-4'>
//                 <div style={{ height: '300px', width: '500px' }}>
//                   <SmartTableDetails
//                     selectedData={customTableData}
//                     camposLabels={[
//                       { field: 'nome', label: 'Nome' },
//                       { field: 'descricao', label: 'Descrição' },
//                     ]}
//                     tituloProps={{
//                       title: 'Categorias selecionadas',
//                       color: '#2e8b57',
//                       fontSize: 30,
//                       // ... outras props que você deseja passar para o Banner ...
//                     }}

//                     // outros props necessários
//                   />
//                 </div>
//               </Col>
//             </Row>

//             <Row>
//               <Col md={4} className='mb-3'>
//                 <input
//                   type='text'
//                   name='sku'
//                   className='form-control'
//                   placeholder='SKU do Produto'
//                   onChange={handleChange}
//                   value={produtoPF.sku}
//                 />
//               </Col>
//               <Col md={4} className='mb-3'>
//                 <input
//                   type='text'
//                   name='descricao'
//                   className='form-control'
//                   placeholder='Descrição do Produto'
//                   onChange={handleChange}
//                   value={produtoPF.descricao}
//                 />
//               </Col>
//             </Row>
//             <Row>
//               <Col md={6} className='mb-3'>
//                 <input
//                   type='number'
//                   name='gramas'
//                   className='form-control'
//                   placeholder='Gramas'
//                   onChange={handleChange}
//                   value={produtoPF.gramas}
//                 />
//               </Col>
//               <Col md={6} className='mb-3'>
//                 <input
//                   type='number'
//                   name='custo'
//                   className='form-control'
//                   placeholder='Custo'
//                   onChange={handleChange}
//                   value={produtoPF.custo}
//                 />
//               </Col>
//             </Row>
//             <Button
//               type='submit'
//               className='btn btn-success'
//               style={{ marginTop: '20px' }}
//             >
//               Cadastrar
//             </Button>
//           </form>
//         </FormGroup>
//       </div>
//     </div>
//   );
// };

// export default CadastroProdutoPF;

// //-------------------------------------------------------------------------------------------------
// export async function CarregarCategorias(tenantId) {
//   try {
//     let dados = {
//       requisicao: 'CarregarCategoriasPF',
//       tenantId: tenantId,
//     };
//     let url = config.host + '/categoriaPF';

//     let response = await fetchData(url, dados);

//     return response;
//   } catch (error) {
//     console.error('Erro ao carregar categorias:', error);
//     // Trate o erro conforme necessário
//   }
// }
