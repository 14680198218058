// CadastroSubSistema
import { useState } from 'react'
import Banner from '/src/components/pages/CadastroGeral/Banner'
import { fetchData } from '/src/backend/model'

import { useNavigate } from 'react-router-dom'
import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'

const CadastroSubSistema = () => {
  const [subsystemName, setSubsystemName] = useState('')
  const [subsystemDescription, setSubsystemDescription] = useState('')
  const [subsystemUrlBase, setSubsystemUrlBase] = useState('')
  const [subsystemRouteBase, setSubsystemRouteBase] = useState('')
  const [subsystemStatus, setSubsystemStatus] = useState('ativo')
  const [subsystemVersion, setSubsystemVersion] = useState('')
  const [subsystemResponsible, setSubsystemResponsible] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const handleSubmit = async event => {
    event.preventDefault()

    try {
      let url = host + '/subSistemas' // Endpoint para cadastro de subsistemas
      let dados = {
        name: subsystemName,
        description: subsystemDescription,
        urlBase: subsystemUrlBase,
        routeBase: subsystemRouteBase,
        status: subsystemStatus,
        version: subsystemVersion,
        responsible: subsystemResponsible,
        requisicao: 'registerSubsystem'
      }

      let response = await fetchData(url, dados)

      if (response && response.success) {
        setShowAlertMessage(true)
        setAlertMessage('SUB SISTEMA GRAVADO COM SUCESSO!')
        setTimeout(() => {
          navigate('/home') // Redirecionar para a home ou página de confirmação
        }, 3000)
      } else {
        setErrorMessage(response.message || 'Erro desconhecido')
      }
    } catch (error) {
      console.error('Erro no cadastro do subsistema', error)
      setErrorMessage('Erro ao tentar se conectar ao servidor.')
    }
  }

  return (
    <div className="cadastro-sub-sistema-container">
      <Banner
        title="Cadastro de Sub Sistema"
        className="banner-containerSubSistema"
        width="36%"
        fontSize="70px"
      />
      <ShowAlertGeneric
        variant="success"
        showAlert={showAlertMessage}
        message={alertMessage}
        isBlurred={true} // ou algum outro critério que deseja usar para blur
        setShowAlert={setShowAlertMessage}
      />
      <div className="form-container_subSistema">
        <form onSubmit={handleSubmit} className="subsystem-form">
          {/* Nome do Subsistema */}
          <div className="mb-3">
            <label htmlFor="subsystemName" className="form-label">
              Nome do Subsistema
            </label>
            <input
              type="text"
              id="subsystemName"
              value={subsystemName}
              onChange={e => setSubsystemName(e.target.value)}
              className="form-control"
              required
            />
          </div>

          {/* Descrição do Subsistema */}
          <div className="mb-3">
            <label htmlFor="subsystemDescription" className="form-label">
              Descrição
            </label>
            <textarea
              id="subsystemDescription"
              value={subsystemDescription}
              onChange={e => setSubsystemDescription(e.target.value)}
              className="form-control"
              rows="3"
              required
            ></textarea>
          </div>

          {/* URL Base do Subsistema */}
          <div className="mb-3">
            <label htmlFor="subsystemUrlBase" className="form-label">
              URL Base
            </label>
            <input
              type="text"
              id="subsystemUrlBase"
              value={subsystemUrlBase}
              onChange={e => setSubsystemUrlBase(e.target.value)}
              className="form-control"
              required
            />
          </div>
          {/* Campo de entrada para Rota Base */}
          <div className="mb-3">
            <label htmlFor="subsystemRouteBase" className="form-label">
              Rota Base
            </label>
            <input
              type="text"
              id="subsystemRouteBase"
              value={subsystemRouteBase}
              onChange={e => setSubsystemRouteBase(e.target.value)}
              className="form-control"
              required
            />
          </div>
          {/* Status do Subsistema */}
          <div className="mb-3">
            <label htmlFor="subsystemStatus" className="form-label">
              Status
            </label>
            <select
              id="subsystemStatus"
              value={subsystemStatus}
              onChange={e => setSubsystemStatus(e.target.value)}
              className="form-select"
              required
            >
              <option value="ativo">Ativo</option>
              <option value="em manutenção">Em manutenção</option>
              <option value="desativado">Desativado</option>
            </select>
          </div>

          {/* Versão do Subsistema */}
          <div className="mb-3">
            <label htmlFor="subsystemVersion" className="form-label">
              Versão
            </label>
            <input
              type="text"
              id="subsystemVersion"
              value={subsystemVersion}
              onChange={e => setSubsystemVersion(e.target.value)}
              className="form-control"
              required
            />
          </div>

          {/* Responsável pelo Subsistema */}
          <div className="mb-3">
            <label htmlFor="subsystemResponsible" className="form-label">
              Responsável
            </label>
            <input
              type="text"
              id="subsystemResponsible"
              value={subsystemResponsible}
              onChange={e => setSubsystemResponsible(e.target.value)}
              className="form-control"
              required
            />
          </div>

          {/* Botão de Submissão */}
          <div className="submit-container">
            <button type="submit" className="btn btn-primary">
              Cadastrar Subsistema
            </button>
          </div>

          {/* Mensagem de Erro */}
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

export default CadastroSubSistema
