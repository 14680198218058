import React, { useState, useEffect } from "react";


const ListaProdutosMercadoLivre = () => {
  const [produtos, setProdutos] = useState([]);
  const [access_token, setAccess_token] = useState(null);
  const [errored, setErrored] = useState(false);
  const fetchToken = async (validToken = true) => {
    try {
      const res = await fetch(
        "https://api.negocios360.com.br/get-token-ml?validToken=" + validToken
      );
      const data = await res.json();
      setAccess_token(data.access_token);
      setErrored(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []); // Aqui fetchToken é chamado na montagem do componente.

  useEffect(() => {
    if (errored) {
      fetchToken(false); // Aqui fetchToken é chamado se errored for verdadeiro.
    }
  }, [errored]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          "https://api.negocios360.com.br/listagem-produtos-mercado-livre",
          {
            headers: {
              Authorization: "Bearer " + access_token,
              "id-loja": "7821284113928377",
              "Content-Type": "application/json",
            },
          }
        );

        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }

        const data = await res.json();
        setProdutos(data.items);
      } catch (err) {
        console.error(err);
        setErrored(true);
      }
    };

    if (access_token) {
      fetchData();
    }
  }, [access_token]);

  return (
    <div className="container">
      <h1>Produtos Mercado Livre</h1>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Titulo</th>
            <th>Preco</th>
            <th>Estoque</th>
          </tr>
        </thead>
        <tbody>
          {produtos.map((produto) => (
            <tr key={produto.id}>
              <td>{produto.id}</td>
              <td>{produto.title}</td>
              <td>R$ {produto.price}</td>
              <td>{produto.available_quantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaProdutosMercadoLivre;
