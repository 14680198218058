import Banner from '../Home/Banner'

function Inicio() {
  return (
    <div>
      <Banner />
    </div>
  )
}
export default Inicio
