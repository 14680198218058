import CreatableSelect from 'react-select/creatable'
import React, { useRef, useEffect, useState } from 'react'
import AlertMessage from '/src/components/pages/PLOTAGEM/AlertMessage'

interface Option {
  readonly value: string
  readonly label: string
}

interface Props {
  Options: Option[]
  id?: string
  isClearable?: boolean
  isMulti?: boolean
  value?: any
  onChange?: (value: any, actionMeta: any) => void
  placeholder?: string
  isLoading?: boolean
  showLoading?: boolean
  variant1?: string
  variant2?: string
}

const SelectFixedWithLoading: React.FC<Props> = ({
  Options,
  id,
  isClearable,
  isMulti,
  value,
  onChange,
  placeholder,
  isLoading,
  showLoading,
  variant1,
  variant2
}) => {
  const selectRef = useRef<HTMLDivElement | null>(null)
  const [selectSize, setSelectSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (selectRef.current) {
      setSelectSize({
        width: selectRef.current.offsetWidth,
        height: selectRef.current.offsetHeight
      })
    }
  }, [selectRef])

  const createLoadingMessage = placeholder => {
    const words = placeholder.split(' ')
    const lastTwoWords = words.slice(-2)
    const pluralizedWords = lastTwoWords.map(word => word + 's')
    return `Carregando ${pluralizedWords.join(' ')}`
  }
  return (
    <div style={{ position: 'relative' }} ref={selectRef}>
      <CreatableSelect
        isMulti
        options={Options}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {isLoading && showLoading && (
        <div
          style={{
            position: 'absolute',
            top: '-8px',
            left: '-7px'
          }}
        >
          <AlertMessage
            message={createLoadingMessage(placeholder)}
            size="small"
            customHeight={selectSize.height}
            customWidth={selectSize.width}
            variant1={variant1}
            variant2={variant2}
            variantDefault="success"
            isBlinking={true}
          />
        </div>
      )}
    </div>
  )
}
export default SelectFixedWithLoading
