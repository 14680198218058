import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../../../src/context/AuthContext.jsx'
import { fetchData } from '../../../../src/backend/model.js'
import { config } from '../../../../src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../../src/utils/form-utils.jsx'
import { Container, Spinner, Form, Button } from 'react-bootstrap'
import Banner from '../../../../src/components/pages/CadastroGeral/Banner'

const GerenciamentoHorariosEntradaSaida = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [setores, setSetores] = useState([])
  const [setorSelecionado, setSetorSelecionado] = useState('')
  const [cargosDoSetor, setCargosDoSetor] = useState([])
  const [cargoSelecionado, setCargoSelecionado] = useState('')
  const [horaEntrada, setHoraEntrada] = useState('')
  const [horaSaida, setHoraSaida] = useState('')
  const [horarios, setHorarios] = useState(null) // Estado para armazenar os horários
  const [loading, setLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')

  // Carregar setores ao iniciar
  useEffect(() => {
    const fetchSetores = async () => {
      try {
        const url = `${config.host}/gerenciarSetores`

        const setoresResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarTodosOsSetores'
        })
        setSetores(setoresResponse.setores || [])
      } catch (error) {
        console.error('Erro ao buscar setores:', error)
      }
    }

    fetchSetores()
  }, [RotaBase, tenantId])

  // Carregar cargos ao selecionar setor
  useEffect(() => {
    if (setorSelecionado) {
      const fetchCargos = async () => {
        try {
          const urlCargos = `${config.host}/gerenciarCargos`
          const cargosResponse = await fetchData(urlCargos, {
            tenantId,
            rota_base: RotaBase,
            setorId: setorSelecionado,
            requisicao: 'listarCargosDoSetor'
          })
          setCargosDoSetor(cargosResponse.cargos || [])
        } catch (error) {
          console.error('Erro ao buscar cargos:', error)
        }
      }

      fetchCargos()
    }
  }, [setorSelecionado])

  // Carregar horários ao selecionar cargo
  useEffect(() => {
    if (cargoSelecionado) {
      const fetchHorarios = async () => {
        try {
          const urlHorarios = `${config.host}/gerenciarHorarios`
          const horariosResponse = await fetchData(urlHorarios, {
            tenantId,
            rota_base: RotaBase,
            cargoId: cargoSelecionado,
            requisicao: 'listarHorariosPorCargo'
          })
          console.log('horariosResponse', horariosResponse)

          // Verifica se a resposta tem ao menos um horário e acessa o primeiro
          if (horariosResponse && horariosResponse.length > 0) {
            const horario = horariosResponse[0]
            setHorarios(horario)
            setHoraEntrada(horario.horaEntrada || '')
            setHoraSaida(horario.horaSaida || '')
          } else {
            setHorarios(null)
            setHoraEntrada('')
            setHoraSaida('')
          }
        } catch (error) {
          console.error('Erro ao buscar horários:', error)
        }
      }

      fetchHorarios()
    } else {
      setHorarios(null)
      setHoraEntrada('')
      setHoraSaida('')
    }
  }, [cargoSelecionado])

  const handleSalvarHorarios = async () => {
    setLoading(true)
    try {
      const url = `${config.host}/gerenciarHorarios`

      await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        setorId: setorSelecionado,
        cargoId: cargoSelecionado,
        horaEntrada,
        horaSaida,
        requisicao: 'salvarHorarios'
      })

      setHoraEntrada('')
      setHoraSaida('')
      setSetorSelecionado('')
      setCargoSelecionado('')
      setAlertVariant('success')
      setAlertMessage('Horários salvos com sucesso!')
      setShowAlert(true)
      setLoading(false)
    } catch (error) {
      console.error('Erro ao salvar horários:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao salvar horários')
      setShowAlert(true)
      setLoading(false)
    }
  }

  return (
    <Container
      fluid
      className="p-4"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div style={{ width: '100%', maxWidth: '700px' }}>
        <Banner
          title="Gerenciamento de Horários"
          width="100%"
          fontSize={47}
          classEfeito3dMouseOver=""
        />
        <Form.Group className="mb-3">
          <Form.Label>Selecione o Setor</Form.Label>
          <Form.Control
            as="select"
            value={setorSelecionado}
            onChange={e => setSetorSelecionado(e.target.value)}
          >
            <option value="">Selecione um setor</option>
            {setores.map(setor => (
              <option key={setor._id} value={setor._id}>
                {setor.setor}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Selecione o Cargo</Form.Label>
          <Form.Control
            as="select"
            value={cargoSelecionado}
            onChange={e => setCargoSelecionado(e.target.value)}
            disabled={!setorSelecionado}
          >
            <option value="">Selecione um cargo</option>
            {cargosDoSetor.map(cargo => (
              <option key={cargo._id} value={cargo._id}>
                {cargo.nome}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Hora de Entrada</Form.Label>
          <Form.Control
            type="time"
            value={horaEntrada}
            onChange={e => setHoraEntrada(e.target.value)}
            disabled={!cargoSelecionado}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Hora de Saída</Form.Label>
          <Form.Control
            type="time"
            value={horaSaida}
            onChange={e => setHoraSaida(e.target.value)}
            disabled={!cargoSelecionado}
          />
        </Form.Group>

        <Button
          variant="primary"
          onClick={handleSalvarHorarios}
          disabled={!cargoSelecionado || !horaEntrada || !horaSaida}
        >
          Salvar Horários
        </Button>
      </div>

      {/* {loading && <Spinner animation="border" />} */}

      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
    </Container>
  )
}

export default GerenciamentoHorariosEntradaSaida
