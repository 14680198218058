import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Table, Button, Form, Modal, Alert } from 'react-bootstrap'
import { formatDate } from '/src/utils/date-utils'

export function imprimirFinanceiro(
  cliente,
  totaisAreceber,
  totalPago,
  faltaPagar,
  cortes,
  plotagems
) {
  let popupWin = window.open('', '_blank', 'width=' + screen.width + ',height=' + screen.height)

  let htmlContent = ReactDOMServer.renderToStaticMarkup(
    <div>
      <h2>Relatório Financeiro: {cliente}</h2>
      <div>
        <p>
          Cortes:{' '}
          {totaisAreceber.cortes.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })}
        </p>
        <p>
          Embalagem:{' '}
          {totaisAreceber.embalagens.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })}
        </p>
        {totaisAreceber.riscos > 0 && (
          <p>
            Plotagens:{' '}
            {totaisAreceber.riscos.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL'
            })}
          </p>
        )}

        <p>
          Total:{' '}
          {totaisAreceber.total.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })}
        </p>
        <p>
          Total Pago:{' '}
          {totalPago.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })}
        </p>
        <p>
          Falta Pagar:{' '}
          {faltaPagar.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })}
        </p>
      </div>
      <h3>Detalhamento dos Cortes</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Data</th>
            <th>Descrição</th>
            <th>Tecido</th>
            <th>Corte</th>
            <th>Embalagem</th>
          </tr>
        </thead>
        <tbody>
          {cortes.map((corte, index) => (
            <tr key={index}>
              <td>{corte.id}</td>
              <td>{formatDate(corte.data)}</td>
              <td>{corte.descricao}</td>
              <td>{corte.tecido}</td>
              <td>{corte.a_receber}</td>
              <td>{corte.embalagem}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <h3>Detalhamento das Plotagens</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Data</th>
            <th>Metros</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {plotagems.map((plotagem, index) => (
            <tr key={index}>
              <td>{plotagem.id}</td>
              <td>{formatDate(plotagem.data)}</td>
              <td>{plotagem.metros}</td>
              <td>{plotagem.apagar}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )

  popupWin.document.open()
  popupWin.document.write(
    '<html><head><title>Impressão de Financeiro</title><style>' +
      '/* Definindo uma fonte geral para o documento */' +
      'body {' +
      '  font-family: Arial, sans-serif;' +
      '  font-size: 14px;' +
      '  color: #333;' +
      '}' +
      '/* Personalizando o cabeçalho */' +
      'h2 {' +
      '  color: #444;' +
      '  margin-bottom: 20px;' +
      '}' +
      '/* Adicionando um espaçamento entre as células da tabela */' +
      'table {' +
      '  border-spacing: 10px;' +
      '  border-collapse: separate;' +
      '}' +
      '/* Estilizando as células do cabeçalho da tabela */' +
      'th {' +
      '  background-color: #f0f0f0;' +
      '  padding: 10px;' +
      '  text-align: left;' +
      '}' +
      '/* Estilizando as células do corpo da tabela */' +
      'td {' +
      '  padding: 10px;' +
      '}' +
      '</style></head><body onload="window.print(); window.close();">' +
      htmlContent +
      '</body></html>'
  )

  popupWin.document.close()
  popupWin.onload = function () {
    popupWin.print()
  }
}
