const initialState = {
  tipoPessoa: '',
  showAlertGravando: false
}

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TIPO_PESSOA':
      return { ...state, tipoPessoa: action.payload }
    case 'SET_SHOW_ALERT_GRAVANDO':
      return { ...state, showAlertGravando: action.payload }
    case 'SET_SHOW_ALERT':
      return { ...state, showAlert: action.payload }
    default:
      return state
  }
}
