import { pegarDados, puxarDadosDaPlanilha } from '../../src/backend/model.js'
import sheetsConfig from '../../src/config/sheetsConfig'
import { tratarString } from '../../src/components/pages/Financeiro/financeiro.jsx'
import { config } from '../backend/configConexaoGeral'
import { fetchData } from '../../src/backend/model'
export async function buscarClientePorId(clienteId, setClienteEditando, selectedSector) {
  const sheetUrl = sheetsConfig[selectedSector].sheetUrl
  const paginaClientes = sheetsConfig[selectedSector].tabs.clientes

  const dados = {
    link: sheetUrl,
    pagina1: paginaClientes,
    celulas: 'A2:R',
    requisicao: 'buscarClientePorId',
    verificarDados: tratarDadosPlotagemClientePorId,
    colunaUltimaLinha: 'A',
    tituloColunaUltimaLinha: 'id',
    filtro: '',
    id: clienteId
  }

  const response = await puxarDadosDaPlanilha(dados)

  const clienteEncontrado = response

  setClienteEditando(clienteEncontrado)
}

//-------------------------------------------------------------------------------------
export async function carregarClientesDoServico(idServico, tenantId, rota_base) {
  const sheetUrl = sheetsConfig.plotagem.sheetUrl
  const Clientes = sheetsConfig.plotagem.tabs.clientes
  const ClientesServicos = sheetsConfig.cadastroClienteServico.tabs.clientes_servicos

  const clientes = await pegarDados({
    link: sheetUrl,
    requisicao: 'filtrarPorServico',
    rota: 'cadastroServicoModelagem',
    verificarDados: verificarDadosPlotagem,
    sheet_dos_dados: Clientes,
    sheet_de_ligacao: ClientesServicos,
    id_ligacao: idServico,
    tenantId: tenantId,
    rota_base: rota_base
  })

  return clientes
}

function verificarDadosPlotagem(dados) {
  const clientes = dados.slice(0).map(cliente => {
    const nomeTratado = tratarString(`${cliente.Nome} ${cliente.Sobrenome} - ${cliente.Empresa}`)

    return {
      id: cliente.id,
      _id: cliente._id,
      data: cliente.Data,
      nome: nomeTratado,
      // empresa: cliente[3],
      telefone: cliente.Tel,
      // sobrenome: cliente[5],
      cpf: cliente.CPF,
      tipoPessoa: cliente.Tipo_Pessoa,
      sexo: cliente.Sexo,
      email: cliente.Email,
      cep: cliente.Cep,
      endereco: cliente.Endereco,
      numero: cliente.Numero,
      complemento: cliente.Complemento,
      bairro: cliente.Bairro,
      cidade: cliente.Cidade,
      uf: cliente.UF,
      cnpj: cliente.CNPJ,
      categoria_cliente: cliente.Categoria_Cliente
    }
  })

  return clientes
}

//--------------------------------------------------------------------------------------

function tratarDadosPlotagemClientePorId(dados) {
  const clientesData = dados

  const clientes = {
    id: clientesData[0],
    data: clientesData[1],
    nome: clientesData[2],
    empresa: clientesData[3],
    telefone: clientesData[4],
    sobrenome: clientesData[5],
    cpf: clientesData[6],
    tipoPessoa: clientesData[7],
    sexo: clientesData[8],
    email: clientesData[9],
    cep: clientesData[10],
    endereco: clientesData[11],
    numero: clientesData[12],
    complemento: clientesData[13],
    bairro: clientesData[14],
    cidade: clientesData[15],
    uf: clientesData[16],
    cnpj: clientesData[17],
    categoria_cliente: clientesData[18]
  }

  return clientes
}

//--------------------------------------------------------------------------------------
export async function buscarServicos(RotaBase) {
  const dados = {
    requisicao: 'buscarServicos',
    rota_base: RotaBase
  }

  const url = `${config.host}/configuracoesGerais` // Rota definida para 'buscarServicos'

  try {
    const response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao buscar serviços:', error)
    throw error
  }
}
//-------------------------------------------------------------------------------------
function tratarDadosServicos(dados) {
  const servicosData = dados.result

  const servicos = servicosData.slice(0).map(servico => {
    return {
      id_servico: servico[0],
      data: servico[1],
      nome_servico: servico[2],
      descricao_servico: servico[3],
      ativo: servico[4]
    }
  })

  return servicos
}
