import { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { groups } from './cadastro-tamanho-corte.js'
export const CadastroTamanhosCorte = ({ tamanhos, setTamanhos }) => {
  // Definição dos grupos de tamanhos

  // Estado para controlar a visibilidade de cada grupo
  const [visibleGroups, setVisibleGroups] = useState({
    grupo1: false,
    grupo2: false,
    grupo3: false
  })

  // Estado para armazenar IDs dos timeouts por grupo
  const [timeoutIds, setTimeoutIds] = useState({
    grupo1: null,
    grupo2: null,
    grupo3: null
  })

  // Função para lidar com o mouse entrando em um título de grupo
  const handleGroupMouseEnter = groupId => {
    // Limpa qualquer timeout existente para o grupo atual
    if (timeoutIds[groupId]) {
      clearTimeout(timeoutIds[groupId])
      setTimeoutIds(prev => ({ ...prev, [groupId]: null }))
    }

    // Atualiza a visibilidade de todos os grupos
    setVisibleGroups(prev => {
      const newVisibleGroups = { ...prev }

      groups.forEach(group => {
        if (group.id === groupId) {
          newVisibleGroups[group.id] = true // Mostra o grupo atualmente hover
        } else {
          // Verifica se o grupo possui algum tamanho selecionado
          const anyChecked = group.sizes.some(size => tamanhos[size] !== undefined)
          newVisibleGroups[group.id] = anyChecked // Mantém visível se algum tamanho estiver selecionado
        }
      })

      return newVisibleGroups
    })
  }

  // Função para lidar com o mouse saindo de um título de grupo
  const handleGroupMouseLeave = groupId => {
    // Inicia um timeout para esconder o grupo após 2 segundos
    const timeoutId = setTimeout(() => {
      // Verifica se algum tamanho está selecionado no grupo
      const group = groups.find(g => g.id === groupId)
      const anyChecked = group.sizes.some(size => tamanhos[size] !== undefined)
      if (!anyChecked) {
        setVisibleGroups(prev => ({ ...prev, [groupId]: false }))
        setTimeoutIds(prev => ({ ...prev, [groupId]: null }))
      }
    }, 1200)

    setTimeoutIds(prev => ({ ...prev, [groupId]: timeoutId }))
  }

  // Função para lidar com mudanças no checkbox
  const handleCheckboxChange = (e, tamanho, groupId) => {
    const isChecked = e.target.checked

    setTamanhos(prev => ({
      ...prev,
      [tamanho]: isChecked ? 1 : undefined
    }))

    if (isChecked) {
      // Se o checkbox for marcado, mostra o grupo e limpa qualquer timeout
      setVisibleGroups(prev => {
        const newVisibleGroups = { ...prev }
        newVisibleGroups[groupId] = true

        // Oculta todos os outros grupos que não possuem tamanhos selecionados
        groups.forEach(group => {
          if (group.id !== groupId) {
            const anyChecked = group.sizes.some(size => tamanhos[size] !== undefined)
            newVisibleGroups[group.id] = anyChecked
          }
        })

        return newVisibleGroups
      })

      if (timeoutIds[groupId]) {
        clearTimeout(timeoutIds[groupId])
        setTimeoutIds(prev => ({ ...prev, [groupId]: null }))
      }
    } else {
      // Se o checkbox for desmarcado, verifica se há outros checkboxes marcados no grupo
      const group = groups.find(g => g.id === groupId)
      const anyChecked = group.sizes.some(size => size !== tamanho && tamanhos[size] !== undefined)
      if (!anyChecked) {
        // Inicia um timeout para esconder o grupo após 2 segundos
        const timeoutId = setTimeout(() => {
          setVisibleGroups(prev => ({ ...prev, [groupId]: false }))
          setTimeoutIds(prev => ({ ...prev, [groupId]: null }))
        }, 1200)
        setTimeoutIds(prev => ({ ...prev, [groupId]: timeoutId }))
      }
    }
  }

  // Função para lidar com mudanças nos valores dos inputs
  const handleValueChange = (e, tamanho) => {
    const value = parseInt(e.target.value, 10)
    setTamanhos(prev => ({
      ...prev,
      [tamanho]: value > 0 ? value : 1
    }))
  }

  // Função para lidar com o evento de blur nos inputs
  const handleBlur = () => {
    setTamanhos(tamanhos)
  }

  // Limpa os timeouts quando o componente é desmontado
  useEffect(() => {
    return () => {
      Object.values(timeoutIds).forEach(id => {
        if (id) clearTimeout(id)
      })
    }
  }, [timeoutIds])

  return (
    <Form>
      <h2 className="CTC-main-title">Marque os tamanhos</h2>
      <div className="CTC-border-container">
        {/* Coluna Esquerda: Títulos dos Grupos */}
        <div className="CTC-left-column">
          {groups.map(group => (
            <div
              key={group.id}
              className="CTC-group-title"
              onMouseEnter={() => handleGroupMouseEnter(group.id)}
              onMouseLeave={() => handleGroupMouseLeave(group.id)}
            >
              {group.title}
            </div>
          ))}
        </div>

        {/* Coluna Direita: Conteúdo dos Grupos */}
        <div className="CTC-right-column">
          {groups.map(group =>
            visibleGroups[group.id] ? (
              <div
                key={group.id}
                className="CTC-group-content"
                onMouseEnter={() => handleGroupMouseEnter(group.id)}
                onMouseLeave={() => handleGroupMouseLeave(group.id)}
              >
                {group.sizes.map(tamanho => (
                  <CTCTamanhoItem
                    key={tamanho}
                    tamanho={tamanho}
                    groupId={group.id}
                    tamanhos={tamanhos}
                    handleCheckboxChange={handleCheckboxChange}
                    handleValueChange={handleValueChange}
                    handleBlur={handleBlur}
                  />
                ))}
              </div>
            ) : null
          )}
        </div>
      </div>
    </Form>
  )
}

const CTCTamanhoItem = ({
  tamanho,
  groupId,
  tamanhos,
  handleCheckboxChange,
  handleValueChange,
  handleBlur
}) => {
  const isChecked = tamanhos[tamanho] !== undefined
  const value = tamanhos[tamanho] || 1

  return (
    <div className="CTC-tamanho-item mb-3 d-flex align-items-center">
      {/* Container fixo para o checkbox */}
      <div className="CTC-checkbox-container">
        <Form.Check
          type="checkbox"
          label={tamanho}
          checked={isChecked}
          onChange={e => handleCheckboxChange(e, tamanho, groupId)}
        />
      </div>
      {/* Container para o input, reservado mesmo quando não está visível */}
      <div className="CTC-input-container">
        {isChecked && (
          <Form.Control
            type="number"
            min="1"
            value={value}
            onChange={e => handleValueChange(e, tamanho)}
            onBlur={handleBlur}
            className="CTC-input-control"
          />
        )}
      </div>
    </div>
  )
}
