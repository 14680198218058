export const groups = [
  {
    id: 'grupo1',
    title: 'PP ao XXGG',
    sizes: ['PP', 'P', 'M', 'G', 'GG', 'UNICO']
  },
  {
    id: 'grupo2',
    title: '36 ao 50',
    sizes: ['36', '38', '40', '42', '44', '46', '48', '50']
  },
  {
    id: 'grupo3',
    title: '1 ao 16',
    sizes: ['1', '2', '3', '4', '5', '6', '8', '10', '12', '14', '16']
  },

  {
    id: 'grupo4',
    title: 'Detalhes',
    sizes: ['Entretela', 'Forro', 'Cinto']
  }
]
