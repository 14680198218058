import { useState, useEffect } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import logoAmericanas from './logoAmericanas.png'
import logoViaVarejo from './logoViaVarejo.jpeg'
import { AuthContext } from '../../../../../../src/context/AuthContext'
import { useContext } from 'react'
import { carregaIdMarketplace } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraMagalu/regra-magalu.jsx'
import { useParams } from 'react-router-dom'
const CadastroRegrasFreteAmVv = () => {
  const { marketplace } = useParams()
  const [regra, setRegra] = useState({
    faixa_preco: { min: '', max: '' },
    grupo: '',
    peso: { min: '', max: '' },
    valor_frete: '',
    subsidio: { percentual: '' }
  })
  const [valorFreteSubsidiado, setValorFreteSubsidiado] = useState('')
  const [idMarketplace, setIdMarketplace] = useState('')
  const { tenantId } = useContext(AuthContext)

  const calcularValorFreteSubsidiado = (valorFrete, percentualSubsidio) => {
    if (valorFrete && percentualSubsidio) {
      const valorSubsidiado = valorFrete - valorFrete * (percentualSubsidio / 100)
      return valorSubsidiado.toFixed(2) // Formatar para duas casas decimais
    }
    return ''
  }

  const handleChange = e => {
    const { name, value } = e.target
    const keys = name.split('.')
    const updatedRegra = { ...regra }

    if (keys.length === 1) {
      const [field] = keys
      updatedRegra[field] = value
    } else if (keys.length === 2) {
      const [field, subfield] = keys
      if (!updatedRegra[field]) {
        updatedRegra[field] = {}
      }
      updatedRegra[field][subfield] = value
    } else if (keys.length === 3) {
      const [field, subfield, subsubfield] = keys
      if (!updatedRegra[field]) {
        updatedRegra[field] = {}
      }
      if (!updatedRegra[field][subfield]) {
        updatedRegra[field][subfield] = {}
      }
      updatedRegra[field][subfield][subsubfield] = value
    } else {
      console.error('Unexpected key length:', keys.length)
    }

    setRegra(updatedRegra)

    // Recalcular o valor do frete subsidiado
    if (keys.includes('valor_frete') || keys.includes('subsidio')) {
      const novoValorFrete = updatedRegra.valor_frete
      const novoPercentualSubsidio = updatedRegra.subsidio.percentual
      const novoValorFreteSubsidiado = calcularValorFreteSubsidiado(
        novoValorFrete,
        novoPercentualSubsidio
      )
      setValorFreteSubsidiado(novoValorFreteSubsidiado)
    }
  }

  useEffect(() => {
    async function carregarDados() {
      let idMarketplace
      if (marketplace === 'americanas') {
        idMarketplace = await carregaIdMarketplace('Americanas')
      } else if (marketplace === 'viavarejo') {
        idMarketplace = await carregaIdMarketplace('Via Varejo')
      } else {
        console.error('Marketplace inválido:', marketplace)
      }

      setIdMarketplace(idMarketplace[0]._id)
    }
    carregarDados()
  }, [marketplace])
  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const dados = {
        marketplaceId: idMarketplace,
        regra: {
          faixa_preco: {
            min: regra.faixa_preco.min,
            max: regra.faixa_preco.max
          },
          regras: [
            {
              grupo: regra.grupo,
              peso: {
                min: regra.peso.min,
                max: regra.peso.max
              },
              valor_frete: regra.valor_frete,
              subsidio: {
                percentual: regra.subsidio.percentual
              }
            }
          ]
        },
        requisicao: 'GravarRegraFreteAmVv'
      }
      const url = config.host + '/regraDescontoFrete'
      await fetchData(url, dados)
      alert('Regra de frete cadastrada com sucesso!')
    } catch (error) {
      console.error('Erro ao gravar a regra de frete:', error)
      alert('Erro ao gravar a regra de frete.')
    }
  }

  return (
    <div
      className="d-flex align-items-center form-container"
      style={{ height: '100vh', position: 'relative', paddingTop: '16px', overflow: 'auto' }}
    >
      <div className="text-center mx-auto" style={{ maxWidth: '1100px', padding: '16px' }}>
        <div style={{ marginBottom: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={
                marketplace === 'americanas'
                  ? logoAmericanas
                  : marketplace === 'viavarejo'
                  ? logoViaVarejo
                  : ''
              }
              alt="Logo"
              className="logo-americanas"
              style={{ width: '153px', height: '69px' }}
            />
            <div style={{ marginLeft: '16px' }}>
              <Banner title="Cadastro de Regras de Frete" color="#2e8b57" fontSize={50} />
            </div>
          </div>
        </div>

        <Row>
          <Col md={12}>
            <h5>Cadastro de Regras de Frete</h5>
            <Form onSubmit={handleSubmit}>
              <Form.Group as={Row} controlId="faixaPrecoMin">
                <Form.Label column sm={4}>
                  Faixa de Preço Mínima
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    name="faixa_preco.min"
                    value={regra.faixa_preco.min}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="faixaPrecoMax">
                <Form.Label column sm={4}>
                  Faixa de Preço Máxima
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    name="faixa_preco.max"
                    value={regra.faixa_preco.max}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="grupo">
                <Form.Label column sm={4}>
                  Grupo
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    name="grupo"
                    value={regra.grupo}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="pesoMin">
                <Form.Label column sm={4}>
                  Peso Mínimo
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    name="peso.min"
                    value={regra.peso.min}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="pesoMax">
                <Form.Label column sm={4}>
                  Peso Máximo
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    name="peso.max"
                    value={regra.peso.max}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="valorFrete">
                <Form.Label column sm={4}>
                  Valor do Frete
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    name="valor_frete"
                    value={regra.valor_frete}
                    onChange={handleChange}
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="subsidiadoPercentual">
                <Form.Label column sm={4}>
                  Percentual de Subsídio
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="number"
                    name="subsidio.percentual"
                    value={regra.subsidio.percentual}
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="subsidiadoValorFrete">
                <Form.Label column sm={4}>
                  Valor do Frete Subsidiado
                </Form.Label>
                <Col sm={8}>
                  <Form.Control type="text" value={valorFreteSubsidiado} readOnly />
                </Col>
              </Form.Group>

              <Button variant="primary" type="submit">
                Cadastrar Regra de Frete
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CadastroRegrasFreteAmVv
