//calculo-precoPF
import {
  ResultSearch,
  mergeComissoesWithMarketplaces,
  CarregarComissoesProduto,
  ComissaoMarketplace,
  CarregarRegrasPorGrupoAmVv
} from './calculo-precoPF.js'
import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { AuthContext } from '/src/context/AuthContext'
import SelectMarketPlace from '/PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/SelectMarkeplace/select-marketplace'
import { Button, Row, Col, Modal, Form, InputGroup, Table } from 'react-bootstrap'
import {
  CarregarRegrasPrecoFrete, // peso na verdade é desconto em porcentagem
  CarregarRegrasPesoFrete,
  carregaIdMarketplace
} from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraMagalu/regra-magalu'
import { CarregarProdutos } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/EdicaoProduto/edicao-produto'

import { CustomField } from '../../../../../../src/components/CustomField/custom-field'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import { CarregarMarketplaces } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraGlobal/cadastro-regra-global'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../../../src/backend/model'
import { FaPlus } from 'react-icons/fa'
import CadastroProduto from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CadastroProduto/cadastro-produto'
import { carregarUltimoImposto } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/CadastroImposto/cadastro_imposto.js'
import { useDynamicState } from '../../../../../../src/utils/form-utils'
// import MarketplaceSelector from 'PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/marketplace-selector';
import { ProfitMarginCalculator } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/comissao-display.jsx'
//import PrecoSugerido from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/PrecoSugerido/preco-sugerido'
//import Lucro from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/Lucro/lucro'
//import Margem from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/Margem/margem'

function CalculoPreco() {
  const [produtos, setProdutos] = useState({})
  const [produtoSelecionado, setProdutoSelecionado] = useState({})
  const [precoVenda, setPrecoVenda] = useState('')
  const [lucro, setLucro] = useState('')
  const [editableTotal, setEditableTotal] = useState('')
  const [margem, setMargem] = useState('')
  const { tenantId } = useContext(AuthContext)
  const [Dados, setDados] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [marketplaces, setMarketplaces] = useState([])
  const [mostrarRadioBoxes, setMostrarRadioBoxes] = useState(false)
  const [selectedMarketplaces, setSelectedMarketplaces] = useState({})
  const [comissoes, setComissoes] = useState([])
  const [comissaoMarketplace, setComissaoMarketplace] = useState(null)
  const [trava, setTrava] = useState({
    margem: false,
    precoVenda: false,
    lucro: false
  })
  const [displayDetails, setDisplayDetails] = useState(false) // referente as comissoes
  const [displayDetailsPrecoVendaSugerido, setDisplayDetailsPrecoVendaSugerido] = useState(false)
  const [isOpen, setIsOpen] = useState(false) //referente as comissoes
  const [isOpenPrecoVendaSugerido, setIsOpenPrecoVendaSugerido] = useState(false)
  const [stateMargem, setDynamicStateMargem] = useDynamicState()
  const [statePrecoVendaSugerido, setDynamicStatePrecoVendaSugerido] = useDynamicState()
  const [stateLucro, setDynamicStateLucro] = useDynamicState()
  const [marketplacesWithComissoes, setMarketplacesWithComissoes] = useState()

  const [showAddProductModal, setShowAddProductModal] = useState(false)
  const toggleAddProductModal = () => setShowAddProductModal(!showAddProductModal)
  const [hasMarketPlacesSelecionadosNosChecbox, setHasMarketPlacesSelecionadosNosChecbox] =
    useState(false)
  const [idMarketplace, setIdMarketplace] = useState('') ///usando para magalu e amazon
  const [idMarketplaceAmericanas, setIdMarketplaceAmericanas] = useState('') //usando para americanas
  const [idMarketplaceViaVarejo, setIdMarketplaceViaVarejo] = useState('') //usando para via varejo
  const [ultimaRegraSelecionada, setUltimaRegraSelecionada] = useState('')
  const [ultimaRegraSelecionadaML, setUltimaRegraSelecionadaML] = useState('')
  const [descontoAtualFreteMagalu, setDescontoAtualFreteMagalu] = useState(0)
  const [descontoAtualFreteMercadoLivre, setDescontoAtualFreteMercadoLivre] = useState(0)
  const [valorFreteMagalu, setValorFreteMagalu] = useState(0)
  const [valorFreteMercadoLivre, setValorFreteMercadoLivre] = useState(0)
  const [valorFreteAmericanas, setValorFreteAmericanas] = useState(0)
  const [valorFreteViaVarejo, setValorFreteViaVarejo] = useState(0)
  const [valorFreteAmazon, setValorFreteAmazon] = useState(0)
  const [regrasPesoFreteMagalu, setRegrasPesoFreteMagalu] = useState([])
  const [regrasPesoFreteMercadoLivre, setRegrasPesoFreteMercadoLivre] = useState([])
  const [regrasPorGrupoAmericanas, setRegrasPorGrupoAmericanas] = useState([])
  const [regrasPorGrupoViaVarejo, setRegrasPorGrupoViaVarejo] = useState([])
  const [grupoConfiguradoAmericanas, setGrupoConfiguradoAmericanas] = useState('')
  const [grupoConfiguradoViaVarejo, setGrupoConfiguradoViaVarejo] = useState('')
  const [ultimoImposto, setUltimoImposto] = useState('')
  const [marketplaceColors, setMarketplaceColors] = useState({
    Amazon: '#FF9900', // Laranja Amazon
    'Mercado Livre': '#FFE600', // Amarelo Mercado Livre
    Shein: '#EE1D52', // Vermelho Shein
    Magalu: '#2AB598', // Turquesa Magalu
    Americanas: '#FF0000', // Vermelho Americanas
    'Via Varejo': '#0000FF' // Azul Via Varejo
  })

  // Supondo que esta função busque os dados do produto por um ID ou nome
  //crie um useEffect para carregar todos os produtos usando a funcao CarregarProdutos
  useEffect(() => {
    let peso = obterPesoEmbalagem(25, 20, 7)
    console.log(`O peso da embalagem é: ${peso} gramas`)
    const fetchImposto = async () => {
      await carregarUltimoImposto(tenantId, 'icms', setUltimoImposto)
    }
    if (tenantId) {
      fetchImposto()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId])
  useEffect(() => {
    const fetchData = async () => {
      if (!tenantId) return
      setIsLoading(true)
      const resultado = await CarregarProdutos(tenantId)

      setProdutos(resultado)

      // Faça algo com o resultado, se necessário
    }

    fetchData()
  }, [tenantId])

  useEffect(() => {
    const fetchTenantMarketplaces = async () => {
      try {
        const url = config.host + '/regraGlobalPF'

        const dados = { tenantId, requisicao: 'getTenantMarketplaces' }

        const response = await fetchData(url, dados)

        if (response) {
          setSelectedMarketplaces(response.marketplaces || [])

          const allMarketplaces = await CarregarMarketplaces()

          const tenantMarketplaces = allMarketplaces.filter(marketplace =>
            response.marketplaces.includes(marketplace._id)
          )
          setMarketplaces(tenantMarketplaces)
        }
      } catch (error) {
        console.error('Erro ao carregar marketplaces do tenant:', error)
        // Trate o erro conforme necessário
      }
    }

    if (tenantId) {
      fetchTenantMarketplaces()
    }
  }, [tenantId])

  useEffect(() => {
    if (produtoSelecionado) {
      const comissoes = async () => {
        try {
          const response = await CarregarComissoesProduto(produtoSelecionado.categoriaId)

          setComissoes(response || [])
        } catch (error) {
          console.error('Erro ao carregar as comissões:', error)
          // Trate o erro conforme necessário
        }
      }
      // if (produtoSelecionado?.length > 0)
      comissoes()
    }
  }, [produtoSelecionado])

  const { data: regrasDesconto, isLoading: isLoadingRegras } = useQuery(
    'regrasDescontoFrete',
    () => CarregarRegrasPrecoFrete(),
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    const carregarDados = async () => {
      try {
        const dadosCarregados = await CarregarRegrasPesoFrete('magalu')

        setRegrasPesoFreteMagalu(dadosCarregados) // magalu e amazon
      } catch (err) {
        console.error('Erro ao carregar as regras de peso de frete magalu e amazon:', err)
      }
    }

    carregarDados()
  }, [])

  useEffect(() => {
    const carregarDados = async () => {
      try {
        const dadosCarregados = await CarregarRegrasPesoFrete('mercadolivre')

        setRegrasPesoFreteMercadoLivre(dadosCarregados) // mercado livre
      } catch (err) {
        console.error('Erro ao carregar as regras de peso de frete do mercado livre:', err)
      }
    }

    carregarDados()
  }, [])

  useEffect(() => {
    async function carregarDados() {
      let idMarketplace = await carregaIdMarketplace('Magalu')

      setIdMarketplace(idMarketplace[0]._id)
    }
    carregarDados()
  }, [])

  useEffect(() => {
    async function carregarDados() {
      let idMarketplace = await carregaIdMarketplace('Americanas')

      setIdMarketplaceAmericanas(idMarketplace[0]._id)
    }
    carregarDados()
  }, [])

  useEffect(() => {
    async function carregarDados() {
      let idMarketplace = await carregaIdMarketplace('Via Varejo')

      setIdMarketplaceViaVarejo(idMarketplace[0]._id)
    }
    carregarDados()
  }, [])

  useEffect(() => {
    async function fetchUltimaRegraSelecionada() {
      //referente a magalu
      if (idMarketplace) {
        try {
          let dados = {
            tenantId: tenantId,
            marketplace: idMarketplace,
            requisicao: 'BuscarUltimaRegraSelecionada'
          }
          let url = config.host + '/regraDescontoFrete'
          let response = await fetchData(url, dados)
          let ultimaRegraSelecionada = response?.regra // Suponha que o backend retorna o objeto da regra com '_id'

          if (ultimaRegraSelecionada) {
            setUltimaRegraSelecionada(ultimaRegraSelecionada)
          }
        } catch (error) {
          console.error('Erro ao buscar a última regra selecionada:', error)
        }
      }
    }

    fetchUltimaRegraSelecionada()
  }, [tenantId, idMarketplace])
  //----------------------ULTIMA REGRA SELECIONADA DO MERCADO LIVRE ( SE É VERDE OU AMARELO)-------------------
  useEffect(() => {
    async function fetchUltimaRegraSelecionada() {
      if (idMarketplace) {
        try {
          let dados = {
            tenantId: tenantId,
            requisicao: 'BuscarUltimaRegraDescontoFreteMercadoLivre'
          }
          let url = config.host + '/regraDescontoFrete'

          let response = await fetchData(url, dados)

          let ultimaRegraSelecionada = response

          if (ultimaRegraSelecionada) {
            setUltimaRegraSelecionadaML(ultimaRegraSelecionada)
          }
        } catch (error) {
          console.error('Erro ao buscar a última regra selecionada:', error)
        }
      }
    }

    fetchUltimaRegraSelecionada()
  }, [tenantId, idMarketplace])
  function getDescontoById(ultimaRegraSelecionada, regras) {
    // Encontrar a regra que corresponde ao ID fornecido
    const regraEncontrada = regras.find(regra => regra._id === ultimaRegraSelecionada)

    // Se a regra for encontrada, retornar o valor de desconto, senão retornar undefined ou algum valor padrão
    return regraEncontrada ? regraEncontrada.desconto : undefined
  }

  useEffect(() => {
    async function fetchDesconto() {
      if (regrasDesconto) {
        const desconto = await getDescontoById(ultimaRegraSelecionada, regrasDesconto)

        setDescontoAtualFreteMagalu(desconto)
      }
    }

    fetchDesconto()
  }, [regrasDesconto, ultimaRegraSelecionada])

  useEffect(() => {
    async function fetchDesconto() {
      const desconto = ultimaRegraSelecionadaML.regraId.desconto

      setDescontoAtualFreteMercadoLivre(desconto)
    }

    fetchDesconto()
  }, [ultimaRegraSelecionadaML])

  //------------------------------REFERENTE A AMERICANAS E VIA VAREJO-----------------------------------
  useEffect(() => {
    const carregarConfiguracao = async () => {
      try {
        const url = config.host + '/regraDescontoFrete'
        const dados = {
          tenantId: tenantId,
          marketplaceId: idMarketplaceAmericanas,
          requisicao: 'CarregarConfigAmVv'
        }
        const response = await fetchData(url, dados)

        if (response) {
          setGrupoConfiguradoAmericanas(response.grupoNum)
        }
      } catch (error) {
        console.error('Erro ao carregar configuração da americanas:', error)
      }
    }

    if (tenantId && idMarketplaceAmericanas) {
      carregarConfiguracao()
    }
  }, [tenantId, idMarketplaceAmericanas])

  useEffect(() => {
    const carregarConfiguracao = async () => {
      try {
        const url = config.host + '/regraDescontoFrete'
        const dados = {
          tenantId: tenantId,
          marketplaceId: idMarketplaceViaVarejo,
          requisicao: 'CarregarConfigAmVv'
        }
        const response = await fetchData(url, dados)

        if (response) {
          setGrupoConfiguradoViaVarejo(response.grupoNum)
        }
      } catch (error) {
        console.error('Erro ao carregar configuração do viavarejo:', error)
      }
    }

    if (tenantId && idMarketplaceViaVarejo) {
      carregarConfiguracao()
    }
  }, [tenantId, idMarketplaceViaVarejo])

  //----------------------------------------------------------
  useEffect(() => {
    const carregarRegras = async () => {
      if (idMarketplaceAmericanas && tenantId) {
        try {
          const response = await CarregarRegrasPorGrupoAmVv(
            grupoConfiguradoAmericanas,
            idMarketplaceAmericanas
          )

          setRegrasPorGrupoAmericanas(response || [])
        } catch (error) {
          console.error('Erro ao carregar as regras por grupo:', error)
        }
      }
    }
    carregarRegras()
  }, [idMarketplaceAmericanas, tenantId, grupoConfiguradoAmericanas])

  useEffect(() => {
    const carregarRegras = async () => {
      if (idMarketplaceViaVarejo && tenantId) {
        try {
          const response = await CarregarRegrasPorGrupoAmVv(
            grupoConfiguradoViaVarejo,
            idMarketplaceViaVarejo
          )

          setRegrasPorGrupoViaVarejo(response || [])
        } catch (error) {
          console.error('Erro ao carregar as regras por grupo:', error)
        }
      }
    }
    carregarRegras()
  }, [idMarketplaceViaVarejo, tenantId, grupoConfiguradoViaVarejo])
  function calcularFreteComDescontoMagalu(regrasPesoFreteMagalu, pesoAtual, descontoPercentual) {
    // Encontrar a faixa de peso que inclui o peso atual
    const faixa = regrasPesoFreteMagalu.find(
      peso => peso.limite_inferior <= pesoAtual && peso.limite_superior >= pesoAtual
    )

    if (!faixa) {
      throw new Error('Nenhuma faixa de peso encontrada para o peso atual.')
    }

    // Calcular o valor do frete após aplicar o desconto
    const valorFreteComDesconto = ((faixa.valor_frete * (100 - descontoPercentual)) / 100).toFixed(
      2
    )

    return parseFloat(valorFreteComDesconto)
  }
  //----------------------------------------------------------
  function calcularFreteComDescontoMercadoLivre(
    regrasPesoFreteMercadoLivre,
    pesoAtual,
    descontoPercentual,
    precoVendaBruto
  ) {
    // Encontrar a faixa de peso que inclui o peso atual
    const faixa = regrasPesoFreteMercadoLivre.find(
      peso => peso.limite_inferior <= pesoAtual && peso.limite_superior >= pesoAtual
    )

    if (!faixa) {
      throw new Error('Nenhuma faixa de peso encontrada para o peso atual.')
    }

    // Se o preço de venda bruto for abaixo de 79, não aplicar o desconto
    let valorFreteComDesconto
    if (precoVendaBruto < 79) {
      valorFreteComDesconto = faixa.valor_frete.toFixed(2)
    } else {
      // Calcular o valor do frete após aplicar o desconto
      valorFreteComDesconto = ((faixa.valor_frete * (100 - descontoPercentual)) / 100).toFixed(2)
    }

    return parseFloat(valorFreteComDesconto)
  }

  function valorFretePorPeso(regrasPesoFrete, pesoAtual, valorProduto) {
    // Filtrar as faixas de peso que incluem o peso atual

    const faixasPeso = regrasPesoFrete.filter(
      regra => regra.limite_inferior <= pesoAtual && regra.limite_superior >= pesoAtual
    )

    if (faixasPeso.length === 0) {
      throw new Error('Nenhuma faixa de peso encontrada para o peso atual.')
    }

    // Filtrar as faixas de peso que também correspondem ao valor mínimo do produto
    const faixasValidas = faixasPeso.filter(regra => valorProduto >= regra.valor_minimo_produto)

    if (faixasValidas.length === 0) {
      throw new Error('Nenhuma faixa de peso encontrada que corresponda ao valor do produto.')
    }

    // Encontrar a regra mais específica (com maior valor_minimo_produto)
    const faixa = faixasValidas.reduce((prev, curr) => {
      return prev.valor_minimo_produto > curr.valor_minimo_produto ? prev : curr
    })

    return parseFloat(faixa.valor_frete)
  }

  // Função para resetar os campos e começar uma nova simulação
  const limparCampos = () => {
    setProdutoSelecionado({
      sku: '',
      descricao: '',
      custo: '',
      gramas: ''
      // Defina todas as propriedades que você precisa resetar
    })
  }

  // Função para salvar os dados da simulação (a implementar)
  const gravarSimulacao = () => {
    // Aqui você iria enviar os dados da simulação para serem gravados no backend
  }
  function btnInput(e) {
    const inputValue = e.target.value // Pega o valor digitado como string

    setEditableTotal(inputValue)

    const value = e.target.value
    let resultsFromSearch = []

    // Se o input tiver um valor, obtenha os resultados da pesquisa.
    if (value) {
      // resultsFromSearch = ResultSearch(
      //   value,
      //   [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      //   produtos
      // );

      let resultsFromSearch = ResultSearch(value, produtos)

      setDados(resultsFromSearch)
    }
  }
  // crie um useeffect para mostrar o valor de Dados  em um console.log

  function handleClear() {
    // Define o valor de editableTotal como uma string vazia
    setEditableTotal('')

    // Cria um evento falso com um valor de target vazio para simular o campo CustomField vazio
    const fakeEvent = { target: { value: '' } }

    // Chama a função btnInput com o evento falso
    btnInput(fakeEvent)
  }
  useEffect(() => {
    if (produtoSelecionado && marketplaces.length && comissoes.length) {
      let comissao
      if (!selectedMarketplaces) {
        comissao = ComissaoMarketplace(comissoes, marketplaces[0])
      } else {
        comissao = ComissaoMarketplace(comissoes, selectedMarketplaces)
      }

      setComissaoMarketplace(comissao)

      // Chamada da função para recalcular os preços
      recalcularPreco(comissao)
    }
  }, [produtoSelecionado, marketplaces, comissoes, selectedMarketplaces])

  useEffect(() => {
    if (marketplaces?.length > 0 && comissoes?.length > 0) {
      const marktpla = mergeComissoesWithMarketplaces(marketplaces, comissoes, marketplaceColors)

      setMarketplacesWithComissoes(marktpla)
    }
  }, [marketplaces, comissoes])

  const recalcularPreco = comissao => {
    // Aqui você usaria as funções que já definiu para calcular o preço de venda e o lucro
    // com base na comissão que você acabou de definir e a margem que já existe.
    const margemPercentual = parseFloat(margem)
    const custo = parseFloat(produtoSelecionado?.custo || 0)

    if (custo && !isNaN(margemPercentual) && !isNaN(comissao)) {
      const incrementoPorMargem = custo * (margemPercentual / 100)
      const precoVendaLiquido = custo + incrementoPorMargem
      const precoVendaBruto = precoVendaLiquido / (1 - comissao / 100)

      setPrecoVenda(precoVendaBruto.toFixed(2))
      setLucro(incrementoPorMargem.toFixed(2))
    }
  }

  const handleRowClick = produto => {
    setProdutoSelecionado({
      sku: produto.sku,
      descricao: produto.descricao,
      custo: produto.custo,
      gramas: produto.gramas,
      categoriaId: produto.categoriaId
      // Inclua outras propriedades conforme necessário
    })
    setEditableTotal('')
  }

  const handleCustoChange = newCusto => {
    setProdutoSelecionado(prev => ({ ...prev, custo: newCusto }))

    if (trava.precoVenda && precoVenda) {
      recalculateFromPrecoVenda(newCusto, parseFloat(precoVenda))
    } else if (trava.margem && margem) {
      recalculateFromMargem(newCusto, parseFloat(margem))
    } else if (trava.lucro && lucro) {
      recalculateFromLucro(newCusto, parseFloat(lucro))
    }
  }

  const recalculateFromPrecoVenda = (custo, precoVenda) => {
    const novoLucro = precoVenda - custo

    const novaMargem = precoVenda !== 0 ? (novoLucro / precoVenda) * 100 : 0

    setLucro(novoLucro.toFixed(2))
    setMargem(novaMargem.toFixed(2))
  }
  const recalculateFromMargem = (custo, margem) => {
    const novoPrecoVenda = custo / (1 - margem / 100)
    const novoLucro = novoPrecoVenda - custo

    setPrecoVenda(novoPrecoVenda.toFixed(2))
    setLucro(novoLucro.toFixed(2))
  }
  const recalculateFromLucro = (custo, lucro) => {
    const novoPrecoVenda = custo + lucro
    const novaMargem = novoPrecoVenda !== 0 ? (lucro / novoPrecoVenda) * 100 : 0

    setPrecoVenda(novoPrecoVenda.toFixed(2))
    setMargem(novaMargem.toFixed(2))
  }

  useEffect(() => {
    // Verifica se `selectedMarketplaces` existe e, em seguida, verifica cada entrada
    const hasTrueValue =
      selectedMarketplaces &&
      Object.entries(selectedMarketplaces).some(
        ([key, value]) => key !== 'status_ativo' && value === true
      )

    setHasMarketPlacesSelecionadosNosChecbox(hasTrueValue)
  }, [selectedMarketplaces])

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row', // organiza os filhos em linha
          alignItems: 'center', // centraliza verticalmente
          justifyContent: 'center', // centraliza horizontalmente
          height: '10vh', // altura do viewport para garantir que há espaço para centralizar
          marginTop: '1vw', // margem superior para separar do topo da página
          marginLeft: '7%'
        }}
      >
        <Banner
          title="CÁLCULO DE PREÇO"
          color="#2e8b57"
          width="48vw"
          fontSize={50}
          classEfeito3dMouseOver=""
        />
      </div>

      {/* ------------- BLOCO PESQUISA E SELECIONE O MARKETPLACE -------------------- */}

      <div
        style={{
          width: '31%',
          marginLeft: '35%',
          display: 'flex', // Adiciona flex para alinhar os itens
          flexDirection: 'column', // Orienta os filhos na vertical
          justifyContent: 'center', // Alinha os itens verticalmente ao centro
          marginTop: '2vw', // Margem superior de 20px
          position: 'relative'
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '-8vw'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '2vw',
              position: 'absolute'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '0.8vw'
              }}
            >
              <CustomField
                label="pesquisa"
                placeholder="pesquisa"
                width="20vw"
                value={editableTotal}
                disabled={isLoading}
                onChange={btnInput}
                onClear={handleClear}
                className={'Efeito3dMouseOver-class'}
              />
              <FaPlus
                onClick={toggleAddProductModal}
                size={20}
                color="green"
                style={{ cursor: 'pointer', marginLeft: '0.8vw' }}
                title="Adicionar Produto"
              />
            </div>
            <SelectMarketPlace
              marketplaces={marketplaces}
              setDisplayDetails={setDisplayDetails}
              selectedMarketplaces={selectedMarketplaces}
              setSelectedMarketplaces={setSelectedMarketplaces}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              marketplaceColors={marketplaceColors}
            />
            <Modal
              show={showAddProductModal}
              onHide={toggleAddProductModal}
              contentClassName="custom-modal-content-width"
              className="custom-modal-dialog-PF"
            >
              <Modal.Header closeButton>
                <Modal.Title>Adicionar Novo Produto</Modal.Title>
              </Modal.Header>
              <Modal.Body className="custom-modal-body">
                <CadastroProduto />
              </Modal.Body>
              <Modal.Footer className="custom-modal-footer">
                <Button variant="secondary" onClick={toggleAddProductModal}>
                  Fechar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <div
          style={{
            display: editableTotal ? 'block' : 'none',
            position: 'absolute',
            zIndex: 10,
            top: '4.6vw',
            left: '-9.2vw'
          }}
        >
          <Table striped bordered hover style={{ backgroundColor: 'white' }}>
            <thead>
              <tr>
                <th>Código</th>
                <th>Descrição</th>
                <th>Custo</th>
                <th>Peso</th>
              </tr>
            </thead>
            <tbody>
              {Dados.map(produto => (
                <tr key={produto.sku} onClick={() => handleRowClick(produto)}>
                  <td>{produto.sku}</td>
                  <td>{produto.descricao}</td>
                  <td>
                    {produto?.custo !== undefined && produto?.custo !== null
                      ? produto.custo.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        })
                      : ''}
                  </td>
                  <td>{produto.gramas}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>

      {/* -------------------------BLOCO DADOS CARREGADOS -----------------------------*/}
      <div
        style={{
          width: '90%',
          height: '100%',
          marginLeft: '3%',
          borderRadius: '5px',
          padding: '1vw'
        }}
      >
        <div
          style={{
            minHeight: '10vw'
          }}
        >
          <div
            style={{
              minHeight: '10vw',
              display: hasMarketPlacesSelecionadosNosChecbox ? 'none' : 'block' // ou 'flex', 'grid', etc.
            }}
          ></div>
        </div>
        <div style={{ marginTop: '-5vw', position: 'absolute', left: '4vw' }}>
          <Form>
            <div style={{ paddingLeft: '20vw', display: 'flex' }}>
              {/* Código do Produto */}
              <div style={{ flex: '25%', padding: '0.5vw' }}>
                {' '}
                {/* Aproximadamente equivalente a md={2} */}
                <div className="form-field-PF">
                  <strong>Código do Produto:</strong>
                  <div
                    style={{
                      fontWeight: 'normal',
                      marginTop: '0.2vh',
                      fontSize: '1.8vh'
                    }}
                  >
                    {produtoSelecionado.sku || ''}
                  </div>
                </div>
              </div>

              {/* Descrição do Produto */}
              <div style={{ flex: '40%', padding: '0.5vw' }}>
                {' '}
                {/* Ajustado para mais espaço, equivalente a md={4} */}
                <div className="form-field-PF">
                  <strong>Descrição do Produto:</strong>
                  <div
                    style={{
                      fontWeight: 'bold',
                      marginTop: '0.2vh',
                      fontSize: '2.3vh',
                      color: '#277a4c'
                    }}
                  >
                    {produtoSelecionado.descricao || 'Não disponível'}
                  </div>
                </div>
              </div>

              {/* Peso */}
              <div style={{ flex: '10%', padding: '0.5vw' }}>
                {' '}
                {/* Ajustado para menos espaço, equivalente a md={1} */}
                <div className="form-field-PF">
                  <strong>Peso:</strong>
                  <div
                    style={{
                      fontWeight: 'normal',
                      marginTop: '0.2vh',
                      fontSize: '1.8vh'
                    }}
                  >
                    {produtoSelecionado.gramas ? `${produtoSelecionado.gramas} g` : ''}
                  </div>
                </div>
              </div>

              {/* Custo */}
              <div style={{ flex: '10%', padding: '0.5vw' }}>
                {' '}
                {/* Mais espaço para custo, equivalente a md={5} */}
                <div className="form-field-PF">
                  <strong>Custo</strong>
                  <CurrencyInput
                    value={produtoSelecionado.custo}
                    onChange={handleCustoChange}
                    onFocus={() => setMostrarRadioBoxes(true)}
                  />
                </div>
              </div>
            </div>

            <Row>
              <Col md={12} style={{ paddingLeft: '10vw' }}>
                <div
                  id="comissao"
                  style={{
                    width: '90%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  {marketplacesWithComissoes && (
                    <ProfitMarginCalculator
                      produtoSelecionado={produtoSelecionado}
                      marketplacesWithComissoes={marketplacesWithComissoes}
                      selectedMarketplaces={selectedMarketplaces}
                      calcularFreteComDescontoMagalu={calcularFreteComDescontoMagalu}
                      calcularFreteComDescontoMercadoLivre={calcularFreteComDescontoMercadoLivre}
                      valorFretePorPeso={valorFretePorPeso}
                      descontoAtualFreteMagalu={descontoAtualFreteMagalu}
                      descontoAtualFreteMercadoLivre={descontoAtualFreteMercadoLivre}
                      regrasPesoFreteMagalu={regrasPesoFreteMagalu}
                      regrasPesoFreteMercadoLivre={regrasPesoFreteMercadoLivre}
                      peso={produtoSelecionado.gramas}
                      setValorFreteMagalu={setValorFreteMagalu}
                      setValorFreteMercadoLivre={setValorFreteMercadoLivre}
                      setValorFreteAmazon={setValorFreteAmazon}
                      setValorFreteAmericanas={setValorFreteAmericanas}
                      setValorFreteViaVarejo={setValorFreteViaVarejo}
                      valorFreteMagalu={valorFreteMagalu}
                      valorFreteMercadoLivre={valorFreteMercadoLivre}
                      valorFreteAmazon={valorFreteAmazon}
                      valorFreteAmericanas={valorFreteAmericanas}
                      valorFreteViaVarejo={valorFreteViaVarejo}
                      marketplaceColors={marketplaceColors}
                      setDynamicStateMargem={setDynamicStateMargem}
                      stateMargem={stateMargem}
                      setDynamicStatePrecoVendaSugerido={setDynamicStatePrecoVendaSugerido}
                      statePrecoVendaSugerido={statePrecoVendaSugerido}
                      setDynamicStateLucro={setDynamicStateLucro}
                      stateLucro={stateLucro}
                      regrasPorGrupoAmericanas={regrasPorGrupoAmericanas}
                      regrasPorGrupoViaVarejo={regrasPorGrupoViaVarejo}
                      ultimoImposto={ultimoImposto}
                    />
                  )}
                </div>
              </Col>
            </Row>

            <Row
              style={{
                marginBottom: '1vw',
                marginLeft: '-12.5vw',
                width: '100%'
              }}
            >
              <Col
                xs={12}
                style={{
                  display: 'flex',

                  paddingLeft: '51vw' // Ajuste este valor para controlar o quão distante do lado direito os botões estarão
                }}
              >
                <Button
                  variant="danger"
                  onClick={limparCampos}
                  style={{ padding: '0.2vw', width: '7vw' }}
                >
                  Limpar
                </Button>
                <Button
                  variant="success"
                  onClick={gravarSimulacao}
                  style={{
                    padding: '0.2vw',
                    marginLeft: '2vw',
                    width: '10vw'
                  }}
                >
                  Gravar Simulação
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default CalculoPreco

const CurrencyInput = ({ value, onChange, onFocus, onBlur }) => {
  CurrencyInput.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
  }
  const [inputValue, setInputValue] = useState('')

  // Atualiza o valor do input formatado quando o valor externo muda
  useEffect(() => {
    if (value !== undefined && value !== null) {
      setInputValue(
        value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          style: 'currency',
          currency: 'BRL'
        })
      )
    } else {
      setInputValue('')
    }
  }, [value])

  const handleChange = e => {
    const cleanValue = e.target.value.replace(/\D/g, '')

    const numericValue = cleanValue === '' ? '' : parseFloat(cleanValue) / 100

    // Atualiza o valor de entrada para refletir o que o usuário digitou
    setInputValue(e.target.value)

    // Chama onChange apenas com valores numéricos
    if (numericValue !== '') {
      onChange(numericValue)
    }
  }

  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      style={{ width: '5.3vw' }}
    />
  )
}

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
function obterPesoEmbalagem(
  altura,
  largura,
  comprimento,
  alturaBase = 23,
  larguraBase = 17,
  comprimentoBase = 7,
  pesoBase = 13
) {
  // Calcula a área total da superfície da embalagem de referência
  const areaBase =
    2 * (alturaBase * larguraBase + alturaBase * comprimentoBase + larguraBase * comprimentoBase) // cm²

  // Calcula a área total da superfície da embalagem desejada
  const areaTotal = 2 * (altura * largura + altura * comprimento + largura * comprimento) // cm²

  // Calcula o peso total da embalagem desejada com base na proporção da área
  const pesoTotal = (areaTotal / areaBase) * pesoBase // gramas

  return pesoTotal // Retorna o peso total em gramas
}
