// AppPrecoFacilMarket.js
import { useContext, useState, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import AppRoutes from '../routes/routePrecoFacilMarket'
import { fetchData } from '../backend/model'
import { CarregarMarketplaces } from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraGlobal/cadastro-regra-global'
import PropTypes from 'prop-types'
//import bcrypt from 'bcryptjs'
import { config } from '../backend/configConexaoGeral'
//import { SYSTEM_OWNER } from '../config/constantes'
import { isSystemOwner, deslogar } from '../utils/AppMenuFunctions'
const queryClient = new QueryClient()
const RESTRICTED_MENUS = ['CADASTRAR MARKETPLACE', 'GERENCIAR MARKETPLACE', 'Regras']

// const isSystemOwner = async (user, password) => {
//   // Verifica se o login do usuário é igual ao login do dono do sistema
//   if (user !== SYSTEM_OWNER.login) {
//     return false
//   }

//   // Compara a senha fornecida (criptografada) com a senha armazenada (não criptografada)
//   const senhaCorreta = await bcrypt.compare(SYSTEM_OWNER.senha, password)

//   return senhaCorreta
// }

const PrivateMenuItem = ({ children, tela }) => {
  const { isAuthenticated, user, idNivelAcesso, nivel_acesso, telasPermissao, password } =
    useContext(AuthContext)
  const [isOwner, setIsOwner] = useState(false)

  useEffect(() => {
    const checkSystemOwner = async () => {
      if (user) {
        const ownerStatus = await isSystemOwner(user, password)

        setIsOwner(ownerStatus)
      }
    }
    checkSystemOwner()
  }, [user, password])

  if (!isAuthenticated) {
    return null
  }

  if (isOwner) {
    return children
  }

  if (RESTRICTED_MENUS.includes(tela)) {
    return null
  }

  if (telasPermissao && tela) {
    let podeAcessar

    if (nivel_acesso === 'Administrador') {
      podeAcessar = true
    } else {
      podeAcessar = telasPermissao.some(
        permissao => permissao.tela === tela && permissao.id_nivel === idNivelAcesso
      )
    }

    if (!podeAcessar) {
      return null
    }
  }

  return children
}

PrivateMenuItem.propTypes = {
  children: PropTypes.node,
  tela: PropTypes.string.isRequired
}
function AppPrecoFacilMarket() {
  let { isAuthenticated, assinaturaStatus, logout } = useContext(AuthContext) // Substitua pelo estado real
  //const navigate = useNavigate()
  const { tenantId } = useContext(AuthContext)
  const [marketplaces, setMarketplaces] = useState([])

  useEffect(() => {
    const fetchTenantMarketplaces = async () => {
      try {
        const url = config.host + '/regraGlobalPF'

        const dados = { tenantId, requisicao: 'getTenantMarketplaces' }

        const response = await fetchData(url, dados)

        if (response) {
          const allMarketplaces = await CarregarMarketplaces()

          const tenantMarketplaces = allMarketplaces.filter(marketplace =>
            response.marketplaces.includes(marketplace._id)
          )

          setMarketplaces(tenantMarketplaces)
        }
      } catch (error) {
        console.error('Erro ao carregar marketplaces do tenant:', error)
        // Trate o erro conforme necessário
      }
    }

    if (tenantId) {
      fetchTenantMarketplaces()
    }
  }, [tenantId])

  const isMarketplacePresent = nome => {
    return marketplaces.some(marketplace => marketplace.nome === nome)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        {isAuthenticated && (
          <Navbar bg="dark" expand="lg">
            <Navbar.Brand href="/" className="logo" style={{ fontSize: '1.2rem' }}>
              PREÇO FÁCIL MARKET
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navbar-nav">
                <Nav.Link as={Link} to="/">
                  Inicio
                </Nav.Link>

                {/* Menu Usuário */}
                <PrivateMenuItem tela="Usuário">
                  <NavDropdown title="Usuário" id="usuario-dropdown">
                    <NavDropdown.Item as={Link} to="/cadastro-usuario">
                      CADASTRAR USUÁRIO
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/cadastro-nivel-acesso">
                      CADASTRAR NIVEL ACESSO
                    </NavDropdown.Item>
                    <PrivateMenuItem tela="Configuração de Níveis de Acesso">
                      <NavDropdown.Item as={Link} to="/configuracao-usuario-precofacilmarket">
                        CONFIGURAÇÃO DE NIVEL DE ACESSO
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <NavDropdown.Item as={Link} to="/gerenciador-de-ips">
                      GERENCIAR IPS DE ACESSO
                    </NavDropdown.Item>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Menu Imposto */}
                <NavDropdown title="Imposto" id="produto-dropdown">
                  <NavDropdown.Item as={Link} to="/cadastro-imposto">
                    CADASTRAR IMPOSTO
                  </NavDropdown.Item>
                </NavDropdown>

                {/* Menu Produto */}
                <PrivateMenuItem tela="Produto">
                  <NavDropdown title="Produto" id="produto-dropdown">
                    <NavDropdown.Item as={Link} to="/calculo-precoPF">
                      CALCULO DE PREÇO
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/cadastro-produto">
                      CADASTRAR PRODUTO
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/editar-produto">
                      GERENCIAR PRODUTO
                    </NavDropdown.Item>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Menu Categoria */}
                <PrivateMenuItem tela="Categoria">
                  <NavDropdown title="Categoria" id="categoria-dropdown">
                    <NavDropdown.Item as={Link} to="/cadastro-categoria">
                      CADASTRAR CATEGORIA
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/edicao-categoria">
                      GERENCIAR CATEGORIA
                    </NavDropdown.Item>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Menu Marketplace */}
                <PrivateMenuItem tela="Marketplace">
                  <NavDropdown title="Marketplace" id="marketplace-dropdown">
                    {isMarketplacePresent('Amazon') && (
                      <NavDropdown title="AMAZON" id="amazon-dropdown">
                        <NavDropdown.Item as={Link} to="/amazon/cadastrar-regras">
                          Configurar Regras
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {isMarketplacePresent('Mercado Livre') && (
                      <NavDropdown title="MERCADO LIVRE" id="mercadolivre-dropdown">
                        <NavDropdown.Item as={Link} to="/config-regras-frete-ML">
                          Configurar Regras
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {isMarketplacePresent('Magalu') && (
                      <NavDropdown title="MAGALU" id="magalu-dropdown">
                        <NavDropdown.Item as={Link} to="/regra-magalu">
                          Configurar Regras
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {isMarketplacePresent('Americanas') && (
                      <NavDropdown title="AMERICANAS" id="magalu-dropdown">
                        <NavDropdown.Item as={Link} to="/config-regras-frete-americanas/americanas">
                          Configurar Regras
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {isMarketplacePresent('Via Varejo') && (
                      <NavDropdown title="VIA VAREJO" id="magalu-dropdown">
                        <NavDropdown.Item as={Link} to="/config-regras-frete-americanas/viavarejo">
                          Configurar Regras
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    <NavDropdown.Item as={Link} to="/tenant-marketplaces-config">
                      CONFIGURAR MARKETPLACES EM USO
                    </NavDropdown.Item>
                    <PrivateMenuItem tela="CADASTRAR MARKETPLACE">
                      <NavDropdown.Item as={Link} to="/cadastro-marketplace">
                        CADASTRAR MARKETPLACE
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                    <PrivateMenuItem tela="GERENCIAR MARKETPLACE">
                      <NavDropdown.Item as={Link} to="/editar-marketplace">
                        GERENCIAR MARKETPLACE
                      </NavDropdown.Item>
                    </PrivateMenuItem>
                  </NavDropdown>
                </PrivateMenuItem>

                {/* Menu Regras */}
                <PrivateMenuItem tela="Regras">
                  <NavDropdown title="Regras" id="regras-dropdown">
                    {isMarketplacePresent('Magalu') && (
                      <NavDropdown title="Magalu" id="magalu-dropdown">
                        <NavDropdown.Item as={Link} to="/cadastro-regra-preco-frete">
                          CADASTRO DE REGRA DE PREÇO DE FRETE
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/cadastro-regra-peso-frete/magalu">
                          CADASTRO DE REGRA DE PESO DE FRETE
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/editar-regra">
                          EDITAR REGRA
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {isMarketplacePresent('Amazon') && (
                      <NavDropdown title="Amazon" id="amazon-dropdown">
                        <NavDropdown.Item as={Link} to="/cadastro-regra-peso-frete/amazon">
                          CADASTRO DE REGRA DE PESO DE FRETE
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/editar-regra">
                          EDITAR REGRA
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {isMarketplacePresent('Mercado Livre') && (
                      <NavDropdown title="Mercado Livre" id="mercadolivre-dropdown">
                        <NavDropdown.Item as={Link} to="/cadastro-regra-peso-frete/mercadolivre">
                          CADASTRO DE REGRA DE PESO DE FRETE
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/cadastro-regras-freteML">
                          CADASTRO DE REGRA DE DESCONTO DE FRETE
                        </NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/editar-regra">
                          EDITAR REGRA
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {isMarketplacePresent('Americanas') && (
                      <NavDropdown title="Americanas" id="americanas-dropdown">
                        <NavDropdown.Item as={Link} to="/cadastro-regra-freteAmVv/americanas">
                          Cadastrar Regras
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    {isMarketplacePresent('Via Varejo') && (
                      <NavDropdown title="Via Varejo" id="viavarejo-dropdown">
                        <NavDropdown.Item as={Link} to="/cadastro-regra-freteAmVv/viavarejo">
                          Cadastrar Regras
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                  </NavDropdown>
                </PrivateMenuItem>
                <PrivateMenuItem tela="ClientEmail">
                  <Nav.Link as={Link} to="/email-client" className="">
                    Email Client
                  </Nav.Link>
                </PrivateMenuItem>
                <PrivateMenuItem tela="Assinar Plano">
                  {assinaturaStatus !== 'ativa' && (
                    <Nav.Link as={Link} to="/assinar-plano" className="cta-button">
                      Assinar Plano
                    </Nav.Link>
                  )}
                </PrivateMenuItem>
                <Nav.Link onClick={() => deslogar(logout, 'precofacilmarket')}>Sair</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}

        <AppRoutes />
      </div>
    </QueryClientProvider>
  )
}

export default AppPrecoFacilMarket
