// PrintTable.js
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Table } from 'react-bootstrap'
import { formatDate } from '/src/utils/date-utils'

// Função para renomear os campos para impressão
export function imprimirTabela(cortes, linhasSelecionadas, quantidadesImpressao, medidasEtiqueta) {
  // Filtrar apenas os cortes que foram selecionados
  const cortesSelecionados = cortes.filter(corte => linhasSelecionadas[corte._id])

  // Abrir uma nova janela de impressão
  let popupWin = window.open('', '_blank', 'width=' + screen.width + ',height=' + screen.height)

  // Criar conteúdo HTML para impressão
  let htmlContent = ReactDOMServer.renderToStaticMarkup(
    <div>
      {cortesSelecionados.map((corte, index) => {
        const quantidade = quantidadesImpressao[corte._id] || 1

        if (corte.tamanhos) {
          // Extrair tamanhos com valor maior que zero
          const tamanhosValidos = Object.entries(corte.tamanhos).filter(
            ([tamanho, valor]) => valor > 0
          )

          // Gerar etiquetas para cada tamanho válido
          return tamanhosValidos.flatMap(([tamanho, valor]) => {
            const totalEtiquetas = valor * quantidade
            return Array.from({ length: totalEtiquetas }).map((_, etiquetaIndex) => (
              <div key={`${index}-${tamanho}-${etiquetaIndex}`} className="etiqueta">
                <div className="etiqueta-content">
                  {(corte.camposPersonalizados.length > 0
                    ? corte.camposPersonalizados.filter(campo => campo !== 'tamanhos') // Exclui 'tamanhos'
                    : corte.camposTabela.filter(campo => campo !== 'tamanhos')
                  ).map((campo, campoIndex) => {
                    let valorCampo = corte[campo]
                    if (campo === 'data_corte' || campo === 'data' || campo === 'entregar') {
                      valorCampo = formatDate(valorCampo)
                    }
                    // Verificar se o campo é 'id' para renderizar lado a lado
                    if (
                      campo === 'id' ||
                      campo === 'total_rolos' ||
                      campo === 'lar_tecido' ||
                      campo === 'total_ref' ||
                      campo === 'total_pecas' ||
                      campo === 'valor_do_corte'
                    ) {
                      return (
                        <div key={`${campoIndex}`} className="campo-valor campo-id">
                          <div
                            className="campo-nome"
                            style={{ fontSize: '18px', marginBottom: '2px', marginRight: '5px' }}
                          >
                            {renameField(campo)}:
                          </div>
                          <div
                            className="campo-valor-content"
                            style={{ fontSize: '36px', marginTop: '2px' }}
                          >
                            <strong>{valorCampo}</strong>
                          </div>
                        </div>
                      )
                    }

                    // Verificar se o campo é 'tecido' e se o tamanho é 'Entretela' ou 'Forro'
                    if ((tamanho === 'Entretela' || tamanho === 'Forro') && campo === 'tecido') {
                      return null // Não exibir o campo 'tecido' para 'Entretela' ou 'Forro'
                    }

                    return (
                      <div key={`${campoIndex}`} className="campo-valor">
                        <div
                          className="campo-nome"
                          style={{ fontSize: '18px', marginBottom: '2px' }}
                        >
                          {renameField(campo)}:
                        </div>
                        <div
                          className="campo-valor-content"
                          style={{ fontSize: '36px', marginTop: '2px' }}
                        >
                          <strong>{valorCampo}</strong>
                        </div>
                      </div>
                    )
                  })}
                  {/* Adicionar a linha de Tamanho ou Detalhe */}
                  <div className="campo-valor campo-id">
                    <div className="campo-nome" style={{ fontSize: '18px', marginBottom: '2px' }}>
                      {tamanho === 'Entretela' || tamanho === 'Forro' ? 'Detalhe' : 'Tamanho'}:
                    </div>
                    <div
                      className="campo-valor-content"
                      style={{ fontSize: '40px', marginTop: '2px' }}
                    >
                      <strong>{tamanho}</strong>
                    </div>
                  </div>
                </div>
              </div>
            ))
          })
        } else {
          // Lógica original para cortes sem 'tamanhos'
          return Array.from({ length: quantidade }).map((_, etiquetaIndex) => (
            <div key={`${index}-${etiquetaIndex}`} className="etiqueta">
              <div className="etiqueta-content">
                {(corte.camposPersonalizados.length > 0
                  ? corte.camposPersonalizados
                  : corte.camposTabela.filter(campo => campo !== 'tamanhos')
                ) // Exclui 'tamanhos'
                  .map((campo, campoIndex) => {
                    let valorCampo = corte[campo]
                    if (campo === 'data_corte' || campo === 'data' || campo === 'entregar') {
                      valorCampo = formatDate(valorCampo)
                    }
                    // Verificar se o campo é 'id' para renderizar lado a lado
                    if (campo === 'id') {
                      return (
                        <div key={`${campoIndex}`} className="campo-valor campo-id">
                          <div
                            className="campo-nome"
                            style={{ fontSize: '18px', marginBottom: '2px', marginRight: '5px' }}
                          >
                            {renameField(campo)}:
                          </div>
                          <div
                            className="campo-valor-content"
                            style={{ fontSize: '36px', marginTop: '2px' }}
                          >
                            <strong>{valorCampo}</strong>
                          </div>
                        </div>
                      )
                    }

                    return (
                      <div key={`${campoIndex}`} className="campo-valor">
                        <div className="campo-nome" style={{ fontSize: '18px' }}>
                          {renameField(campo)}:
                        </div>
                        <div className="campo-valor-content" style={{ fontSize: '36px' }}>
                          <strong>{valorCampo}</strong>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          ))
        }
      })}
    </div>
  )

  // Configurar o conteúdo da janela de impressão
  popupWin.document.open()
  popupWin.document.write(`
    <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            font-size: 14px;
            color: #333;
          }
          h2 {
            color: #444;
            margin-bottom: 20px;
          }
          .etiqueta {
            width: 10cm;
            height: 16cm;
            page-break-after: always; /* Garante que cada etiqueta comece em uma nova página */
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .etiqueta-content {
            width: 95%;
            height: 100%;
          }
          .campo-valor {
            border: 1px solid #000000;
            padding: 3px;
            margin-bottom: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .campo-id {
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
          .campo-nome {
            margin-bottom: 3px;
            font-weight: normal;
          }
          .campo-valor-content {
            font-weight: bold;
          }
        </style>
      </head>
      <body onload="window.print(); window.close();">${htmlContent}</body>
    </html>
  `)

  popupWin.document.close()
}

function renameField(field) {
  switch (field) {
    case 'lar_tecido':
      return 'Largura Tecido'
    case 'total_ref':
      return 'Total de Modelos'
    case 'total_rolos':
      return 'Total de Rolos'
    case 'data_corte':
      return 'Foi Cortado'
    case 'total_pecas':
      return 'Total de Peças'
    case 'valor_do_corte':
      return 'Valor'
    case 't_grade':
      return 'Grade'
    case 'data':
      return 'Cadastro'
    default:
      return field.charAt(0).toUpperCase() + field.slice(1)
  }
}
