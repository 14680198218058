import React, { useState, useEffect, useContext, Suspense } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import ModelagemCompletaHome from '../components/pages/Home/index'
import ModelagemCompletaInicio from '../components/pages/Inicio/inicio'
import ImpressaoEtiquetaCorte from '../components/pages/CORTE/ImpressaoEtiquetaCorte/impressao-etiqueta-corte.jsx'
import { AuthContext } from '../context/AuthContext'
import BotaoExterno from '../components/BotaoExterno/botao-externo.jsx'
import CadastroGeral from '../components/pages/CadastroGeral/cadastro/cadastro-geral'
// const ModelagemCompletaLogin = React.lazy(() => import('../components/pages/Usuarios/login/login'))
// const ModelagemCompletaTenants = React.lazy(() => import('../components/pages/Tenant/tenant'))
import ModelagemCompletaLogin from '../components/pages/Usuarios/login/login'
import ModelagemCompletaTenants from '../components/pages/Tenant/tenant'
import VisualizarTenants from '../components/pages/Tenant/visualizarTenants.jsx'
import SelectPlan from '../components/pages/Payments/SelectPlan.jsx'
import PaymentSuccess from '../components/pages/Payments/PaymentSuccess.jsx'
import PaymentFailure from '../components/pages/Payments/PaymentFailure.jsx'
import PaymentPending from '../components/pages/Payments/PaymentPending.jsx'
import ConfigurarValorPlotagem from '../components/pages/PLOTAGEM/ConfigurarValorPlotagem'
import ConfigurarValorEmbalagem from '../components/pages/CORTE/CadastroCorte/configurar-valor-embalagem'
import RelatoriosCorte from '../components/pages/CORTE/CadastroCorte/RelatoriosCorte/RelatoriosCorte'
import RelatoriosModelagem from '../components/pages/MODELAGEM/RelatoriosModelagem/RelatoriosModelagem'
import AnaliseReceita from '../components/pages/CORTE/CadastroCorte/RelatoriosCorte/AnaliseReceita'
import GerenciamentoSetores from '../components/pages/GerenciamentoSetores/gerenciamento-setores'
import GerenciamentoCargos from '../components/pages/GerenciamentoCargos/gerenciamento-cargos'
import GerenciamentoHorarios from '../components/pages/GerenciamentoHorarios/gerenciamento-horarios'
import FuncionariosAtivos from '../components/pages/FuncionariosAtivos/funcionarios-ativos'
import AnalizeLucroBruto from '../components/pages/CORTE/CadastroCorte/RelatoriosCorte/AnalizeLucroBruto.jsx'
import AnalizeLucroBrutoModelagem from '../components/pages/MODELAGEM/RelatoriosModelagem/AnalizeLucroBrutoModelagem.jsx'
import AnalizeLucroBrutoPlotagem from '../components/pages/PLOTAGEM/RelatoriosPlotagem/AnalizeLucroBrutoPlotagem.jsx'
import CadastroPagFuncionarios from '../components/pages/GerenciamentoPagFuncionarios/cadastro-pag-funcionarios.jsx'
import ModuleDescription from '../components/pages/ModuleDescription/ModuleDescription.jsx'
import SaibaMais from '../components/pages/Home/saibaMais.jsx'
import PlanosEPrecosModa from '../components/pages/Home/planos-e-precos-moda.jsx'
import Funcionalidades from '../components/pages/Home/funcionalidades.jsx'
import FaleConosco from '../components/pages/Home/FaleConosco.jsx'
import PasswordRecovery from '../components/pages/Usuarios/login/PasswordRecovery.jsx'
import ResetarSenha from '../components/pages/Usuarios/login/ResetarSenha.jsx'
import AnalizeReceitaModelagem from '../components/pages/MODELAGEM/RelatoriosModelagem/AnaliseReceitaModelagem.jsx'
import RelatoriosPlotagem from '../components/pages/PLOTAGEM/RelatoriosPlotagem/RelatoriosPlotagem.jsx'
import AnaliseReceitaPlotagem from '../components/pages/PLOTAGEM/RelatoriosPlotagem/AnaliseReceitaPlotagem.jsx'
import CadastroModulo from '../components/pages/Modulos/criar-modulo.jsx'
import PropTypes from 'prop-types'
// const CadastroGeral = React.lazy(() =>
//   import('../components/pages/CadastroGeral/cadastro/cadastro-geral')
// )
// const CadastroGeralPE = React.lazy(() =>
//   import('../components/pages/CadastroGeral/cadastro-geral-pesquisa-e-edicao')
// )
import CadastroGeralPE from '../components/pages/CadastroGeral/cadastro-geral-pesquisa-e-edicao'
const CadastroPlotagem = React.lazy(() => import('../components/pages/PLOTAGEM/CadastroPlotagemv2'))
const EditarPlotagem = React.lazy(() => import('../components/pages/PLOTAGEM/EditarPlotagem'))
const EditarModelagem = React.lazy(() => import('../components/pages/MODELAGEM/EditarModelagem'))
//const CadastroCorte = React.lazy(() => import('../components/pages/CadastroCorte/cadastro-corte'))
import CadastroCorte from '../components/pages/CORTE/CadastroCorte/cadastro-corte'
const EditarCorte = React.lazy(() => import('../components/pages/CORTE/CadastroCorte/editar-corte'))
const CadastroProduto = React.lazy(() =>
  import('../components/pages/CadastroProduto/cadastro-produto')
)
const FinalizacaoCortes = React.lazy(() =>
  import('../components/pages/CORTE/FinalizacaoCortes/finalizacao-cortes')
)
const FinalizacaoModelagems = React.lazy(() =>
  import('../components/pages/MODELAGEM/finalizacao-modelagems')
)
//const Financeiro = React.lazy(() => import('../components/pages/Financeiro/financeiro'))
import Financeiro from '../components/pages/Financeiro/financeiro.jsx'
const EmailTemplate = React.lazy(() =>
  import('../components/pages/Financeiro/financeiro-envio-por-email')
)
const CadastroModelagem = React.lazy(() =>
  import('../components/pages/MODELAGEM/cadastro-servico-modelagem')
)
const CadastroGrauDificuldadeModelagem = React.lazy(() =>
  import('../components/pages/MODELAGEM/cadastro-grau')
)
const CadastroPrecosModelagem = React.lazy(() =>
  import('../components/pages/MODELAGEM/cadastro-precos-modelagem')
)
const EditarPrecosModelagem = React.lazy(() =>
  import('../components/pages/MODELAGEM/editar_precos_modelagem')
)
// const AccessLevelRegistration = React.lazy(() =>
//   import('../components/pages/Usuarios/NivelAcesso/nivel_acesso')
// )
import AccessLevelRegistration from '../components/pages/Usuarios/NivelAcesso/nivel_acesso'
// const IpRegistration = React.lazy(() =>
//   import('../components/pages/Usuarios/login/GerenciadorDeIPs')
// )
import IpRegistration from '../components/pages/Usuarios/login/GerenciadorDeIPs'
// const ConfiguracaoUsuarioModelagemCompleta = React.lazy(() =>
//   import('../components/pages/Usuarios/NivelAcesso/config_niveis_acesso')
// )
import ConfiguracaoUsuarioModelagemCompleta from '../components/pages/Usuarios/NivelAcesso/config_niveis_acesso'
// const ConfiguracaoUsuarioPrecoFacilMarket = React.lazy(() =>
//   import('../../PRECO_FACIL_MARKET/src/components/pages/Usuarios/NivelAcesso/config_niveis_acesso')
// )
import ConfiguracaoUsuarioPrecoFacilMarket from '../../PRECO_FACIL_MARKET/src/components/pages/Usuarios/NivelAcesso/config_niveis_acesso'
//const AcessoNegado = React.lazy(() => import('../components/pages/Usuarios/login/AcessoNegado'))
import AcessoNegado from '../components/pages/Usuarios/login/AcessoNegado'
// const CadastroSubSistema = React.lazy(() =>
//   import('../components/pages/CadastroSubSistema/cadastro-sub-sistema')
// )
import CadastroSubSistema from '../components/pages/CadastroSubSistema/cadastro-sub-sistema'
const RelatorioDebitosClientes = React.lazy(() =>
  import('../components/pages/Financeiro/financeiro-geral')
)
//const UserRegistration = React.lazy(() => import('../components/pages/Usuarios/usuarios'))
import UserRegistration from '../components/pages/Usuarios/usuarios'
const CadastroServico = React.lazy(() =>
  import('../components/pages/CadastroServico/cadastro_servico')
)
const CadastroCategoria = React.lazy(() =>
  import('../components/pages/CadastroCategoria/cadastro-categoria')
)
const AssociacaoServicoCategoria = React.lazy(() =>
  import('../components/pages/AssociacaoServicoCategoria/associacao-servico-categoria')
)
// const AssociacaoClienteServico = React.lazy(() =>
//   import('../components/pages/AssociacaoClienteServico/associacao-cliente-servico')
// )
import AssociacaoClienteServico from '../components/pages/AssociacaoClienteServico/associacao-cliente-servico'
const VisualizacaoCategorias = React.lazy(() =>
  import('../components/pages/ViewCategorias/view-categorias')
)
const PagamentosMarketplaces = React.lazy(() =>
  import('../components/pages/Marketplaces/Pagamentos/VisaoGeral/visao-geral')
)
// const MarketplaceDetalhamento = React.lazy(() =>
//   import('../components/pages/Marketplaces/Pagamentos/Detalhamento/marketplace-detalhamento')
// )
// const ListaProdutosMercadoLivre = React.lazy(() =>
//   import('../components/pages/Marketplaces/MercadoLivre/Produtos/listagem')
// )
import ListaProdutosMercadoLivre from '../components/pages/Marketplaces/MercadoLivre/Produtos/listagem'
const ListaVendasMercadoLivre = React.lazy(() =>
  import('../components/pages/Marketplaces/MercadoLivre/Vendas/vendas')
)

export const PrivateRoute = ({ children, tela }) => {
  const {
    isAuthenticated,
    isLoading,
    checkAuth,
    idNivelAcesso,
    nivel_acesso,
    telasPermissao,
    user,
    password,
    carregarPermissoes,
    RotaBase,
    superUser
  } = useContext(AuthContext)

  const location = useLocation()

  const [permissoesCarregadas, setPermissoesCarregadas] = useState(false)

  useEffect(() => {
    if (!superUser) {
      checkAuth().then(() => setPermissoesCarregadas(true))
    } else {
      setPermissoesCarregadas(true)
    }
  }, [checkAuth, superUser])

  if (isLoading || !permissoesCarregadas) {
    return <div>Carregando...</div>
  }

  if (!isAuthenticated && !isLoading) {
    const searchParams = new URLSearchParams(location.search)

    const from = searchParams.get('from')
    const token = searchParams.get('token')
    let url = `/login${from ? `?from=${from}` : ''}`
    if (token) {
      url += `&token=${token}`
    }
    return <Navigate to={url} />
  }

  if (telasPermissao?.length > 0 && tela) {
    let podeAcessar =
      nivel_acesso === 'Administrador' || telasPermissao.some(permissao => permissao.tela === tela)

    if (!podeAcessar && user !== 'c' && password !== '321') {
      return <Navigate to="/acesso-negado" />
    }
  }

  return children
}

const AppRoutes = ({ rotaBase }) => {
  AppRoutes.propTypes = {
    rotaBase: PropTypes.string.isRequired // ou o tipo apropriado para 'rotaBase'
  }
  // Verifica se estamos em produção ou desenvolvimento
  const isProduction = import.meta.env.MODE === 'production'

  // Defina o prefixo da rota com base no ambiente
  const basePath = isProduction ? '' : '/moda.negocios360'
  const location = useLocation()
  const hostname = window.location.hostname
  const pathname = location.pathname

  const isModaNegocios360 =
    (hostname && hostname.includes('moda.negocios360')) ||
    (pathname && pathname.includes('moda.negocios360'))
  const termoDescricao = isModaNegocios360 ? 'moda.negocios360' : 'Modelagem Completa'
  const descricao2 =
    'Você está a um passo de ter o ' +
    termoDescricao +
    ' como aliado do crescimento do seu negócio.'
  return (
    <Suspense fallback={<div>Carregando...</div>}>
      <Routes>
        {/* Rotas públicas */}
        <Route path="/" element={<ModelagemCompletaHome />} />
        <Route path="/home" element={<ModelagemCompletaHome />} />
        <Route path="/modelagemcompleta" element={<ModelagemCompletaHome />} />
        <Route path="/moda.negocios360" element={<ModelagemCompletaHome />} />
        <Route path="/login" element={<ModelagemCompletaLogin />} />
        <Route
          path="/cadastro-tenant"
          element={
            <ModelagemCompletaTenants
              sistema={rotaBase}
              titulo="Comece a gestão do seu negócio!"
              descricao1="Otimize processos e eleve a qualidade da sua produção têxtil."
              descricao2={descricao2}
            />
          }
        />
        <Route path="/acesso-negado" element={<AcessoNegado />} />

        {/* Rotas protegidas */}
        <Route
          path="/relatorio-debitos-clientes"
          element={
            <PrivateRoute tela="Financeiro Geral">
              <RelatorioDebitosClientes />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-sub-sistema"
          element={
            <PrivateRoute tela="Cadastro de Sub Sistemas">
              <CadastroSubSistema />
            </PrivateRoute>
          }
        />
        <Route
          path="/configuracao-usuario"
          element={
            <PrivateRoute tela="Configuração de Níveis de Acesso">
              <ConfiguracaoUsuarioModelagemCompleta />
            </PrivateRoute>
          }
        />
        <Route
          path="/configuracao-usuario-precofacilmarket"
          element={
            <PrivateRoute tela="Configuração de Níveis de Acesso">
              <ConfiguracaoUsuarioPrecoFacilMarket />
            </PrivateRoute>
          }
        />
        <Route
          path="/gerenciador-de-ips"
          element={
            <PrivateRoute tela="Gerenciar IPs de Acesso">
              <IpRegistration />
            </PrivateRoute>
          }
        />
        <Route
          path="/inicio-mc"
          element={
            <PrivateRoute tela="Início">
              <ModelagemCompletaInicio />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-nivel-acesso"
          element={
            <PrivateRoute tela="Cadastro de Níveis de Acesso">
              <AccessLevelRegistration />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-geral"
          element={
            <PrivateRoute tela="Cadastro Geral">
              <CadastroGeral isCadastroGeral={true} isPlotagem={false} />
            </PrivateRoute>
          }
        />
        <Route
          path="/financeiro/:cliente?/:clienteId?"
          element={
            <PrivateRoute tela="Financeiro">
              <Financeiro />
            </PrivateRoute>
          }
        />

        <Route
          path="/financeiro-envio-email"
          element={
            <PrivateRoute tela="Financeiro Envio Email">
              <EmailTemplate />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-cliente-plotagem-editar/:clienteId/"
          element={
            <PrivateRoute tela="Cadastro Cliente Plotagem Editar">
              <CadastroGeral showCategory={false} isEditing={true} isPlotagem={true} />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-cliente-modelagem-editar/:clienteId/"
          element={
            <PrivateRoute tela="Cadastro Cliente Modelagem Editar">
              <CadastroGeral showCategory={false} isEditing={true} isModelagem={true} />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-cliente-corte-editar/:clienteId/"
          element={
            <PrivateRoute tela="Cadastro Cliente Corte Editar">
              <CadastroGeral
                showCategory={false}
                isEditing={true}
                isCorte={true}
                isPlotagem={false}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-cliente-plotagem-visualizar/:clienteId/"
          element={
            <PrivateRoute tela="Cadastro Cliente Plotagem visualizando">
              <CadastroGeral showCategory={false} isVisualizando={true} isPlotagem={true} />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-cliente-modelagem-visualizar/:clienteId/"
          element={
            <PrivateRoute tela="Cadastro Cliente Modelagem visualizando">
              <CadastroGeral showCategory={false} isVisualizando={true} isModelagem={true} />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-cliente-corte-visualizar/:clienteId/"
          element={
            <PrivateRoute tela="Cadastro Cliente Corte visualizando">
              <CadastroGeral
                showCategory={false}
                isVisualizando={true}
                isCorte={true}
                isPlotagem={false}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/plotagem-cadastro-cliente"
          element={
            <PrivateRoute tela="Cadastro Cliente Plotagem">
              <CadastroGeral showCategory={false} isPlotagem={true} />
            </PrivateRoute>
          }
        />
        <Route
          path="/corte-cadastro-cliente"
          element={
            <PrivateRoute tela="Cadastro Cliente Corte">
              <CadastroGeral showCategory={false} isCorte={true} />
            </PrivateRoute>
          }
        />
        <Route
          path="/modelagem-cadastro-cliente"
          element={
            <PrivateRoute tela="Cadastro Cliente Modelagem">
              <CadastroGeral showCategory={false} isModelagem={true} />
            </PrivateRoute>
          }
        />

        <Route
          path="/relogiodeponto-cadastro-cliente"
          element={
            <PrivateRoute tela="Cadastro Cliente Relogiodeponto">
              <CadastroGeral showCategory={false} isRelogiodeponto={true} />
            </PrivateRoute>
          }
        />
        {/* ultima rota cadastro cliente */}

        <Route
          path="/corte-cadastro-corte"
          element={
            <PrivateRoute tela="Cadastro de Corte">
              <CadastroCorte />
            </PrivateRoute>
          }
        />
        <Route
          path="/modelagem-cadastro-servico-modelagem"
          element={
            <PrivateRoute tela="Cadastro Serviço Modelagem">
              <CadastroModelagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/modelagem-cadastro-grau-dificuldade-modelagem"
          element={
            <PrivateRoute tela="Cadastro Grau Dificuldade Modelagem">
              <CadastroGrauDificuldadeModelagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/modelagem-cadastro-precos-modelagem"
          element={
            <PrivateRoute tela="Cadastro Preços Modelagem">
              <CadastroPrecosModelagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/modelagem-editar-precos-modelagem"
          element={
            <PrivateRoute tela="Editar Preços Modelagem">
              <EditarPrecosModelagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/corte-editar-corte"
          element={
            <PrivateRoute tela="Editar Corte">
              <EditarCorte />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-geral-pesquisa-e-edicao"
          element={
            <PrivateRoute tela="Cadastro Geral Pesquisa e Edição">
              <CadastroGeralPE />
            </PrivateRoute>
          }
        />
        <Route
          path="/plotagem-pesquisa-e-edicao-cliente"
          element={
            <PrivateRoute tela="Plotagem Pesquisa e Edição Cliente">
              <CadastroGeralPE isPlotagem={true} />
            </PrivateRoute>
          }
        />
        <Route
          path="/corte-pesquisa-e-edicao-cliente"
          element={
            <PrivateRoute tela="Corte Pesquisa e Edição Cliente">
              <CadastroGeralPE isCorte={true} />
            </PrivateRoute>
          }
        />
        <Route
          path="/modelagem-pesquisa-e-edicao-cliente"
          element={
            <PrivateRoute tela="Modelagem Pesquisa e Edição Cliente">
              <CadastroGeralPE isModelagem={true} />
            </PrivateRoute>
          }
        />

        <Route
          path="/relogiodeponto-pesquisa-e-edicao-cliente"
          element={
            <PrivateRoute tela="relogiodeponto Pesquisa e Edição Cliente">
              <CadastroGeralPE isRelogiodeponto={true} />
            </PrivateRoute>
          }
        />

        {/* ultima rota pesquisa e edicao cliente */}
        <Route
          path="/plotagem-cadastro-plotagem"
          element={
            <PrivateRoute tela="Cadastro de Plotagem">
              <CadastroPlotagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/plotagem-cadastro-plotagem/:plotagemId"
          element={
            <PrivateRoute tela="Editar Plotagem">
              <CadastroPlotagem isEditing={true} />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-usuario"
          element={
            <PrivateRoute tela="Cadastro Usuário Sistema">
              <UserRegistration />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-servico"
          element={
            <PrivateRoute tela="Cadastro de Serviço">
              <CadastroServico />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-categoria"
          element={
            <PrivateRoute tela="Cadastro de Categoria">
              <CadastroCategoria />
            </PrivateRoute>
          }
        />
        <Route
          path="/associacao-servico-categoria"
          element={
            <PrivateRoute tela="Cadastro de Associação Serviço Categoria">
              <AssociacaoServicoCategoria />
            </PrivateRoute>
          }
        />
        <Route
          path="/associacao-cliente-servico"
          element={
            <PrivateRoute tela="Cadastro de Associação Cliente Serviço">
              <AssociacaoClienteServico />
            </PrivateRoute>
          }
        />
        <Route
          path="/visualizacao-categorias"
          element={
            <PrivateRoute tela="Visualização das Categorias">
              <VisualizacaoCategorias />
            </PrivateRoute>
          }
        />
        <Route
          path="/pagamentos-marketplaces"
          element={
            <PrivateRoute tela="Pagamentos Marketplaces">
              <PagamentosMarketplaces />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/marketplace-detalhamento/:id"
          element={
            <PrivateRoute tela="MarketPlaces Detalhamento">
              <MarketplaceDetalhamento />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/listagem-produtos-mercadolivre"
          element={
            <PrivateRoute tela="Listagem Produtos Mercado Livre">
              <ListaProdutosMercadoLivre />
            </PrivateRoute>
          }
        />
        <Route
          path="/vendas-mercado-livre"
          element={
            <PrivateRoute tela="Vendas do Mercado Livre">
              <ListaVendasMercadoLivre />
            </PrivateRoute>
          }
        />
        <Route
          path="/editando-plotagems"
          element={
            <PrivateRoute tela="Editar Plotagem">
              <EditarPlotagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/editando-modelagems"
          element={
            <PrivateRoute tela="Editar Modelagem">
              <EditarModelagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-produto"
          element={
            <PrivateRoute tela="Cadastro de Produto">
              <CadastroProduto />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-tecido"
          element={
            <PrivateRoute tela="Cadastro de Tecido">
              <CadastroProduto PRODUTO={'tecido'} />
            </PrivateRoute>
          }
        />
        <Route
          path="/finalizacao-de-cortes"
          element={
            <PrivateRoute tela="Finalização de Corte">
              <FinalizacaoCortes />
            </PrivateRoute>
          }
        />
        <Route
          path="/finalizacao-de-modelagems"
          element={
            <PrivateRoute tela="Finalização de Modelagem">
              <FinalizacaoModelagems />
            </PrivateRoute>
          }
        />
        <Route path="/botao-externo" element={<BotaoExterno />} />

        <Route
          path="/assinar-plano"
          element={
            <PrivateRoute tela="Assinar Plano">
              <SelectPlan />
            </PrivateRoute>
          }
        />

        <Route
          path="/payment-success"
          element={<PaymentSuccess systemName="Modelagem Completa" />}
        />
        <Route
          path="/payment-failure"
          element={<PaymentFailure systemName="Modelagem Completa" />}
        />
        <Route
          path="/payment-pending"
          element={<PaymentPending systemName="Modelagem Completa" />}
        />
        <Route path="/configurar-valor-plotagem" element={<ConfigurarValorPlotagem />} />
        <Route path="/configurar-valor-embalagem" element={<ConfigurarValorEmbalagem />} />
        <Route
          path="/resumo-corte"
          element={
            <PrivateRoute tela="Resumo do Corte">
              <RelatoriosCorte />
            </PrivateRoute>
          }
        />
        <Route
          path="/resumo-modelagem"
          element={
            <PrivateRoute tela="Resumo da Modelagem">
              <RelatoriosModelagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/analise-receita-corte"
          element={
            <PrivateRoute tela="Análise de Receita">
              <AnaliseReceita />
            </PrivateRoute>
          }
        />
        <Route
          path="/gerenciamento-setores"
          element={
            <PrivateRoute tela="Gerenciamento de Setores">
              <GerenciamentoSetores />
            </PrivateRoute>
          }
        />
        <Route
          path="/gerenciamento-cargos"
          element={
            <PrivateRoute tela="Gerenciamento de Cargos">
              <GerenciamentoCargos />
            </PrivateRoute>
          }
        />
        <Route
          path="/funcionarios-ativos"
          element={
            <PrivateRoute tela="Funcionários Ativos">
              <FuncionariosAtivos />
            </PrivateRoute>
          }
        />
        <Route
          path="/gerenciamento-horarios"
          element={
            <PrivateRoute tela="Gerenciamento de Horários">
              <GerenciamentoHorarios />
            </PrivateRoute>
          }
        />
        <Route
          path="/analise-lucro-bruto-corte"
          element={
            <PrivateRoute tela="Análise de Lucro Bruto">
              <AnalizeLucroBruto />
            </PrivateRoute>
          }
        />
        <Route
          path="/analise-lucro-bruto-modelagem"
          element={
            <PrivateRoute tela="Análise de Lucro Bruto Modelagem">
              <AnalizeLucroBrutoModelagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/analise-lucro-bruto-plotagem"
          element={
            <PrivateRoute tela="Análise de Lucro Bruto Plotagem">
              <AnalizeLucroBrutoPlotagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/cadastro-pagamentos-funcionarios"
          element={
            <PrivateRoute tela="Cadastro de Pagamentos">
              <CadastroPagFuncionarios />
            </PrivateRoute>
          }
        />
        <Route
          path="/module-description/:moduleName"
          element={
            <PrivateRoute tela="Descrição do Módulo">
              <ModuleDescription />
            </PrivateRoute>
          }
        />
        <Route
          path="/saiba-mais"
          element={
            // <PrivateRoute tela="Saiba Mais">
            <SaibaMais />
            // </PrivateRoute>
          }
        />
        <Route
          path="/planos-e-precos-moda"
          element={
            // <PrivateRoute tela="Planos e Preços">
            <PlanosEPrecosModa />
            // </PrivateRoute>
          }
        />
        <Route
          path="/funcionalidades"
          element={
            // <PrivateRoute tela="Funcionalidades">
            <Funcionalidades />
            // </PrivateRoute>
          }
        />
        <Route
          path="/fale-conosco"
          element={
            // <PrivateRoute tela="Fale Conosco">
            <FaleConosco />
            // </PrivateRoute>
          }
        />
        <Route
          path="/visualizar-tenants"
          element={
            //apenas o owner pode acessar
            <PrivateRoute tela="Usuarios cadastrados">
              <VisualizarTenants />
            </PrivateRoute>
          }
        />
        <Route
          path="/analise-receita-modelagem"
          element={
            <PrivateRoute tela="Análise de Receita Modelagem">
              <AnalizeReceitaModelagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/resumo-plotagem"
          element={
            <PrivateRoute tela="Relatórios de Plotagem">
              <RelatoriosPlotagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/analise-receita-plotagem"
          element={
            <PrivateRoute tela="Análise de Receita Plotagem">
              <AnaliseReceitaPlotagem />
            </PrivateRoute>
          }
        />
        <Route
          path="/imprimir-etiqueta-de-corte"
          element={
            <PrivateRoute tela="Impressão de Etiqueta de Corte">
              <ImpressaoEtiquetaCorte />
            </PrivateRoute>
          }
        />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route path={`${basePath}/resetar_senha/:token`} element={<ResetarSenha />} />
        <Route path={'/criar-modulo'} element={<CadastroModulo />} />
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
