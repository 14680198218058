// funcionalidadesData.js

export const funcionalidadesData = [
  {
    modulo: 'Corte',
    descricao: 'Gerencie todas as funcionalidades relacionadas ao processo de corte.',
    funcionalidades: [
      'Backup automático de dados',
      'Suporte prioritário',
      'Acesso multiusuário com diferentes níveis de permissão',
      'Relatórios avançados de desempenho',
      'Dashboard interativo para monitoramento de desempenho'
    ]
  },
  {
    modulo: 'Modelagem',
    descricao: 'Explore as funcionalidades avançadas do módulo de modelagem.',
    funcionalidades: [
      'Suporte prioritário',
      'Backup automático de dados',
      'Relatórios avançados de desempenho',
      'Gestão de versões de modelos',
      'Controle de revisões e aprovações',
      'Acesso multiusuário com diferentes níveis de permissão'
    ]
  },
  {
    modulo: 'Plotagem',
    descricao: 'Gerencie as funcionalidades essenciais do módulo de plotagem.',
    funcionalidades: [
      'Suporte prioritário',
      'Backup automático de dados',
      'Monitoramento em tempo real das plotagens',
      'Relatórios de eficiência e produtividade',
      'Notificações de status de plotagem',
      'Configuração de perfis de usuário personalizados',
      'Acesso multiusuário com diferentes níveis de permissão'
    ]
  }
  // Adicione mais módulos conforme necessário
]
