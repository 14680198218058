import { pegarDados } from '../backend/model'
import sheetsConfig from '../config/sheetsConfig'
import { fetchData } from '../../src/backend/model'
import { config } from '../backend/configConexaoGeral'

//-------------------------------------------------------------------------------------
export async function buscarCategorias(tenantId, rota_base) {
  const dados = {
    requisicao: 'buscarCategorias',
    tenantId,
    rota_base
  }

  const url = `${config.host}/configuracoesGerais` // Rota definida para 'buscarCategorias'

  try {
    const response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao buscar categorias:', error)
    throw error
  }
}
//-------------------------------------------------------------------------------------
