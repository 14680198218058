import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../../../src/context/AuthContext'
import { fetchData } from '../../../../src/backend/model'
import { config } from '../../../../src/backend/configConexaoGeral'
import Banner from '../../../components/pages/CadastroGeral/Banner'
import { Button } from 'react-bootstrap'
import { ShowAlertGeneric } from '../../../utils/form-utils.jsx'

const ConfigurarValorPlotagem = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [valorMetroPlotagem, setValorMetroPlotagem] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('') // 'success' or 'danger'

  useEffect(() => {
    const fetchValorMetroPlotagem = async () => {
      try {
        const dados = {
          tenantId: tenantId,
          rota_base: RotaBase,
          requisicao: 'getValorMetroPlotagem'
        }
        const url = config.host + '/configuracoesGerais'
        const response = await fetchData(url, dados)

        if (response.success && response.valorMetroPlotagem !== undefined) {
          setValorMetroPlotagem(response.valorMetroPlotagem)
        } else {
          setAlertVariant('danger')
          setAlertMessage('Erro ao buscar o valor da plotagem')
          setShowAlert(true)
        }
      } catch (error) {
        console.error('Erro ao buscar o valor da plotagem:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar o valor da plotagem')
        setShowAlert(true)
      }
    }

    fetchValorMetroPlotagem()
  }, [RotaBase, tenantId])

  const handleSubmit = async event => {
    event.preventDefault()
    try {
      const dados = {
        tenantId: tenantId,
        rota_base: RotaBase,
        valorMetroPlotagem: parseFloat(valorMetroPlotagem),
        requisicao: 'saveValorMetroPlotagem'
      }
      const url = config.host + '/configuracoesGerais'
      const response = await fetchData(url, dados)

      if (response.success) {
        setAlertVariant('success')
        setAlertMessage('Valor da plotagem atualizado com sucesso')
      } else {
        setAlertVariant('danger')
        setAlertMessage('Erro ao atualizar o valor da plotagem')
      }
    } catch (error) {
      console.error('Erro ao atualizar o valor da plotagem:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao atualizar o valor da plotagem')
    } finally {
      setShowAlert(true)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        margin: '40px 0px' // Pequena margem horizontal
      }}
    >
      <Banner title="PLOTAGEM" width="20%" />
      <h2 style={{ textAlign: 'center' }}>Configurar Valor do Metro da Plotagem</h2>
      <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '1rem' }}>
          <label>Valor do Metro da Plotagem:</label>
          <input
            type="number"
            value={valorMetroPlotagem}
            onChange={e => setValorMetroPlotagem(e.target.value)}
            required
            style={{ marginLeft: '0.5rem' }}
          />
        </div>
        <Button variant="primary" type="submit" style={{ alignSelf: 'center' }}>
          Atualizar Valor
        </Button>
      </form>
      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
    </div>
  )
}

export default ConfigurarValorPlotagem
