import { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../../../context/AuthContext.jsx'
import { fetchData } from '../../../../backend/model.js'
import { config } from '../../../../backend/configConexaoGeral.js'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'
import { Container, Form } from 'react-bootstrap'
import Banner from '../../CadastroGeral/Banner.jsx'
import DatePicker from 'react-datepicker'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell
} from 'recharts'

const AnaliseReceitaPlotagem = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [dataInicio, setDataInicio] = useState(new Date())
  const [dataFim, setDataFim] = useState(new Date())
  const [periodoSelecionado, setPeriodoSelecionado] = useState('7d') // Default to last 7 days
  const [receitaTotal, setReceitaTotal] = useState(0)
  const [receitaPorCliente, setReceitaPorCliente] = useState([])
  const [receitaPorDia, setReceitaPorDia] = useState([])
  const [receitaPorDiaDaSemana, setReceitaPorDiaDaSemana] = useState([])
  const [loading, setLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('') // 'success' or 'danger'

  const updateDateRange = period => {
    const end = new Date()
    let start = new Date()
    switch (period) {
      case '7d':
        start.setDate(end.getDate() - 2)
        break
      case '15d':
        start.setDate(end.getDate() - 15)
        break
      case '1m':
        start.setMonth(end.getMonth() - 1)
        break
      case '3m':
        start.setMonth(end.getMonth() - 3)
        break
      case '6m':
        start.setMonth(end.getMonth() - 6)
        break
      case '1y':
        start.setFullYear(end.getFullYear() - 1)
        break
      case 'all':
        start = new Date(0) // Start of UNIX time
        break
      case 'custom':
        // Keep current custom range
        return
      default:
        return
    }
    setDataInicio(start)
    setDataFim(end)
  }
  useEffect(() => {
    const fetchDadosReceita = async () => {
      setLoading(true)
      try {
        const url = `${config.host}/analiseReceitaPlotagem`
        const receitaTotalResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          dataInicio,
          dataFim,
          requisicao: 'obterReceitaTotalPeriodoPlotagem'
        })
        if (receitaTotalResponse) {
          setReceitaTotal(receitaTotalResponse.totalReceita || 0)
        }

        const receitaPorClienteResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          dataInicio,
          dataFim,
          requisicao: 'obterDetalheReceitaPorClientePlotagem'
        })
        if (receitaPorClienteResponse) {
          console.log('receitaPorClienteResponse:', receitaPorClienteResponse)
          setReceitaPorCliente(receitaPorClienteResponse.slice(0, 5) || [])
        }

        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar dados de receita:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar dados de receita')
        setShowAlert(true)
        setLoading(false)
      }
    }

    fetchDadosReceita()
  }, [dataInicio, dataFim, RotaBase, tenantId])

  //agora o useEffect para a funcao de servidor obterDetalheReceitaPorDia
  useEffect(() => {
    const fetchDadosReceitaPorDia = async () => {
      setLoading(true)
      try {
        const url = `${config.host}/analiseReceitaPlotagem`
        const receitaPorDiaResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          dataInicio,
          dataFim,
          requisicao: 'obterDetalheReceitaPorDiaPlotagem'
        })
        if (receitaPorDiaResponse) {
          console.log('receitaPorDiaResponse:', receitaPorDiaResponse)
          setReceitaPorDia(receitaPorDiaResponse || [])
        }

        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar dados de receita por dia:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar dados de receita por dia')
        setShowAlert(true)
        setLoading(false)
      }
    }

    fetchDadosReceitaPorDia()
  }, [dataInicio, dataFim, RotaBase, tenantId])

  //agora o useEffect para a funcao de servidor obterDetalheReceitaPorDiaDaSemana
  useEffect(() => {
    const fetchDadosReceitaPorDiaDaSemana = async () => {
      setLoading(true)
      try {
        const url = `${config.host}/analiseReceitaPlotagem`
        const receitaPorDiaDaSemanaResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          dataInicio,
          dataFim,
          requisicao: 'obterDetalheReceitaPorDiaDaSemanaPlotagem'
        })
        if (receitaPorDiaDaSemanaResponse) {
          console.log('receitaPorDiaDaSemanaResponse:', receitaPorDiaDaSemanaResponse)
          setReceitaPorDiaDaSemana(receitaPorDiaDaSemanaResponse || [])
        }

        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar dados de receita por dia da semana:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar dados de receita por dia da semana')
        setShowAlert(true)
        setLoading(false)
      }
    }

    fetchDadosReceitaPorDiaDaSemana()
  }, [dataInicio, dataFim, RotaBase, tenantId])

  const handlePeriodoSelecionado = event => {
    const period = event.target.value
    setPeriodoSelecionado(period)
    if (period !== 'custom') {
      updateDateRange(period)
    }
  }

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    payload
  }) => {
    const RADIAN = Math.PI / 180
    // Ajuste dinâmico para mover os rótulos mais para fora baseado no ângulo
    const radius = outerRadius + 10 + (index % 2) * 20 // Alternando a distância para evitar sobreposição
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text
        x={x}
        y={y}
        fill={COLORS[index % COLORS.length]} // Cor do texto como a da fatia
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontWeight: 'bold' }}
      >
        {`${payload.clienteNome}: ${payload.totalReceita.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })}`}
      </text>
    )
  }

  const receitaPorDiaFormatada = receitaPorDia.map(d => ({
    ...d,
    dataCompleta: `${d.data} (${d.diaDaSemana})`
  }))

  function handleDateChange(dates) {
    const [start, end] = dates // Extrai as datas de início e fim do array
    setDataInicio(start) // Atualiza o estado da data de início
    setDataFim(end) // Atualiza o estado da data de fim
  }

  return (
    <Container
      fluid
      className="p-4"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center' // Adiciona alinhamento central para os itens horizontalmente
        // height: '100vh' // Assegura que o Container tome toda a altura da viewport
      }}
    >
      <div style={{ width: '100%', maxWidth: '600px' }}>
        <Banner
          title="Análise de Receita - plotagem"
          width="100%"
          fontSize={42}
          classEfeito3dMouseOver=""
        />
        <Form.Group className="mb-3">
          <h4>Selecione o Período</h4>
          {['7d', '15d', '1m', '3m', '6m', '1y', 'all', 'custom'].map(period => (
            <Form.Check
              key={period}
              type="radio"
              label={periodLabels[period]}
              name="periodoAnalise"
              id={`radio-${period}`}
              value={period}
              checked={periodoSelecionado === period}
              onChange={handlePeriodoSelecionado}
              className="mb-2"
            />
          ))}
          {periodoSelecionado === 'custom' && (
            <DatePicker
              selectsRange={true}
              startDate={dataInicio}
              endDate={dataFim}
              onChange={handleDateChange}
              isClearable={true}
              className="form-control"
              dateFormat="dd/MM/yyyy"
            />
          )}
        </Form.Group>
      </div>
      {/* Gráficos e Tabelas */}
      <div className="section-container">
        <h2>Receita Total</h2>
        <p>{`Total de Receita: ${receitaTotal.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })}`}</p>

        <h2 style={{ marginBottom: '0x' }}>Gráfico de Receita por Cliente</h2>
        <BarChart
          width={600}
          height={300}
          data={receitaPorCliente}
          margin={{ top: 40, right: 30, left: 20, bottom: 40 }}
          style={{ height: '320px' }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="clienteNome" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="totalReceita" fill="#8884d8" label={{ position: 'top' }} />
        </BarChart>
        <h2 style={{ marginTop: '20px' }}>Receita por Dia</h2>
        <BarChart
          width={800}
          height={400}
          data={receitaPorDiaFormatada}
          margin={{ top: 40, right: 30, left: 20, bottom: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dataCompleta" />

          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="totalReceita" fill="#8884d8" label={{ position: 'top' }} />
        </BarChart>

        <h2 style={{ marginTop: '20px' }}>Receita por Dia da Semana</h2>
        <BarChart
          width={800}
          height={400}
          data={receitaPorDiaDaSemana}
          margin={{ top: 40, right: 30, left: 20, bottom: 40 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="diaDaSemana" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="totalReceita" fill="#8884d8" label={{ position: 'top' }} />
        </BarChart>

        <h2 style={{ marginTop: '120px' }}>Os 5 Melhores clientes</h2>
        <PieChart width={800} height={400}>
          <Pie
            data={receitaPorCliente}
            dataKey="totalReceita"
            nameKey="clienteNome"
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            label={renderCustomizedLabel}
          >
            {receitaPorCliente.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </div>

      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
    </Container>
  )
}

export default AnaliseReceitaPlotagem

const periodLabels = {
  '7d': 'Últimos 7 dias',
  '15d': 'Últimos 15 dias',
  '1m': 'Último mês',
  '3m': 'Últimos 3 meses',
  '6m': 'Últimos 6 meses',
  '1y': 'Último ano',
  all: 'Todo o período',
  custom: 'Intervalo personalizado'
}
