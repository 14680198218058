//regra-magalu.js
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Row, Col } from 'react-bootstrap'
import SmartTable from '../../../../../../src/components/SmartTable/smartTable'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import logoMagalu from './logo.jpeg'
import { AuthContext } from '/src/context/AuthContext'
import { useContext } from 'react'
const CadastroRegrasFreteMagalu = () => {
  // const [regraSelecionada, setRegraSelecionada] = useState([]);
  const [regrasFormatadas, setRegrasFormatadas] = useState([{}])
  const [idMarketplace, setIdMarketplace] = useState('') // Supondo que você deseja gravar apenas o primeiro item selecionado
  const { tenantId } = useContext(AuthContext)
  const [ultimaRegraSelecionada, setUltimaRegraSelecionada] = useState('')

  useEffect(() => {
    async function fetchUltimaRegraSelecionada() {
      if (regrasFormatadas.length > 0 && idMarketplace) {
        try {
          let dados = {
            tenantId: tenantId,
            marketplace: idMarketplace,
            requisicao: 'BuscarUltimaRegraSelecionada'
          }
          let url = config.host + '/regraDescontoFrete'
          let response = await fetchData(url, dados)
          let ultimaRegraSelecionada = response?.regra // Suponha que o backend retorna o objeto da regra com '_id'

          if (ultimaRegraSelecionada) {
            setUltimaRegraSelecionada(ultimaRegraSelecionada)
          }
        } catch (error) {
          console.error('Erro ao buscar a última regra selecionada:', error)
        }
      }
    }

    fetchUltimaRegraSelecionada()
  }, [tenantId, idMarketplace, regrasFormatadas.length])

  const { data: regras, isLoading: isLoadingRegras } = useQuery(
    'regrasDescontoFrete',
    () => CarregarRegrasPrecoFrete(),
    {
      refetchOnWindowFocus: false
    }
  )
  useEffect(() => {
    async function carregarDados() {
      let idMarketplace = await carregaIdMarketplace('Magalu')

      setIdMarketplace(idMarketplace[0]._id)
    }

    carregarDados()
  }, [])

  useEffect(() => {
    if (regras) setRegrasFormatadas(formatarRegras(regras))
  }, [regras])

  const handleSelectedRegras = async selectedItems => {
    //  setRegraSelecionada(selectedItems[0]);
    // if (selectedItems.length > 0)

    {
      await gravarRegraNoBanco(selectedItems) // Supondo que você deseja gravar apenas o primeiro item selecionado
    }
  }

  const gravarRegraNoBanco = async regra => {
    try {
      let dados = {
        tenantId: tenantId,
        marketplace: idMarketplace, // Supondo que cada regra tem um identificador 'id'
        regra: regra, // Supondo que cada regra tem um identificador 'id'
        requisicao: 'GravarAssociacaoRegrasCorreiosMarketplaces' // Altere conforme necessário
      }
      let url = config.host + '/regraDescontoFrete' // Altere para a URL apropriada

      await fetchData(url, dados)
    } catch (error) {
      console.error('Erro ao gravar a regra no banco:', error)
    }
  }

  function formatarRegras(regras) {
    const regrasFormatadas = regras.map(regra => {
      let descricao

      switch (regra.tipo_comparacao) {
        case 'menor':
          descricao = `Para porcentagem de entrega menor que ${regra.limite_unico} %`
          break
        case 'maior':
          descricao = `Para porcentagem de entrega maior que ${regra.limite_unico} %`
          break
        case 'entre':
          descricao = `Para porcentagem de entrega entre ${regra.limite_inferior} % e ${regra.limite_superior} %`
          break
        default:
          descricao = 'Descrição não definida'
      }

      return {
        ...regra,
        descricao,
        desconto: `${regra.desconto} %`
      }
    })

    return regrasFormatadas
  }

  // const selectedItemsForSmartTable = regrasFormatadas
  //   .filter((regra) => regra.selecionada)
  //   .map((regra) => regra._id);

  return (
    <div
      className="d-flex align-items-center form-container"
      style={{ height: '100vh', position: 'relative', paddingTop: '16px' }}
    >
      <div className="text-center mx-auto" style={{ maxWidth: '2000px' }}>
        <div
          style={{
            position: 'absolute',
            top: 16,
            marginTop: '20px', // Ajuste o valor de marginTop conforme necessário
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={logoMagalu} alt="Logo" className="logo-magalu" />

            <div style={{ marginLeft: '16px' }}>
              <Banner title="Cadastro de Regras" color="#2e8b57" />
            </div>
          </div>
        </div>

        <Row style={{ maxWidth: '1100px', margin: 'auto', paddingTop: '20px' }}>
          <Col md={12} className="mb-3">
            <h5 style={{ marginTop: '30px' }}>Cadastro de Regras de Desconto de Frete</h5>
            {regrasFormatadas && (
              <SmartTable
                data={regrasFormatadas}
                camposLabels={[
                  { field: 'descricao', label: 'Descrição da Regra' },
                  { field: 'desconto', label: 'desconto' }
                ]}
                fixedHeight="300px"
                tituloProps={{
                  title: 'Regras Disponíveis',
                  color: '#2e8b57',
                  fontSize: 30
                }}
                onSelectedItemsChange={handleSelectedRegras}
                radioBox={true}
                pesquisa={'off'}
                selectedItems={ultimaRegraSelecionada}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default CadastroRegrasFreteMagalu

//-------------------------------------------------------------------------------------------------
export async function CarregarRegrasPrecoFrete() {
  try {
    let dados = {
      requisicao: 'CarregarRegrasDescontoFrete'
    }
    let url = config.host + '/regraDescontoFrete'

    let response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao carregar regras de desconto de frete:', error)
  }
}
//-------------------------------------------------------------------------------------------------
export async function CarregarRegrasPesoFrete(marketplace) {
  try {
    let dados = {
      requisicao: 'CarregarRegrasPesoFrete',
      marketplace: marketplace
    }
    let url = config.host + '/regraDescontoFrete'

    let response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao carregar regras de peso de frete:', error)
  }
}
//-------------------------------------------------------------------------------------------------
export const carregaIdMarketplace = async marketplace => {
  try {
    let dados = {
      nome: marketplace, // Supondo que cada marketplace tem um identificador 'id'
      requisicao: 'CarregarIDMarketplacePF' // Altere conforme necessário
    }
    let url = config.host + '/regraDescontoFrete' // Altere para a URL apropriada
    let id = await fetchData(url, dados)

    return id
  } catch (error) {
    console.error('Erro ao carregar o ID do marketplace:', error)
  }
}
