import { useState, useContext, useEffect } from 'react'
import { Button, Col, Row, FormGroup, Alert, Form } from 'react-bootstrap'
import { enviar } from '../../../../src/backend/model'
import { useUsersEfects } from '../../../../src/utils/UsersEffectsCadastros.js'
import Banner from '../../../../src/components/pages/CadastroGeral/Banner'
import { Uset } from '../../../../src/components/pages/CadastroGeral/cadastro/cadastro-fields.jsx'
import { MyContext } from '../../../../src/components/pages/CadastroGeral/cadastro-geral-context'
import AlertMessage from '../../../../src/components/pages/PLOTAGEM/AlertMessage'
import { buscarServicos } from '../../../../src/servicos/dadosServicos'
import { config } from '../../../../src/backend/configConexaoGeral'
import { fetchData } from '../../../../src/backend/model'
import sheetsConfig from '../../../../src/config/sheetsConfig'

import { useSelector, useDispatch } from 'react-redux'
import { setShowAlertGravando } from '../../../../src/utils/actions'
import { setShowAlert } from '../../../../src/utils/actions'
import Select from 'react-select'
import { buscarCategorias } from '../../../../src/categorias/dados-categorias'
import { AuthContext } from '../../../../src/context/AuthContext'
// import { getAvailableModules } from '../../../../src/components/pages/Payments/SelectPlan.js'
function CadastroCategoria({ isEditing = false }) {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [categoriaOptions, setCategoriaOptions] = useState([])
  const [travarCategoria, setTravarCategoria] = useState(false)
  const [servicoOptions, setServicoOptions] = useState([])
  const [mensagemErro, setMensagemErro] = useState('')
  // const [availableModules, setAvailableModules] = useState([])

  // useEffect(() => {
  //   const fetchAvailableModules = async () => {
  //     const modules = await getAvailableModules(tenantId, RotaBase)

  //     setAvailableModules(modules)
  //   }

  //   fetchAvailableModules()
  // }, [tenantId, RotaBase])
  async function buscarModulos(tenantId, RotaBase) {
    const dados = {
      requisicao: 'listarModulosTenant',
      rota_base: RotaBase,
      tenantId: tenantId
    }

    const url = `${config.host}/tenants` // Rota definida para 'buscarServicos'

    try {
      const response = await fetchData(url, dados)

      return response
    } catch (error) {
      console.error('Erro ao buscar serviços:', error)
      throw error
    }
  }
  useEffect(() => {
    buscarCategorias(tenantId, RotaBase).then(categorias => {
      const categoriasOptions = categorias.categorias.map(categoria => {
        return {
          value: categoria.id_categoria,
          label: categoria.nome_categoria
        }
      })
      setCategoriaOptions(categoriasOptions)
    })

    buscarModulos(tenantId, RotaBase).then(modulos => {
      // Primeiro passo: aplicar o filter para filtrar apenas aqueles que atendem aos critérios
      const modulosFiltrados = Object.values(modulos.modules).filter(modulo => {
        console.log('Antes do filtro - modulo.modulo:', modulo.modulo) // Log do nome do módulo antes do filtro
        console.log('Antes do filtro - modulo.idServico:', modulo.idServico) // Log do idServico antes do filtro
        console.log('Antes do filtro - assinado:', modulo.estaAssinado) // Log do valor de assinado antes do filtro
        console.log('Antes do filtro - gratuito:', modulo.periodoGratuito) // Log do valor de gratuito antes do filtro

        const retorna =
          (modulo.estaAssinado === true || modulo.periodoGratuito === true) &&
          modulo.modulo !== 'corte'
        console.log('Retorna do filtro:', retorna) // Log para verificar se o módulo será incluído ou não no filtro

        return retorna // Retornar true se o módulo atender aos critérios
      })

      console.log('Após o filtro - módulos filtrados:', modulosFiltrados) // Log dos módulos que passaram pelo filtro

      // Segundo passo: aplicar o map nos módulos filtrados para transformar em objetos com 'value' e 'label'
      const servicosOptions = modulosFiltrados.map(modulo => {
        console.log('Antes do map - módulo que passou no filtro:', modulo) // Log do módulo que está passando para o map
        return {
          value: modulo.idServico, // Define 'value' como o 'idServico'
          label: modulo.modulo // Define 'label' como o nome do módulo (ex: 'corte', 'modelagem')
        }
      })

      console.log('Após o map - servicosOptions:', servicosOptions) // Log dos objetos finais após o map
      console.log('servicosOptions.length:', servicosOptions.length)
      console.log('formState.id_servico:', formState.id_servico)
      if (servicosOptions.length === 1) {
        // Se houver apenas um módulo, definir como padrão no formState
        console.log('servicosOptions 3:', servicosOptions[0].value)
        setFormState(prevState => ({
          ...prevState,
          id_servico: servicosOptions[0].value
        }))
        setServicoOptions(servicosOptions)
      } else {
        setServicoOptions(servicosOptions)
      }
    })
  }, [tenantId, RotaBase])

  let contextValue = Uset().Get_contextValue
  contextValue = {
    ...contextValue,
    isEditing
  }
  let varsCad = useUsersEfects(contextValue)
  const { selectedSector, clienteId, clienteData } = useContext(MyContext)
  //---------------------------------------------
  const initialFormState = {
    categoria: '',
    id_categoria_pai: null,
    descricao: '',
    ATIVO: true,
    id_servico: null
  }
  const [formState, setFormState] = useState(initialFormState)
  const resetForm = () => {
    console.log('servicosOptions2:', servicoOptions)
    setFormState(prevState => ({
      ...initialFormState,
      id_categoria_pai: travarCategoria ? prevState.id_categoria_pai : null,
      id_servico: servicoOptions.length === 1 ? servicoOptions[0].value : null
    }))
  }

  //---------------------------------------------

  const updateField = (field, value) => {
    setFormState(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const showAlertGravando = useSelector(state => state.showAlertGravando)
  const showAlert = useSelector(state => state.showAlert)
  const dispatch = useDispatch()

  const cadastroDados = {
    informacoes: {
      Nome_Categoria: formState.categoria,
      ID_Categoria_Pai: formState.id_categoria_pai ? formState.id_categoria_pai.value : null,
      Descricao: formState.descricao,
      ATIVO: formState.ATIVO,
      rota_base: RotaBase,
      tenantId: tenantId
    },
    rota: 'funcoesGenericas',
    requisicao: 'enviar',
    pagina: sheetsConfig['cadastroCategoria'].tabs.categorias
  }

  //---------------------------------------------
  const handleCloseAlert = () => {
    setShowAlert(false)
  }
  //---------------------------------------------
  const [isSaved, setIsSaved] = useState(false)

  //---------------------------------------------
  async function verificarCategoriaServicoAux() {
    const url = `${config.host}/cadastroGeral`
    const dadosVerificacao = {
      requisicao: 'verificarCategoriaServico',
      rota_base: RotaBase,
      tenantId: tenantId,
      idServico: formState.id_servico,
      Nome_Categoria: formState.categoria
    }

    try {
      const response = await fetchData(url, dadosVerificacao)
      if (response.existe) {
        return {
          existe: true,
          mensagemErro: 'Essa categoria já existe no modulo em questão.'
        }
      }
      return { existe: false }
    } catch (error) {
      console.error('Erro ao verificar a categoria do serviço:', error)
      return {
        existe: true,
        mensagemErro: 'Erro ao verificar a categoria do serviço. Tente novamente mais tarde.'
      }
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    // Verificação se o campo Nome da Categoria está preenchido
    if (!formState.categoria.trim()) {
      setMensagemErro('Por favor, preencha o nome da categoria antes de prosseguir.')
      dispatch(setShowAlert(true))
      setTimeout(() => {
        dispatch(setShowAlert(false))
        setMensagemErro('') // Limpa a mensagem após o alerta ser fechado
      }, 2200)
      return
    }
    // Verificação se o serviço está selecionado
    console.log('formState.id_servico:', formState.id_servico)
    if (!formState.id_servico) {
      setMensagemErro('Por favor, selecione o módulo relacionado antes de cadastrar a categoria.')
      dispatch(setShowAlert(true))
      setTimeout(() => {
        dispatch(setShowAlert(false))
        setMensagemErro('') // Limpa a mensagem após o alerta ser fechado
      }, 2200)
      return
    }
    // Chamar a função auxiliar para verificar a categoria do serviço
    const verificacao = await verificarCategoriaServicoAux()

    if (verificacao.existe) {
      setMensagemErro(verificacao.mensagemErro)
      dispatch(setShowAlert(true))
      setTimeout(() => {
        dispatch(setShowAlert(false))
        setMensagemErro('') // Limpa a mensagem após o alerta ser fechado
      }, 2200)
      return
    }
    // Prosseguir com a função de cadastro, se a verificação não encontrar conflito
    dispatch(setShowAlertGravando(true))

    const params = {
      cadastroDados: cadastroDados,
      clienteId: clienteId,
      clienteData: clienteData
    }

    // Realiza o cadastro da categoria
    const idCategoria = await SalvarCategoria(isEditing, params)

    if (idCategoria) {
      // Após cadastrar a categoria, realiza a associação ao serviço selecionado
      if (formState.id_servico) {
        console.log('formState.id_servico:', formState.id_servico)
        console.log('idCategoria:', idCategoria)
        await SalvarAssociacaoServicoCategoria(
          formState.id_servico,
          idCategoria,
          tenantId,
          RotaBase
        )
      }
      setIsSaved(true)
    }
    dispatch(setShowAlertGravando(false))
    dispatch(setShowAlert(true))

    setTimeout(() => {
      dispatch(setShowAlert(false))
    }, 2000)

    resetForm()
  }

  // useEffect(() => {
  //   if (isSaved) {
  //     buscarCategorias(tenantId, RotaBase).then(categorias => {
  //       console.log('categorias:', categorias)
  //       const categoriasOptions = categorias.categorias.map(categoria => {
  //         return {
  //           value: categoria.id_categoria,
  //           label: categoria.nome_categoria
  //         }
  //       })
  //       setCategoriaOptions(categoriasOptions)
  //       setIsSaved(false)
  //     })
  //   }
  // }, [isSaved])
  const customStyles = {
    control: base => ({
      ...base,
      minWidth: '300px' // Ajuste para a largura desejada
      // outros estilos personalizados para o control
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'blue' : 'white'

      // outros estilos personalizados para os options
    })
    // outros estilos personalizados
  }
  //---------------------------------------------
  const [isClearable, setIsClearable] = useState(true)

  return (
    <MyContext.Provider value={varsCad}>
      <div className="d-flex align-items-center form-container" style={{ height: '100vh' }}>
        <div className="text-center mx-auto" style={{ maxWidth: '2000px' }}>
          <div className="title-container">
            <Banner title="CATEGORIAS" />
          </div>
          <h2 style={{ marginBottom: '3rem' }}>NOVO CADASTRO DE CATEGORIA</h2>
          {mensagemErro && (
            <Alert variant="danger" onClose={() => setMensagemErro('')} dismissible>
              <Alert.Heading style={{ fontSize: '16px' }}>{mensagemErro}</Alert.Heading>
            </Alert>
          )}
          {showAlertGravando && (
            <AlertMessage
              message={isEditing ? 'Editando dados' : 'Gravando dados'}
              size="x-large"
              customHeight={100}
              variant1="primary"
              variant2="light"
              variantDefault="success"
              isBlinking={false}
            />
          )}
          {showAlert && !mensagemErro && (
            <Alert variant="success" onClose={handleCloseAlert} dismissible>
              <Alert.Heading>
                {isEditing ? 'Cadastro editado com sucesso!' : 'Cadastro realizado com sucesso!'}
              </Alert.Heading>
            </Alert>
          )}
          <FormGroup>
            <form>
              <Row>
                {/* Campo Nome da Categoria */}
                <Col md={6} className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="categoria"
                    placeholder="Nome da Categoria"
                    value={formState.categoria}
                    onChange={e => updateField('categoria', e.target.value)}
                    autoComplete="off"
                  />
                </Col>
                {/* Campo Selecionar Serviço */}
                {servicoOptions.length > 1 && (
                  <Col md={6} className="mb-3">
                    <Select
                      styles={customStyles}
                      id="id_servico"
                      isClearable={isClearable}
                      options={servicoOptions}
                      value={
                        formState.id_servico
                          ? servicoOptions.find(option => option.value === formState.id_servico)
                          : null
                      }
                      onChange={selectedOption => {
                        updateField('id_servico', selectedOption ? selectedOption.value : null)
                      }}
                      placeholder="Selecione o modulo relacionado"
                    />
                  </Col>
                )}

                {/* Campo Selecionar Categoria Pai */}
                {/* <Col md={5} className="mb-3">
                  <Select
                    styles={customStyles}
                    id="categoria_pai"
                    isClearable={isClearable}
                    options={categoriaOptions}
                    value={
                      formState.id_categoria_pai
                        ? categoriaOptions.find(
                            option => option.value === formState.id_categoria_pai.value
                          )
                        : null
                    }
                    onChange={selectedOption => {
                      updateField('id_categoria_pai', selectedOption)
                    }}
                    placeholder="Selecione a categoria pai"
                  />
                </Col> */}

                {/* Checkbox Travar Categoria */}
                {/* <Col md={2} className="mb-3">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: '10px'
                    }}
                  >
                    <Form.Check
                      type="checkbox"
                      id="travarCategoria"
                      label=""
                      checked={travarCategoria}
                      onChange={() => setTravarCategoria(!travarCategoria)}
                      style={{ position: 'relative', left: '0px' }}
                    />
                    <label
                      htmlFor="travarCategoria"
                      style={{ position: 'relative', left: '0px', marginLeft: '5px' }}
                    >
                      Travar Categoria
                    </label>
                  </div>
                </Col> */}
              </Row>

              <Row>
                {/* Campo Descrição da Categoria */}
                <Col md={10} className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="descricao"
                    placeholder="Descrição da Categoria"
                    value={formState.descricao}
                    onChange={e => updateField('descricao', e.target.value)}
                    autoComplete="off"
                  />
                  <span style={{ marginLeft: '10px', fontSize: '12px', color: '#6c757d' }}>
                    (Opcional)
                  </span>
                </Col>
              </Row>

              {/* Botão Cadastrar */}
              <Row>
                <Col md={{ span: 4, offset: 4 }} className="mb-3">
                  <Button
                    type="submit"
                    className="form-control btn-success btnSubmit"
                    onClick={handleSubmit}
                  >
                    Cadastrar
                  </Button>
                </Col>
              </Row>
            </form>
          </FormGroup>
        </div>
      </div>
    </MyContext.Provider>
  )
}

export default CadastroCategoria

export async function SalvarCategoria(isEditing, params = {}) {
  const cadastroDados_local = params.cadastroDados
  try {
    const response = await enviar(cadastroDados_local)
    console.log('response do salvar:', response)
    if (response) {
      // Retorna o id da categoria cadastrada
      return response.generatedIds // Assumindo que o backend retorne o id da categoria cadastrada
    } else {
      console.error('Erro ao cadastrar categoria:', response.message)
      return null
    }
  } catch (error) {
    console.error('Erro ao enviar os dados para cadastro:', error)
    return null
  }
}

export async function SalvarAssociacaoServicoCategoria(
  id_servico,
  id_categoria,
  tenantId,
  RotaBase
) {
  const dados = {
    rota_base: RotaBase,
    tenantId: tenantId,
    id_servico: id_servico,
    id_categoria: id_categoria,
    collection: 'servicos_categorias',
    requisicao: 'checkRecordAndInsertIfNotExist'
  }
  try {
    const url = config.host + '/funcoesGenericas'
    const response = await fetchData(url, dados)
    if (response.success) {
      console.log(response.message)
      return response
    } else {
      console.error('Erro ao associar serviço à categoria:', response.message)
      return null
    }
  } catch (error) {
    console.error('Erro ao chamar a função de associação no servidor:', error)
    return null
  }
}
