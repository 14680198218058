import { handleForm } from '../components/pages/CadastroGeral/cadastro/cadastro-fields'

import sheetsConfig from '../config/sheetsConfig'

import { config } from '../backend/configConexaoGeral'

//import { medirTempoExecucao } from '/src/components/pages/FinalizacaoCortes/finalizacao-cortes';

//TipoConexao({ tipo: 1 }); // Defina 1 para produção ou 2 para desenvolvimento
//export const host = config.host;

// const formatObjectDate = (obj) => {
//   for (let key in obj) {
//     if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
//       formatObjectDate(obj[key]);
//     }
//     if (key === 'entregue' || key === 'prevista' || key === 'data') {
//       obj[key] = formatDate(obj[key]);
//     }
//   }
//   return obj;
// };
export async function enviarOuAtualizarDadosNaPlanilha(
  dadosCompletos,
  planilha,
  pagina,
  mapeamentoColunas,
  isEditing,
  requisicao,
  campos,
  clienteId,
  ...extraArgs
) {
  const defaults = { id_pk: 0 }
  const actualArgs = Object.assign({}, defaults, ...extraArgs)

  let dadosParaGravar

  requisicao = isEditing ? 'alterar' : requisicao
  console.log('requisicao2', requisicao)
  try {
    const extraArgsObject = { ...actualArgs } // Converta a matriz de argumentos em um objeto.

    if (requisicao === 'alterar') {
      extraArgsObject.rota = 'funcoesGenericas' // Substitua 'chave' pela chave real que você deseja definir.
    }

    let id = null

    try {
      const isArray = Array.isArray(dadosCompletos)
      if (isArray) {
        // Se for array, pega o id do primeiro elemento
        id = dadosCompletos[0].id
      } else {
        // Se não for, pega direto do objeto
        id = dadosCompletos.id
      }
    } catch (error) {
      console.error('Erro ao pegar id:', error)
    }
    if (clienteId) {
      id = clienteId
    }
    const dados = {
      link: planilha,
      pagina: pagina,
      requisicao: requisicao,
      id: id,
      informacoes: dadosCompletos, // informaçoes equivale a dadoscompletos dessa funcao
      campos,
      chaveParaAlterar: extraArgsObject?.chaveParaAlterar,
      dadosExtra: extraArgsObject // Adicione os argumentos adicionais ao corpo da solicitação.
    }

    let response

    //------USANDO MONGODB COMO BANCO DE DADOS--------------------------

    let url = config.host + '/' + extraArgs[0].rota

    response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao executar enviarOuAtualizarDadosNaPlanilha:', error)
  }
}

//-------------------------------------------------------------

//-------------------------------------------------------------
// Função modificada no lado do cliente
export async function puxarDadosDaPlanilha({
  link,
  pagina1,
  celulas,
  requisicao,
  verificarDados,
  colunaUltimaLinha,
  tituloColunaUltimaLinha,
  filtro = '',
  campos = [],
  id,
  id_servico,
  pagina2,
  data1,
  data2,
  sql,
  colsTab1,
  colsTab2,
  sheet_dos_dados,
  sheet_de_ligacao,
  id_ligacao,
  isFrontendCall,
  clienteConcatenado = false
}) {
  const response = await fetch(linkGoogleScript, {
    method: 'post', // metodo POST sempre

    // passe os parametros abaixo para que o google Script possa inserir os dados corretamente
    body: JSON.stringify({
      link,
      pagina1,
      celulas,
      requisicao,
      colunaUltimaLinha,
      tituloColunaUltimaLinha,
      filtro,
      campos,
      id,
      id_servico,
      pagina2,
      data1,
      data2,
      sql,
      colsTab1,
      colsTab2,
      sheet_dos_dados,
      sheet_de_ligacao,
      id_ligacao,
      isFrontendCall,
      clienteConcatenado
    })
  })

  if (response.ok) {
    const dados = await response.json()

    return verificarDados(dados)
  } else {
    console.error('Erro ao buscar dados:', response.status, response.statusText)
    return []
  }
}
export async function pegarDados({
  link,
  pagina1,
  celulas,
  requisicao,
  query,
  verificarDados,
  colunaUltimaLinha,
  tituloColunaUltimaLinha,
  filtro = '',
  campos = [],
  id,
  id_servico,
  pagina2,
  data1,
  data2,
  sql,
  colsTab1,
  colsTab2,
  sheet_dos_dados,
  sheet_de_ligacao,
  id_ligacao,
  isFrontendCall,
  clienteConcatenado = false,
  rota = '',
  tenantId,
  rota_base
}) {
  let response
  // USANDO MONGODB COMO BANCO DE DADOS--------------------------

  let url
  if (rota === '') url = config.host + '/finalizacaoModelagem'
  else url = config.host + '/' + rota

  let dados = {
    link,
    pagina1,
    celulas,
    requisicao,
    query,
    colunaUltimaLinha,
    tituloColunaUltimaLinha,
    filtro,
    campos,
    id,
    id_servico,
    pagina2,
    data1,
    data2,
    sql,
    colsTab1,
    colsTab2,
    sheet_dos_dados,
    sheet_de_ligacao,
    id_ligacao,
    isFrontendCall,
    clienteConcatenado,
    rota,
    tenantId,
    rota_base
  }

  response = await fetchData(url, dados)

  if (response) {
    //const dados = await response.json();

    return verificarDados(response)
  } else {
    console.error('Erro ao buscar dados:', response)
    return []
  }
}

//-------------------------------------------------------------
export async function gerarIdUnico(planilha, pagina) {
  const dados = await fetch(linkGoogleScript, {
    method: 'post',
    body: JSON.stringify({
      link: planilha,
      pagina: pagina,
      requisicao: 'gerarId'
    })
  })

  const dados_1 = await dados.json()
  return dados_1.id
}

//-------------------------------------------------------------
export function addFieldsExtra(dados, fieldsExtra) {
  // Verifica se 'dados' é um array e, caso contrário, converte em um array com um único elemento
  let retorno

  if (Array.isArray(dados)) {
    // se for array, faz a lógica atual
    retorno = dados.map(item => ({
      ...item,
      ...fieldsExtra
    }))
  } else if (typeof dados === 'object') {
    // se for objeto, mescla direto
    retorno = {
      ...dados,
      ...fieldsExtra
    }
  } else {
    throw new Error('Dados precisa ser array ou objeto')
  }

  return retorno
}

//--------------------------------------------------------------------------------------------------------

export function replicateObject(obj, times) {
  let newObj = {}
  for (let i = 0; i < times; i++) {
    newObj[i] = { ...obj } // Clone the original object without reassigning the id
  }
  return newObj
}

export function keysToLower(obj) {
  return Object.keys(obj).reduce((newObj, key) => {
    newObj[key.toLowerCase()] = obj[key]
    return newObj
  }, {})
}

export function isPlainObject(obj) {
  for (let prop in obj) {
    if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      return false // Objeto não é simples, pois tem pelo menos uma propriedade que é outro objeto ou array
    }
  }
  return true // Se chegamos até aqui, então todas as propriedades são valores simples, não objetos ou arrays
}

function gerarArrayDadosParaGravar(mapeamentoColunas, dadosCompletos) {
  // Garante que dadosCompletos é um array

  if (isPlainObject(dadosCompletos)) {
    // se for um objeto simples sera transformado em um objeto bidimenssional
    dadosCompletos = replicateObject(dadosCompletos, 1)
  }

  if (!Array.isArray(dadosCompletos)) {
    dadosCompletos = Object.values(dadosCompletos)
  }

  // Converte chaves para string
  const mapeamentoNumerico = Object.fromEntries(
    Object.entries(mapeamentoColunas).map(([chave, valor]) => [chave.toString(), valor])
  )

  // Array que irá conter as linhas
  const linhas = []

  // Itera no array de dados
  for (let dados of dadosCompletos) {
    // Inicia o array da linha
    let linha = []
    const dadosLower = keysToLower(dados)
    // Mapeia os valores para as colunas

    for (let [index, nomeColuna] of Object.entries(mapeamentoNumerico)) {
      const valor = dadosLower[mapeamentoNumerico[index]]

      function addValueAtIndex(index, valor) {
        for (let i = linha.length; i <= index - 1; i++) {
          linha.push('')
        }
        linha[index - 1] = valor
      }
      // Adiciona o valor na linha na sua posicao correta
      addValueAtIndex(index, valor)
    }

    // Adiciona linha no array de retorno
    linhas.push(linha)
  }

  return linhas
}

function extrairSegundaColuna(celulas) {
  const partes = celulas.split(':')
  const segundaColuna = partes[1].replace(/\d/g, '')
  return segundaColuna
}

//--------------------------------------------------------------------------------------------------------
export async function SalvarEdicao(params = {}, ...extraArgs) {
  const {
    isEditing = true,

    configKeySheet,
    tab,
    cadastroDados,
    clienteId = null,

    requisicao = 'enviar',
    campos
  } = params

  const pagina = sheetsConfig[configKeySheet].tabs[tab]

  let clienteData_local = ''
  if (!isEditing) clienteData_local = new Date()

  const retorno = await handleForm(
    isEditing,
    cadastroDados,
    clienteId,
    clienteData_local,
    null,
    null,
    null,
    null,
    pagina,
    requisicao,
    campos,

    ...extraArgs
  )

  return retorno
}
//------------------------------------------------------------------------------------
export async function alterarRegistro({
  id,
  sheetConfigKey,
  tab,
  requisicao,
  rota,
  chaveParaAlterar,
  informacoes
}) {
  const dados = {
    link: sheetsConfig[sheetConfigKey].sheetUrl,
    pagina: sheetsConfig[sheetConfigKey].tabs[tab],
    id: id,
    informacoes: informacoes.informacoes,
    requisicao: requisicao,
    rota: rota,
    chaveParaAlterar: chaveParaAlterar
  }

  let response
  // USANDO MONGODB COMO BANCO DE DADOS--------------------------

  let url

  url = config.host + '/' + rota

  response = await fetchData(url, dados)

  if (response) {
    return response
  } else {
    throw new Error('Houve um problema ao alterar o registro: ' + response)
  }
}
//------------------------------------------------------------------------------------
export async function deletarRegistro({
  id,
  chaveParaDeletar,
  sheetConfigKey,
  tab,
  requisicao,
  rota,
  indiceColunaId
}) {
  const dados = {
    // link: sheetsConfig[sheetConfigKey].sheetUrl,
    pagina: sheetsConfig[sheetConfigKey].tabs[tab],
    id: id,
    chaveParaDeletar: chaveParaDeletar,
    requisicao: requisicao,
    indiceColunaId: indiceColunaId,
    rota: rota
  }
  let response
  // USANDO MONGODB COMO BANCO DE DADOS--------------------------

  let url

  url = config.host + '/' + rota

  response = await fetchData(url, dados)

  if (response) {
    return response.res
  } else {
    throw new Error('Houve um problema ao deletar o registro: ' + response)
  }
}
export async function excluirPlanilhaPorNome(nomeDaPlanilha) {
  const dados = {
    nomeDaPlanilha: nomeDaPlanilha,
    requisicao: 'excluirPlanilhaPorNome'
  }

  const response = await fetch(linkGoogleScript, {
    method: 'post',

    body: JSON.stringify(dados)
  })

  if (!response.ok) {
    throw new Error('Houve um problema ao deletar o registro: ' + response.status)
  }
}
export async function copiarPlanilhaPorNome(nomeDaPlanilha) {
  const dados = {
    nomeDaPlanilha: nomeDaPlanilha,
    requisicao: 'copiarPlanilhaPorNome'
  }

  const response = await fetch(linkGoogleScript, {
    method: 'post',

    body: JSON.stringify(dados)
  })

  if (!response.ok) {
    throw new Error('Houve um problema ao criar a planilha: ' + response.status)
  }
}

//-----------------------------------------------------------------
export async function SelectAllFromTablePorId({
  table,
  Id,
  colsTab1,
  selectedSector,
  VerificarDados,
  colId = 'A'
}) {
  const sheetUrl = sheetsConfig[selectedSector].sheetUrl
  const dados = {
    link: sheetUrl,
    requisicao: 'SelectAllFromTablePorId',
    verificarDados: VerificarDados,
    colsTab1: colsTab1,
    id: Id,
    sql: 'SELECT * FROM ' + table + ' WHERE ' + colId + ' = ' + Id,
    rota: 'funcoesGenericas'
  }

  const response = await pegarDados(dados)

  const registros = response
  return registros
}

//------------------------------------------------------------------------------------------
export async function SelectFromTablePorMultiplosIds({ dadosExtra }) {
  const { selectedSector, campos, funcaoDeRetorno } = dadosExtra
  const sheetUrl = sheetsConfig[selectedSector].sheetUrl

  const dados = {
    link: sheetUrl,
    requisicao: 'selectFromTablePorMultiplosIds',
    campos: campos,
    dadosExtra: dadosExtra
  }

  //------USANDO MONGODB COMO BANCO DE DADOS--------------------------
  let url = config.host + '/funcoesGenericas'

  let resultadoMongo = await fetchData(url, dados)

  // resultadoMongo = converterValoresParaInteiros(resultadoMongo);

  if (resultadoMongo.length > 0) return resultadoMongo
}
//-----------------------------------------------------------------------------
export async function buscarProdutosPorIdServicoClient(
  id_servico,
  selectedSector,
  rota_base,
  tenantId
) {
  const sheetUrl = sheetsConfig[selectedSector].sheetUrl
  const dados = {
    link: sheetUrl,
    requisicao: 'buscarProdutosPorIdServico',
    rota: 'cadastroServicoModelagem',
    verificarDados: tratarBuscarProdutosPorIdServico,
    id_servico: id_servico,
    rota_base: rota_base,
    tenantId: tenantId
  }

  const response = await pegarDados(dados)

  //await puxarDadosDaPlanilha(dados);

  const produtos = response

  return produtos
}

function tratarBuscarProdutosPorIdServico(dados) {
  // Verifica se dados e dados.result existem antes de tentar usar slice

  const produtosData = dados

  // Se produtosData for [], então o map não será executado e produtos será []
  const produtos = produtosData.map(produtoData => ({
    id: produtoData.id,
    nome: produtoData.nome,
    descricao: produtoData.descricao,
    id_categoria: produtoData.id_categoria
  }))

  return produtos
}
//---------------------------------------------------------------------------------------
export async function carregarClientes_Nome_Sobrenome_Empresa(id_servico, tenantId) {
  // Defina as informações necessárias para a requisição
  const sheetUrl = sheetsConfig.plotagem.sheetUrl
  const paginaClientes = sheetsConfig.plotagem.tabs.clientes
  const paginaCadastroClienteServico = sheetsConfig.cadastroClienteServico.tabs.clientes_servicos

  // Chame a função puxarDadosDaPlanilha passando os parâmetros corretos e a função verificarDados

  const clientes = await pegarDados({
    link: sheetUrl,
    pagina1: paginaClientes,
    celulas: 'A2:U',
    requisicao: 'buscarClientesPorServico',
    verificarDados: verificarDados,
    colunaUltimaLinha: 'A',
    tituloColunaUltimaLinha: 'id',
    id_servico: id_servico,
    pagina2: paginaCadastroClienteServico,
    tenantId: tenantId
  })

  return clientes
}
//---------------------Funções auxiliares---------------------
function verificarDados(dados) {
  const clientesData = dados

  // Remova a primeira linha (cabeçalho) e mapeie os dados para a estrutura necessária pelo componente Select
  const clientes = clientesData.slice(0).map(cliente => {
    return {
      value: cliente.id, // ID do cliente
      label: cliente.nome_sobrenome_empresa // Nome e Sobrenome do cliente concatenados com o nome da empresa
    }
  })

  return clientes
}
//--------------------------------------------------------------------------------
export function getClienteNameById(clientes, id) {
  const cliente = clientes.find(cliente => {
    // Compara tanto como string quanto como número
    return String(cliente.value) === String(id) || Number(cliente.value) === Number(id)
  })

  return cliente ? cliente.label : ''
}
//---------------------------------------------------------------------------------------
export function verificarCortes(dados) {
  const cortesData = dados

  const cortes = cortesData.map(corte => {
    return {
      id: corte.id,
      data: corte.data,
      entregar: corte.entregar,
      data_corte: corte.data_corte,
      valor_do_corte: corte.valor_do_corte,
      pago: corte.pago,
      a_receber: corte.a_receber,
      lar_tecido: corte.lar_tecido,
      total_ref: corte.total_ref,
      folhas: corte.folhas,
      t_grade: corte.t_grade,
      total_rolos: corte.total_rolos,
      total_pecas: corte.total_pecas,
      tecido: corte.tecido,
      cliente: corte.cliente,
      id_cliente: corte.id_cliente,
      id_tecido: corte.id_tecido,
      descricao: corte.descricao,
      embalagem: corte.embalagem
    }
  })

  return cortes
}
export function verificarMoldes(dados) {
  const moldesData = dados

  const moldes = moldesData.map(molde => {
    return {
      id: molde.id,
      data: molde.data,
      prevista: molde.prevista,
      entregue: molde.entregue,
      id_cliente: molde.id_cliente,
      sinal: molde.sinal,
      valor_total: molde.valor_total
    }
  })

  return moldes
}
//------------------------------------------------------------------------------------
export async function atualizarColunaPorId(dados) {
  const response = await fetch(linkGoogleScript, {
    method: 'post',

    body: JSON.stringify(dados)
  })
  if (response.ok) {
    const dadosretorno = await response.json()

    // return dados.dadosExtra.funcaoDeRetorno(dadosretorno);
  } else {
    console.error('Erro ao buscar dados:', response.status, response.statusText)
    return []
  }
}
//------------------------------------------------------------------------------------------------------------
export function aplanarArray(arrayDeArrays) {
  let resultado = []

  for (const subArray of arrayDeArrays) {
    for (const obj of subArray) {
      resultado.push(obj)
    }
  }

  return resultado
}

//-----------------------------------------------------------------------------
export async function gravarLinhasEmLote(
  {
    aba,
    cadastroDados,
    todasColunasSheet = null,
    colunasParaGravarSheet = null,
    columnAliases = {},
    requisicao,
    isEditing = false,
    structSheet
  },
  ...extraArgs
) {
  // const structSheet = 'cadastroClienteServico'; // Mantive este como constante. Se varia, deve ser passado como argumento.
  const planilha = sheetsConfig[structSheet].sheetUrl

  // Se todasColunasSheet não foi passado, busque o valor padrão
  if (!todasColunasSheet) {
    todasColunasSheet = sheetsConfig[structSheet].fields[aba]
  }

  // Se colunasParaGravarSheet não foi passado, use o valor de todasColunasSheet
  if (!colunasParaGravarSheet) {
    colunasParaGravarSheet = todasColunasSheet
  }

  const pagina = sheetsConfig[structSheet].tabs[aba]

  return await handleForm(
    isEditing,
    cadastroDados,
    null,
    null,
    todasColunasSheet,
    colunasParaGravarSheet,
    columnAliases,
    planilha,
    pagina,
    requisicao,
    null,
    ...extraArgs
  )
}

// Exemplo de uso:
// gravarLinhasEmLote({
//   aba: 'clientes_servicos',
//   cadastroDados: [/* ... */],
//   requisicao: 'enviar_servicos_cliente',
//   structSheet:'cadastroClienteServico'
// });

//-----------------------------------------------------------------------------
export async function buscaEAgrega({
  // essa funcao traz todos os campos da tabela principal
  // e se tiver um campo que é chave estrangeira de uma terceira tabela, ele traz os dados dessa tabela tbm
  link,
  configPrincipal,
  configSecundaria1,
  configSecundaria2,
  requisicao = 'buscaEAgrega',
  rota = 'editarModelagem'
}) {
  // Agora, você pode acessar as propriedades desses objetos de configuração diretamente.
  // Por exemplo: configPrincipal.tabela, configSecundaria1.tabela, etc.

  const dados = {
    link: link,
    tabelaPrincipal: configPrincipal.tabela,
    idTabelaPrincipal: configPrincipal.id,
    colIdTabelaPrincipal: configPrincipal.colId,
    campoRetornoTabelaPrincipal: configPrincipal.campoRetorno,
    tabelaSecundaria1: configSecundaria1.tabela,
    colIdTabelaSecundaria1: configSecundaria1.colId,
    campoRetornoTabelaSecundaria1: configSecundaria1.campoRetorno,
    tabelaSecundaria2: configSecundaria2.tabela,
    colIdTabelaSecundaria2: configSecundaria2.colId,
    campoRetornoTabelaSecundaria2: configSecundaria2.campoRetorno,
    setorTabelaPrincipal: configPrincipal.setor,
    setorTabelaSecundaria1: configSecundaria1.setor,
    setorTabelaSecundaria2: configSecundaria2.setor,
    VerificarDadosTabelaPrincipal: configPrincipal.VerificarDados,
    VerificarDadosTabelaSecundaria1: configSecundaria1.VerificarDados,
    VerificarDadosTabelaSecundaria2: configSecundaria2.VerificarDados,
    requisicao: requisicao,
    rota: rota
  }

  let response
  let url = config.host + '/' + rota

  response = await fetchData(url, dados)

  // const response = await fetch(linkGoogleScript, {
  //   method: 'post',
  //   body: JSON.stringify(dados),
  // });

  if (response) {
    return response
  } else {
    console.error('Erro ao buscar dados:', response.status, response.statusText)
    return []
  }
}
//-----------------------------------------------------------------------------
export async function TotalDeModelsParaEntregar({
  // essa funcao traz todos os campos da tabela principal
  // e se tiver um campo que é chave estrangeira de uma terceira tabela, ele traz os dados dessa tabela tbm
  link,
  configPrincipal,
  configSecundaria1,

  campoRetorno,
  requisicao = 'TotalDeModelsParaEntregar'
}) {
  const dados = {
    link: link,
    tenantId_TabelaPrincipal: configPrincipal.tenantId,
    tabelaPrincipal: configPrincipal.tabela,
    valorProcurado_TabelaPrincipal: configPrincipal.valorProcurado,
    letraColValorProcurado_TabelaPrincipal: configPrincipal.letraColValorProcurado,
    letraCampoRetorno_TabelaPrincipal: configPrincipal.letraCampoRetorno,
    setor_TabelaPrincipal: configPrincipal.setor,
    nomeCampoRetorno_TabelaPrincipal: configPrincipal.nomeCampoRetorno,

    tabelaSecundaria1: configSecundaria1.tabela,
    //  valorProcurado_TabelaSecundaria1: configSecundaria1.valorProcurado,
    letraColValorProcurado_TabelaSecundaria1: configSecundaria1.letraColValorProcurado,
    letraCampoRetorno_TabelaSecundaria1: configSecundaria1.letraCampoRetorno,
    setor_TabelaSecundaria1: configSecundaria1.setor,
    nomeCampoRetorno_TabelaSecundaria1: configSecundaria1.nomeCampoRetorno,
    requisicao: requisicao
  }

  let response
  // USANDO MONGODB COMO BANCO DE DADOS--------------------------
  let url = config.host + '/finalizacaoModelagem'

  response = await fetchData(url, dados)

  if (response) {
    return response
  } else {
    console.error('Erro ao buscar dados 3:', response.status, response.statusText)
    return []
  }
}
//-----------------------------------------------------------------------------
export async function TotalDeServicosParaEntregarPorIds({
  link,
  ids,
  configPrincipal,
  configSecundaria1,
  configSecundaria2,
  configSecundaria3,
  configSecundaria4,
  requisicao = 'TotalDeServicosParaEntregarPorIds'
}) {
  const dados = {
    link: link,
    tenantId_TabelaPrincipal: configPrincipal.tenantId,
    tabelaPrincipal: configPrincipal.tabela,
    valorProcurado_TabelaPrincipal: configPrincipal.valorProcurado,
    letraColValorProcurado_TabelaPrincipal: configPrincipal.letraColValorProcurado,
    letraCampoRetorno_TabelaPrincipal: configPrincipal.letraCampoRetorno,
    nomeCampoRetorno_TabelaPrincipal: configPrincipal.nomeCampoRetorno,
    setor_TabelaPrincipal: configPrincipal.setor,

    tabelaSecundaria1: configSecundaria1.tabela,
    letraColValorProcurado_TabelaSecundaria1: configSecundaria1.letraColValorProcurado,
    letraCampoRetorno_TabelaSecundaria1: configSecundaria1.letraCampoRetorno,
    nomeCampoRetorno_TabelaSecundaria1: configSecundaria1.nomeCampoRetorno,
    setor_TabelaSecundaria1: configSecundaria1.setor,

    tabelaSecundaria2: configSecundaria2.tabela,
    letraColValorProcurado_TabelaSecundaria2: configSecundaria2.letraColValorProcurado,
    letraCampoRetorno_TabelaSecundaria2: configSecundaria2.letraCampoRetorno,
    nomeCampoRetorno_TabelaSecundaria2: configSecundaria2.nomeCampoRetorno,
    setor_TabelaSecundaria2: configSecundaria2.setor,

    tabelaSecundaria3: configSecundaria3.tabela,
    letraColValorProcurado_TabelaSecundaria3: configSecundaria3.letraColValorProcurado,
    letraCampoRetorno_TabelaSecundaria3: configSecundaria3.letraCampoRetorno,
    nomeCampoRetorno_TabelaSecundaria3: configSecundaria3.nomeCampoRetorno,
    setor_TabelaSecundaria3: configSecundaria3.setor,

    tabelaSecundaria4: configSecundaria4.tabela,
    letraColValorProcurado_TabelaSecundaria4: configSecundaria4.letraColValorProcurado,
    letraCampoRetorno_TabelaSecundaria4: configSecundaria4.letraCampoRetorno,
    nomeCampoRetorno_TabelaSecundaria4: configSecundaria4.nomeCampoRetorno,
    setor_TabelaSecundaria4: configSecundaria4.setor,

    ids: ids, // Parâmetro adicional para filtrar por IDs

    requisicao: requisicao
  }

  //------USANDO MONGODB COMO BANCO DE DADOS--------------------------
  let url = config.host + '/finalizacaoModelagem'
  let resultadoMongo
  resultadoMongo = await fetchData(url, dados)

  //essa parte funciona medindo o tempo de execução
  // try {
  //   resultadoMongo = await buscarDadosEMedirTempo(fetchData, url, dados);
  //   // O código aqui será executado somente após a resolução da promise de buscarDadosEMedirTempo()
  // } catch (erro) {
  //   console.error('Erro na operação principal:', erro);
  //   // Trate o erro como achar necessário
  // }

  return resultadoMongo

  // Agora você pode usar a função 'buscarDadosEMedirTempo' para chamar 'fetchDataGoogleSheets' e medir o tempo
}
//-----------------------------------------------------------------------------
async function buscarDadosEMedirTempo(fetchData, url, dados) {
  try {
    let resultadoMongo = await medirTempoExecucao(fetchData, url, dados)

    return resultadoMongo
  } catch (erro) {
    console.error('Erro ao buscar dados:', erro)
  }
}
//-----------------------------------------------------------------------------
export async function renewTokenIfNeeded() {
  let url = config.host + '/renewCookie'

  try {
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include' // Necessário para incluir o cookie na requisição
    })

    return response.ok // Retorna true se a renovação for bem-sucedida
  } catch (error) {
    console.error('Erro ao renovar o token:', error)
    return false // Retorna false em caso de erro
  }
}

//-----------------------------------------------------------------------------
// export async function fetchData(url, dados) {
//   try {
//     const response = await fetch(url, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(dados),
//       credentials: 'include'
//     })

//     if (!response.ok) {
//       // Trata qualquer resposta que não seja de sucesso (2xx)
//       const errorText = await response.text()
//       // throw new Error(`Erro ${response.status}: ${errorText}`)
//     }

//     // Quando a resposta é OK, processa conforme o tipo de conteúdo
//     const contentType = response.headers.get('content-type')
//     if (contentType && contentType.includes('application/json')) {
//       return await response.json()
//     } else {
//       // Trata o caso em que a resposta não é JSON
//       return await response.text()
//     }
//   } catch (error) {
//     // console.error('Erro na função fetchData', error)
//     // throw error // Relança o erro para ser tratado por quem chama a função
//   }
// }
export async function fetchData(url, dados) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dados),
      credentials: 'include'
    })

    if (!response.ok) {
      // Trata qualquer resposta que não seja de sucesso (2xx)
      const contentType = response.headers.get('content-type')
      let errorMessage = `Erro ${response.status}`

      if (contentType && contentType.includes('application/json')) {
        const errorData = await response.json()
        errorMessage = errorData.message || errorMessage
      } else {
        const errorText = await response.text()
        errorMessage = errorText || errorMessage
      }

      console.error(`Erro na resposta da API: ${errorMessage}`)
      throw new Error(errorMessage)
    }

    // Quando a resposta é OK, processa conforme o tipo de conteúdo
    const contentType = response.headers.get('content-type')
    if (contentType && contentType.includes('application/json')) {
      const data = await response.json()

      return data
    } else {
      const data = await response.text()

      return data
    }
  } catch (error) {
    console.error('Erro na função fetchData:', error)
    throw error // Relança o erro para ser tratado por quem chama a função
  }
}

//-----------------------------------------------------------------------------
function converterValoresParaInteiros(arrayDeObjetos) {
  return arrayDeObjetos.map(objeto => {
    return {
      idComponent: parseInt(objeto.idComponent, 10),
      x: parseInt(objeto.x, 10),
      y: parseInt(objeto.y, 10)
    }
  })
}

const medirTempoExecucao = async (funcao, ...args) => {
  const inicio = Date.now()

  const resultado = await funcao(...args)
  const fim = Date.now()
  const milissegundos = fim - inicio
  const segundos = (milissegundos / 1000).toFixed(2)
  console.log(
    'Tempo de execução: ' + segundos + ' segundos (ou ' + milissegundos + ' milissegundos)'
  )

  return resultado
}

//-----------------------------------------------------------------------------
export async function enviar(dados) {
  dados.requisicao = 'enviar' // Adicionando a chave requisicao com o valor SalvarNoBancoDeDados em dados
  let rota = dados.rota || 'funcoesGenericas'
  let url = config.host + '/' + rota

  let response = await fetchData(url, dados)

  if (response) {
    return response
  } else {
    console.error('Erro ao buscar dados :', response)
    return []
  }
}
//-----------------------------------------------------------------------------
export async function requisitar(dados) {
  dados.requisicao = 'requisitar'
  let rota = dados.rota || 'funcoesGenericas'
  let url = config.host + '/' + rota

  let response = await fetchData(url, dados)

  if (response) {
    return response
  } else {
    console.error('Erro ao buscar dados :', response)
    return []
  }
}
//-----------------------------------------------------------------------------
export async function BuscarPlotagemPorId(id_plotagem) {
  try {
    let url = config.host + '/plotagens'

    const dados = {
      query: { _id: id_plotagem }, // Convert id_plotagem to integer
      requisicao: 'BuscarPlotagemPorId'
    }

    let response = await fetchData(url, dados)

    if (response) {
      return response
    }
  } catch (error) {
    console.error('Erro ao buscar plotagem por ID 1:', error)
    throw error
  }
}
export async function DarBaixaNoServico(dados) {
  try {
    let url = config.host + '/' + dados.rota

    let response = await fetchData(url, dados)

    if (response) {
      return response
    }
  } catch (error) {
    console.error('Erro ao buscar plotagem por ID 2:', error)
    throw error
  }
}
//-----------------------------------------------------------------------------
