import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from '../../../../../../src/context/AuthContext'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import { CarregarMarketplaces } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraGlobal/cadastro-regra-global'
import { ShowAlertGeneric } from '../../../../../../src/utils/form-utils.jsx'

const TenantMarketplacesConfig = () => {
  const [marketplaces, setMarketplaces] = useState([])
  const [selectedMarketplaces, setSelectedMarketplaces] = useState([])
  const { tenantId } = useContext(AuthContext)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [corAlerta, setCorAlerta] = useState('danger')

  useEffect(() => {
    const carregarDados = async () => {
      try {
        const response = await CarregarMarketplaces()
        setMarketplaces(response || [])
      } catch (error) {
        console.error('Erro ao carregar os marketplaces:', error)
      }
    }

    carregarDados()
  }, [])

  useEffect(() => {
    const carregarMarketplacesSelecionados = async () => {
      try {
        const url = config.host + '/regraGlobalPF'
        const dados = {
          tenantId,
          requisicao: 'getTenantMarketplaces'
        }
        const response = await fetchData(url, dados)
        if (response && response.marketplaces) {
          setSelectedMarketplaces(response.marketplaces)
        }
      } catch (error) {
        console.error('Erro ao carregar marketplaces selecionados:', error)
      }
    }

    if (tenantId) {
      carregarMarketplacesSelecionados()
    }
  }, [tenantId])

  const handleMarketplaceChange = async marketplaceId => {
    const updatedMarketplaces = selectedMarketplaces.includes(marketplaceId)
      ? selectedMarketplaces.filter(id => id !== marketplaceId)
      : [...selectedMarketplaces, marketplaceId]

    setSelectedMarketplaces(updatedMarketplaces)

    const url = config.host + '/regraGlobalPF'
    const dados = {
      tenantId,
      marketplaces: updatedMarketplaces,
      requisicao: 'saveTenantMarketplaces'
    }

    try {
      await fetchData(url, dados)
      setShowAlert(true)
      setCorAlerta('success')
      setAlertMessage('Configuração salva com sucesso')
    } catch (error) {
      console.error('Erro ao salvar configuração:', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao salvar configuração')
    }
  }

  return (
    <div style={styles.container}>
      <Banner
        title="CONFIGURAR MARKETPLACES EM USO"
        color="#2e8b57"
        width="100%"
        fontSize={50}
        style={styles.banner}
        // classEfeito3dMouseOver=""
      />
      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          isBlurred={true}
          setShowAlert={setShowAlert}
          variant={corAlerta}
          className="show-alert-genericPF"
          fontSize="1.5rem"
          dismissible={false}
        />
      )}
      <form style={styles.form}>
        {marketplaces.map(marketplace => (
          <div key={marketplace._id} style={styles.checkboxContainer}>
            <input
              type="checkbox"
              id={marketplace._id}
              checked={selectedMarketplaces.includes(marketplace._id)}
              onChange={() => handleMarketplaceChange(marketplace._id)}
              style={styles.checkbox}
            />
            <label htmlFor={marketplace._id} style={styles.label}>
              {marketplace.nome}
            </label>
          </div>
        ))}
      </form>
    </div>
  )
}

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'center'
  },
  banner: {
    margin: '0 auto',
    display: 'block'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    justifyContent: 'flex-start',
    width: '200px'
  },
  checkbox: {
    marginRight: '10px'
  },
  label: {
    fontSize: '16px'
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer'
  }
}

export default TenantMarketplacesConfig
