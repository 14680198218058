import { useState, useEffect } from 'react'
import { Button, FormGroup, Row, Col, Alert } from 'react-bootstrap'
import Banner from '../CadastroGeral/Banner'
import { SalvarEdicao, deletarRegistro, pegarDados } from '../../../backend/model'
import AlertMessage from '../PLOTAGEM/AlertMessage'
import sheetsConfig from '/src/config/sheetsConfig'
import Select from 'react-select'
import { puxarDadosDaPlanilha } from '../../../backend/model'
import { AuthContext } from '/src/context/AuthContext'
import { useContext } from 'react'
const CadastroProduto = ({ PRODUTO }) => {
  const [produto, setProduto] = useState({
    id: '',
    nome: '',
    descricao: '',
    preco: '',
    id_categoria: ''
  })

  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [largura, setLargura] = useState()
  const [altura, setAltura] = useState()
  const [sizeAlert, setSizeAlert] = useState()
  const [categorias, setCategorias] = useState([])
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [variantDefault, setVariantDefault] = useState('success')

  function menssagem(menssagem, altura, largura, size, variant) {
    setAltura(altura)
    setLargura(largura)
    setAlertMessage(menssagem)
    setSizeAlert(size)
    setVariantDefault(variant)
  }
  const handleChange = e => {
    setProduto({
      ...produto,
      [e.target.name]: e.target.value
    })
  }

  async function handleSubmit(e) {
    e.preventDefault()

    // Verificar se o nome do produto está preenchido
    // Verificar se o nome do produto está preenchido
    if (!produto.nome) {
      menssagem('Por favor, insira o nome do produto.', 50, 400, '1.1rem', 'danger')
      setAlertMessage('Por favor, insira o nome do produto.')

      setShowAlert(true)

      // Esperar 3 segundos e depois ocultar o alerta
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)

      return
    }

    // Verificar se a categoria do produto está preenchida
    if (!produto.id_categoria) {
      menssagem('Por favor, selecione uma categoria para o produto.', 50, 400, '1rem', 'danger')

      setShowAlert(true)

      // Esperar 3 segundos e depois ocultar o alerta
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)

      return
    }
    menssagem('Salvando', 100, 400, 'x-large')
    setShowAlert(true)
    try {
      await SalvarEdicao(
        {
          isEditing: false,

          configKeySheet: 'produtos',
          tab: 'produtos',
          cadastroDados: {
            ...produto,
            id_categoria: produto.id_categoria, // Aqui estamos adicionando o id da categoria no objeto de dados do produto
            tenantId: tenantId,
            rota_base: RotaBase,
            data: new Date()
          }, // Utilizar 'produto' em vez de 'dadosEdicao'
          clienteId: produto.id
        },
        { rota: 'funcoesGenericas' }
      )

      menssagem('Produto cadastrado !', 60, 400, 'large')
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } catch (error) {
      // Lidar com qualquer erro que possa ocorrer durante a gravação dos dados

      setAlertMessage('Ocorreu um erro ao salvar o produto. Tente novamente.')
    }
  }
  const handleCloseAlert = () => {
    setShowAlert(false)
  }
  //----------------------------------------------------------------------------------
  useEffect(() => {
    async function fetchCategorias() {
      const data = await CarregaCategorias(tenantId, RotaBase)
      setCategorias(data)
    }
    fetchCategorias()
  }, [])

  useEffect(() => {
    if (PRODUTO === 'tecido' && categorias.length > 0) {
      const categoriaTecido = categorias.find(cat => cat.nome_categoria.toLowerCase() === 'tecido')

      if (categoriaTecido) {
        setProduto(prevProduto => ({
          ...prevProduto,
          id_categoria: categoriaTecido.id_categoria
        }))
      }
    }
  }, [PRODUTO, categorias])
  //-----------------------------------------------------------------------------
  const handleSelectChange = selectedOption => {
    setProduto({
      ...produto,
      id_categoria: selectedOption ? selectedOption.value : null
    })
  }

  //--------------------------------------------------------------------------------

  return (
    <div
      className="d-flex justify-content-center align-items-center form-container"
      style={{ height: '100vh' }}
    >
      <div className="text-center mx-auto" style={{ maxWidth: '800px', padding: '2rem' }}>
        <div className="mb-4" style={{ marginTop: '20px' }}>
          <Banner title={PRODUTO === 'tecido' ? 'TECIDO' : 'PRODUTO'} marginTop="-40%" />
        </div>
        <h1 className="mb-4">
          {PRODUTO === 'tecido' ? 'Cadastro de Tecido' : 'Cadastro de Produto'}
        </h1>
        {showAlert && (
          <div
            className="mb-4"
            style={{
              position: 'fixed', // Garante que ele fique fixo na tela
              top: '20%', // Ajuste para o posicionamento vertical
              left: '35%', // Centraliza horizontalmente
              transform: 'translateX(-50%)', // Corrige o centro da posição para o meio do alerta
              zIndex: 9999 // Certifique-se de que o alerta fica acima dos outros elementos
            }}
          >
            <AlertMessage
              message={alertMessage}
              size={sizeAlert}
              customHeight={altura}
              customWidth={largura}
              variant1="primary"
              variant2="light"
              variantDefault={variantDefault}
              isBlinking={false}
              top={'15%'}
            />
          </div>
        )}
        <FormGroup>
          <form>
            <Row className="mb-3">
              <Col md={12}>
                {produto.nome && (
                  <label htmlFor="nome">
                    {PRODUTO === 'tecido' ? 'Nome do Tecido' : 'Nome do Produto'}
                  </label>
                )}
                <input
                  type="text"
                  name="nome"
                  id="nome"
                  className="form-control"
                  placeholder={PRODUTO === 'tecido' ? 'Nome do Tecido' : 'Nome do Produto'}
                  onChange={handleChange}
                  value={produto.nome}
                  autoComplete="off"
                />
              </Col>
            </Row>

            {/* <Row className="mb-3">
              <Col md={12}>
                {produto.id_categoria && <label htmlFor="id_categoria">Categoria</label>}
                <Select
                  name="id_categoria"
                  id="id_categoria"
                  placeholder="Categoria"
                  isClearable
                  value={
                    categorias.find(cat => cat.id_categoria === produto.id_categoria)
                      ? {
                          value: produto.id_categoria,
                          label: categorias.find(cat => cat.id_categoria === produto.id_categoria)
                            .nome_categoria
                        }
                      : null
                  }
                  onChange={handleSelectChange}
                  options={categorias.map(cat => ({
                    value: cat.id_categoria,
                    label: cat.nome_categoria
                  }))}
                  styles={{
                    container: provided => ({
                      ...provided,
                      width: '100%'
                    })
                  }}
                />
              </Col>
            </Row> */}

            <Row className="mb-3">
              <Col md={12}>
                {produto.descricao && (
                  <label htmlFor="nome">
                    {PRODUTO === 'tecido' ? 'Descriçao do Tecido' : 'Descriçao do Produto'}
                  </label>
                )}

                <input
                  name="descricao"
                  id="descricao"
                  className="form-control"
                  placeholder={
                    PRODUTO === 'tecido' ? 'Descriçao do Tecido' : 'Descriçao do Produto'
                  }
                  onChange={handleChange}
                  value={produto.descricao}
                  autoComplete="off"
                />

                {/* Informativo de campo não obrigatório */}
                <small className="text-muted">(Opcional)</small>
              </Col>
            </Row>

            <Row className="text-center">
              <Col>
                <Button type="submit" className="btn btn-success" onClick={handleSubmit}>
                  Cadastrar
                </Button>
              </Col>
            </Row>
          </form>
        </FormGroup>
      </div>
    </div>
  )
}

export default CadastroProduto
//-------------------------------------------------------------------------------------------
export async function CarregaCategorias(tenantId, rota_base) {
  const sheetUrl = sheetsConfig['cadastroCategoria'].sheetUrl
  const paginaCategorias = sheetsConfig['cadastroCategoria'].tabs.categorias

  const dados = {
    link: sheetUrl,
    pagina1: paginaCategorias,
    celulas: 'A2:G',
    requisicao: 'requisitar',
    query: { tenantId: tenantId, rota_base: rota_base },
    rota: 'cadastroServicoModelagem',
    verificarDados: tratarDadosCategorias,
    colunaUltimaLinha: 'A',
    tituloColunaUltimaLinha: 'id_categoria',
    filtro: '',
    isFrontendCall: true
  }

  const response = await pegarDados(dados)

  const produtosEncontrados = response

  return produtosEncontrados
}
//-------------------------------------------------------------------------------------------
export async function CarregaGraus() {
  const sheetUrl = sheetsConfig['modelagem'].sheetUrl
  const paginaGraus = sheetsConfig['modelagem'].tabs.grau_dificuldade_mod

  const dados = {
    link: sheetUrl,
    pagina1: paginaGraus,
    celulas: 'A2:C',
    requisicao: 'requisitar',
    verificarDados: tratarDadosGraus,
    colunaUltimaLinha: 'A',
    tituloColunaUltimaLinha: 'id',
    filtro: '',
    isFrontendCall: true
  }

  const response = await puxarDadosDaPlanilha(dados)

  const produtosEncontrados = response

  return produtosEncontrados
}
//-------------------------------------------------------------------------------------------
function tratarDadosGraus(dados) {
  const grausData = dados.result

  const graus = grausData.slice(0).map(grau => {
    return {
      id: grau[0],
      grau: grau[1],
      data: grau[2]
    }
  })

  return categorias
}
function tratarDadosCategorias(dados) {
  const categoriasData = dados

  const categorias = categoriasData.slice(0).map(categoria => {
    return {
      id_categoria: categoria.id_categoria,
      data: categoria.Data,
      nome_categoria: categoria.Nome_Categoria,
      descricao_categoria: categoria.Descricao,
      ativo: categoria.ATIVO
    }
  })

  return categorias
}
