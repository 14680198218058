// src/components/ModelagemDescription.jsx

import React from 'react'
// Importando o arquivo CSS para estilização

const ModelagemDescription = () => {
  return (
    <div className="modelagem-description">
      <h2 className="modelagem-title">Detalhes do Módulo de Modelagem</h2>

      {/* Seção de Cadastro de Pedidos de Modelagem */}
      <section className="modelagem-section">
        <h3>Cadastro de Pedidos de Modelagem</h3>
        <p>
          O módulo de modelagem permite o cadastro detalhado de pedidos, facilitando o controle e
          acompanhamento de cada solicitação. Através desta ferramenta, é possível registrar
          informações essenciais sobre o pedido, cliente, datas e valores envolvidos.
        </p>
        <ul className="modelagem-list">
          <li>
            <strong>Registro de Datas Importantes:</strong> Possibilidade de definir a data de
            cadastro, data prevista para entrega e data efetiva de conclusão.
          </li>
          <li>
            <strong>Seleção de Clientes:</strong> Integração com a base de clientes, permitindo a
            seleção rápida e precisa do cliente associado ao pedido.
          </li>
          <li>
            <strong>Gerenciamento de Valores:</strong> Controle de valores pendentes, sinal, total
            de moldes e cálculo automático do valor total dos pedidos.
          </li>
          <li>
            <strong>Cadastro de Itens:</strong> Adição e gerenciamento de múltiplos itens dentro de
            um pedido, com detalhes como modelagem, preço, unidades e total.
          </li>
          <li>
            <strong>Validações e Alertas:</strong> Sistema de validação dos campos obrigatórios, com
            alertas personalizados para garantir a integridade dos dados.
          </li>
          <li>
            <strong>Resumo Financeiro:</strong> Visualização do valor total pendente do cliente,
            facilitando o acompanhamento financeiro.
          </li>
        </ul>
        <p>
          Este módulo é essencial para gerenciar os pedidos de modelagem de forma eficiente,
          garantindo que todas as informações estejam centralizadas e atualizadas.
        </p>
      </section>

      {/* Seção de Ajuste de Layout */}
      <section className="modelagem-section">
        <h3>Ajuste de Layout</h3>
        <p>
          A interface permite o ajuste do layout dos componentes através de uma funcionalidade de
          arrastar e soltar, proporcionando flexibilidade na organização dos elementos conforme a
          preferência do usuário.
        </p>
        <ul className="modelagem-list">
          <li>
            <strong>Modo de Edição:</strong> Opção para habilitar ou desabilitar o ajuste do layout,
            garantindo segurança na disposição dos componentes.
          </li>
          <li>
            <strong>Componentes Arrastáveis:</strong> Elementos como campos, tabelas e botões podem
            ser reposicionados conforme necessário.
          </li>
        </ul>
      </section>

      {/* Seção de Integração com Outros Módulos */}
      <section className="modelagem-section">
        <h3>Integração com Outros Módulos</h3>
        <p>
          O módulo de modelagem está integrado com outros módulos do sistema, como financeiro e
          cadastro de clientes, permitindo uma gestão completa e unificada.
        </p>
        <ul className="modelagem-list">
          <li>
            <strong>Sincronização de Dados:</strong> Atualização automática das informações
            financeiras e de clientes.
          </li>
          <li>
            <strong>Consulta de Pagamentos:</strong> Acesso ao histórico de pagamentos do cliente
            diretamente no módulo de modelagem.
          </li>
        </ul>
      </section>

      {/* Seção de Edição e Suporte */}
      <section className="modelagem-section">
        <h3>Edição e Suporte</h3>
        <p>
          Os pedidos de modelagem podem ser editados conforme necessário. Para mais informações
          sobre o sistema, os usuários podem navegar pelas funcionalidades ou entrar em contato com
          os canais de suporte da empresa.
        </p>
      </section>
    </div>
  )
}

export default ModelagemDescription
