import React, { createContext } from 'react'

export const MyContext = createContext({
  isBlurred: false,
  setisBlurred: () => {},
  showAlert_cpf: false,
  setShowAlertCPF: () => {},
  showAlert_cnpj: false,
  setShowAlertCNPJ: () => {},
  showAlert_cep: false,
  setShowAlertCep: () => {},
  showAlert_email: false,
  setShowAlertEmail: () => {},
  showAlert_tel: false,
  setShowAlertTel: () => {},
  message: '',
  setMessage: () => {},
  nome: '',
  setNome: () => {},
  sobrenome: '',
  setSobrenome: () => {},
  cpf: '',
  setCpf: () => {},
  isValidCpf: true,
  setisValidCpf: () => {},
  cnpj: '',
  setCnpj: () => {},
  setpegarCep: () => {},
  pegarCep: () => {},
  tipoPessoa: '',
  setTipoPessoa: () => {},
  categoria: '',
  setCategoria: () => {},
  sexo: '',
  setSexo: () => {},
  telefone: '',
  setTelefone: () => {},
  email: '',
  setEmail: () => {},
  originalEmail: '',
  setOriginalEmail: () => {},
  isValidEmail: true,
  setisValidEmail: () => {},
  cep: '',
  setCep: () => {},
  isValidCep: true,
  setisValidCep: () => {},
  endereco: '',
  setEndereco: () => {},
  numero: '',
  setNumero: () => {},
  complemento: '',
  setComplemento: () => {},
  bairro: '',
  setBairro: () => {},
  cidade: { cidade: '', source: null },
  setCidade: () => {},
  uf: '',
  setUf: () => {},
  municipios: [],
  setMunicipios: () => {},
  ufs: [],
  setUfs: () => {},
  cidadeSelecionada: '',
  setCidadeSelecionada: () => {},
  searchType: '',
  setsearchType: () => {},
  setCepUpdateCounter: () => {},
  cepUpdateCounter: 0,
  setSelectedSector: () => {},
  selectedSector: '',
  nomeEmpresa: '',
  setNomeEmpresa: () => {},
  // showAlertGravando: false,
  // setShowAlertGravando: () => {},
  clienteEditando: false,
  setClienteEditando: () => {},
  clienteId: '',
  clienteData: '',
  showAlert: false,
  setShowAlert: () => {}
})
