//import municipios from '/src/utils/municipios';
import { useContext } from 'react'
import { MyContext } from '/src/components/pages/CadastroGeral/cadastro-geral-context'

export const lookupAddress = async cep => {
  let cepSemHifen = cep.replace('-', '')
  let url = 'https://viacep.com.br/ws/' + cepSemHifen + '/json/'

  const response = await fetch(url)
  const data = await response.json()
  return data
}

export const lookupCep = async (uf, cidade, endereco, setpegarCep) => {
  try {
    let url =
      'https://viacep.com.br/ws/' +
      encodeURIComponent(uf) +
      '/' +
      encodeURIComponent(cidade.cidade) +
      '/' +
      encodeURIComponent(endereco) +
      '/json/'

    const response = await fetch(url, { mode: 'no-cors' })
    const data = await response.json()

    return data
  } catch (error) {
    console.error(`Erro ao buscar CEP: ${error}`)
    throw new Error('Não foi possível buscar o CEP. Por favor, tente novamente mais tarde.')
  }
}
export async function checkEnderecoExists(uf, municipio, endereco) {
  try {
    // Utilize a função lookupCep para buscar os dados do endereço com base no estado, município e rua fornecidos.
    const addressData = await lookupCep(uf, municipio, endereco)

    // Verifique se a API retornou algum resultado para o endereço fornecido.
    if (addressData && addressData.length > 0) {
      // Se houver resultados, retorne true, indicando que o endereço existe.
      return true
    } else {
      // Caso contrário, retorne false, indicando que o endereço não existe.
      return false
    }
  } catch (error) {
    console.error(`Erro ao verificar a existência do endereço: ${error}`)
    // Retorne false em caso de erro, indicando que o endereço não existe.
    return false
  }
}
//--------------------------------------------------------------------------------
export async function checkCepExists(uf, municipio, cep) {
  try {
    // Utilize a função lookupAddress para buscar os dados do endereço com base no CEP fornecido.
    // const bairroExiste = await verificaBairroNaCidade(
    //    "sp",
    //    "ubatuba",
    //    "centro"
    // )
    const addressData = await lookupAddress(cep)

    // Verifique se a API retornou algum resultado para o CEP fornecido.
    if (addressData) {
      // Verifique se o estado e município retornados pela API correspondem aos fornecidos.
      if (addressData.uf === uf && addressData.localidade === municipio) {
        // Se corresponderem, retorne true, indicando que o CEP existe no estado e município selecionados.
        return true
      } else {
        // Caso contrário, retorne false, indicando que o CEP não existe no estado e município selecionados.
        return false
      }
    } else {
      // Se a API não retornou resultados, retorne false, indicando que o CEP não existe.
      return false
    }
  } catch (error) {
    console.error(`Erro ao verificar a existência do CEP: ${error}`)
    // Retorne false em caso de erro, indicando que o CEP não existe.
    return false
  }
}
///
export async function verificaBairroNaCidade(uf, cidade, bairro) {
  try {
    let ufvalue = getUfValueByName(uf)
    const encodedCidade = encodeURIComponent(cidade.cidade)
    const url =
      'https://nominatim.openstreetmap.org/search?q=' +
      encodeURIComponent(bairro) +
      ',' +
      encodedCidade +
      ',' +
      encodeURIComponent(ufvalue) +
      ',Brasil&format=json'

    const response = await fetch(url)

    const data = await response.json()

    if (data.length > 0) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

//--------------------------------------------------------------------------------
export function getAllUfs() {
  return [
    { value: 'AC', label: 'Acre', codigo: 12 },
    { value: 'AL', label: 'Alagoas', codigo: 27 },
    { value: 'AP', label: 'Amapá', codigo: 16 },
    { value: 'AM', label: 'Amazonas', codigo: 13 },
    { value: 'BA', label: 'Bahia', codigo: 29 },
    { value: 'CE', label: 'Ceará', codigo: 23 },
    { value: 'DF', label: 'Distrito Federal', codigo: 53 },
    { value: 'ES', label: 'Espírito Santo', codigo: 32 },
    { value: 'GO', label: 'Goiás', codigo: 52 },
    { value: 'MA', label: 'Maranhão', codigo: 21 },
    { value: 'MT', label: 'Mato Grosso', codigo: 51 },
    { value: 'MS', label: 'Mato Grosso do Sul', codigo: 50 },
    { value: 'MG', label: 'Minas Gerais', codigo: 31 },
    { value: 'PA', label: 'Pará', codigo: 15 },
    { value: 'PB', label: 'Paraíba', codigo: 25 },
    { value: 'PR', label: 'Paraná', codigo: 41 },
    { value: 'PE', label: 'Pernambuco', codigo: 26 },
    { value: 'PI', label: 'Piauí', codigo: 22 },
    { value: 'RJ', label: 'Rio de Janeiro', codigo: 33 },
    { value: 'RN', label: 'Rio Grande do Norte', codigo: 24 },
    { value: 'RS', label: 'Rio Grande do Sul', codigo: 43 },
    { value: 'RO', label: 'Rondônia', codigo: 11 },
    { value: 'RR', label: 'Roraima', codigo: 14 },
    { value: 'SC', label: 'Santa Catarina', codigo: 42 },
    { value: 'SP', label: 'São Paulo', codigo: 35 },
    { value: 'SE', label: 'Sergipe', codigo: 28 },
    { value: 'TO', label: 'Tocantins', codigo: 17 }
  ]
}

export function getUfNameByValue(value) {
  // retorna o nome do estado a partir do valor . exemplo: 'sp' retorna 'São Paulo'
  const allUfs = getAllUfs()
  const matchingUfs = allUfs.filter(uf => uf.value === value)
  if (matchingUfs.length > 0) {
    return matchingUfs[0].label
  } else {
    return null
  }
}

export function getUfValueByName(name) {
  // retorna o valor do estado a partir do nome . exemplo: 'São Paulo' retorna 'sp'
  const allUfs = getAllUfs()
  const matchingUfs = allUfs.filter(uf => uf.label === name)
  if (matchingUfs.length > 0) {
    return matchingUfs[0].value
  } else {
    return null
  }
}
