import { puxarDadosDaPlanilha } from '/src/backend/model'
import sheetsConfig from '/src/config/sheetsConfig'

export async function buscarClientes() {
  const structSheet = 'cadastroClienteG'
  const sheetUrl = sheetsConfig[structSheet].sheetUrl
  const pagina = sheetsConfig[structSheet].tabs.clientes

  const dados = {
    link: sheetUrl,
    pagina1: pagina,
    celulas: 'A2:U',
    requisicao: 'requisitar',
    verificarDados: tratarDadosCliente,
    colunaUltimaLinha: 'A',
    tituloColunaUltimaLinha: 'id',
    filtro: '',
    isFrontendCall: true
  }

  const response = await puxarDadosDaPlanilha(dados)

  const clientesEncontrados = response

  return clientesEncontrados
}
//-------------------------------------------------------------------------------------
function tratarDadosCliente(dados) {
  const clientesData = dados.result

  const clientes = clientesData.slice(0).map(cliente => {
    return {
      id: cliente[0],
      data: cliente[1],
      nome: cliente[2] + ' ' + cliente[5] + ' - ' + cliente[3]
    }
  })

  return clientes
}
//-------------------------------------------------------------------------------------
export async function getServicosAssociados(idCliente) {
  const stuctSheet = 'cadastroClienteServico'
  const sheetUrl = sheetsConfig[stuctSheet].sheetUrl
  const paginaAssociacaoClientesServicos = sheetsConfig[stuctSheet].tabs.clientes_servicos

  const dados = {
    link: sheetUrl,
    pagina1: paginaAssociacaoClientesServicos,
    celulas: 'A2:E',
    requisicao: 'buscarServicosAssociadosCliente',
    verificarDados: tratarDadosServicosAssociados,
    colunaUltimaLinha: 'A',
    tituloColunaUltimaLinha: 'id',
    filtro: '',
    id: idCliente
  }

  const response = await puxarDadosDaPlanilha(dados)

  const servicosAssociados = response

  return servicosAssociados
}
//-------------------------------------------------------------------------------------
function tratarDadosServicosAssociados(dados) {
  const associacoesData = dados.result

  const associacoes = associacoesData.slice(0).map(associacao => {
    return {
      id_cliente_servico: associacao[0],
      data: associacao[1],
      id_cliente: associacao[2],
      id_servico: associacao[3]
    }
  })

  return associacoes
}
