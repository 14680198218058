import { useState } from 'react'
import { Button, FormGroup, Row, Col } from 'react-bootstrap'
import Banner from '/src/components/pages/CadastroGeral/Banner'
import { fetchData } from '/src/backend/model'
import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import { CustomField } from '/src/components/CustomField/custom-field'
import Select from 'react-select'
import { config } from '../../../../../../src/backend/configConexaoGeral'

const CadastroRegraDescontoFreteML = () => {
  const [regraDescontoML, setRegraDescontoML] = useState({
    categoria: '', // 'Verde' ou 'Amarela'
    desconto: ''
  })

  const [showAlert, setShowAlert] = useState(false)
  const [corAlerta, setCorAlerta] = useState('success')
  const [alertMessage, setAlertMessage] = useState('')

  const validarCampos = () => {
    const camposVazios = Object.values(regraDescontoML).some(value => value === '')
    if (camposVazios) {
      setAlertMessage('Todos os campos são obrigatórios.')
      setCorAlerta('danger')
      setShowAlert(true)
      return false
    }
    return true
  }

  const handleClear = fieldName => {
    setRegraDescontoML(prevState => ({
      ...prevState,
      [fieldName]: ''
    }))

    handleChange({ target: { name: fieldName, value: '' } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (!validarCampos()) {
      return
    }

    let dados = {
      ...regraDescontoML,
      requisicao: 'GravarRegraDescontoFreteMercadoLivre'
    }
    let url = config.host + '/regraDescontoFrete'

    try {
      let response = await fetchData(url, dados)

      if (response.message === 'Gravado') {
        setRegraDescontoML({
          categoria: '',
          desconto: ''
        })

        setShowAlert(true)
        setCorAlerta('success')
        setAlertMessage('Regra de desconto do Mercado Livre cadastrada com sucesso')
      } else {
        setShowAlert(true)
        setCorAlerta('danger')
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao tentar cadastrar a regra de desconto. Tente novamente.')
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    setRegraDescontoML({
      ...regraDescontoML,
      [name]: value
    })
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px'
        }}
      >
        <div style={{ width: '60%' }}>
          <Banner
            title="Cadastro de Regras de Desconto no Mercado Livre"
            color="#2e8b57"
            fontSize={50}
          />
        </div>
      </div>

      <div
        className="d-flex justify-content-center"
        style={{ height: '100vh', position: 'relative', marginTop: '20px' }}
      >
        <FormGroup className="mx-auto" style={{ maxWidth: '500px' }}>
          {showAlert && (
            <ShowAlertGeneric
              showAlert={showAlert}
              message={alertMessage}
              setShowAlert={setShowAlert}
              variant={corAlerta}
              className="show-alert-genericPF"
              fontSize="1.5rem"
              dismissible={false}
              isBlurred={true}
            />
          )}
          <form onSubmit={handleSubmit} className="form-fields-container">
            <Row>
              <Col md={10} className="mb-5">
                <CustomField
                  label="Categoria"
                  placeholder="Digite a Categoria"
                  name="categoria"
                  value={regraDescontoML.categoria}
                  onChange={e =>
                    handleChange({ target: { name: 'categoria', value: e.target.value } })
                  }
                  onClear={() => handleClear('categoria')}
                  className="Efeito3dMouseOver-class"
                />
              </Col>
            </Row>
            <Row>
              <Col md={10} className="mb-5">
                <CustomField
                  label="Desconto"
                  placeholder="Digite o valor do Desconto"
                  name="desconto"
                  value={regraDescontoML.desconto}
                  onChange={e =>
                    handleChange({ target: { name: 'desconto', value: e.target.value } })
                  }
                  onClear={() => handleClear('desconto')}
                  className="Efeito3dMouseOver-class"
                />
              </Col>
            </Row>
            <Button type="submit" className="btn btn-success" style={{ marginTop: '20px' }}>
              Cadastrar Regra de desconto do Mercado Livre
            </Button>
          </form>
        </FormGroup>
      </div>
    </div>
  )
}

export default CadastroRegraDescontoFreteML
