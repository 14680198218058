//cadastro-categoria preco-facil-market
import React, { useState, useRef, useEffect } from 'react'
import Select from 'react-select'
import { components } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { FaPencilAlt, FaTimes, FaEraser, FaSave, FaPlus } from 'react-icons/fa'

import { IoMdArrowDropdown } from 'react-icons/io' // Importando um ícone de seta para baixo

import { Button, FormGroup, Row, Col, Form } from 'react-bootstrap'
import { CustomField } from '/src/components/CustomField/custom-field'
import Banner from '/src/components/pages/CadastroGeral/Banner'
import { fetchData } from '/src/backend/model'
import { CarregarMarketplaces } from '/PRECO_FACIL_MARKET/src/components/pages/Regras/RegraGlobal/cadastro-regra-global'
import { config } from '/src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import { useDynamicState } from '/src/utils/form-utils.js'

import { CarregarCategorias } from '/PRECO_FACIL_MARKET/src/components/pages/Produtos/CadastroProduto/cadastro-produto'
import { AuthContext } from '/src/context/AuthContext'
import { useContext } from 'react'
import mongoose from 'mongoose'
import { Types } from 'mongoose'
import PropTypes from 'prop-types'
const CadastroCategoria = props => {
  const [categoria, setCategoria] = useState({
    nome: '',
    descricao: ''
  })
  const [categorias, setCategorias] = useState([])
  const { tenantId } = useContext(AuthContext)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [corAlerta, setCorAlerta] = useState('danger') // ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
  const [state, setDynamicState] = useDynamicState()
  const [marketplaces, setMarketplaces] = useState([])
  const [fields, setFields] = useState([])
  const [selectValue, setSelectValue] = useState(null)
  const [options, setOptions] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [isEditingComissao, setIsEditingComissao] = useState(false)
  const [originalValue, setOriginalValue] = useState(null)
  const [selectedOption, setSelectedOption] = useState(selectValue || null)
  // Armazenar o valor original ao entrar em modo de edição
  const [optionsList, setOptionsList] = useState(options || [])
  const [inputValue, setInputValue] = useState('')
  const [isEspecial, setIsEspecial] = useState(false)
  const selectRef = useRef(null)

  // esse useEffect é para carregar as categorias com a funcao  CarregarCategorias
  useEffect(() => {
    const carregarDados = async () => {
      try {
        const response = await CarregarCategorias(tenantId)

        setCategorias(response || [])
      } catch (error) {
        console.error('Erro ao carregar as categorias:', error)
        // Trate o erro conforme necessário
      }
    }

    carregarDados()
  }, [])
  //---------------------------------------------------
  useEffect(() => {
    // Mapeia 'categorias' para o formato esperado pelo componente Select
    const updatedOptions = categorias.map(cat => ({
      value: cat._id, // Assume que cada categoria tem um campo '_id'
      label: cat.nome // O texto que será mostrado no dropdown
    }))

    // Atualiza o estado das opções do select
    setOptions(updatedOptions)
  }, [categorias]) // Dependência 'categorias', que quando alterada, aciona este useEffect

  useEffect(() => {
    const carregarDados = async () => {
      try {
        const response = await CarregarMarketplaces(tenantId)

        setMarketplaces(response || [])
      } catch (error) {
        console.error('Erro ao carregar os marketplaces:', error)
        // Trate o erro conforme necessário
      }
    }

    carregarDados()
  }, [])

  useEffect(() => {
    if (marketplaces.length > 0) {
      const newFields = createFieldsFromMarketplaces(marketplaces)
      setFields(newFields)
    }
  }, [marketplaces])

  useEffect(() => {
    if (isEditing && selectRef.current) {
      // Foca no input quando entra em modo de edição

      setOriginalValue(selectedOption) // Salva o valor original ao entrar em modo de edição

      selectRef.current.focus()
    }
  }, [isEditing])

  const limparCampos = () => {
    // Definir campos específicos no state de volta para uma string vazia
    setCategoria({ nome: '' })
    let newState = {}
    fields.forEach(field => {
      newState[field.label] = ''
    })
    setDynamicState(newState) // Atenção para esta linha!
  }
  //---------------------------------------------------
  async function createCategory(categoria, tenantId, isEspecial) {
    let dados = {
      nome: categoria.nome,
      descricao: categoria.descricao,
      tenantId: tenantId,
      especial: isEspecial,
      requisicao: 'GravarCategoriaPF'
    }
    let url = config.host + '/categoriaPF'

    try {
      let response = await fetchData(url, dados)

      if (response.categoria) {
        setShowAlert(true)
        setCorAlerta('success')
        setAlertMessage('Salvo Com Sucesso')
        return response.categoria
      } else {
        setShowAlert(true)
        setCorAlerta('danger')
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
        return false
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao tentar gravar a categoria. Tente novamente.')
      return false
    }
  }
  //---------------------------------------------------
  async function createComissionPF(comissao, categoriaId, marketplaceId) {
    let dados = {
      comissao: comissao,
      categoriaId: categoriaId,
      marketplaceId: marketplaceId,
      requisicao: 'createComissionPF'
    }
    let url = config.host + '/categoriaPF'

    try {
      let response = await fetchData(url, dados)

      if (response.comissao) {
        return true
      } else {
        setShowAlert(true)
        setCorAlerta('danger')
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
        return false
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao tentar gravar a comissão. Tente novamente.')
      return false
    }
  }
  //---------------------------------------------------
  async function checkComissionExistsPF(categoriaId, marketplaceId) {
    let dados = {
      categoriaId: categoriaId,
      marketplaceId: marketplaceId,
      requisicao: 'checkComissionExistsPF'
    }
    let url = config.host + '/categoriaPF'

    try {
      let response = await fetchData(url, dados)

      if (response.comissao) {
        return response.comissao
      } else {
        return null
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      return null
    }
  }
  //---------------------------------------------------
  async function updateComissionPF(_id, comissaoValue) {
    let dados = {
      _id: _id,
      comissao: comissaoValue,
      requisicao: 'updateComissionPF'
    }

    let url = config.host + '/categoriaPF'

    try {
      let response = await fetchData(url, dados)

      if (response.comissao) {
        // setShowAlert(true);
        // setCorAlerta('success');
        // setAlertMessage('Atualizado Com Sucesso');
        return true
      } else {
        setShowAlert(true)
        setCorAlerta('danger')
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
        return false
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao tentar atualizar a comissão. Tente novamente.')
      return false
    }
  }
  //---------------------------------------------------

  async function checkCategoryExistsPF(nome, tenantId) {
    let dados = {
      nome: nome,
      tenantId: tenantId,
      requisicao: 'checkCategoryExistsPF'
    }
    let url = config.host + '/categoriaPF'

    try {
      let response = await fetchData(url, dados)

      if (response.categoria) {
        return response.categoria
      } else {
        return null
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      return null
    }
  }
  //---------------------------------------------------
  async function updateCategoryEspecialPF(categoria, id, novoNome) {
    let dados = {
      id: id,
      novoNome: novoNome,
      requisicao: 'updateCategoryEspecialPF'
    }

    let url = config.host + '/categoriaPF'

    try {
      let response = await fetchData(url, dados)

      if (response.categoria) {
        setShowAlert(true)
        setCorAlerta('success')
        setAlertMessage('Atualizado Com Sucesso')
        return true
      } else {
        setShowAlert(true)
        setCorAlerta('danger')
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
        return false
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao tentar atualizar a categoria. Tente novamente.')
      return false
    }
  }
  //---------------------------------------------------
  // async function updateOrCreateCommissions(fields, categoryId, state) {
  //   try {
  //     await Promise.all(
  //       fields.map(async field => {
  //         const marketplaceId = field._id // Supondo que cada field tem um marketplaceId associado
  //         const comissaoValue = state[field.label]

  //         if (comissaoValue) {
  //           const existingComission = await checkComissionExistsPF(categoryId, marketplaceId)

  //           if (existingComission) {
  //             await updateComissionPF(existingComission._id, comissaoValue)
  //           } else {
  //             await createComissionPF(comissaoValue, categoryId, marketplaceId)
  //           }
  //         }
  //       })
  //     )
  //   } catch (error) {
  //     console.error('Error updating or creating commissions:', error)
  //     throw error // Re-throw the error if you need to handle it higher up the call stack.
  //   }
  // }
  async function updateOrCreateCommissions(fields, categoryId, state) {
    try {
      await Promise.all(
        fields.map(async field => {
          const marketplaceId = field._id // Supondo que cada field tem um marketplaceId associado
          const comissaoValue = state[field.label]

          if (comissaoValue) {
            const categoryObjectId = mongoose.Types.ObjectId(categoryId)
            const marketplaceObjectId = mongoose.Types.ObjectId(marketplaceId)

            const existingComission = await checkComissionExistsPF(
              categoryObjectId,
              marketplaceObjectId
            )

            if (existingComission) {
              await updateComissionPF(existingComission._id, comissaoValue)
            } else {
              await createComissionPF(comissaoValue, categoryObjectId, marketplaceObjectId)
            }
          }
        })
      )
    } catch (error) {
      console.error('Error updating or creating commissions:', error)
      throw error // Re-throw the error if you need to handle it higher up the call stack.
    }
  }
  //---------------------------------------------------
  async function updateCategoryEspecialPF(id, isEspecial) {
    let dados = {
      id: id,
      especial: isEspecial,
      requisicao: 'updateCategoryEspecialPF'
    }

    let url = config.host + '/categoriaPF'

    try {
      let response = await fetchData(url, dados)

      if (response.categoria) {
        setShowAlert(true)
        setCorAlerta('success')
        setAlertMessage('Categoria Atualizada Com Sucesso')
        return true
      } else {
        setShowAlert(true)
        setCorAlerta('danger')
        setAlertMessage(response.message || 'Erro ao processar a requisição.')
        return false
      }
    } catch (error) {
      console.error('Erro na função fetchData', error)
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao tentar atualizar a categoria. Tente novamente.')
      return false
    }
  }

  const handleSubmitComissao = async event => {
    event.preventDefault()
    try {
      const existingCategory = await checkCategoryExistsPF(categoria.nome, tenantId)
      let categoryId

      if (existingCategory) {
        categoryId = existingCategory._id // Usamos o ID da categoria existente para as comissões
        if (categoryId) {
          updateOrCreateCommissions(fields, categoryId, state)
          await updateCategoryEspecialPF(categoryId, isEspecial)
          setAlertMessage('Comissoes e Categoria Atualizadas com Sucesso')
          setIsEditingComissao(false)
        }
      }
    } catch (error) {
      console.error('Erro ao salvar a categoria ou comissões:', error)
      setAlertMessage('Erro na operação')
    }
    setShowAlert(true)
    setCorAlerta('success') // Você pode decidir mudar dinamicamente com base no sucesso ou erro das operações
  }
  //---------------------------------------------------
  const handleSubmit = async event => {
    event.preventDefault()
    try {
      const existingCategory = await checkCategoryExistsPF(categoria.nome, tenantId)
      let categoryId

      if (existingCategory && inputValue !== originalValue) {
        const updateResponse = await updateCategoryEspecialPF(
          existingCategory.nome,
          existingCategory._id,
          inputValue
        )
        updateOptionsAndExitEditing(inputValue, existingCategory._id)
        categoryId = existingCategory._id // Usamos o ID da categoria existente para as comissões
        setAlertMessage(
          updateResponse ? 'Categoria Alterada com Sucesso' : 'Erro ao atualizar categoria'
        )
      } else {
        const createResponse = await createCategory(categoria, tenantId, isEspecial)
        categoryId = createResponse._id // Guardamos o ID da nova categoria para as comissões
        setAlertMessage(createResponse ? 'Categoria Salva com Sucesso' : 'Erro ao salvar categoria')
      }

      // Processar comissões
      if (categoryId) {
        updateOrCreateCommissions(fields, categoryId, state)
      }
    } catch (error) {
      console.error('Erro ao salvar a categoria ou comissões:', error)
      setAlertMessage('Erro na operação')
    }
    setShowAlert(true)
    setCorAlerta('success') // Você pode decidir mudar dinamicamente com base no sucesso ou erro das operações
  }
  //---------------------------------------------------

  const handleChangeField = (e, field) => {
    setDynamicState(field, e.target.value)
  }

  const handleSelectChange = async selectedOption => {
    if (selectedOption) {
      setCategoria({ nome: selectedOption.label })
      setSelectValue(selectedOption) // Mantendo o valor do select sincronizado com a seleção
    } else {
      setCategoria({ nome: '' })
      setSelectValue(null) // Limpar o valor do select se nada for selecionado
    }
    // Carregar comissões como explicado anteriormente
    try {
      let url = config.host + '/categoriaPF'

      let dados = {
        categoriaId: selectedOption.value,
        requisicao: 'CarregarComissoesPorCategoriaPF'
      }

      const response = await fetchData(url, dados)
      const comissoes = response

      let newState = {}
      fields.forEach(field => {
        const comissao = comissoes.find(c => c.marketplaceId === field._id)
        newState[field.label] = comissao ? comissao.comissao : ''
      })
      setDynamicState(newState)
    } catch (error) {
      console.error('Erro ao carregar comissões:', error)
    }
  }

  const handleCreateOption = async inputValue => {
    // Tenta criar a categoria no backend e obter o ID definitivo
    try {
      const newCategory = await createCategory(
        {
          nome: inputValue.label,
          descricao: '' // Você pode adicionar mais campos conforme necessário
        },
        tenantId,
        isEspecial
      )

      if (newCategory && newCategory._id) {
        // Cria a nova opção com o ID real fornecido pelo backend
        const newOption = {
          value: newCategory._id,
          label: inputValue.label
        }

        // Atualiza o estado das categorias e das opções no componente
        setCategorias(prevCategories => [
          ...prevCategories,
          { _id: newCategory._id, nome: inputValue.label, descricao: '' }
        ])
        setOptions(prevOptions => [...prevOptions, newOption])

        // Configura a nova categoria como selecionada no Select
        setSelectValue(newOption)
        setCategoria({
          nome: inputValue.label,
          descricao: ''
        })

        // Inicializa ou atualiza os estados relacionados
        let newState = {}
        fields.forEach(field => {
          newState[field.label] = '' // Inicializar com valores vazios ou padrões
        })
        setDynamicState(newState)

        // Opcional: Exibir uma mensagem indicando que a categoria foi adicionada com sucesso
        setShowAlert(true)
        setCorAlerta('success')
        setAlertMessage('Categoria adicionada com sucesso!')
      } else {
        throw new Error('Falha ao obter o ID da nova categoria.')
      }
    } catch (error) {
      console.error('Erro ao cadastrar nova categoria', error)
      // Exibir mensagem de erro
      setShowAlert(true)
      setCorAlerta('danger')
      setAlertMessage('Erro ao adicionar categoria. Tente novamente.')
    }
  }

  const handlePencilComissaoClick = () => {
    setIsEditingComissao(!isEditingComissao)
  }
  const handlePencilClick = () => {
    setIsEditing(!isEditing) // Alterna o estado de edição
  }

  const updateOptionsAndExitEditing = (newLabel, newValue) => {
    // Atualize as opções com o novo valor
    const newOptions = options.map(option =>
      option.value === newValue ? { ...option, label: newLabel } : option
    )
    setOptions(newOptions)

    // Atualize a opção selecionada com o novo valor
    const newSelectedOption = { label: newLabel, value: newValue }
    setSelectedOption(newSelectedOption)
    setSelectValue(newSelectedOption) // Atualize selectValue se necessário

    // Saia do modo de edição
    setIsEditing(false)
  }
  function handleAddClick() {
    // implemente uma menssagem explicando que para adicionar basta digitar o nome de uma categoria que nao existe e clicar na opçao cadastrar que aparece
    setShowAlert(true)
    setCorAlerta('warning')
    setAlertMessage(
      'Digite uma categoria que ainda nao existe no select e aparecera a opçao para cadastrar!'
    )
  }

  const handleCheckboxChange = e => {
    const { checked } = e.target
    setIsEspecial(checked)
  }

  return (
    <div
      className="cadastro-categoria-container"
      style={{
        display: 'flex', // Define o display para flex
        flexDirection: 'column', // Orientação do flex
        justifyContent: 'center' // Centraliza verticalmente
      }}
    >
      <div className="text-center" style={{ maxWidth: '900px', margin: 'auto' }}>
        <Banner
          title="Cadastro de Categoria"
          color="#2e8b57"
          fontSize={props.fontSize}
          classEfeito3dMouseOver={props.classEfeito3dMouseOver}
        />
        <div
          style={{
            marginTop: '50px' // Adiciona uma margem superior de 20px
          }}
        >
          <FormGroup>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <SimpleCreatableSelect
                    options={options}
                    selectValue={selectValue}
                    handleSelectChange={handleSelectChange}
                    handleCreateOption={handleCreateOption}
                    isEditing={isEditing}
                    selectRef={selectRef}
                    setSelectedOption={setSelectedOption}
                    selectedOption={selectedOption}
                    setOptionsList={setOptionsList}
                    optionsList={optionsList}
                    setInputValue={setInputValue}
                    inputValue={inputValue}
                    createCategory={createCategory}
                    tenantId={tenantId}
                    isEspecial={isEspecial}
                  />

                  <div
                    style={{
                      marginTop: '20px', // Adiciona uma margem superior de 20px
                      display: 'flex', // Usa flexbox para alinhar os botões lado a lado
                      justifyContent: 'start' // Alinha os botões à esquerda
                    }}
                  >
                    <PencilButton handlePencilClick={handlePencilClick} />
                    {!isEditing && <AddButton handleAddClick={handleAddClick} />}
                    {isEditing && <SaveButton handleSaveClick={handleSubmit} />}
                  </div>
                </Col>
                <Col md={6}>
                  <div
                    style={{
                      flex: 1,
                      marginRight: '10px',
                      position: 'relative'
                    }}
                  >
                    {isEditingComissao && (
                      <div className="activeEditingLabel" style={{ left: '263px' }}>
                        Edição Ativa
                      </div>
                    )}
                    <div
                      className="comissoes-container"
                      style={{
                        border: '1px solid #ddd',
                        padding: '20px',
                        borderRadius: '5px',
                        maxWidth: '220px',
                        marginLeft: 'auto'
                      }}
                    >
                      <h5 style={{ marginTop: '-10px' }}>Comissões</h5>

                      {fields.map((fieldObj, index) => (
                        <div
                          className="field-container"
                          style={{
                            marginBottom: '40px',
                            marginTop: index === 0 ? '35px' : '0'
                          }}
                          key={index} // Add a unique "key" prop
                        >
                          {fieldObj.label === 'Mercado Livre' ? (
                            <div
                              style={{
                                border: '1px solid #ddd',
                                padding: '10px',
                                borderRadius: '5px'
                              }}
                            >
                              <CustomField
                                key={fieldObj.label}
                                label={fieldObj.label}
                                placeholder={fieldObj.label}
                                value={state[fieldObj.label] || ''}
                                onChange={e => handleChangeField(e, fieldObj.label)}
                                onClear={() => setDynamicState(fieldObj.label, '')}
                                width={fieldObj.width} // Faz com que o campo ocupe a largura total do contêiner
                                isEditing={isEditingComissao}
                              />

                              <div
                                style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}
                              >
                                <input
                                  type="checkbox"
                                  id="categoriaEspecial"
                                  onChange={e => handleCheckboxChange(e, fieldObj.label)}
                                />
                                <label
                                  htmlFor="categoriaEspecial"
                                  style={{ marginLeft: '8px' }}
                                  title="Marque aqui se essa categoria for especial, para que aplique o desconto de 25% sobre o valor do frete"
                                >
                                  Categoria especial
                                </label>
                              </div>
                            </div>
                          ) : (
                            <CustomField
                              key={fieldObj.label}
                              label={fieldObj.label}
                              placeholder={fieldObj.label}
                              value={state[fieldObj.label] || ''}
                              onChange={e => handleChangeField(e, fieldObj.label)}
                              onClear={() => setDynamicState(fieldObj.label, '')}
                              width={fieldObj.width} // Faz com que o campo ocupe a largura total do contêiner
                              isEditing={isEditingComissao}
                            />
                          )}
                        </div>
                      ))}

                      <div
                        style={{
                          marginTop: '20px', // Adiciona uma margem superior de 20px
                          display: 'flex', // Usa flexbox para alinhar os botões lado a lado
                          justifyContent: 'start' // Alinha os botões à esquerda
                        }}
                      >
                        <CleanButton onClick={limparCampos} />
                        <PencilButton handlePencilClick={handlePencilComissaoClick} />
                        {isEditingComissao && <SaveButton handleSaveClick={handleSubmitComissao} />}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
            {showAlert && (
              <ShowAlertGeneric
                showAlert={showAlert}
                message={alertMessage}
                isBlurred={true}
                setShowAlert={setShowAlert}
                variant={corAlerta}
              />
            )}
          </FormGroup>
        </div>
      </div>
    </div>
  )
}

export default CadastroCategoria

const SimpleCreatableSelect = ({
  options,
  selectValue,
  handleSelectChange,
  handleCreateOption,
  isEditing,
  selectRef,
  setSelectedOption,
  selectedOption,
  setOptionsList,
  optionsList,
  setInputValue,
  inputValue,
  createCategory,
  tenantId,
  isEspecial

  // Nova função para lidar com a atualização de opções
}) => {
  SimpleCreatableSelect.propTypes = {
    options: PropTypes.array.isRequired,
    selectValue: PropTypes.object,
    handleSelectChange: PropTypes.func.isRequired,
    handleCreateOption: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    selectRef: PropTypes.object.isRequired,
    setSelectedOption: PropTypes.func.isRequired,
    selectedOption: PropTypes.object,
    setOptionsList: PropTypes.func.isRequired,
    optionsList: PropTypes.array.isRequired,
    setInputValue: PropTypes.func.isRequired,
    inputValue: PropTypes.string.isRequired,
    createCategory: PropTypes.func.isRequired,
    tenantId: PropTypes.string.isRequired,
    isEspecial: PropTypes.bool.isRequired
  }

  useEffect(() => {
    // Atualiza com as propriedades externas
    setOptionsList(options)
    setSelectedOption(selectValue)
  }, [options, selectValue])

  const handleChange = async (newValue, actionMeta) => {
    setSelectedOption(newValue)

    if (
      !isEditing &&
      (actionMeta.action === 'select-option' || actionMeta.action === 'create-option')
    ) {
      if (actionMeta.action === 'create-option') {
        // Adiciona novo item à lista se não estiver em modo de edição
        const newOption = { label: newValue.label, value: newValue.value }
        setOptionsList(prevOptions => [...prevOptions, newOption])

        // if (handleCreateOption) {
        //   handleCreateOption(newOption)
        // }
        // Cadastra no banco de dados
        try {
          await createCategory(
            {
              nome: newValue.label,
              descricao: '' // Descrição pode ser ajustada conforme necessário
            },
            tenantId,
            isEspecial
          )
        } catch (error) {
          console.error('Erro ao cadastrar nova categoria', error)
        }
      }
    }

    if (handleSelectChange && actionMeta.action === 'select-option') {
      handleSelectChange(newValue)
    }
  }
  const inputRef = useRef() // Ref para acessar o DOM do input

  useEffect(() => {
    if (isEditing && selectedOption) {
      setInputValue(selectedOption.label)
    }
  }, [isEditing, selectedOption])

  const handleInputChange = (value, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      // Atualiza o inputValue baseado na entrada do usuário
      setInputValue(value)
    }
  }
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        }}
      >
        {/* A mensagem de edição agora está dentro do mesmo flex container do CreatableSelect */}
        <div style={{ flex: 1, marginRight: '10px', position: 'relative' }}>
          {isEditing && <div className="activeEditingLabel">Edição Ativa</div>}
          <CreatableSelect
            key={selectedOption ? selectedOption.value : 'select'}
            value={selectedOption}
            ref={selectRef}
            isClearable
            isSearchable
            onChange={handleChange}
            options={optionsList}
            onInputChange={handleInputChange}
            inputValue={isEditing ? inputValue : undefined}
            menuIsOpen={isEditing ? false : undefined}
            styles={{
              container: base => ({ ...base, width: '100%' }),
              control: base => ({
                ...base,
                backgroundColor: isEditing ? '#E3F9E5' : 'white'
              })
            }}
            formatCreateLabel={inputValue => (isEditing ? null : `Cadastrar "${inputValue}"`)}
          />
        </div>
      </div>
    </div>
  )
}

const CleanButton = ({ onClick, marginRight = '20px' }) => {
  return (
    <FaEraser
      onClick={onClick}
      style={{ cursor: 'pointer', fontSize: '32px', marginRight: marginRight }}
      title="Limpar campos"
    />
  )
}
const PencilButton = ({
  handlePencilClick,
  fontSize = '24px',
  title = 'Editar',
  marginRight = '20px'
}) => {
  return (
    <FaPencilAlt
      onClick={handlePencilClick}
      style={{
        cursor: 'pointer',
        fontSize: fontSize,
        marginRight: marginRight
      }}
      title={title}
    />
  )
}

const SaveButton = ({
  handleSaveClick,
  fontSize = '24px',
  title = 'Salvar',
  marginRight = '20px'
}) => {
  return (
    <FaSave
      onClick={handleSaveClick}
      color="green"
      style={{
        cursor: 'pointer',
        fontSize: fontSize,

        marginRight: marginRight
      }}
      title={title}
    />
  )
}

const AddButton = ({ handleAddClick, marginRight = '20px' }) => {
  return (
    <FaPlus
      onClick={handleAddClick}
      style={{ cursor: 'pointer', fontSize: '24px', marginRight: marginRight }}
      color="green"
      title="Adicionar"
    />
  )
}

//---------------------------------------------------
export function createFieldsFromMarketplaces(marketplaces) {
  // Cria o array fields dinamicamente a partir dos marketplaces

  const newFields = marketplaces.map(marketplace => ({
    label: marketplace.nome, // Define o label com base no nome do marketplace
    _id: marketplace._id, // Adiciona o ID do marketplace
    width: '140px', // Define uma largura padrão
    validate: true // Define um valor padrão para validate
  }))

  return newFields
}

//---------------------------------------------------
