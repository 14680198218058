import React from 'react'
import { Link } from 'react-router-dom'
import logoTopoPrecoFacilMarket from '../../../../../PRECO_FACIL_MARKET/src/components/pages/Usuarios/login/logo_topo.jpg'
import logoTopoModelagemCompleta from '../../../../../src/components/pages/Usuarios/login/logo_topo.jpg'
import { getLogoTopo, getTitle } from '../../../../../src/components/pages/Tenant/tenant.jsx'
import { NavbarModelagemcompleta } from '../../../../../src/components/pages/Home/NavbarModelagemcompleta.jsx'
const PlanosDetalhesPF = () => {
  return (
    <div>
      <NavbarModelagemcompleta
        logoTopo={getLogoTopo('precofacilmarket')}
        title={getTitle('precofacilmarket')}
      />
      <div style={styles.planosDetalhes}>
        <h2 style={styles.h2}>Planos sob consulta</h2>
        <p style={styles.p}>
          Após o cadastro, você terá acesso parcial ao sistema, onde poderá cadastrar produtos,
          categorias e regras, mas as funcionalidades de cálculo de custos estarão bloqueadas até
          que você conclua a assinatura.
        </p>
        <div style={styles.plano}>
          <h3 style={styles.h3}>Mensal Sem Fidelidade</h3>
          <p style={styles.preco}>R$179,00/mês</p>
          <p style={styles.p}>Flexibilidade total, cancele a qualquer momento sem compromisso.</p>
          <Link to="/cadastro-tenant" style={styles.cadastroLink}>
            Cadastre-se Agora
          </Link>
        </div>
        <div style={styles.plano}>
          <h3 style={styles.h3}>Mensal com Fidelidade de 6 Meses</h3>
          <p style={styles.preco}>R$159,00/mês</p>
          <p style={styles.p}>Comprometa-se por 6 meses e aproveite um desconto mensal.</p>
          <Link to="/cadastro-tenant" style={styles.cadastroLink}>
            Cadastre-se Agora
          </Link>
        </div>
      </div>
    </div>
  )
}

const styles = {
  planosDetalhes: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
  },
  h2: {
    fontSize: '2.5rem',
    marginBottom: '20px',
    color: '#333'
  },
  plano: {
    marginBottom: '30px',
    padding: '20px',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px'
  },
  h3: {
    fontSize: '2rem',
    marginBottom: '10px',
    color: '#007bff'
  },
  preco: {
    fontSize: '1.5rem',
    color: '#28a745',
    marginBottom: '10px'
  },
  p: {
    fontSize: '1rem',
    marginBottom: '20px',
    color: '#555'
  },
  cadastroLink: {
    display: 'inline-block',
    padding: '10px 20px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#007bff',
    borderRadius: '5px',
    textDecoration: 'none',
    transition: 'background-color 0.3s'
  },
  cadastroLinkHover: {
    backgroundColor: '#0056b3'
  }
}
export default PlanosDetalhesPF
