import { useEffect, useContext, useState } from "react";
import { fetchData } from "/src/backend/model";
import { config } from "/src/backend/configConexaoGeral";
import { AuthContext } from "/src/context/AuthContext";
import { renewTokenIfNeeded } from "/src/backend/model";

// export const useInactivityLogout = () => {
//   const { logout, isAuthenticated } = useContext(AuthContext);

//   const tempoEmsegundos = 1000 * 10; // ultimo numero e o tempo em segundos
//   const tempoEmMinutos = 1000 * 60 * 15; // ultimo numero e o tempo em minutos
//   useEffect(() => {
//     const inactivityTimeMilliseconds = tempoEmMinutos; // Converte minutos em milissegundos
//     const tokenRenewalTime = tempoEmMinutos - tempoEmsegundos; // Tempo para iniciar a tentativa de renovação do token

//     let inactivityTimer;
//     let lastActivityTime = Date.now();

//     const logoutUser = async () => {
//       if (isAuthenticated) {
//         let url = host + '/logout';
//         let dados = { requisicao: 'logout' };
//         await fetchData(url, dados);
//         logout();
//       }
//     };

//     const checkRenewToken = async () => {
//       const currentTime = Date.now();
//       if (currentTime - lastActivityTime >= tokenRenewalTime) {
//         await renewTokenIfNeeded(); // Chama a função de renovação do token definida externamente
//         lastActivityTime = currentTime; // Atualiza o tempo da última atividade
//       }
//     };

//     const resetTimer = () => {
//       if (isAuthenticated) {
//         const currentTime = Date.now();
//         // Verifique se o tempo suficiente passou desde o último login para tentar renovar o token
//         if (currentTime - lastActivityTime >= tokenRenewalTime) {
//           checkRenewToken();
//         }
//         clearTimeout(inactivityTimer);
//         inactivityTimer = setTimeout(logoutUser, inactivityTimeMilliseconds);
//       }
//     };

//     // Adicionar event listeners para resetar o timer
//     document.addEventListener('mousemove', resetTimer);
//     document.addEventListener('keypress', resetTimer);
//     inactivityTimer = setTimeout(logoutUser, inactivityTimeMilliseconds); // Inicia o timer

//     // Limpar event listeners e o timer ao desmontar o componente
//     return () => {
//       clearTimeout(inactivityTimer);
//       document.removeEventListener('mousemove', resetTimer);
//       document.removeEventListener('keypress', resetTimer);
//     };
//   }, [isAuthenticated]);

//   return null; // O hook não precisa renderizar nada
// };

export const useInactivityLogout = () => {
  const { logout, isAuthenticated } = useContext(AuthContext);
  const [userHasActed, setUserHasActed] = useState(false);

  const tempoEmsegundos = 1000 * 10; // último número é o tempo em segundos
  const tempoEmMinutos = 1000 * 60 * 15; // último número é o tempo em minutos
  const inactivityTimeMilliseconds = tempoEmMinutos; // Converte minutos em milissegundos
  const tokenRenewalTime = tempoEmMinutos - tempoEmsegundos; // Tempo para iniciar a tentativa de renovação do token

  useEffect(() => {
    let inactivityTimer;

    const logoutUser = async () => {
      if (isAuthenticated && userHasActed) {
        let url = config.host + "/logout";
        let dados = { requisicao: "logout" };
        await fetchData(url, dados);
        logout();
      }
    };

    const checkRenewToken = async () => {
      const currentTime = Date.now();
      if (currentTime - lastActivityTime >= tokenRenewalTime) {
        await renewTokenIfNeeded(); // Chama a função de renovação do token definida externamente
        lastActivityTime = currentTime; // Atualiza o tempo da última atividade
      }
    };

    const handleUserAction = () => {
      setUserHasActed(true);
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(logoutUser, inactivityTimeMilliseconds);
    };

    // Só instala os event listeners se o usuário estiver autenticado
    if (isAuthenticated) {
      document.addEventListener("mousemove", handleUserAction);
      document.addEventListener("keypress", handleUserAction);
    }

    // Inicia o timer se o usuário já fez alguma ação
    if (userHasActed) {
      inactivityTimer = setTimeout(logoutUser, inactivityTimeMilliseconds);
    }

    // Limpar event listeners e o timer ao desmontar o componente
    return () => {
      clearTimeout(inactivityTimer);
      if (isAuthenticated) {
        document.removeEventListener("mousemove", handleUserAction);
        document.removeEventListener("keypress", handleUserAction);
      }
    };
  }, [isAuthenticated, userHasActed]);

  return null; // O hook não precisa renderizar nada
};
