import { useState, useEffect } from 'react'
import { getNiveisDeAcesso } from '/src/components/pages/Usuarios/usuarios'
import { Container, Form, Row, Col, Button } from 'react-bootstrap'
import Banner from '/src/components/pages/CadastroGeral/Banner'
import { fetchData } from '/src/backend/model'

import { config } from '/src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'

import { AuthContext } from '/src/context/AuthContext'
import { useContext } from 'react'

const ConfiguracaoUsuario = () => {
  const [niveisDeAcesso, setNiveisDeAcesso] = useState([])
  const [nivelDeAcessoSelecionado, setNivelDeAcessoSelecionado] = useState('')
  const [todosNiveisAcesso, setTodosNiveisAcesso] = useState([])
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [idsNiveisAcesso, setIdsNiveisAcesso] = useState({})
  const [responseData, setResponseData] = useState(null)
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [labels, setLabels] = useState({
    cadastroServicoModelagem: 'Cadastro Serviço Modelagem',
    cadastroPlotagem: 'Cadastro de Plotagem',
    cadastroCorte: 'Cadastro de Corte',
    financeiro: 'Financeiro',
    cadastroUsuario: 'Cadastro de Usuário',
    cadastroNiveisAcesso: 'Cadastro de Níveis de Acesso',
    configuracaoNiveisAcesso: 'Configuração de Níveis de Acesso',
    home: 'Home',
    inicio: 'Início',
    ipsDeAcesso: 'Gerenciar IPs de Acesso',
    cadastroGeral: 'Cadastro Geral',
    financeiroEnvioEmail: 'Financeiro Envio Email',
    cadastroClientePlotagemEditar: 'Cadastro Cliente Plotagem Editar',
    cadastroClienteModelagemEditar: 'Cadastro Cliente Modelagem Editar',
    cadastroClienteCorteEditar: 'Cadastro Cliente Corte Editar',
    cadastroClientePlotagem: 'Cadastro Cliente Plotagem',
    cadastroClienteModelagem: 'Cadastro Cliente Modelagem',
    cadastroClienteCorte: 'Cadastro Cliente Corte',
    cadastroGrauDificuldadeModelagem: 'Cadastro Grau Dificuldade Modelagem',
    cadastroPrecosModelagem: 'Cadastro Preços Modelagem',
    editarPrecosModelagem: 'Editar Preços Modelagem',
    editarCorte: 'Editar Corte',
    cadastroGeralPesquisaEedicao: 'Cadastro Geral Pesquisa e Edição',
    plotagemPesquisaEedicaoCliente: 'Plotagem Pesquisa e Edição Cliente',
    cortePesquisaEedicaoCliente: 'Corte Pesquisa e Edição Cliente',
    modelagemPesquisaEedicaoCliente: 'Modelagem Pesquisa e Edição Cliente',
    editarPlotagem: 'Editar Plotagem',
    cadastroUsurioSistema: 'Cadastro Usuário Sistema',
    cadastroDeServico: 'Cadastro de Serviço',
    cadastroCategoria: 'Cadastro de Categoria',
    cadastroAssociacaoServicoCategoria: 'Cadastro de Associação Serviço Categoria',
    cadastroAssociacaoClienteServico: 'Cadastro de Associação Cliente Serviço',
    visualizacaDasCategorias: 'Visualização das Categorias',
    pagamentosMarketplaces: 'Pagamentos Marketplaces',
    marketPlacesDetalhamento: 'MarketPlaces Detalhamento',
    listagemProdutosMercadoLivre: 'Listagem Produtos Mercado Livre',
    vendasDoMercadoLivre: 'Vendas do Mercado Livre',
    editarModelagem: 'Editar Modelagem',
    cadastroProduto: 'Cadastro de Produto',
    finalizacaoDeCorte: 'Finalização de Corte',
    finalizacaoDeModelagem: 'Finalização de Modelagem',
    usuario: 'Usuário',
    corte: 'Corte',
    marketplaces: 'Marketplaces',
    subsistemas: 'Sub Sistemas',
    cadastroSubSistema: 'Cadastro de Sub Sistemas',
    financeiroGeral: 'Financeiro Geral',
    assinarPlano: 'Assinar Plano'
  })

  const [checkboxes, setCheckboxes] = useState({
    cadastroServicoModelagem: false,
    cadastroPlotagem: false,
    cadastroCorte: false,
    financeiro: false,
    cadastroUsuario: false,
    cadastroNiveisAcesso: false,
    configuracaoNiveisAcesso: false,
    home: false,
    inicio: false,
    ipsDeAcesso: false,
    cadastroGeral: false,
    financeiroEnvioEmail: false,
    cadastroClientePlotagemEditar: false,
    cadastroClienteModelagemEditar: false,
    cadastroClienteCorteEditar: false,
    cadastroClientePlotagem: false,
    cadastroClienteModelagem: false,
    cadastroClienteCorte: false,
    cadastroGrauDificuldadeModelagem: false,
    cadastroPrecosModelagem: false,
    editarPrecosModelagem: false,
    editarCorte: false,
    cadastroGeralPesquisaEedicao: false,
    plotagemPesquisaEedicaoCliente: false,
    cortePesquisaEedicaoCliente: false,
    modelagemPesquisaEedicaoCliente: false,
    editarPlotagem: false,
    cadastroUsurioSistema: false,
    cadastroDeServico: false,
    cadastroCategoria: false,
    cadastroAssociacaoServicoCategoria: false,
    cadastroAssociacaoClienteServico: false,
    visualizacaDasCategorias: false,
    pagamentosMarketplaces: false,
    marketPlacesDetalhamento: false,
    listagemProdutosMercadoLivre: false,
    vendasDoMercadoLivre: false,
    editarModelagem: false,
    cadastroProduto: false,
    marketplaces: false,
    finalizacaoDeCorte: false,
    finalizacaoDeModelagem: false,
    usuario: false,
    corte: false,
    subsistemas: false,
    cadastroSubSistema: false,
    financeiroGeral: false,
    assinarPlano: false
  })

  const names = [
    'home',
    'inicio',
    'cadastroServicoModelagem',
    'cadastroPlotagem',
    'cadastroCorte',
    'cadastroUsuario',
    'configuracaoNiveisAcesso',
    'cadastroNiveisAcesso',
    'ipsDeAcesso',
    'cadastroGeral',
    'financeiroEnvioEmail',
    'cadastroClientePlotagemEditar',
    'cadastroClienteModelagemEditar',
    'cadastroClienteCorteEditar',
    'cadastroClientePlotagem',
    'cadastroClienteModelagem',
    'cadastroClienteCorte',
    'cadastroGrauDificuldadeModelagem',
    'cadastroPrecosModelagem',
    'editarPrecosModelagem',
    'editarCorte',
    'cadastroGeralPesquisaEedicao',
    'plotagemPesquisaEedicaoCliente',
    'cortePesquisaEedicaoCliente',
    'modelagemPesquisaEedicaoCliente',
    'editarPlotagem',
    'cadastroUsurioSistema',
    'cadastroDeServico',
    'cadastroCategoria',
    'cadastroAssociacaoServicoCategoria',
    'cadastroAssociacaoClienteServico',
    'visualizacaDasCategorias',
    'pagamentosMarketplaces',
    'marketPlacesDetalhamento',
    'listagemProdutosMercadoLivre',
    'vendasDoMercadoLivre',
    'editarModelagem',
    'cadastroProduto',
    'finalizacaoDeCorte',
    'finalizacaoDeModelagem',
    'usuario',
    'financeiro',
    'marketplaces',
    'corte',
    'subsistemas',
    'cadastroSubSistema',
    'financeiroGeral',
    'assinarPlano'
  ]

  const titles = [
    'Home',
    'Início',
    'Cadastro Serviço Modelagem',
    'Cadastro Plotagem',
    'Cadastro Corte',
    'Cadastro de Usuário',
    'Configuração Níveis de Acesso',
    'Cadastro Níveis de Acesso',
    'Gerenciar IPs de Acesso',
    'Cadastro Geral',
    'Financeiro Envio Email',
    'Cadastro Cliente Plotagem - Editar',
    'Cadastro Cliente Modelagem - Editar',
    'Cadastro Cliente Corte - Editar',
    'Cadastro Cliente Plotagem',
    'Cadastro Cliente Modelagem',
    'Cadastro Cliente Corte',
    'Cadastro Grau Dificuldade Modelagem',
    'Cadastro Preços Modelagem',
    'Editar Preços Modelagem',
    'Editar Corte',
    'Cadastro Geral Pesquisa e Edição',
    'Plotagem Pesquisa e Edição Cliente',
    'Corte Pesquisa e Edição Cliente',
    'Modelagem Pesquisa e Edição Cliente',
    'Editar Plotagem',
    'Cadastro Usuário Sistema',
    'Cadastro de Serviço',
    'Cadastro Categoria',
    'Cadastro Associação Serviço Categoria',
    'Cadastro Associação Cliente Serviço',
    'Visualização das Categorias',
    'Pagamentos Marketplaces',
    'Marketplaces Detalhamento',
    'Listagem Produtos Mercado Livre',
    'Vendas do Mercado Livre',
    'Editar Modelagem',
    'Cadastro Produto',
    'Finalização de Corte',
    'Finalização de Modelagem',
    'Usuário',
    'Financeiro',
    'Marketplaces',
    'Corte',
    'Sub Sistemas',
    'Cadastro de Sub Sistemas',
    'Financeiro Geral',
    'Assinar Plano'
  ]
  useEffect(() => {
    if (responseData && responseData.length > 0) {
      atualizarCheckboxesComDados(
        responseData,
        checkboxes,
        setCheckboxes,
        setIdsNiveisAcesso,
        labels
      )
    }
  }, [responseData])

  useEffect(() => {
    const carregarNiveisDeAcesso = async () => {
      getNiveisDeAcesso(setNiveisDeAcesso, RotaBase)
    }

    carregarNiveisDeAcesso()
  }, [])

  const loadTodasConfigNivelAcesso = async () => {
    let dados = {
      requisicao: 'retornar_todos_niveis'
    }
    let url = config.host + '/config_nivel_acesso'
    let response = await fetchData(url, dados)

    setTodosNiveisAcesso(response)
  }

  useEffect(() => {
    loadTodasConfigNivelAcesso()
  }, [])
  const handleChange = async e => {
    const nivelSelecionado = e.target.value

    setNivelDeAcessoSelecionado(nivelSelecionado)

    // Desmarca todos os checkboxes ao mudar o nível de acesso
    const checkboxesDesmarcados = Object.keys(checkboxes).reduce((acc, key) => {
      acc[key] = false
      return acc
    }, {})
    setCheckboxes(checkboxesDesmarcados)

    if (nivelSelecionado) {
      const response = await RetornarTodosNiveis(nivelSelecionado, RotaBase, tenantId)
      setResponseData(response) // Armazenar a resposta para uso no useEffect
    }
  }

  const handleCheckboxChange = e => {
    if (nivelDeAcessoSelecionado === '') {
      setAlertMessage('Selecione um nível de acesso!')
      setShowAlertMessage(true)
      return
    }
    const { name, checked } = e.target

    setCheckboxes({ ...checkboxes, [name]: checked })

    // Verifica se o checkbox foi marcado ou desmarcado
    if (checked) {
      GravarNivelAcesso(name, labels, RotaBase, nivelDeAcessoSelecionado, tenantId)
    } else {
      const idParaDeletar = idsNiveisAcesso[name]
      if (idParaDeletar) {
        DeletarNivelAcesso(idParaDeletar)
      }
    }
  }

  // Supondo que 'dadosDoServidor' é a variável que contém os dados recebidos

  return (
    <>
      <ShowAlertGeneric
        showAlert={showAlertMessage}
        message={alertMessage}
        isBlurred={true} // ou algum outro critério que deseja usar para blur
        setShowAlert={setShowAlertMessage}
      />
      <Container>
        <Banner title="Configuração de nível de acesso" fontSize="60px" width="73%" />

        <Form.Control
          as="select"
          style={{ width: '30%', marginTop: '20px', fontSize: '1.25rem' }}
          value={nivelDeAcessoSelecionado}
          onChange={handleChange}
        >
          <option value="">Selecione um Nível de Acesso</option>
          {niveisDeAcesso.map(nivel => (
            <option key={nivel.value} value={nivel.value}>
              {nivel.label}
            </option>
          ))}
        </Form.Control>
      </Container>
      {/* Adicione os checkboxes para as páginas do sistema */}

      <div className="checkbox-container">
        <div className="checkboxes-container">
          <div className="checkbox-block">
            <Banner title="Páginas do sistema:" fontSize="30px" width="82%" />
            {/* Primeiro bloco de 13 checkboxes */}
            {renderCheckboxes(
              0,
              14,
              nomeDoCheckbox,
              nomeDoLabel,
              checkboxes,
              handleCheckboxChange,
              titles,
              names
            )}
          </div>
          <div className="checkbox-block">
            <Banner title="Páginas do sistema:" fontSize="30px" width="82%" />
            {/* Segundo bloco de 13 checkboxes */}
            {renderCheckboxes(
              15,
              29,
              nomeDoCheckbox,
              nomeDoLabel,
              checkboxes,
              handleCheckboxChange,
              titles,
              names
            )}
          </div>
          <div className="checkbox-block">
            <Banner title="Páginas do sistema:" fontSize="30px" width="82%" />
            {/* Terceiro bloco de 16 checkboxes */}
            {renderCheckboxes(
              30,
              46,
              nomeDoCheckbox,
              nomeDoLabel,
              checkboxes,
              handleCheckboxChange,
              titles,
              names
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ConfiguracaoUsuario

export function nomeDoLabel(index, labels) {
  return labels[index]
}

export function nomeDoCheckbox(index, names) {
  return names[index]
}

export async function RetornarTodosNiveis(nivelId, rota_base, tenantId) {
  let dados = {
    requisicao: 'retornar_todos_niveis',
    id_nivel: nivelId,
    rota_base: rota_base,
    tenantId: tenantId
  }
  let url = config.host + '/config_nivel_acesso'
  let response = await fetchData(url, dados)

  return response // Certifique-se de que esta função retorna a resposta
}
//-------------------------------------------------------------------------------------------------
export const atualizarCheckboxesComDados = (
  dados,
  checkboxes,
  setCheckboxes,
  setIdsNiveisAcesso,
  labels
) => {
  const novosCheckboxes = { ...checkboxes }
  const novosIds = {}

  dados.forEach(item => {
    const chaveCheckbox = Object.keys(labels).find(chave => labels[chave] === item.tela)

    if (chaveCheckbox) {
      novosCheckboxes[chaveCheckbox] = true
      novosIds[chaveCheckbox] = item._id // Armazena o ID associado ao checkbox
    }
  })

  setCheckboxes(novosCheckboxes)
  setIdsNiveisAcesso(novosIds) // Atualiza o estado dos IDs
}
//-------------------------------------------------------------------------------------------------
export function renderCheckboxes(
  start,
  end,
  nomeDoCheckbox,
  nomeDoLabel,
  checkboxes,
  handleCheckboxChange,
  labels,
  names
) {
  const checkboxesToRender = []

  for (let i = start; i <= end; i++) {
    checkboxesToRender.push(
      <Form.Check
        type="checkbox"
        label={nomeDoLabel(i, labels)}
        name={nomeDoCheckbox(i, names)}
        checked={checkboxes[nomeDoCheckbox(i, names)]}
        onChange={handleCheckboxChange}
        className="checkbox-label"
      />
    )
  }

  return checkboxesToRender
}
//-------------------------------------------------------------------------------------------------
export async function GravarNivelAcesso(
  checkboxName,
  labels,
  rota_base,
  nivelDeAcessoSelecionado,
  tenantId
) {
  let dados = {
    requisicao: 'gravar_nivel_acesso',
    id_nivel: nivelDeAcessoSelecionado,
    tela: labels[checkboxName],
    rota_base: rota_base,
    tenantId: tenantId
  }

  let url = config.host + '/config_nivel_acesso'
  let response = await fetchData(url, dados)
}
//-------------------------------------------------------------------------------------------------
export async function DeletarNivelAcesso(id) {
  let dados = {
    requisicao: 'deletar_nivel_acesso',
    id: id
  }
  let url = config.host + '/config_nivel_acesso'
  let response = await fetchData(url, dados)
}
