import React from 'react'
import { Button } from 'react-bootstrap'

const BotaoExterno = () => {
  const handleClick = () => {
    fetch(
      'https://script.google.com/macros/s/AKfycbw-DOcuqMXwRDBT1v2oOUZLnTheq5q8d8I45Ov9CwfIk9XnvdRY82tP4YRHFp7C56lR/exec',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          action: 'formatarEAtualizarPlanilha'
        })
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          alert('Ação concluída com sucesso!')
        } else {
          alert('Ocorreu um erro: ' + data.message)
        }
      })
      .catch(error => {
        console.error('Error:', error)
        alert('Ocorreu um erro.')
      })
  }

  return (
    <Button variant="primary" onClick={handleClick}>
      Formatar Planilha
    </Button>
  )
}

export default BotaoExterno
