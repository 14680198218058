import { FormControl, Button } from 'react-bootstrap'
import { DraggableContainer } from '../../../src/components/DraggableContainer/draggable-container'
import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { ShowAlertGeneric } from '../../../src/utils/form-utils.jsx'
/**
 * CustomField Component
 *
 * Este é um componente personalizado de campo de formulário para simplificar a criação
 * de campos de entrada com um botão de limpeza ("x"). O componente recebe várias propriedades,
 * como `label`, `placeholder`, `value`, `onChange`, e `onClear` para configurar seu comportamento
 * e aparência.
 *
 * Propriedades:
 * - label: O rótulo a ser exibido acima do campo de entrada quando há valor.
 * - placeholder: Texto de preenchimento para o campo de entrada.
 * - value: Valor atual do campo de entrada.
 * - onChange: Função a ser chamada quando o valor do campo de entrada muda.
 * - onClear: Função a ser chamada quando o botão de limpeza é clicado.
 *
 * Este componente também suporta outros parâmetros opcionais, como `id` e `components`,
 * para permitir uma maior flexibilidade na sua utilização.
 *
 * Autor: [Claudio Fernandes] - Data: [07/07/2023]
 */

export function CustomField({
  draggableId = null,
  components = null,
  label,
  labelVisible = false,
  placeholder,
  value,
  onChange,
  onBlur,
  onClear,
  width = 'auto', // Valor padrão, pode ser ajustado conforme necessário
  className = '',
  type = 'text',
  flex = false,
  buttonTop = '0px',
  buttonLeft = '0px',
  isEditing = false,
  onKeyDown,
  name,
  style = {},
  editable = { isEditable: true, alertMessage: 'Campo Bloqueado.' },
  positionConfig,
  maxWidth,
  setaIncremento = true
}) {
  CustomField.propTypes = {
    draggableId: PropTypes.string,
    components: PropTypes.object,
    label: PropTypes.string,
    labelVisible: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onClear: PropTypes.func,
    width: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    flex: PropTypes.bool,
    buttonTop: PropTypes.string,
    buttonLeft: PropTypes.string,
    isEditing: PropTypes.bool,
    onKeyDown: PropTypes.func,
    name: PropTypes.string,
    style: PropTypes.object,
    editable: PropTypes.object,
    positionConfig: PropTypes.object,
    maxWidth: PropTypes.string,
    setaIncremento: PropTypes.bool
  }
  const [showAlert, setShowAlert] = useState(false)

  const inputRef = useRef(null)
  const handleClear = () => {
    onClear() // Chama a função onClear passada como prop
    inputRef.current.focus() // Retorna o foco para o FormControl
  }

  const handleKeyDown = e => {
    if (!setaIncremento) {
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault()
      }
    }
    if (!editable.isEditable) {
      e.preventDefault()
      setShowAlert(true)
    } else if (onKeyDown) {
      onKeyDown(e)
    } else if (e.key === 'Enter') {
      e.preventDefault() // Prevenindo a ação padrão para a tecla Enter
    }
  }
  const renderFieldContent = () => (
    <div>
      {' '}
      <ShowAlertGeneric
        showAlert={showAlert}
        message={editable.alertMessage}
        isBlurred={true}
        setShowAlert={setShowAlert}
        variant="danger"
        className="show-alert-genericPF" // Mantém o prefixo "PF"
        fontSize="1.5rem"
        dismissible={true}
      />
      <div className="form-field-wrapper" style={{ width: width, flex: flex }}>
        {((value && value !== 0 && value !== '0') || labelVisible) && (
          <label className="label-above-field">{label}</label>
        )}

        <div className={'btnInternoLimpar ' + className}>
          <FormControl
            ref={inputRef}
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            style={{
              ...style,
              backgroundColor: isEditing ? '#E3F9E5' : 'white' // Cor de fundo condicional
            }}
            // className={className}
            onKeyDown={handleKeyDown}
            width={width}
            maxWidth={maxWidth}
          />
          <Button
            className="button-clear"
            style={{
              marginLeft: type === 'number' ? '-62px' : '-32px', // Ajuste os valores conforme necessário
              display: value ? '' : 'none',
              top: buttonTop,
              left: buttonLeft
            }}
            onClick={handleClear}
          >
            x
          </Button>
        </div>
      </div>
    </div>
  )

  if (draggableId && components) {
    return (
      <div>
        <DraggableContainer
          id={draggableId}
          components={components}
          positionConfig={positionConfig}
        >
          {renderFieldContent()}
        </DraggableContainer>
      </div>
    )
  }

  return renderFieldContent()
}
