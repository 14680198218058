import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '../../../../src/context/AuthContext.jsx'
import { fetchData } from '../../../../src/backend/model.js'
import { config } from '../../../../src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../../src/utils/form-utils.jsx'
import { Container, Spinner, Form, Row, Col, Table, Modal, Button } from 'react-bootstrap'
import { FaChevronDown } from 'react-icons/fa'
import HorarioFuncionarioModal from './HorarioFuncionarioModal'
import Banner from '../../../../src/components/pages/CadastroGeral/Banner'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { converterHorasParaHorasEMinutos } from './HorarioFuncionarioModal'

const ConfigurarFuncionariosAtivos = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [setores, setSetores] = useState([])
  const [setorSelecionado, setSetorSelecionado] = useState('')
  const [funcionarios, setFuncionarios] = useState([])
  const [funcionariosAtivos, setFuncionariosAtivos] = useState(new Set())
  const [loading, setLoading] = useState(true)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [alertVariant, setAlertVariant] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [selectedFuncionario, setSelectedFuncionario] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())

  const location = useLocation()
  const origem = location.state?.origem

  console.log('Origem:', origem)
  useEffect(() => {
    const fetchSetores = async () => {
      try {
        const url = `${config.host}/gerenciarSetores`

        const setoresResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarTodosOsSetores'
        })

        setSetores(setoresResponse.setores || [])
      } catch (error) {
        console.error('Erro ao buscar setores:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar setores')
        setShowAlert(true)
      }
    }

    fetchSetores()
  }, [RotaBase, tenantId])

  useEffect(() => {
    if (origem === 'Corte' && setores.length > 0) {
      // Encontra o setor com o nome "Corte"
      const setorCorte = setores.find(setor => setor.setor.toLowerCase() === 'corte')
      if (setorCorte) {
        setSetorSelecionado(setorCorte._id)
      }
    }
  }, [origem, setores])
  useEffect(() => {
    const fetchFuncionarios = async () => {
      if (!setorSelecionado) return
      console.log('setorSelecionado', setorSelecionado)
      setLoading(true)
      try {
        const url = `${config.host}/funcionariosAtivos`
        console.log('selectedDate 2', selectedDate)
        const funcionariosResponse = await fetchData(url, {
          tenantId,
          rota_base: RotaBase,
          requisicao: 'listarFuncionariosAtivos',
          setor: setorSelecionado,
          data: selectedDate // Envia a data selecionada
        })
        console.log('funcionariosResponse', funcionariosResponse)
        // Carrega horários de cada funcionário
        const funcionariosComHorarios = await Promise.all(
          funcionariosResponse.map(async funcionario => {
            const horarios = await buscarHorariosFuncionario(funcionario._id)
            return {
              ...funcionario,
              ...horarios
            }
          })
        )

        setFuncionarios(funcionariosComHorarios || [])
        setFuncionariosAtivos(new Set(funcionariosResponse.filter(f => f.ativo).map(f => f._id)))
        setLoading(false)
      } catch (error) {
        console.error('Erro ao buscar funcionários:', error)
        setAlertVariant('danger')
        setAlertMessage('Erro ao buscar funcionários')
        setShowAlert(true)
        setLoading(false)
      }
    }

    fetchFuncionarios()
  }, [RotaBase, tenantId, setorSelecionado, selectedDate])

  const buscarHorariosFuncionario = async funcionarioId => {
    try {
      let url = `${config.host}/horariosFuncionarios`
      const horariosResponse = await fetchData(url, {
        funcionarioId,
        requisicao: 'listarHorarioPorFuncionario',
        data: selectedDate // Envia a data selecionada para a busca dos horários
      })
      if (horariosResponse) {
        url = `${config.host}/horarioExtraFuncionarios`
        const { horaEntrada, horaSaida } = horariosResponse
        console.log('funcionarioId', funcionarioId)
        const horasExtrasResponse = await fetchData(url, {
          funcionarioId,
          requisicao: 'listarHorasExtrasPorFuncionario',
          data: selectedDate // Envia a data selecionada para a busca das horas extras
        })
        console.log('horasExtrasResponse', horasExtrasResponse)
        const { horas, minutos } = converterHorasParaHorasEMinutos(horasExtrasResponse?.horasExtras)
        console.log('horas', horas)
        console.log('minutos', minutos)
        const horasExtras = horas + ':' + minutos

        return {
          horaEntrada,
          horaSaida,
          horasExtras
        }
      } else {
        return {
          horaEntrada: '',
          horaSaida: '',
          horasExtras: 0
        }
      }
    } catch (error) {
      console.error('Erro ao buscar horários do funcionário:', error)
      return {
        horaEntrada: '',
        horaSaida: '',
        horasExtras: 0
      }
    }
  }

  const handleCheckboxChange = async funcionarioId => {
    const updatedSet = new Set(funcionariosAtivos)
    let ativo = false
    if (updatedSet.has(funcionarioId)) {
      updatedSet.delete(funcionarioId)
      ativo = false
    } else {
      updatedSet.add(funcionarioId)
      ativo = true
    }
    setFuncionariosAtivos(updatedSet)

    try {
      const url = `${config.host}/funcionariosAtivos`
      await fetchData(url, {
        tenantId,
        rota_base: RotaBase,
        requisicao: 'salvarEstadoFuncionario',
        funcionarioId,
        ativo,
        setor: setorSelecionado,
        data: selectedDate // Envia a data selecionada ao salvar o estado
      })

      setAlertVariant('success')
    } catch (error) {
      console.error('Erro ao salvar estado do funcionário:', error)
      setAlertVariant('danger')
      setAlertMessage('Erro ao atualizar estado do funcionário')
      setShowAlert(true)
    }
  }

  const handleSetorChange = event => {
    console.log('event.target.', event.target)
    setSetorSelecionado(event.target.value)
  }

  const handleShowHorarioModal = funcionario => {
    setSelectedFuncionario(funcionario)
    setShowModal(true)
  }

  const handleCloseHorarioModal = () => {
    setShowModal(false)
    setSelectedFuncionario(null)
  }

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const funcionariosAusentes = funcionarios.filter(f => !funcionariosAtivos.has(f._id))
  const funcionariosPresentes = funcionarios.filter(f => funcionariosAtivos.has(f._id))
  const titulo = `Gerenciamento de Presença${
    origem && origem.toLowerCase().includes('corte') ? ' - corte' : ''
  }`

  return (
    <Container fluid className="p-4">
      <div className="d-flex justify-content-center mb-4 align-items-center">
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Banner title={titulo} width="auto" fontSize={57} classEfeito3dMouseOver="" />
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '20px' }}>
          {!origem && (
            <div style={{ marginRight: '20px' }}>
              <label style={{ marginBottom: '8px', fontWeight: 'bold', fontSize: '1.2rem' }}>
                Selecione o Setor
              </label>
              <Form.Control
                as="select"
                value={setorSelecionado}
                onChange={handleSetorChange}
                style={{
                  width: '220px',
                  padding: '10px 30px 10px 10px',
                  fontSize: '1.1rem',
                  borderRadius: '8px',
                  border: '2px solid #007bff',
                  backgroundColor: '#f8f9fa',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                  appearance: 'none'
                }}
              >
                <option value="" disabled>
                  Selecione o setor
                </option>
                {setores.map(setor => (
                  <option key={setor._id} value={setor._id}>
                    {setor.setor}
                  </option>
                ))}
              </Form.Control>
            </div>
          )}

          <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'column' }}>
            <label style={{ marginBottom: '8px', fontWeight: 'bold', fontSize: '1.2rem' }}>
              Selecione a Data
            </label>
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="form-control"
              style={{
                width: '220px',
                padding: '10px',
                fontSize: '1.1rem',
                borderRadius: '8px',
                border: '2px solid #007bff',
                backgroundColor: '#f8f9fa',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
              }}
            />
          </div>
        </div>
      </div>

      <Row>
        <Col md={6}>
          <Banner
            title={`Ausentes (${funcionariosAusentes.length})`}
            width="100%"
            fontSize={47}
            color="#dc3545"
            classEfeito3dMouseOver=""
          />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome do Funcionário</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {funcionariosAusentes.map(funcionario => (
                <tr key={funcionario._id}>
                  <td>{funcionario.Nome}</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={false}
                      onChange={() => handleCheckboxChange(funcionario._id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col md={6}>
          <Banner
            title={`Presentes (${funcionariosPresentes.length})`}
            width="100%"
            fontSize={47}
            classEfeito3dMouseOver=""
            color="#28a745"
          />
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome do Funcionário</th>
                <th>Estado</th>
                <th>Hora de Entrada</th>
                <th>Hora de Saída</th>
                <th>Horas Extras</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {funcionariosPresentes.map(funcionario => (
                <tr key={funcionario._id}>
                  <td>{funcionario.Nome}</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={true}
                      onChange={() => handleCheckboxChange(funcionario._id)}
                    />
                  </td>
                  <td style={{ width: '140px' }}>{funcionario.horaEntrada || '--:--'}</td>
                  <td style={{ width: '123px' }}>{funcionario.horaSaida || '--:--'}</td>
                  <td style={{ width: '117px' }}>
                    {funcionario.horasExtras && !funcionario.horasExtras.includes('NaN')
                      ? `${funcionario.horasExtras}h`
                      : '--'}
                  </td>
                  <td style={{ width: '90px' }}>
                    <Button variant="primary" onClick={() => handleShowHorarioModal(funcionario)}>
                      Mudar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <HorarioFuncionarioModal
            show={showModal}
            onHide={handleCloseHorarioModal}
            funcionario={selectedFuncionario}
            selectedDate={selectedDate}
          />
        </Col>
      </Row>

      {loading && !origem && <Spinner animation="border" />}

      {showAlert && (
        <ShowAlertGeneric
          showAlert={showAlert}
          message={alertMessage}
          variant={alertVariant}
          setShowAlert={setShowAlert}
        />
      )}
    </Container>
  )
}

export default ConfigurarFuncionariosAtivos
