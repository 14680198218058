import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from 'react-query'
import { Row, Col } from 'react-bootstrap'
import SmartTable from '../../../../../../src/components/SmartTable/smartTable'
import { fetchData } from '../../../../../../src/backend/model'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import Banner from '../../../../../../src/components/pages/CadastroGeral/Banner'
import { carregaIdMarketplace } from '../../../../../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraMagalu/regra-magalu.jsx'
import { AuthContext } from '/src/context/AuthContext'

const ConfiguracaoRegrasFreteMercadoLivre = () => {
  const [regrasFormatadas, setRegrasFormatadas] = useState([{}])
  const [idMarketplace, setIdMarketplace] = useState('')
  const { tenantId } = useContext(AuthContext)
  const [ultimaRegraSelecionada, setUltimaRegraSelecionada] = useState('')

  useEffect(() => {
    async function fetchUltimaRegraSelecionada() {
      if (regrasFormatadas.length > 0 && idMarketplace) {
        try {
          let dados = {
            tenantId: tenantId,
            requisicao: 'BuscarUltimaRegraDescontoFreteMercadoLivre'
          }
          let url = config.host + '/regraDescontoFrete'

          let response = await fetchData(url, dados)

          let ultimaRegraSelecionada = response?.regraId._id

          if (ultimaRegraSelecionada) {
            setUltimaRegraSelecionada(ultimaRegraSelecionada)
          }
        } catch (error) {
          console.error('Erro ao buscar a última regra selecionada:', error)
        }
      }
    }

    fetchUltimaRegraSelecionada()
  }, [tenantId, idMarketplace, regrasFormatadas.length])

  const { data: regras, isLoading: isLoadingRegras } = useQuery(
    'regrasDescontoFrete',
    () => CarregarRegrasDescontoFreteMercadoLivre(),
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    async function carregarDados() {
      let idMarketplace = await carregaIdMarketplace('Mercado Livre')
      setIdMarketplace(idMarketplace[0]._id)
    }

    carregarDados()
  }, [])

  useEffect(() => {
    if (regras) setRegrasFormatadas(formatarRegras(regras))
  }, [regras])

  const handleSelectedRegras = async selectedItems => {
    await gravarRegraNoBanco(selectedItems)
  }

  const gravarRegraNoBanco = async regra => {
    try {
      let dados = {
        tenantId: tenantId,
        regraId: regra,
        requisicao: 'GravarAssociacaoRegrasDescontoFreteMercadoLivre'
      }
      let url = config.host + '/regraDescontoFrete'

      await fetchData(url, dados)
    } catch (error) {
      console.error('Erro ao gravar a regra no banco:', error)
    }
  }

  function formatarRegras(regras) {
    const regrasFormatadas = regras.map(regra => {
      return {
        ...regra,
        descricao: `Categoria ${regra.categoria}`,
        desconto: `${regra.desconto} %`
      }
    })

    return regrasFormatadas
  }

  return (
    <div
      className="d-flex align-items-center form-container"
      style={{ height: '100vh', position: 'relative', paddingTop: '16px' }}
    >
      <div className="text-center mx-auto" style={{ maxWidth: '2000px' }}>
        <div
          style={{
            position: 'absolute',
            top: 16,
            marginTop: '20px',
            left: '50%',
            transform: 'translateX(-50%)'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <img src={logoMercadoLivre} alt="Logo" className="logo-mercadolivre" /> */}
            <div style={{ marginLeft: '16px' }}>
              <Banner
                title="Configuraçao de Regra de frete Mercado Livre"
                color="#2e8b57"
                fontSize={55}
              />
            </div>
          </div>
        </div>

        <Row style={{ maxWidth: '1100px', margin: 'auto', paddingTop: '20px' }}>
          <Col md={12} className="mb-3">
            <h5 style={{ marginTop: '30px' }}>Configuração de Regra de Desconto de Frete</h5>
            {regrasFormatadas && (
              <SmartTable
                data={regrasFormatadas}
                camposLabels={[
                  { field: 'descricao', label: 'Descrição da Regra' },
                  { field: 'desconto', label: 'Desconto' }
                ]}
                fixedHeight="300px"
                tituloProps={{
                  title: 'Regras Disponíveis',
                  color: '#2e8b57',
                  fontSize: 30
                }}
                onSelectedItemsChange={handleSelectedRegras}
                radioBox={true}
                pesquisa={'off'}
                selectedItems={ultimaRegraSelecionada}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ConfiguracaoRegrasFreteMercadoLivre

//-------------------------------------------------------------------------------------------------
export async function CarregarRegrasDescontoFreteMercadoLivre() {
  try {
    let dados = {
      requisicao: 'CarregarRegrasDescontoFreteMercadoLivre'
    }

    let url = config.host + '/regraDescontoFrete'

    let response = await fetchData(url, dados)

    return response
  } catch (error) {
    console.error('Erro ao carregar regras de desconto do Mercado Livre:', error)
  }
}
//-------------------------------------------------------------------------------------------------
