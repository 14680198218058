// Banner.js

import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // Importa estilos básicos do carousel

function Banner() {
  return (
    <div>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={true}
        showArrows={true}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
      >
        {/* Slide 1 com altura maior */}
        <div className="banner-slide large">
          <div className="banner-caption">
            <h1 style={{ color: '#0d47a1', fontSize: '88px' }}>Atendimento</h1>
            <p style={{ color: '#5E35B1', fontSize: '39px' }}>
              Um bom atendimento faz toda a diferença.
            </p>
          </div>
        </div>

        {/* Slide 2 */}
        <div className="banner-slide">
          <div className="banner-caption">
            <h1 style={{ color: '#0d47a1', fontSize: '88px' }}>Modelagem</h1>
            <p style={{ color: '#FF7043', fontSize: '39px' }}>
              Uma modelagem bem feita é a chave para o sucesso.
            </p>
          </div>
        </div>

        {/* Slide 3 */}
        <div className="banner-slide">
          <div className="banner-caption">
            <h1 style={{ color: '#0d47a1', fontSize: '88px' }}>Corte</h1>
            <p style={{ color: '#039BE5', fontSize: '39px' }}>
              Um corte preciso garante a qualidade do produto final.
            </p>
          </div>
        </div>

        {/* Slide 4 */}
        <div className="banner-slide">
          <div className="banner-caption">
            <h1 style={{ color: '#0d47a1', fontSize: '88px' }}>Digitalização</h1>
            <p style={{ color: '#7CB342', fontSize: '39px' }}>
              A digitalização agiliza o processo e reduz o erro humano.
            </p>
          </div>
        </div>

        {/* Slide 5 */}
        <div className="banner-slide">
          <div className="banner-caption">
            <h1 style={{ color: '#0d47a1', fontSize: '88px' }}>Plotagem</h1>
            <p style={{ color: '#F4511E', fontSize: '39px' }}>
              A plotagem garante a precisão e a qualidade na reprodução de projetos.
            </p>
          </div>
        </div>
      </Carousel>
    </div>
  )
}

export default Banner
