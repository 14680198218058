import { useState } from 'react'
import { Button, Row, Col, FormGroup } from 'react-bootstrap'
import { CustomField } from '/src/components/CustomField/custom-field'
//import Select from 'react-select'
import Banner from '/src/components/pages/CadastroGeral/Banner'
import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import { config } from '../../../../../../src/backend/configConexaoGeral'
import { fetchData } from '/src/backend/model'
import logoMagalu from '../../../../components/pages/Regras/RegraMagalu/logo.jpeg'
import logoAmazon from '/PRECO_FACIL_MARKET/src/components/pages/Regras/RegraMagalu/amazon.jpeg'
import logoMercadoLivre from '/PRECO_FACIL_MARKET/src/components/pages/Regras/RegraMagalu/mercadolivre.png'
import { useParams } from 'react-router-dom'
const CadastroRegrasPesoFrete = () => {
  const { marketplace } = useParams()
  const [formData, setFormData] = useState({
    limite_inferior: '',
    limite_superior: '',
    valor_frete: '',
    valor_minimo_produto: ''
  })

  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [corAlerta, setCorAlerta] = useState('')

  const handleChange = e => {
    const { name, value } = e.target

    setFormData(prevState => ({
      ...prevState,
      [name]: value.replace(/[^0-9,.]+/g, '').replace(',', '.')
    }))
  }

  const handleSubmit = async event => {
    event.preventDefault()
    // Implementar validação e submissão dos dados
    let dados = {
      ...formData,
      requisicao: 'GravarRegraPesoFrete',
      marketplace: marketplace
    }
    let url = config.host + '/regraDescontoFrete'

    try {
      let response = await fetchData(url, dados)

      if (response.message === 'Gravado') {
        //limpo os campos
        setFormData({
          limite_inferior: '',
          limite_superior: '',
          valor_frete: '',
          valor_minimo_produto: ''
        })

        setAlertMessage('Regra de peso no frete cadastrada com sucesso.')
        setCorAlerta('success')
        setShowAlert(true)
      }
    } catch (error) {
      setAlertMessage('Erro ao cadastrar regra de peso no frete.')
      setCorAlerta('danger')
      setShowAlert(true)
    }
  }

  function simplifyURL(url) {
    // Esta regex extrai o protocolo, o host, e o último segmento da URL que é o nome do arquivo
    const regex = /(http:\/\/[^\/]+)\/.*\/([^\/?]+(\?.*)?)$/
    const match = url.match(regex)
    if (match) {
      // Retorna o protocolo e host combinados com o nome do arquivo extraído
      return `${match[1]}/${match[2]}`
    }
    return url // Retorna a URL original se a regex não encontrar correspondências
  }
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100px'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={
              marketplace === 'magalu'
                ? simplifyURL(logoMagalu)
                : marketplace === 'mercadolivre'
                ? simplifyURL(logoMercadoLivre)
                : simplifyURL(logoAmazon)
            }
            alt="Logo"
            className="logo-magalu"
            style={{ width: '153px', height: '69px' }}
          />

          <div style={{ marginLeft: '16px' }}>
            <Banner
              title="Cadastro de Regras de Peso no Frete"
              color="#2e8b57"
              classEfeito3dMouseOver=""
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ height: '100vh', position: 'relative', marginTop: '20px' }}
      >
        <FormGroup className="mx-auto" style={{ maxWidth: '500px' }}>
          {showAlert && (
            <ShowAlertGeneric
              showAlert={showAlert}
              message={alertMessage}
              setShowAlert={setShowAlert}
              variant={corAlerta}
              className="show-alert-genericPF"
              fontSize="1.5rem"
              dismissible={false}
              isBlurred={true}
            />
          )}
          <form onSubmit={handleSubmit} className="form-fields-container">
            <Row>
              <Col md={10} className="mb-5">
                <CustomField
                  label="Limite Inferior"
                  placeholder="Digite o Limite Inferior"
                  name="limite_inferior"
                  value={formData.limite_inferior}
                  onChange={handleChange}
                  onClear={() => setFormData({ ...formData, limite_inferior: '' })}
                  className="Efeito3dMouseOver-class"
                />
              </Col>
              <Col md={10} className="mb-5">
                <CustomField
                  label="Limite Superior"
                  placeholder="Digite o Limite Superior"
                  name="limite_superior"
                  value={formData.limite_superior}
                  onChange={handleChange}
                  onClear={() => setFormData({ ...formData, limite_superior: '' })}
                  className="Efeito3dMouseOver-class"
                />
              </Col>
              <Col md={10} className="mb-5">
                <CustomField
                  label="Valor do frete" // Rótulo do campo
                  placeholder="Digite o Valor do Frete" // Texto de preenchimento
                  name="valor_frete" // Nome do campo, usado para identificação em eventos
                  value={formData.valor_frete} // Valor atual do campo, vinculado ao estado do componente
                  onChange={handleChange} // Função chamada quando o valor do campo muda
                  className="Efeito3dMouseOver-class" // Classe CSS para estilização
                  onClear={() => setFormData({ ...formData, valor_frete: '' })} // Função para limpar o campo
                  width="100%" // Largura do campo, pode ser ajustado conforme necessário
                />
              </Col>
              {(marketplace === 'amazon' || marketplace === 'mercadolivre') && (
                <Col md={10} className="mb-5">
                  <CustomField
                    label="Valor minimo do Produto" // Rótulo do campo
                    placeholder="Digite o Valor minimo do produto" // Texto de preenchimento
                    name="valor_minimo_produto" // Nome do campo, usado para identificação em eventos
                    value={formData.valor_minimo_produto} // Valor atual do campo, vinculado ao estado do componente
                    onChange={handleChange} // Função chamada quando o valor do campo muda
                    className="Efeito3dMouseOver-class" // Classe CSS para estilização
                    onClear={() => setFormData({ ...formData, valor_minimo_produto: '' })} // Função para limpar o campo
                    width="100%" // Largura do campo, pode ser ajustado conforme necessário
                  />
                </Col>
              )}
            </Row>
            <Button type="submit" className="btn btn-success" style={{ marginTop: '20px' }}>
              Cadastrar
            </Button>
          </form>
        </FormGroup>
      </div>
    </div>
  )
}

export default CadastroRegrasPesoFrete
