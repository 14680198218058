// src/components/CorteDescription.jsx

import React from 'react'
import './CorteDescription.css' // Importando o arquivo CSS

const CorteDescription = () => {
  return (
    <div className="corte-description">
      <h2 className="corte-title">Detalhes do Módulo de Corte</h2>

      {/* Seção de Gerenciamento de Clientes e Funcionários */}
      <section className="corte-section">
        <h3>Cadastro e Gerenciamento de Clientes e Funcionários</h3>
        <p>
          O módulo de corte permite o cadastro de novos clientes e funcionários, bem como a pesquisa
          e edição de informações já cadastradas. Isso facilita o controle de presença e o
          gerenciamento eficiente das operações.
        </p>
        <ul className="corte-list">
          <li>Cadastro de Clientes</li>
          <li>Pesquisa e Edição de Clientes</li>
          <li>Cadastro de Setores e Cargos</li>
          <li>Definição de Horas de Trabalho por Cargo e Setor</li>
          <li>Gerenciamento de Presença dos Funcionários</li>
        </ul>
      </section>

      {/* Seção de Gerenciamento de Cortes */}
      <section className="corte-section">
        <h3>Gerenciamento de Cortes</h3>
        <p>
          O sistema permite cadastrar e gerenciar cortes de forma completa, desde o registro inicial
          até a finalização. A funcionalidade de edição garante que os dados possam ser ajustados
          conforme necessário.
        </p>
        <ul className="corte-list">
          <li>Cadastro de Corte com data prevista</li>
          <li>Registro de detalhes após execução</li>
          <li>Finalização do corte</li>
          <li>Configuração de Valor de Embalagens</li>
          <li>Pesquisa e Edição de Cortes</li>
        </ul>
      </section>

      {/* Seção de Pontualidade das Entregas */}
      <section className="corte-section">
        <h3>Pontualidade das Entregas</h3>
        <p>
          Esta seção é crítica para acompanhar o desempenho das operações de corte em relação ao
          cumprimento de prazos estabelecidos. O sistema oferece métricas que indicam o percentual
          de cortes entregues no prazo, o número total de cortes realizados, os dias trabalhados, e
          a média de cortes por dia.
        </p>
        <ul className="corte-list">
          <li>
            <strong>Entregas no prazo:</strong> Reflete a eficiência do processo em cumprir prazos.
            Um valor de 0.00% indica que nenhum corte foi entregue no prazo.
          </li>
          <li>
            <strong>Número total de cortes:</strong> Mostra o volume de cortes concluídos no
            período.
          </li>
          <li>
            <strong>Total de dias trabalhados:</strong> Indica os dias úteis contabilizados no
            período de análise.
          </li>
          <li>
            <strong>Média de cortes por dia:</strong> Calcula o desempenho diário da produção.
          </li>
        </ul>
        <p>
          A pontualidade é essencial para garantir a satisfação do cliente e a eficiência da
          produção. Se o percentual de entregas no prazo for baixo, pode ser necessário revisar o
          planejamento e o processo de alocação de recursos.
        </p>
      </section>

      {/* Seção da Tela Financeira */}
      <section className="corte-section">
        <h3>Tela Financeira</h3>
        <p>
          O módulo oferece uma visão completa das finanças associadas ao corte, permitindo controlar
          os valores pendentes e processar pagamentos de forma detalhada e transparente.
        </p>
        <ul className="corte-list">
          <li>Panorama Geral de Cortes Pendentes</li>
          <li>Detalhamento de Pagamentos por Cliente</li>
          <li>Cadastro de Pagamentos (Parciais ou Totais)</li>
          <li>Relatórios Detalhados de Pagamentos</li>
        </ul>
      </section>

      {/* Seção de Relatórios */}
      <section className="corte-section">
        <h3>Relatórios do Módulo de Corte</h3>
        <p>
          O módulo de corte oferece uma série de relatórios analíticos que auxiliam na tomada de
          decisões estratégicas e no acompanhamento financeiro das operações.
        </p>

        {/* Relatório Resumo de Corte */}
        <div className="relatorio">
          <h4>Resumo de Corte</h4>
          <p>
            O <strong>Resumo de Corte</strong> oferece uma visão geral de todas as operações de
            corte realizadas em um determinado período, incluindo o número de cortes, folhas
            cortadas, peças produzidas, e valor total dos cortes.
          </p>
        </div>

        {/* Relatório Análise de Receita */}
        <div className="relatorio">
          <h4>Análise de Receita</h4>
          <p>
            A <strong>Análise de Receita</strong> detalha a receita gerada por cada cliente, com
            gráficos que mostram a distribuição da receita ao longo do tempo. Esse relatório ajuda a
            identificar os clientes mais lucrativos e os períodos de maior faturamento.
          </p>
        </div>

        {/* Relatório Análise de Lucro Bruto */}
        <div className="relatorio">
          <h4>Análise de Lucro Bruto</h4>
          <p>
            A <strong>Análise de Lucro Bruto</strong> calcula o lucro obtido após a dedução dos
            custos totais, apresentando gráficos de lucro por cliente e por período. Esse relatório
            é essencial para avaliar a eficiência operacional e a rentabilidade do setor de corte.
          </p>
        </div>
      </section>

      {/* Nova Seção: Gerenciamento de Presença */}
      <section className="corte-section">
        <h3>Gerenciamento de Presença</h3>
        <p>
          Esta funcionalidade permite monitorar e controlar a presença dos funcionários no setor de
          corte. Através desta ferramenta, é possível registrar e gerenciar a presença diária dos
          funcionários, configurar horários de entrada e saída, além de controlar horas extras.
        </p>
        <ul className="corte-list">
          <li>
            <strong>Controle de Presença Diária:</strong> Marcação dos funcionários presentes e
            ausentes em uma data específica.
          </li>
          <li>
            <strong>Configuração de Horários:</strong> Possibilidade de definir horários
            personalizados de entrada e saída para cada funcionário, com opção de usar horários
            padrão do cargo.
          </li>
          <li>
            <strong>Gestão de Horas Extras:</strong> Registro e cálculo automático de horas extras
            trabalhadas, com ferramentas para ajustar horas e minutos adicionais.
          </li>
          <li>
            <strong>Visualização de Funcionários por Status:</strong> Listas separadas de
            funcionários presentes e ausentes, facilitando o monitoramento da equipe.
          </li>
          <li>
            <strong>Seleção por Setor e Data:</strong> Filtro de funcionários por setor (por
            exemplo, corte) e data específica, permitindo um controle preciso e organizado.
          </li>
        </ul>
        <p>
          O Gerenciamento de Presença é essencial para acompanhar a assiduidade dos funcionários,
          planejar a alocação de recursos humanos e assegurar o cumprimento das jornadas de trabalho
          estabelecidas.
        </p>
      </section>

      {/* Seção de Cadastro de Pagamentos de Funcionários */}
      <section className="corte-section">
        <h3>Cadastro de Pagamentos de Funcionários</h3>
        <p>
          Este módulo permite o registro e gerenciamento de pagamentos realizados aos funcionários,
          proporcionando controle detalhado sobre salários, horas extras e outras remunerações. A
          funcionalidade é essencial para manter um controle financeiro eficiente, garantindo
          transparência e precisão nos registros.
        </p>
        <ul className="corte-list">
          <li>
            <strong>Registro de Pagamentos:</strong> Possibilidade de cadastrar pagamentos com
            detalhes como funcionário, valor, data e observações.
          </li>
          <li>
            <strong>Resumo de Trabalho por Funcionário:</strong> Visualização do total de dias
            trabalhados, horas normais, horas extras, valores correspondentes e total a pagar.
          </li>
          <li>
            <strong>Gestão de Pagamentos Pendentes e Históricos:</strong> Gerenciamento de
            pagamentos já realizados, com opção de editar detalhes ou deletar, respeitando
            restrições para pagamentos finalizados.
          </li>
          <li>
            <strong>Detalhamento de Trabalho por Dia:</strong> Consulta detalhada das horas
            trabalhadas por dia, permitindo selecionar dias específicos para pagamento e calcular o
            valor total automaticamente.
          </li>
          <li>
            <strong>Filtros por Período:</strong> Análise de dados em intervalos de datas
            específicos ou em todo o período, facilitando o acompanhamento e planejamento
            financeiro.
          </li>
          <li>
            <strong>Relatórios de Pagamentos:</strong> Geração de relatórios dos pagamentos
            registrados, auxiliando na tomada de decisões e auditorias internas.
          </li>
        </ul>
        <p>
          Com esta ferramenta, a empresa pode assegurar que os funcionários sejam remunerados
          corretamente, além de manter registros organizados para fins contábeis e legais.
        </p>
      </section>
      {/* Seção de Edição e Suporte */}
      <section className="corte-section">
        <h3>Edição e Suporte</h3>
        <p>
          Tanto os cortes quanto os cadastros de clientes podem ser editados conforme necessário.
          Para mais informações sobre o sistema, os usuários podem navegar pelas funcionalidades ou
          entrar em contato com os canais de suporte da empresa.
        </p>
      </section>
    </div>
  )
}

export default CorteDescription
