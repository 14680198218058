//App.jsx
import React, { Suspense } from 'react'
import RouteHandler from './routes/RouteHandler'

function App() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<div>Carregando...</div>}>
        <RouteHandler />
      </Suspense>
    </ErrorBoundary>
  )
}
export default App

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Atualize o estado para que a próxima renderização mostre a UI alternativa.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // Você também pode logar o erro para um serviço de relatório de erros
    console.error('ErrorBoundary caught an error', error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // Você pode renderizar qualquer UI alternativa
      return <h1>Algo deu errado.</h1>
    }

    return this.props.children
  }
}
