import React, { useState, useEffect, useRef } from 'react'
import { SalvarEdicao, pegarDados } from '../../../../src/backend/model'

import sheetsConfig from '../../../../src/config/sheetsConfig'
import {
  DraggableContainer,
  DroppableArea,
  useArrastar
} from '../../../../src/components/DraggableContainer/draggable-container'
import { Col, Container } from 'react-bootstrap'
import {
  validateAndShowAlerts,
  useDynamicState,
  showAlertFunction,
  getValidationMessages,
  mapCategoriasToOptions,
  mapGrausToOptions
} from '../../../../src/utils/form-utils'
import { CustomField } from '../../../../src/components/CustomField/custom-field'
import { CustomAlert } from '../../../../src/components/CustomAlert/custom-alert'
import { ButtonWithAlerts } from '../../../../src/components/ButtonWithAlerts/button-with-alerts'
import Banner from '../../../../src/components/pages/CadastroGeral/Banner'
import { DatePickerCustom } from '../../../../src/utils/date-utils'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { useQuery, useQueryClient } from 'react-query'

import { CustomSelectWithLoading } from '../../../../src/components/SelectWithLoading/SelectWithLoading'
import { CarregaCategorias } from '../../../../src/components/pages/CadastroProduto/cadastro-produto.jsx'
import { converterPreco } from '../../../../src/utils/form-utils'
import { AuthContext } from '../../../../src/context/AuthContext'
import { useContext } from 'react'
const CadastroPrecosModelagem = () => {
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [state, setDynamicState] = useDynamicState()
  const startId = 8

  const fields = [
    { label: 'Descriçao', maxWidth: '20%', validate: true },
    { label: 'Preço', maxWidth: '10%', validate: true }
  ]

  const [selectedDates, setSelectedDates] = useState({
    data: new Date()
  })

  const datePickerRef = useRef(null)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (datePickerRef.current && datePickerRef.current.clear) {
        datePickerRef.current.clear()
      }
    }, 100)

    return () => clearTimeout(timer)
  }, [])
  //---------------------------------------------------------

  const [isVisible, setIsVisible] = useState(false)
  const [showAlertGravando, setShowAlertGravando] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [isDragEnabled, setIsDragEnabled] = useState(false)
  const [dadosEdicao, setDadosEdicao] = useState({ grau: '' })
  const [todasColunasSheet, set_todasColunasSheet] = useState()
  const [SelectedCategoria, setSelectedCategoria] = useState([])
  const [SelectedGrau, setSelectedGrau] = useState([])
  const [configKeySheet, set_configKeySheet] = useState()
  const [tab, set_tab] = useState()
  const queryClient = useQueryClient()

  const [positionConfig, setPositionConfig] = useState({
    1: { x: 699, y: -9 },
    2: { x: 760, y: 85 },
    3: { x: 1068, y: 297 },
    4: { x: 1045, y: 191 },
    5: { x: 643, y: 189 },
    6: { x: 896, y: 468 },
    7: { x: 0, y: 0 },
    8: { x: 641, y: 295 },
    9: { x: 638, y: 391 },
    10: { x: 0, y: 0 },
    11: { x: 0, y: 0 }
  })

  //--------------------------------------------------------------------------------------
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [showAlert])

  //----------------------------- SETOR DE ARRASTO--------------------------------------------
  const { handleDrop, components, buttonPosition } = useArrastar(6, 11)
  //------------------------------------------------------------------------------------
  const {
    data: categoriasBrutas,
    isLoading: isLoadingCategorias,
    isError: isErrorCategorias
  } = useQuery('categorias', () => CarregaCategorias(tenantId, RotaBase), {
    // pegando as categorias
    staleTime: 5000
  })

  const categorias = mapCategoriasToOptions(isLoadingCategorias, categoriasBrutas)

  const handleSelectedCategoriaChange = selectedOption => {
    setSelectedCategoria(selectedOption)
  }
  const handleSelectedGrauChange = selectedOption => {
    setSelectedGrau(selectedOption)
  }

  const handleCloseAlert = () => {
    setShowAlert(false)
  }

  const limparCampos = () => {
    setSelectedDates({
      data: null
    })

    // Definir campos específicos no state de volta para uma string vazia
    let newState = {}
    fields.forEach(field => {
      newState[field.label] = ''
    })
    setDynamicState(newState) // Atenção para esta linha!
  }

  //---------------------------------------------------------------------
  const validateFields = () => {
    const specialValidations = [
      {
        key: 'data',
        condition: !selectedDates.data,
        message: 'Insira a Data do Cadastro!'
      }
    ]
    const invalidFields = specialValidations.filter(val => val.condition).map(val => val.key)
    return invalidFields
  }

  //-----------------------------------------------------------------------------
  const [alerts, setAlerts] = useState({})

  //------------------------------------------------------------------
  const camposEspeciais = validateFields()
  const handleSubmit = async () => {
    if (
      !validateAndShowAlerts(getValidationMessages(fields, state, camposEspeciais), (k, v) =>
        showAlertFunction(setAlerts, k, v)
      )
    ) {
      // A validação falhou, não prosseguir com o salvamento
      return
    }

    setShowAlertGravando(true)
    let preco = converterPreco(state['Preço'])

    const dados = {
      data: selectedDates.data,
      id_categoria: SelectedCategoria.value,
      id_grau: SelectedGrau.value,
      descricao: state['Descriçao'],
      preco: preco,
      tenantId: tenantId
    }

    try {
      // Salvar na planilha

      await SalvarEdicao(
        {
          todasColunasSheet: sheetsConfig.modelagem.fields.cadastro_preco_molde,
          configKeySheet: 'modelagem',
          tab: 'cadastro_preco_molde',
          cadastroDados: dados,

          isEditing: false,
          requisicao: 'enviar'
        },
        {
          id_pk: 0,
          rota: 'funcoesGenericas'
        }
      )
        .then(() => {
          queryClient.invalidateQueries('precos') // isso faz com que recarregue os dados da sheet e com isso re-renderiza a Table
        })
        .catch(error => {
          console.error('Erro ao salvar edição:', error)
        })

      // Exibir mensagem de sucesso
      setShowAlertGravando(false)
      setShowAlert(true)

      limparCampos()
    } catch (error) {
      // Tratar erro
      console.error(error)
    }
  }

  const handleChange = (e, field) => {
    setDynamicState(field, e.target.value)
  }
  //---------------------------------------------------------------------------
  const {
    data: grausBrutos,
    isLoading: isLoadingGraus,
    isError: isErrorGraus
  } = useQuery('graus', () => CarregaGrausDeDificuldade(tenantId), {
    // pegando os graus
    staleTime: 5000
  })
  const [updatedData, setUpdatedData] = useState(grausBrutos)
  const graus = mapGrausToOptions(isLoadingGraus, grausBrutos)
  //--------------------------------------------------------------------------------
  const {
    data: precosBrutos,
    isLoading: isLoadingPrecos,
    isError: isErrorPrecos
  } = useQuery('precos', () => CarregaPrecosModelagem(tenantId), {
    // pegando os graus
    staleTime: 5000
  })

  useEffect(() => {
    set_todasColunasSheet(sheetsConfig.modelagem.fields.cadastro_preco_molde)
    set_configKeySheet('modelagem')
    set_tab('cadastro_preco_molde')
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      {/* <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}> */}
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="enableDrag"
          checked={isDragEnabled}
          onChange={() => setIsDragEnabled(!isDragEnabled)}
        />
        <label className="form-check-label" htmlFor="enableDrag">
          Ajustar layout
        </label>
      </div>
      <Container fluid className={`my-6 ${isVisible ? '' : 'not-rendered'}`}>
        <DroppableArea onDrop={isDragEnabled ? handleDrop : () => {}}>
          <Banner
            title="MODELAGEM"
            draggableId={1}
            components={components}
            width={'600px'}
            positionConfig={positionConfig}
          />

          <DraggableContainer
            id={2}
            components={components}
            width={450}
            positionConfig={positionConfig}
          >
            <h1 style={{ fontSize: '3rem' }}>Cadastro de Preços</h1>
          </DraggableContainer>
          {/*--------------------------- SEÇAO DOS ALERTAS (N ALERTAS)---------------------------------*/}
          <div style={{ position: 'relative' }}>
            <div className="AlertClass">
              {Object.keys(getValidationMessages(fields, state, camposEspeciais)).map(
                (key, index) => (
                  <CustomAlert
                    key={index}
                    message={getValidationMessages(fields, state, camposEspeciais)[key]}
                    showAlert={alerts[key]}
                    setShowAlert={value => showAlertFunction(setAlerts, key, value)}
                  />
                )
              )}
            </div>
          </div>

          {/* <Form style={{ width: '85%' }}> */}
          {/*--------------------------- SEÇAO DAS DATAS ---------------------------------*/}

          <DatePickerCustom
            draggableId={3}
            components={components}
            id="data"
            selected={selectedDates.data}
            onChange={setSelectedDates}
            placeholder="Data cadastro"
            positionConfig={positionConfig}
          />

          <CustomSelectWithLoading
            draggableId={4}
            components={components}
            id="categoria"
            options={categorias}
            isClearable={true}
            placeholder="Selecione a categoria"
            onChange={handleSelectedCategoriaChange}
            value={SelectedCategoria}
            isLoading={isLoadingCategorias}
            showLoading={true}
            width={300}
            positionConfig={positionConfig}
          />

          <CustomSelectWithLoading
            draggableId={5}
            components={components}
            id="grau"
            options={graus}
            isClearable={true}
            placeholder="Selecione o Grau de dificuldade"
            onChange={handleSelectedGrauChange}
            value={SelectedGrau}
            isLoading={isLoadingGraus}
            showLoading={true}
            width={300}
            positionConfig={positionConfig}
          />

          {/*--------------------------- SEÇAO DOS EDITS (1 EDIT)----------------------------------*/}
          {fields.map((fieldObj, index) => (
            <Col key={fieldObj.label} style={{ maxWidth: fieldObj.maxWidth }}>
              <CustomField
                draggableId={startId + index}
                components={components}
                label={fieldObj.label}
                placeholder={fieldObj.label}
                value={state[fieldObj.label] || ''}
                onChange={e => handleChange(e, fieldObj.label)}
                onClear={() => setDynamicState(fieldObj.label, '')}
                positionConfig={positionConfig}
              />
            </Col>
          ))}
          {/* </Form> */}
          {/* ---------------------------------------------------------------------------------- */}

          {/* <CustomTable
          draggableId={7}
          components={components}
          isDeleteEdit={{
            configKeySheet: configKeySheet,
            exclusaoConfig: {
              tab: tab,
            },
          }}
          data={precosBrutos}
          headerCells={[
            {
              label: 'Categoria',
              dataKey: 'id_categoria',
              width: '30%',
              fetchFunction: pegaDado,
              dados: categorias,
              dadoRetorno: 'label',
              idDado: 'value',
            },
            {
              label: 'Molde',
              dataKey: 'descricao',
              width: '23%',
              edit: true,
            },
            {
              label: 'Grau',
              dataKey: 'id_grau',
              width: '16%',
              fetchFunction: pegaDado,
              dados: graus,
              dadoRetorno: 'label',
              idDado: 'value',
            },
            { label: 'Preço', dataKey: 'preco', width: '10%', edit: true },
          ]}
          title='Preços das Modelagens'
          fontSizeTitle={25}
          dadosEdicao={dadosEdicao}
          setDadosEdicao={setDadosEdicao}
          setUpdatedData={setUpdatedData}
          updatedData={updatedData}
          todasColunasSheet={todasColunasSheet}
          width={'1050px'}
          invalidateQueries='precos'
          maxHeight={600}
        /> */}

          {/*--------------------------- SEÇAO DO BOTÃO DE SALVAR ----------------------------------*/}

          <ButtonWithAlerts
            showAlertGravando={showAlertGravando}
            showAlert={showAlert}
            components={components}
            handleSubmit={handleSubmit}
            buttonPosition={buttonPosition}
            handleCloseAlert={handleCloseAlert}
            draggableId={6}
            positionConfig={positionConfig}
          />
        </DroppableArea>
      </Container>
    </DndProvider>
  )
}
export default CadastroPrecosModelagem

export async function CarregaGrausDeDificuldade(tenantId) {
  const sheetUrl = sheetsConfig['modelagem'].sheetUrl
  const paginaGraus = sheetsConfig['modelagem'].tabs.grau_dificuldade_mod

  const dados = {
    link: sheetUrl,
    pagina1: paginaGraus,
    celulas: 'A2:C',
    requisicao: 'requisitar',
    query: { tenantId: tenantId },
    rota: 'cadastroServicoModelagem',
    verificarDados: tratarDadosGraus,
    colunaUltimaLinha: 'A',
    tituloColunaUltimaLinha: 'id',
    filtro: '',
    isFrontendCall: true
  }

  const response = await pegarDados(dados)

  const produtosEncontrados = response

  return produtosEncontrados
}
//-------------------------------------------------------------------------------------------
function tratarDadosGraus(dados) {
  const grausData = dados

  const graus = grausData.slice(0).map(grau => {
    return {
      id: grau.id,
      grau: grau.grau,
      data: grau.data
    }
  })

  return graus
}
export async function CarregaPrecosModelagem(tenantId) {
  const sheetUrl = sheetsConfig['modelagem'].sheetUrl
  const collection = sheetsConfig['modelagem'].tabs.cadastro_preco_molde

  const dados = {
    link: sheetUrl,
    pagina1: collection,
    celulas: 'A2:F',
    requisicao: 'requisitar',
    query: { tenantId: tenantId },
    rota: 'cadastroServicoModelagem',
    verificarDados: tratarDadosPrecosModelagem,
    colunaUltimaLinha: 'A',
    tituloColunaUltimaLinha: 'id',
    filtro: '',
    isFrontendCall: true
  }

  const response = await pegarDados(dados)

  const produtosEncontrados = response

  return produtosEncontrados
}
//-------------------------------------------------------------------------------------------
function tratarDadosPrecosModelagem(dados) {
  const precosData = dados

  const precos = precosData.slice(0).map(preco => {
    return {
      id: preco.id,
      data: preco.data,
      id_categoria: preco.id_categoria,
      id_grau: preco.id_grau,
      descricao: preco.descricao,
      preco: preco.preco
    }
  })

  return precos
}
