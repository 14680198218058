import { useEffect, useMemo } from 'react'
import { useTodosMunicipiosBrasil } from './municipios' // comentei so pra compilar mais rapido, tem que descomentar depois
import {
  getAllUfs,
  lookupAddress,
  lookupCep,
  checkEnderecoExists,
  verificaBairroNaCidade,
  getUfValueByName,
  getUfNameByValue
} from './address-utils'

import { SelectAllFromTablePorId } from '/src/backend/model'
import { filterMunicipiosByCidade } from '/src/components/pages/CadastroGeral/cadastro/cadastro-fields.jsx'

export function useUsersEfects(varsUsersEfeccts) {
  if (!varsUsersEfeccts) {
    // Você pode lançar um erro personalizado ou simplesmente retornar para evitar a execução.
    console.error('varsUsersEfeccts is null')
    return
  }
  const {
    isEditing,
    isCadastroGeral,
    isPlotagem,
    isCorte,
    isModelagem,
    nome,
    setNome,
    sobrenome,
    setSobrenome,
    cpf,
    setCpf,
    cnpj,
    setCnpj,
    isValidCpf,
    setisValidCpf,
    tipoPessoa,
    setTipoPessoa,
    categoria,
    setCategoria,
    sexo,
    setSexo,
    telefone,
    setTelefone,
    email,
    setEmail,
    originalEmail,
    setOriginalEmail,
    isValidEmail,
    setisValidEmail,
    cep,
    setCep,
    isValidCep,
    setisValidCep,
    endereco,
    setEndereco,
    numero,
    setNumero,
    complemento,
    setComplemento,
    bairro,
    setBairro,
    cidade,
    setCidade,
    uf,
    setUf,
    ufs,
    setUfs,
    isBlurred,
    setisBlurred,
    showAlert_cpf,
    setShowAlertCPF,
    showAlert_cep,
    setShowAlertCep,
    showAlert_email,
    setShowAlertEmail,
    showAlert_tel,
    setShowAlertTel,
    buscarCepAtivo,
    setBuscarCepAtivo,
    Municipios,
    setMunicipios,
    codigoUf,
    setCodigoUf,
    todosMunicipios,
    setTodosMunicipios,
    isValidTel,
    setisValidTel,
    MunicipiosParaOSelectSource,
    setMunicipiosParaOSelectSource,
    title,
    setTitle,
    pegarCep,
    setpegarCep,
    cidadeSelecionada,
    setCidadeSelecionada,
    searchType,
    setsearchType,
    CepUpdateCounter,
    setCepUpdateCounter,
    selectedSector,
    setSelectedSector,
    isValidCnpj,
    setisValidCnpj,
    showAlert_cnpj,
    setShowAlertCNPJ,
    nomeEmpresa,
    setNomeEmpresa,
    clienteEditando,
    setClienteEditando,
    clienteId,
    clienteData,
    showAlertGravando,
    setShowAlertGravando,
    showAlert,
    setShowAlert
  } = varsUsersEfeccts
  async function setAddress(data) {
    //  setIsEditingEndereco(false)
    setpegarCep(true)

    setEndereco(data.logradouro)
    setBairro(data.bairro)

    setTodosMunicipios(useTodosMunicipiosBrasil()) // atualiza o estado

    await setUf(getUfNameByValue(data.uf))

    setCidade({ cidade: data.localidade, source: 'function' })

    setCidadeSelecionada(data.localidade) // adicionado para atualizar a cidadeSelecionada

    const municipiosDaCidade = filterMunicipiosByCidade(todosMunicipios, data.localidade, ufs)
    // filtra os municípios correspondentes à UF e cidade selecionadas
    setMunicipios(municipiosDaCidade)

    setTitle(data.localidade) // adicionado para atualizar o título
    setpegarCep(false)
  }

  useEffect(() => {
    async function fetchData() {
      if (isValidCep && cep.length === 9 && searchType === 'cep') {
        const addressPromise = lookupAddress(cep)
        addressPromise
          .then(data => {
            setAddress(data)
          })
          .catch(error => {
            console.error('erro aqui ', error)
          })
      }
    }

    fetchData()
  }, [cep, isValidCep, searchType])

  useEffect(() => {
    const fetchCep = async () => {
      try {
        if (searchType === 'address' && endereco !== '' && cidade.cidade !== '' && uf !== '') {
          const data = await lookupCep(getUfValueByName(uf), cidade, endereco, setpegarCep)
          if (typeof data.erro === 'undefined' || !data.erro) {
            setCep(data[0].cep)
            setBairro(data[0].bairro)
            setisValidCep(true)
          }
          // setsearchType("idle")
        }
      } catch (error) {
        setisValidCep(false)
        setCep('')
        console.error(`Erro ao buscar CEP: ${error}`)
        //   setsearchType("idle")
      }
    }

    if (endereco !== '' && cidade && cidade.cidade !== '' && uf !== '') {
      fetchCep()
    }
  }, [endereco, cidade, uf, searchType])
  //------------------------------------------------------------
  useEffect(() => {
    async function handleMunicipioChange(cidade) {
      const enderecoExists = await checkEnderecoExists(uf, cidade, endereco)
      if (!enderecoExists) {
        // Se a rua não existir, limpe o campo da rua e o CEP.
        setEndereco('')
        setCep('')
      } else {
        // Caso contrário, verifique se o CEP digitado existe no novo município e estado.
        if (cep !== '') {
          const cepExists = await checkCepExists(uf, cidade, cep)
          if (!cepExists) {
            // Se o CEP não existir, limpe o campo do CEP.
            setCep('')
          }
        }
      }
      if (bairro !== '') {
        const bairroExiste = await verificaBairroNaCidade(uf, cidade, bairro)
        if (!bairroExiste) {
          setBairro('')
        }
      }
    }

    // Passa o valor da propriedade cidade do estado de cidade como argumento para a função.

    if (cidade && cidade.source === 'user') handleMunicipioChange(cidade)
  }, [cidade])

  //---------------------------------comentei so pra compilar mais rapido so pra desenvolvimento mas esta em uso---------------------------
  // useEffect(() => {
  //   try {
  //     import('./municipios').then(module => {
  //       setTodosMunicipios(module.useTodosMunicipiosBrasil)
  //     })
  //     setUfs(getAllUfs())
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }, [])

  useEffect(() => {
    setMunicipiosParaOSelectSource(function () {
      const nomesMunicipios = Municipios.map(municipio => [municipio.nome])

      return nomesMunicipios
    })

    setTitle('Escolha a cidade')
  }, [Municipios, cidadeSelecionada])

  useEffect(() => {
    if (isPlotagem) {
      setCategoria('Plotagem')
      setSelectedSector('plotagem') // protagem é o nome do sector no objeto sheetsConfig
    }
  }, [isPlotagem])

  useEffect(() => {
    if (isCorte) {
      setCategoria('Corte')
      setSelectedSector('cortes') // protagem é o nome do sector no objeto sheetsConfig
    }
  }, [isCorte])
  useEffect(() => {
    if (isModelagem) {
      setCategoria('Modelagem')
      setSelectedSector('modelagem') // protagem é o nome do sector no objeto sheetsConfig
    }
  }, [isModelagem])

  useEffect(() => {
    if (isCadastroGeral) {
      setSelectedSector('cadastroClienteG') // protagem é o nome do sector no objeto sheetsConfig
    }
  }, [isCadastroGeral])

  function tratarSelectAllFromTablePorId(dados) {
    const clientesData = dados

    const clientes = {
      id: clientesData[0].id,
      data: clientesData[0].data,
      nome: clientesData[0].Nome,
      empresa: clientesData[0].Empresa,
      telefone: clientesData[0].Tel,
      sobrenome: clientesData[0].Sobrenome,
      cpf: clientesData[0].CPF,
      tipoPessoa: clientesData[0].Tipo_Pessoa,
      sexo: clientesData[0].Sexo,
      email: clientesData[0].Email,
      cep: clientesData[0].Cep,
      endereco: clientesData[0].Endereco,
      numero: clientesData[0].Numero,
      complemento: clientesData[0].Complemento,
      bairro: clientesData[0].Bairro,
      cidade: clientesData[0].Cidade,
      uf: clientesData[0].UF,
      cnpj: clientesData[0].CNPJ,
      categoria_cliente: clientesData[0].Categoria_Cliente
    }

    return clientes
  }
  useEffect(() => {
    if (isEditing) {
      const fetchClientes = async () => {
        const clientes = await SelectAllFromTablePorId({
          table: 'Cad_G_Cliente',
          Id: clienteId,
          colsTab1: 'A:R',
          selectedSector: 'cadastroClienteG',
          VerificarDados: tratarSelectAllFromTablePorId
        })

        setClienteEditando(clientes)
      }
      // Chamando a função assíncrona imediatamente
      fetchClientes()
    }
  }, [clienteId, isEditing])

  return useMemos(
    isEditing,
    isCadastroGeral,
    isPlotagem,
    isCorte,
    isModelagem,
    nome,
    setNome,
    sobrenome,
    setSobrenome,
    cpf,
    setCpf,
    cnpj,
    setCnpj,
    isValidCpf,
    setisValidCpf,
    tipoPessoa,
    setTipoPessoa,
    categoria,
    setCategoria,
    sexo,
    setSexo,
    telefone,
    setTelefone,
    email,
    setEmail,
    originalEmail,
    setOriginalEmail,
    isValidEmail,
    setisValidEmail,
    cep,
    setCep,
    isValidCep,
    setisValidCep,
    endereco,
    setEndereco,
    numero,
    setNumero,
    complemento,
    setComplemento,
    bairro,
    setBairro,
    cidade,
    setCidade,
    uf,
    setUf,
    ufs,
    setUfs,
    isBlurred,
    setisBlurred,
    showAlert_cpf,
    setShowAlertCPF,
    showAlert_cep,
    setShowAlertCep,
    showAlert_email,
    setShowAlertEmail,
    showAlert_tel,
    setShowAlertTel,
    buscarCepAtivo,
    setBuscarCepAtivo,
    Municipios,
    setMunicipios,
    codigoUf,
    setCodigoUf,
    todosMunicipios,
    setTodosMunicipios,
    isValidTel,
    setisValidTel,
    MunicipiosParaOSelectSource,
    setMunicipiosParaOSelectSource,
    title,
    setTitle,
    pegarCep,
    setpegarCep,
    cidadeSelecionada,
    setCidadeSelecionada,
    searchType,
    setsearchType,
    CepUpdateCounter,
    setCepUpdateCounter,
    selectedSector,
    setSelectedSector,
    isValidCnpj,
    setisValidCnpj,
    showAlert_cnpj,
    setShowAlertCNPJ,
    nomeEmpresa,
    setNomeEmpresa,
    clienteEditando,
    setClienteEditando,
    clienteId,
    clienteData,
    showAlertGravando,
    setShowAlertGravando,
    showAlert,
    setShowAlert
  )
}

function useMemos(
  isEditing,
  isCadastroGeral,
  isPlotagem,
  isCorte,
  isModelagem,
  nome,
  setNome,
  sobrenome,
  setSobrenome,
  cpf,
  setCpf,
  cnpj,
  setCnpj,
  isValidCpf,
  setisValidCpf,
  tipoPessoa,
  setTipoPessoa,
  categoria,
  setCategoria,
  sexo,
  setSexo,
  telefone,
  setTelefone,
  email,
  setEmail,
  originalEmail,
  setOriginalEmail,
  isValidEmail,
  setisValidEmail,
  cep,
  setCep,
  isValidCep,
  setisValidCep,
  endereco,
  setEndereco,
  numero,
  setNumero,
  complemento,
  setComplemento,
  bairro,
  setBairro,
  cidade,
  setCidade,
  uf,
  setUf,
  ufs,
  setUfs,
  isBlurred,
  setisBlurred,
  showAlert_cpf,
  setShowAlertCPF,
  showAlert_cep,
  setShowAlertCep,
  showAlert_email,
  setShowAlertEmail,
  showAlert_tel,
  setShowAlertTel,
  buscarCepAtivo,
  setBuscarCepAtivo,
  Municipios,
  setMunicipios,
  codigoUf,
  setCodigoUf,
  todosMunicipios,
  setTodosMunicipios,
  isValidTel,
  setisValidTel,
  MunicipiosParaOSelectSource,
  setMunicipiosParaOSelectSource,
  title,
  setTitle,
  pegarCep,
  setpegarCep,
  cidadeSelecionada,
  setCidadeSelecionada,
  searchType,
  setsearchType,
  CepUpdateCounter,
  setCepUpdateCounter,
  selectedSector,
  setSelectedSector,
  isValidCnpj,
  setisValidCnpj,
  showAlert_cnpj,
  setShowAlertCNPJ,
  nomeEmpresa,
  setNomeEmpresa,
  clienteEditando,
  setClienteEditando,
  clienteId,
  clienteData,
  showAlertGravando,
  setShowAlertGravando,
  showAlert,
  setShowAlert
) {
  const contextValue = useMemo(
    () => ({
      isEditing,
      isCadastroGeral,
      isPlotagem,
      isCorte,
      isModelagem,
      nome,
      setNome,
      sobrenome,
      setSobrenome,
      cpf,
      setCpf,
      cnpj,
      setCnpj,
      isValidCpf,
      setisValidCpf,
      tipoPessoa,
      setTipoPessoa,
      categoria,
      setCategoria,
      sexo,
      setSexo,
      telefone,
      setTelefone,
      email,
      setEmail,
      originalEmail,
      setOriginalEmail,
      isValidEmail,
      setisValidEmail,
      cep,
      setCep,
      isValidCep,
      setisValidCep,
      endereco,
      setEndereco,
      numero,
      setNumero,
      complemento,
      setComplemento,
      bairro,
      setBairro,
      cidade,
      setCidade,
      uf,
      setUf,
      ufs,
      setUfs,
      isBlurred,
      setisBlurred,
      showAlert_cpf,
      setShowAlertCPF,
      showAlert_cep,
      setShowAlertCep,
      showAlert_email,
      setShowAlertEmail,
      showAlert_tel,
      setShowAlertTel,
      buscarCepAtivo,
      setBuscarCepAtivo,
      Municipios,
      setMunicipios,
      codigoUf,
      setCodigoUf,
      todosMunicipios,
      setTodosMunicipios,
      isValidTel,
      setisValidTel,
      MunicipiosParaOSelectSource,
      setMunicipiosParaOSelectSource,
      title,
      setTitle,
      pegarCep,
      setpegarCep,
      cidadeSelecionada,
      setCidadeSelecionada,
      searchType,
      setsearchType,
      CepUpdateCounter,
      setCepUpdateCounter,
      selectedSector,
      setSelectedSector,
      isValidCnpj,
      setisValidCnpj,
      showAlert_cnpj,
      setShowAlertCNPJ,
      nomeEmpresa,
      setNomeEmpresa,
      clienteEditando,
      setClienteEditando,
      clienteId,
      clienteData,
      showAlertGravando,
      setShowAlertGravando,
      showAlert,
      setShowAlert
    }),
    [
      isEditing,
      isCadastroGeral,
      isPlotagem,
      isCorte,
      isModelagem,
      nome,
      setNome,
      sobrenome,
      setSobrenome,
      cpf,
      setCpf,
      cnpj,
      setCnpj,
      isValidCpf,
      setisValidCpf,
      tipoPessoa,
      setTipoPessoa,
      categoria,
      setCategoria,
      sexo,
      setSexo,
      telefone,
      setTelefone,
      email,
      setEmail,
      originalEmail,
      setOriginalEmail,
      isValidEmail,
      setisValidEmail,
      cep,
      setCep,
      isValidCep,
      setisValidCep,
      endereco,
      setEndereco,
      numero,
      setNumero,
      complemento,
      setComplemento,
      bairro,
      setBairro,
      cidade,
      setCidade,
      uf,
      setUf,
      ufs,
      setUfs,
      isBlurred,
      setisBlurred,
      showAlert_cpf,
      setShowAlertCPF,
      showAlert_cep,
      setShowAlertCep,
      showAlert_email,
      setShowAlertEmail,
      showAlert_tel,
      setShowAlertTel,
      buscarCepAtivo,
      setBuscarCepAtivo,
      Municipios,
      setMunicipios,
      codigoUf,
      setCodigoUf,
      todosMunicipios,
      setTodosMunicipios,
      isValidTel,
      setisValidTel,
      MunicipiosParaOSelectSource,
      setMunicipiosParaOSelectSource,
      title,
      setTitle,
      pegarCep,
      setpegarCep,
      cidadeSelecionada,
      setCidadeSelecionada,
      searchType,
      setsearchType,
      CepUpdateCounter,
      setCepUpdateCounter,
      selectedSector,
      setSelectedSector,
      isValidCnpj,
      setisValidCnpj,
      showAlert_cnpj,
      setShowAlertCNPJ,
      nomeEmpresa,
      setNomeEmpresa,
      clienteEditando,
      setClienteEditando,
      clienteId,
      clienteData,
      showAlertGravando,
      setShowAlertGravando,
      showAlert,
      setShowAlert
    ]
  )

  return contextValue
}
