// //variaveis.js

const mongoServiceIp = '45.33.55.29' // Endereço IP resolvido do serviço mongo

export const DB_CONNECTION_STRING_remota = `mongodb://claudiomf1:3520635lLn360@${mongoServiceIp}:30953/meumongodb?authSource=meumongodb`

export const DB_CONNECTION_STRING_local =
  'mongodb://claudiomf1:3520635lLn360@localhost:27017/meumongodb'

//----------------
