import React, { useRef, useEffect, useState } from 'react'
import Select from 'react-select'
import AlertMessage from '../pages/PLOTAGEM/AlertMessage'
import { DraggableContainer } from '../DraggableContainer/draggable-container'
export const SelectWithLoading = ({
  id,
  isClearable,
  isMulti,
  options,
  value,
  onChange,
  placeholder,
  isLoading,
  showLoading,
  variant1,
  variant2
}) => {
  const selectRef = useRef()
  const [selectSize, setSelectSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (selectRef.current) {
      setSelectSize({
        width: selectRef.current.offsetWidth,
        height: selectRef.current.offsetHeight
      })
    }
  }, [selectRef])
  const createLoadingMessage = placeholder => {
    const words = placeholder.split(' ')
    const lastTwoWords = words.slice(-2)
    const pluralizedWords = lastTwoWords.map(word => word + 's')
    return `Carregando ${pluralizedWords.join(' ')}`
  }
  return (
    <div style={{ position: 'relative' }} ref={selectRef}>
      <Select
        id={id}
        isClearable={isClearable}
        options={options}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {isLoading && showLoading && (
        <div
          style={{
            position: 'absolute',
            top: '-8px',
            left: '-7px'
          }}
        >
          <AlertMessage
            message={createLoadingMessage(placeholder)}
            size="small"
            customHeight={selectSize.height}
            customWidth={selectSize.width}
            variant1={variant1}
            variant2={variant2}
            variantDefault="success"
            isBlinking={true}
          />
        </div>
      )}
    </div>
  )
}

//---------------------------------------------------------------------------------------------

export const CustomSelectWithLoading = ({
  draggableId = null,
  components = null,
  id,
  isClearable,
  isMulti,
  options,
  value,
  onChange,
  placeholder,
  isLoading,
  showLoading,
  variant1,
  variant2,
  className = '',
  width,
  style,
  ...rest
}) => {
  const selectRef = useRef()
  const [selectSize, setSelectSize] = useState({ width: 0, height: 0 })

  useEffect(() => {
    if (selectRef.current) {
      setSelectSize({
        width: selectRef.current.offsetWidth,
        height: selectRef.current.offsetHeight
      })
    }
  }, [selectRef])

  const handleFocus = e => {
    for (const c of document.querySelectorAll('.container')) {
      c.classList.remove('selected-z-index')
    }
    const rowElement = e.target.closest('.row')
    const containerElement = e.target.closest('.container')
    if (containerElement) {
      containerElement.classList.add('selected-z-index')
    }
    if (rowElement) {
      rowElement.classList.add('selected-z-index')
    } else {
      e.target.classList.remove('selected-z-index')
    }
  }

  const handleBlur = e => {
    for (const c of document.querySelectorAll('.container')) {
      c.classList.remove('selected-z-index')
    }
    const rowElement = e.target.closest('.row')
    if (rowElement) {
      rowElement.classList.remove('selected-z-index')
    } else {
      //e.target.classList.remove('selected-z-index');
    }
  }

  const createLoadingMessage = placeholder => {
    const words = placeholder.split(' ')
    const lastTwoWords = words.slice(-2)
    const pluralizedWords = lastTwoWords.map(word => word + 's')
    return `Carregando ${pluralizedWords.join(' ')}`
  }
  // const combinedStyle = {
  //   padding: '5px',
  //   cursor: 'pointer',
  //   ...(rest.width ? { width: rest.width } : {}),
  // };

  const combinedStyle = {
    padding: '5px',
    cursor: 'pointer',
    ...(width ? { width: width } : {})
  }

  const renderSelectContent = () => (
    <div className={'label-container ' + className} style={combinedStyle}>
      <div style={{ position: 'relative' }} ref={selectRef}>
        <Select
          id={id}
          isClearable={isClearable}
          options={options}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={style}
          {...rest}
        />
        {isLoading && showLoading && (
          <div
            style={{
              position: 'absolute',
              top: '-8px',
              left: '-7px'
            }}
          >
            <AlertMessage
              message={createLoadingMessage(placeholder)}
              size="small"
              customHeight={selectSize.height}
              customWidth={selectSize.width}
              variant1={variant1}
              variant2={variant2}
              variantDefault="success"
              isBlinking={true}
            />
          </div>
        )}
      </div>
    </div>
  )

  if (draggableId && components) {
    return (
      <DraggableContainer
        id={draggableId}
        components={components}
        width={width}
        positionConfig={rest.positionConfig}
      >
        {renderSelectContent()}
      </DraggableContainer>
    )
  }

  return renderSelectContent()
}
