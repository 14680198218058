import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // Importa estilos básicos do carousel

function Banner() {
  return (
    <div>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={true}
        showArrows={true}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
      >
        {/* Slide 1 */}
        <div
          style={{
            backgroundColor: '#FFFFFF',
            height: '600px', // Altura específica para este slide
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center' // Centraliza horizontalmente
          }}
        >
          <div className="carousel-caption" style={{ textAlign: 'center' }}>
            <h1 style={{ color: '#2e8b57', fontSize: '88px' }}>Precificação Inteligente</h1>
            <br />
            <p style={{ color: '#5E35B1' }}>Maximize seus lucros com a precificação inteligente.</p>
          </div>
        </div>

        {/* Slide 2 */}
        <div
          style={{
            backgroundColor: '#FFFFFF',
            height: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className="carousel-caption" style={{ textAlign: 'center' }}>
            <h1 style={{ color: '#2e8b57', fontSize: '88px' }}>Análise de Mercado</h1>
            <br />
            <p style={{ color: '#FF7043' }}>
              Esteja sempre à frente com análises de mercado precisas.
            </p>
          </div>
        </div>

        {/* Slide 3 */}
        <div
          style={{
            backgroundColor: '#FFFFFF',
            height: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className="carousel-caption" style={{ textAlign: 'center' }}>
            <h1 style={{ color: '#2e8b57', fontSize: '88px' }}>Estratégias Dinâmicas</h1>
            <br />
            <p style={{ color: '#039BE5' }}>
              Adapte-se rapidamente com estratégias de precificação dinâmicas.
            </p>
          </div>
        </div>

        {/* Slide 4 */}
        <div
          style={{
            backgroundColor: '#FFFFFF',
            height: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className="carousel-caption" style={{ textAlign: 'center' }}>
            <h1 style={{ color: '#2e8b57', fontSize: '88px' }}>Otimização de Lucros</h1>
            <br />
            <p style={{ color: '#7CB342' }}>
              Alcance o sucesso com estratégias de precificação que impulsionam lucros.
            </p>
          </div>
        </div>

        {/* Slide 5 */}
        <div
          style={{
            backgroundColor: '#FFFFFF',
            height: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className="carousel-caption" style={{ textAlign: 'center' }}>
            <h1 style={{ color: '#2e8b57', fontSize: '88px' }}>Gestão Eficiente</h1>
            <br />
            <p style={{ color: '#F4511E' }}>
              Transforme dados em decisões de precificação eficazes.
            </p>
          </div>
        </div>
      </Carousel>
    </div>
  )
}

export default Banner
