import { useLocation } from 'react-router-dom'

const PaymentSuccess = ({ systemName }) => {
  const translationMapping = {
    approved: 'Aprovado',
    rejected: 'Rejeitado',
    pending: 'Pendente',
    cancelled: 'Cancelado',
    credit_card: 'Cartão de Crédito',
    boleto: 'Boleto',
    paypal: 'PayPal',
    bank_transfer: 'Transferência Bancária'
  }

  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const paymentId = query.get('payment_id')
  const status = query.get('status')
  const paymentType = query.get('payment_type')

  function translateValue(value) {
    return translationMapping[value] || 'Valor Desconhecido'
  }

  return (
    <div style={styles.container}>
      <h2>Pagamento Confirmado</h2>
      <p style={styles.paragraph}>
        Obrigado por assinar o {systemName}! Seu pagamento foi processado com sucesso.
      </p>
      <p style={styles.paragraph}>
        <strong>ID do Pagamento:</strong> {paymentId}
      </p>
      <p style={styles.paragraph}>
        <strong>Status do Pagamento:</strong> {translateValue(status)}
      </p>
      <p style={styles.paragraph}>
        <strong>Tipo de Pagamento:</strong> {translateValue(paymentType)}
      </p>
    </div>
  )
}

const styles = {
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
  },
  paragraph: {
    fontSize: '1.2rem',
    marginBottom: '10px',
    color: '#333'
  }
}

export default PaymentSuccess
