import React from 'react'
import { Modal, Alert, Button } from 'react-bootstrap'
import { ButtonWithAlerts } from '/src/components/ButtonWithAlerts/button-with-alerts'
export const ConfirmationModal = ({
  show,
  onHide,
  title = 'Confirmação',
  message,
  onConfirm,
  confirmLabel = 'Confirmar',
  cancelLabel = 'Cancelar',
  variant = 'danger',
  botaoComAlerta, // Esta é a prop booleana que determina se o botão padrão é substituído
  buttonWithAlertsProps // Propriedades adicionais para passar para o ButtonWithAlerts, se necessário
}) => {
  return (
    <Modal show={show} onHide={onHide} style={{ zIndex: 2000 }}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant={variant}>{message}</Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {cancelLabel}
        </Button>
        {botaoComAlerta ? (
          <ButtonWithAlerts {...buttonWithAlertsProps} />
        ) : (
          <Button variant={variant} onClick={onConfirm}>
            {confirmLabel}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}
