import React, { useState, useEffect } from 'react'
import { fetchData } from '/src/backend/model'
import { useContext } from 'react'
import { AuthContext } from '/src/context/AuthContext'
import { config } from '/src/backend/configConexaoGeral'
import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import CustomTable from '/src/components/CustomTable/custom-table'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { CheckboxControl } from '/src/utils/form-utils.jsx'
import {
  DroppableArea,
  useArrastar,
  DraggableContainer
} from '/src/components/DraggableContainer/draggable-container'
import { Button, Form, FormGroup, FormControl, FormLabel } from 'react-bootstrap'

const IpRegistration = ({ url }) => {
  const [ip, setIp] = useState('')
  const [ips, setIps] = useState([])
  const [ipParaCadastro, setIpParaCadastro] = useState('')
  const [updatedIps, setUpdatedIps] = useState([]) // Dados modificados
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [corAlerta, setCorAlerta] = useState('danger') // ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
  const { handleDrop, components } = useArrastar(10, 1)
  const [isDragEnabled, setIsDragEnabled] = useState(false)
  const [dadosEdicao, setDadosEdicao] = useState({ id: 0, ip_liberado: '' })
  const [configKeySheet, set_configKeySheet] = useState()
  const [ip_atual, set_ip_atual] = useState([])
  const [tab, set_tab] = useState()
  const { RotaBase, tenantId } = useContext(AuthContext)

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()
        setIpParaCadastro(data.ip) // Agora está no escopo correto
        set_ip_atual(data.ip)
      } catch (error) {
        console.error('Ocorreu um erro ao obter o IP:', error)
      }
    }

    fetchIP()
  }, [])

  const loadIps = async () => {
    let dados = {
      requisicao: 'RetornarTodosIps',
      rota_base: RotaBase,
      tenantId: tenantId
    }
    let url = config.host + '/liberar_ips'
    let response = await fetchData(url, dados)

    setIps(response) // Store the IPs in the state variable
  }

  useEffect(() => {
    loadIps() // Call the function to load IPs when the component mounts
    set_configKeySheet('ips_liberados')
    set_tab('ips_liberados')
  }, [])

  useEffect(() => {
    setUpdatedIps([...ips]) // Inicializar 'updatedIps' com uma cópia de 'ips'
  }, [ips])

  const handleSubmit = async event => {
    event.preventDefault()
    let ipAUsar = ipParaCadastro
    if (!ipAUsar) {
      ipAUsar = ip_atual // Ou mostrar uma mensagem/alerta para o usuário
    }
    let dados = {
      ip_liberado: ipAUsar,
      requisicao: 'GravarIp',
      rota_base: RotaBase,
      tenantId: tenantId
    }
    let url = config.host + '/liberar_ips'

    let response = await fetchData(url, dados)

    if (response.message === 'Gravado') {
      // Lógica após o cadastro bem-sucedido do IP
      setShowAlertMessage(true)
      setCorAlerta('success')
      setAlertMessage('IP Gravado Com Sucesso')
      setIps(prevIps => [...prevIps, { ip_liberado: ipAUsar, ...response.novoIp }])
      setIpParaCadastro('') // Limpa o campo após o cadastro
    } else {
      setAlertMessage('Este IP já foi gravado antes')
      setCorAlerta('danger')
      setShowAlertMessage(true)
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <DroppableArea onDrop={isDragEnabled ? handleDrop : () => {}}>
        <ShowAlertGeneric
          showAlert={showAlertMessage}
          message={alertMessage}
          isBlurred={true} // ou algum outro critério que deseja usar para blur
          setShowAlert={setShowAlertMessage}
          variant={corAlerta} // ou qualquer outra variante que deseja usar
        />
        <CheckboxControl
          checked={isDragEnabled}
          onChange={() => setIsDragEnabled(!isDragEnabled)}
          label="Ajustar layout"
        />
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel>Liberar o IP</FormLabel>
            <FormControl
              type="text"
              value={ipParaCadastro}
              onChange={e => setIpParaCadastro(e.target.value)}
            />
          </FormGroup>
          <Button variant="primary" type="submit">
            Cadastrar IP
          </Button>
        </Form>

        <CustomTable
          draggableId={1}
          components={components}
          onlyDeleteButton={true}
          isDeleteEdit={{
            configKeySheet: configKeySheet,
            exclusaoConfig: {
              tab: tab
            },
            deletaDoBanco: true
          }}
          title="Ips Liberados"
          fontSizeTitle={40}
          cabecalho={{ titulo: 'Seu Ip Atual', valor: ip_atual }}
          data={ips}
          setData={setIps}
          updatedData={updatedIps}
          setUpdatedData={setUpdatedIps}
          dadosEdicao={dadosEdicao}
          setDadosEdicao={setDadosEdicao}
          // ... outras props ...
          headerCells={[
            {
              label: 'IPS Liberados',
              dataKey: 'ip_liberado', // Certifique-se de que isso corresponde à chave dos objetos IP
              width: '90%', // Ajuste conforme necessário
              edit: false
            }
            // ... outras células, se necessário ...
          ]}
          // ... outras props ...
        />
      </DroppableArea>
    </DndProvider>
  )
}

export default IpRegistration
