import React, { useState } from 'react'
import { CustomField } from '/src/components/CustomField/custom-field'
import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import { useDynamicState } from '/src/utils/form-utils.js'

function SelectMarketPlace({
  marketplaces,

  setDisplayDetails,
  setSelectedMarketplaces,
  selectedMarketplaces,
  isOpen,
  setIsOpen,

  marketplaceColors
}) {
  //const [state, setDynamicState] = useDynamicState();

  const handleMouseEnter = () => {
    setIsOpen(true)

    setDisplayDetails(false)
  }

  function addColorsToMarketplaces(marketplaces, colors) {
    if (!Array.isArray(marketplaces)) {
      console.error('marketplaces is not an array', marketplaces)
      return []
    }
    return marketplaces.map(marketplace => ({
      ...marketplace,
      cor: colors[marketplace.nome] || '#000000' // Usa preto como cor padrão se não encontrar correspondência
    }))
  }

  marketplaces = addColorsToMarketplaces(marketplaces, marketplaceColors)

  const handleMouseLeave = () => {
    setIsOpen(false)
    if (Object.values(selectedMarketplaces).some(v => v)) {
      setDisplayDetails(true) // Mostra detalhes se algo estiver selecionado
    }
  }

  const handleMarketplaceChange = event => {
    const { value, checked } = event.target

    setSelectedMarketplaces(prev => ({
      ...prev,
      [value]: checked
    }))
  }

  return (
    <div
      className="comissao-display-container"
      style={{
        marginLeft: '33px'
      }}
    >
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div
          className={`banner-3d-comissao`}
          style={{
            width: '266px'
          }}
        >
          <span>Selecione o Marketplace</span>
        </div>
        {isOpen && (
          <div
            className="comissao-list"
            style={{
              width: '253px',
              zIndex: 20
            }}
          >
            {marketplaces &&
              marketplaces.map(marketplace => {
                if (!marketplace || !marketplace._id) {
                  console.error('Marketplace or marketplace._id is undefined', marketplace)
                  return null // Isso evita renderizar este elemento se o _id estiver indefinido
                }
                return (
                  <label key={marketplace._id} className="comissao-option">
                    <input
                      type="checkbox"
                      value={marketplace._id}
                      checked={!!(selectedMarketplaces && selectedMarketplaces[marketplace._id])}
                      onChange={handleMarketplaceChange}
                    />
                    {marketplace.nome}
                  </label>
                )
              })}
          </div>
        )}
      </div>
    </div>
  )
}

export default SelectMarketPlace
