import { enviarOuAtualizarDadosNaPlanilha } from '../../../../backend/model'
export async function handleForm( // função que envia os dados para a planilha
  isEditing,
  cadastroDados,
  clienteId,
  clienteData,
  todasColunasSheet,
  colunasParaGravarSheet,
  columnAliases,
  planilha,
  pagina,
  requisicao,
  campos,
  ...extraArgs
) {
  try {
    // const dadosFormulario = clienteData
    //   ? adicionarIdEData(cadastroDados, clienteId, clienteData)
    //   : cadastroDados;

    const retorno = await enviarOuAtualizarDadosNaPlanilha(
      cadastroDados,
      planilha,
      pagina,
      null,
      isEditing,
      requisicao,
      campos,
      clienteId,
      ...extraArgs
    )

    return retorno
  } catch (err) {
    console.error('Erro ao gerar mapeamento de colunas:', err)
  }
}
