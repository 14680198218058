import { useState, useEffect } from "react";
export function useTodosMunicipiosBrasil() {
  const [municipios, setMunicipios] = useState([]);

  useEffect(() => {
    async function loadMunicipios() {
      const parte1 = import("./municipios-parte1");
      const parte2 = import("./municipios-parte2");
      Promise.all([parte1, parte2]).then((values) => {
        setMunicipios(
          values[0].municipiosParte1.concat(values[1].municipiosParte2)
        );
      });
    }

    loadMunicipios();
  }, []);

  return municipios;
}
