import { useState, useEffect } from 'react'
import { SalvarEdicao } from '../../../../backend/model.js'
import { ShowAlertGeneric } from '../../../../utils/form-utils.jsx'
import { CadastroTamanhosCorte } from './cadastro-tamanho-corte.jsx'
//import { Preview } from 'react-dnd-preview';
import {
  DraggableContainer,
  DroppableArea,
  useArrastar
} from '../../../DraggableContainer/draggable-container.jsx'
import { Row, Col, Container, Form } from 'react-bootstrap'
import {
  validateAndShowAlerts,
  NomeSobrenomeEmpresaClientes,
  useDynamicState,
  mapProdutosToOptions,
  showAlertFunction,
  getValidationMessages
} from '../../../../utils/form-utils.js'
import { CustomField } from '../../../CustomField/custom-field.jsx'
import { CustomAlert } from '../../../CustomAlert/custom-alert.jsx'
import { ButtonWithAlerts } from '../../../ButtonWithAlerts/button-with-alerts.jsx'
import Banner from '../../CadastroGeral/Banner.jsx'
import { carregarClientesDoServico } from '../../../../servicos/dadosServicos.js'
import { DatePickerCustom } from '../../../../utils/date-utils.jsx'
import { DndProvider } from 'react-dnd'
import { buscarProdutosPorIdServicoClient } from '../../../../backend/model.js'
import { CustomSelectWithLoading } from '../../../SelectWithLoading/SelectWithLoading.jsx'
import { HTML5Backend } from 'react-dnd-html5-backend'
import * as DND from 'react-dnd-html5-backend'
import { AuthContext } from '../../../../context/AuthContext.jsx'
import { useContext } from 'react'
//import { TouchBackend } from 'react-dnd-touch-backend';

// import { MultiBackend, HTML5toTouch } from 'react-dnd-multi-backend';
import { useQuery } from 'react-query'

const CadastroCorte = () => {
  const [state, setDynamicState] = useDynamicState()
  const [tamanhos, setTamanhos] = useState({})
  const startId = 13
  useEffect(() => {
    console.log('tamanhos', tamanhos)
  }, [tamanhos])
  const fields = [
    { label: 'Largura do tecido', width: '150px', validate: true },
    { label: 'Total modelos', width: '128px', validate: true },
    // { label: 'Grade', width: '100px', validate: true },
    { label: 'Rolos', width: '70px', validate: true },
    { label: 'Descricao', width: '600px', validate: true }
  ]

  const [positionConfig, setPositionConfig] = useState({
    1: { x: 212, y: 329 }, //ok
    2: { x: 506, y: 55 }, //ok
    3: { x: 1129, y: 106 }, //ok
    4: { x: 1127, y: 178 }, //ok
    5: { x: 361, y: 132 }, //ok
    6: { x: 805, y: 129 }, //ok
    7: { x: 572, y: -41 }, //ok
    13: { x: 726, y: 297 }, //ok
    14: { x: 541, y: 295 }, //ok
    15: { x: 443, y: 295 }, //ok
    16: { x: 442, y: 215 }, //ok
    17: { x: 347, y: 243 }, //ok
    18: { x: 19, y: 79 } //ok
  })

  const [selectedDates, setSelectedDates] = useState({
    corte: new Date(),
    prevista: new Date(),
    cortada: ''
  })
  const customStylesCliente = {
    control: base => ({
      ...base,
      minWidth: '400px' // Ajuste para a largura desejada
      // outros estilos personalizados para o control
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'blue' : 'white'

      // outros estilos personalizados para os options
    })
    // outros estilos personalizados
  }
  const customStylesTecido = {
    control: base => ({
      ...base,
      minWidth: '300px' // Ajuste para a largura desejada
      // outros estilos personalizados para o control
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'blue' : 'white'

      // outros estilos personalizados para os options
    })
    // outros estilos personalizados
  }

  //---------------------------------------------------------
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [theme, setTheme] = useState('light')
  const [SelectedCliente, setSelectedCliente] = useState([])
  const [SelectedTecido, setSelectedTecido] = useState([])
  const [isVisible, setIsVisible] = useState(false)
  const [showAlertGravando, setShowAlertGravando] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [isDragEnabled, setIsDragEnabled] = useState(false)
  const [showAlertMessage, setShowAlertMessage] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [timeoutAlert, setTimeoutAlert] = useState(null)
  const backend = DND.HTML5Backend
  // const DndBackend = MultiBackend(HTML5toTouch);
  //--------------------------------------------------------------------------------------
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAlert(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [showAlert])

  const buscarProdutos = () => buscarProdutosPorIdServicoClient('3', 'cortes', RotaBase, tenantId)

  const {
    data: produtos,
    isLoading: isLoadingProdutos,
    isError: isErrorProdutos
  } = useQuery('produtos', buscarProdutos, {
    refetchOnWindowFocus: false
  })

  const {
    data: clientesBrutos,
    isLoading: isLoadingClientes,
    isError: isErrorClientes
  } = useQuery('clientes', () => carregarClientesDoServico(3, tenantId, RotaBase), {
    staleTime: 5000
  })

  const clientes = NomeSobrenomeEmpresaClientes(clientesBrutos || [])
  //console.log('clientes2', clientes)
  const tecidos = mapProdutosToOptions(isLoadingProdutos, produtos)

  //----------------------------- SETOR DE ARRASTO--------------------------------------------
  const { handleDrop, components, buttonPosition } = useArrastar(2, 18)
  //------------------------------------------------------------------------------------
  const handleSelectedClienteChange = selectedOption => {
    setSelectedCliente(selectedOption)
  }
  const handleSelectedTecidoChange = selectedOption => {
    setSelectedTecido(selectedOption)
  }

  const handleCloseAlert = () => {
    setShowAlert(false)
  }

  const limparCampos = () => {
    setSelectedDates({
      corte: '',
      prevista: ''
    })
    setSelectedCliente('')
    setSelectedTecido('')

    // Definir campos específicos no state de volta para uma string vazia
    let newState = {}
    fields.forEach(field => {
      newState[field.label] = ''
    })
    setDynamicState(newState) // Atenção para esta linha!
  }

  //---------------------------------------------------------------------
  const validateFields = () => {
    const specialValidations = [
      {
        key: 'DataCadastro',
        condition: !selectedDates.corte,
        message: 'Insira a Data do Cadastro!'
      },
      {
        key: 'DataPrevista',
        condition: !selectedDates.prevista,
        message: 'Insira a Data Prevista!'
      },
      {
        key: 'Cliente',
        condition: !SelectedCliente || !SelectedCliente.label,
        message: 'Insira o Cliente!'
      },
      {
        key: 'Tecido',
        condition: !SelectedTecido || !SelectedTecido.label,
        message: 'Insira o Tecido!'
      }
    ]
    const invalidFields = specialValidations.filter(val => val.condition).map(val => val.key)
    return invalidFields
  }

  function getFirstName(fullName) {
    // Divide a string em um array usando o hífen como separador
    const parts = fullName.split(' - ')
    // Retorna a primeira parte do array, que será o nome, e remove os espaços em branco no início e no final
    return parts[0].trim()
  }
  //-----------------------------------------------------------------------------
  const [alerts, setAlerts] = useState({})

  //------------------------------------------------------------------
  const camposEspeciais = validateFields()

  const handleSubmit = async () => {
    if (
      !validateAndShowAlerts(getValidationMessages(fields, state, camposEspeciais), (k, v) =>
        showAlertFunction(setAlerts, k, v)
      )
    ) {
      // A validação falhou, não prosseguir com o salvamento
      return
    }

    setShowAlertGravando(true)

    const dados = {
      data: selectedDates.corte,
      entregar: selectedDates.prevista,
      lar_tecido: state['Largura do tecido'].replace(',', '.'),
      total_ref: state['Total modelos'],
      t_grade: state['Grade'],
      cliente: getFirstName(SelectedCliente.label),
      tecido: SelectedTecido.label,
      id_cliente: SelectedCliente.value,
      id_tecido: SelectedTecido.value,
      total_rolos: state['Rolos'],
      descricao: state['Descricao'],
      tenantId: tenantId,
      tamanhos: tamanhos
    }

    try {
      // Salvar na planilha

      let retornoCorte = await SalvarEdicao(
        {
          configKeySheet: 'cortes',
          tab: 'servicos',
          cadastroDados: dados,

          isEditing: false,
          requisicao: 'enviar'
        },
        {
          id_pk: 0,
          rota: 'cadastroGeral'
        }
      )

      if (retornoCorte && retornoCorte.message) {
        setShowAlertGravando(false)
        setTimeoutAlert(10000)
        setAlertMessage(retornoCorte.message)
        setShowAlertMessage(true)
        return
      }
      // Exibir mensagem de sucesso
      setShowAlertGravando(false)
      setShowAlert(true)
      limparCampos()
    } catch (error) {
      // Tratar erro
      console.error(error)
    }
  }

  const handleChange = (e, field) => {
    setDynamicState(field, e.target.value)
  }
  const handleDateChange = changeFunction => {
    if (typeof changeFunction === 'function') {
      setSelectedDates(changeFunction)
    }
  }
  //--------------------------------------------------------------------------------
  return (
    <DndProvider backend={HTML5Backend}>
      <Container fluid className={`my-6 ${isVisible ? '' : 'not-rendered'}`}>
        <ShowAlertGeneric
          showAlert={showAlertMessage}
          message={alertMessage}
          isBlurred={true} // ou algum outro critério que deseja usar para blur
          setShowAlert={setShowAlertMessage}
          variant="danger" // ou qualquer outra variante que deseja usar
          timeOut={timeoutAlert}
        />
        <DroppableArea onDrop={isDragEnabled ? handleDrop : () => {}}>
          <DraggableContainer id={18} components={components} positionConfig={positionConfig}>
            <CadastroTamanhosCorte tamanhos={tamanhos} setTamanhos={setTamanhos} />
          </DraggableContainer>
          <Row>
            <Col>
              <div className="form-check" style={{ zIndex: 9999, position: 'relative' }}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="enableDrag"
                  checked={isDragEnabled}
                  onChange={() => setIsDragEnabled(!isDragEnabled)}
                />
                <label className="form-check-label" htmlFor="enableDrag">
                  Ajustar layout
                </label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '100%' }}>
              <Banner
                title="CORTE"
                draggableId={7}
                components={components}
                positionConfig={positionConfig}
              />
            </Col>
          </Row>

          <Row>
            <Col style={{ width: '80%', margin: '0 auto' }}>
              <DraggableContainer id={2} components={components} positionConfig={positionConfig}>
                <h1 style={{ fontSize: '3rem' }}>Cadastro de Corte</h1>
              </DraggableContainer>

              {/*--------------------------- SEÇAO DOS ALERTAS (N ALERTAS)---------------------------------*/}
              <div style={{ position: 'relative' }}>
                <div className="AlertClass">
                  {Object.keys(getValidationMessages(fields, state, camposEspeciais)).map(
                    (key, index) => (
                      <CustomAlert
                        key={index}
                        message={getValidationMessages(fields, state, camposEspeciais)[key]}
                        showAlert={alerts[key]}
                        setShowAlert={value => showAlertFunction(setAlerts, key, value)}
                      />
                    )
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Form style={{ width: '85%' }}>
            {/*--------------------------- SEÇAO DAS DATAS ---------------------------------*/}
            <Row>
              <Col style={{ maxWidth: '263px' }}>
                <DatePickerCustom
                  draggableId={3}
                  components={components}
                  id="corte"
                  selected={selectedDates.corte}
                  onChange={handleDateChange}
                  placeholder="Data cadastro"
                  positionConfig={positionConfig}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ maxWidth: '263px' }}>
                <DatePickerCustom
                  draggableId={4}
                  autoClearOnMount={true}
                  components={components}
                  id="prevista"
                  selected={selectedDates.prevista}
                  onChange={handleDateChange}
                  placeholder="Data Prevista"
                  positionConfig={positionConfig}
                />
              </Col>
            </Row>
            {/*--------------------------- SEÇAO DOS SELECTS ---------------------------------*/}
            <Row>
              <Col style={{ maxWidth: '500px' }}>
                <CustomSelectWithLoading
                  draggableId={5}
                  components={components}
                  id="cliente"
                  options={clientes}
                  isClearable={true}
                  placeholder="Selecione o cliente"
                  onChange={handleSelectedClienteChange}
                  value={SelectedCliente}
                  isLoading={isLoadingClientes}
                  showLoading={true}
                  styles={customStylesCliente}
                  positionConfig={positionConfig}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ maxWidth: '300px' }}>
                <CustomSelectWithLoading
                  draggableId={6}
                  components={components}
                  id="tecido"
                  options={tecidos}
                  isClearable={true}
                  placeholder="Selecione o tecido"
                  onChange={handleSelectedTecidoChange}
                  value={SelectedTecido}
                  isLoading={isLoadingProdutos}
                  showLoading={true}
                  styles={customStylesTecido}
                  positionConfig={positionConfig}
                />
              </Col>
            </Row>
            {/*--------------------------- SEÇAO DOS EDITS (5 EDITS)----------------------------------*/}
            {fields.map((fieldObj, index) => (
              <Col key={fieldObj.label} style={{ width: fieldObj.width }}>
                <CustomField
                  draggableId={startId + index}
                  components={components}
                  label={fieldObj.label}
                  placeholder={fieldObj.label}
                  value={state[fieldObj.label] || ''}
                  onChange={e => handleChange(e, fieldObj.label)}
                  onClear={() => setDynamicState(fieldObj.label, '')}
                  width={fieldObj.width}
                  positionConfig={positionConfig}
                />
              </Col>
            ))}
          </Form>
          {/*--------------------------- SEÇAO DO BOTÃO DE SALVAR ----------------------------------*/}
          <Row>
            <Col
              md={{ span: 4, offset: 4 }}
              className={`mb-2  d-flex align-items-center justify-content-center`}
            >
              <ButtonWithAlerts
                showAlertGravando={showAlertGravando}
                showAlert={showAlert}
                components={components}
                handleSubmit={handleSubmit}
                buttonPosition={buttonPosition}
                handleCloseAlert={handleCloseAlert}
                draggableId={1}
                positionConfig={positionConfig}
              />
            </Col>
          </Row>
        </DroppableArea>
      </Container>
      ;{/* <CustomDragLayer /> */}
    </DndProvider>
  )
}
export default CadastroCorte
