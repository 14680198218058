import { useState, useEffect } from 'react'
import { fetchData } from '../../../../src/backend/model.js'
import { config } from '../../../../src/backend/configConexaoGeral'
import parse from 'html-react-parser'
import DOMPurify from 'dompurify'
import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
const EmailClient = () => {
  const [emails, setEmails] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedEmail, setSelectedEmail] = useState(null)
  const [selectedMenu, setSelectedMenu] = useState('inbox')
  const [newEmail, setNewEmail] = useState({ to: '', cc: '', subject: '', message: '' })

  useEffect(() => {
    const fetchEmails = async () => {
      const url = config.host + '/tenants'
      const dados = { requisicao: 'receiveEmails' }
      try {
        const data = await fetchData(url, dados)
        setEmails(data.emails.reverse())
        setLoading(false)
      } catch (error) {
        console.error('Erro ao carregar e-mails:', error)
        setError('Erro ao carregar e-mails')
        setLoading(false)
      }
    }
    fetchEmails()
  }, [])

  const handleEmailClick = email => {
    setSelectedEmail(email)
  }

  const handleDeleteEmail = emailToDelete => {
    setEmails(emails.filter(email => email !== emailToDelete))
  }

  const formatEmailContent = content => {
    return content.replace(/(?:\r\n|\r|\n)/g, '<br />').replace(/<\/p><p>/g, '<br /><br />')
  }

  const enviarEmail = async () => {
    const url = config.host + '/tenants'
    const dados = {
      requisicao: 'sendInternalEmail',
      to: newEmail.to,
      subject: newEmail.subject,
      message: newEmail.message,
      sistema: 'precofacilmarket'
    }
    try {
      const data = await fetchData(url, dados)

      alert('E-mail enviado com sucesso!')
      setNewEmail({ to: '', cc: '', subject: '', message: '' }) // Limpa o formulário após o envio
    } catch (error) {
      console.error('Erro ao enviar e-mail:', error)
      alert('Erro ao enviar e-mail')
    }
  }

  const renderEmailsList = () => {
    if (loading) {
      return <div>Carregando...</div>
    }
    if (error) {
      return <div>{error}</div>
    }

    const today = new Date().toDateString()
    const groupedEmails = emails.reduce((groups, email) => {
      const emailDate = new Date(email.date).toDateString()
      if (!groups[emailDate]) {
        groups[emailDate] = []
      }
      groups[emailDate].push(email)
      return groups
    }, {})

    return (
      <div className="email-list">
        {Object.keys(groupedEmails).map(date => (
          <div key={date}>
            <h3 className={date === today ? 'today-header' : 'date-header'}>
              {date === today ? 'Hoje' : new Date(date).toLocaleDateString()}
            </h3>
            <ul>
              {groupedEmails[date].map((email, index) => (
                <li
                  key={index}
                  className={`email-item ${selectedEmail === email ? 'selected' : ''}`}
                  onClick={() => handleEmailClick(email)}
                >
                  <div className="email-sender">{email.from}</div>
                  <div className="email-subject">{email.subject}</div>
                  <div className="email-date" style={{ float: 'right' }}>
                    {date === today
                      ? new Date(email.date).toLocaleTimeString()
                      : new Date(email.date).toLocaleDateString()}
                  </div>
                  <div className="delete-icon" onClick={() => handleDeleteEmail(email)}>
                    🗑️
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    )
  }

  const renderEmailContent = () => {
    if (!selectedEmail) {
      return <div>Selecione um e-mail para visualizar o conteúdo</div>
    }
    return (
      <div className="email-content">
        <h2>{selectedEmail.subject}</h2>
        <div>De: {selectedEmail.from}</div>
        <div>Para: {selectedEmail.to}</div>
        <div>Data: {new Date(selectedEmail.date).toLocaleString()}</div>
        <div className="email-message">
          {parse(DOMPurify.sanitize(formatEmailContent(selectedEmail.message)))}
        </div>
      </div>
    )
  }

  const renderNewEmailForm = () => {
    return (
      <div className="new-email-form">
        <div className="email-form-header">
          <button className="send-email-button" onClick={enviarEmail}>
            Enviar
          </button>
          <button className="save-draft-button">Enviar mais tarde</button>
        </div>
        <div className="email-form-fields">
          <div>
            <label>De: </label>
            <span>Claudio &lt;cadastro@precofacilmarket.com.br&gt;</span>
          </div>
          <div>
            <label>Para: </label>
            <input
              type="text"
              value={newEmail.to}
              onChange={e => setNewEmail({ ...newEmail, to: e.target.value })}
            />
          </div>
          <div>
            <label>Cc: </label>
            <input
              type="text"
              value={newEmail.cc}
              onChange={e => setNewEmail({ ...newEmail, cc: e.target.value })}
            />
          </div>
          <div>
            <label>Assunto: </label>
            <input
              type="text"
              value={newEmail.subject}
              onChange={e => setNewEmail({ ...newEmail, subject: e.target.value })}
            />
          </div>
        </div>
        <ReactQuill
          className="react-quill"
          value={newEmail.message}
          onChange={content => setNewEmail({ ...newEmail, message: content })}
        />
      </div>
    )
  }

  return (
    <div className="email-client">
      <div className="sidebar">
        <button className="new-email-button" onClick={() => setSelectedMenu('new')}>
          Novo e-mail
        </button>
        <ul className="folders-list">
          <li onClick={() => setSelectedMenu('inbox')}>Caixa de Entrada</li>
          <li onClick={() => setSelectedMenu('sent')}>Caixa de Saída</li>
        </ul>
      </div>
      <div className="email-new-section">
        {selectedMenu === 'inbox' && renderEmailsList()}
        {selectedMenu === 'new' && renderNewEmailForm()}
      </div>
      <div className="email-content-section">
        {selectedMenu === 'inbox' && renderEmailContent()}
      </div>
    </div>
  )
}
export default EmailClient
