import { useState, useEffect, useContext } from 'react'
import { fetchData } from '../../../../backend/model'
import { config } from '../../../../backend/configConexaoGeral'
import { imprimirTabela } from './printTable.jsx'
import { Form, Table, Button, Modal, Alert } from 'react-bootstrap'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AuthContext } from '../../../../context/AuthContext'
import { formatDate } from '../../../../utils/date-utils'
import { CortesPendentes } from '../../../../../src/components/pages/Financeiro/financeiro.jsx'

function ImpressaoEtiquetaCorte() {
  const [cortes, setCortes] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const { RotaBase, tenantId } = useContext(AuthContext)
  const [showWarningAlert, setShowWarningAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [camposParaImpressao, setCamposParaImpressao] = useState({})
  const [isLoadingCortes, setIsLoadingCortes] = useState(false)
  const [quantidadesImpressao, setQuantidadesImpressao] = useState({})
  const [isConfiguracaoCliente, setIsConfiguracaoCliente] = useState(false)
  const [clienteSelecionado, setClienteSelecionado] = useState(null)
  const [camposPersonalizadosDiferentes, setCamposPersonalizadosDiferentes] = useState([])

  // Inicializa os campos disponíveis para impressão com base nos campos do objeto "corte"
  const camposPadraoSelecionados = [
    'id',
    'data_corte',
    'descricao',
    'cliente',
    'tecido',
    'tamanhos'
  ]
  const [linhasSelecionadas, setLinhasSelecionadas] = useState({})

  const [camposTabela, setCamposTabela] = useState(camposPadraoSelecionados)
  const deveExcluirCampo = campo => {
    const camposExcluidos = [
      '_id',
      '__v',
      'tenantId',
      'id_cliente',
      'a_receber',
      'pago',
      'id_tecido',
      'valor_embalagem',
      'embalagem',
      'temConfiguracaoPersonalizada',
      'camposPersonalizados',
      'camposDiferentes',
      't_grade'
    ]
    return camposExcluidos.includes(campo)
  }

  useEffect(() => {
    // Inicializa com os campos padrão sempre presentes
    const camposIniciais = camposPadraoSelecionados.reduce((acc, campo) => {
      if (!deveExcluirCampo(campo)) {
        acc[campo] = camposParaImpressao[campo] || true // Sempre marca como selecionado
      }
      return acc
    }, {})

    // Adiciona campos adicionais baseados em 'cortes' se houver
    if (cortes.length > 0) {
      Object.keys(cortes[0]).forEach(campo => {
        if (!deveExcluirCampo(campo) && !camposIniciais.hasOwnProperty(campo)) {
          camposIniciais[campo] =
            camposParaImpressao[campo] || camposPadraoSelecionados.includes(campo)
        }
      })
    }

    setCamposParaImpressao(camposIniciais)
  }, [cortes])

  useEffect(() => {
    // Atribuir sempre os campos padrão aos campos da tabela e ordená-los conforme a ordem preferencial
    let camposIniciais = [...camposPadraoSelecionados]
    camposIniciais = [
      ...ordemPreferencial.filter(campo => camposIniciais.includes(campo)),
      ...camposIniciais.filter(campo => !ordemPreferencial.includes(campo))
    ]
    setCamposTabela(camposIniciais)
  }, [])

  const fetchCamposImpressaoCortePadrao = async () => {
    try {
      setIsConfiguracaoCliente(false) // Definindo como configuração geral
      const url = `${config.host}/configuracoesGerais`
      const dados = {
        tenantId,
        rota_base: RotaBase,
        requisicao: 'lerCamposImpressaoCortePadrao'
      }

      const response = await fetchData(url, dados)

      let camposIniciais = {}
      if (cortes.length > 0) {
        // Se houver cortes, adiciona todos os campos disponíveis no objeto `corte`
        camposIniciais = Object.keys(cortes[0]).reduce((acc, campo) => {
          if (!deveExcluirCampo(campo)) {
            acc[campo] = false // Inicializa todos como não selecionados
          }
          return acc
        }, {})
      }

      if (response.camposSelecionados.length > 0) {
        // Marca os campos retornados pela resposta do servidor
        response.camposSelecionados.forEach(campo => {
          if (!deveExcluirCampo(campo)) {
            camposIniciais[campo] = true // Marca como selecionado
          }
        })
      } else {
        // Se nenhuma configuração for encontrada, marque os campos padrão
        camposPadraoSelecionados.forEach(campo => {
          if (!deveExcluirCampo(campo)) {
            camposIniciais[campo] = true // Marca como selecionado
          }
        })
      }

      setCamposParaImpressao(camposIniciais)
    } catch (error) {
      console.error('Erro ao buscar os campos de impressão de corte padrão:', error)
    }
  }

  useEffect(() => {
    fetchCamposImpressaoCortePadrao()
  }, [])

  const renameField = field => {
    switch (field) {
      case 'lar_tecido':
        return 'Largura Tecido'
      case 'total_ref':
        return 'Total de Modelos'
      case 'total_rolos':
        return 'Total de Rolos'
      case 'data_corte':
        return 'Foi Cortado'
      case 'total_pecas':
        return 'Total de Peças'
      case 'valor_do_corte':
        return 'Valor'

      case 'data':
        return 'Cadastro'
      case 'tamanhos':
        return 'Tamanhos'
      default:
        return field.charAt(0).toUpperCase() + field.slice(1)
    }
  }

  // Função para salvar configurações padrões dos campos

  const handleModalSave = async () => {
    try {
      const camposSelecionados = Object.keys(camposParaImpressao).filter(
        campo => camposParaImpressao[campo]
      )

      const dados = {
        tenantId,
        rota_base: RotaBase,
        camposSelecionados,
        requisicao: isConfiguracaoCliente
          ? 'gravarCamposImpressaoCorteCliente'
          : 'gravarCamposImpressaoCortePadrao'
      }

      if (isConfiguracaoCliente && clienteSelecionado) {
        dados.clienteId = clienteSelecionado.id_cliente
      }

      const url = `${config.host}/configuracoesGerais` // URL para salvar os campos de impressão

      // Fazendo a requisição ao servidor para salvar os dados
      await fetchData(url, dados)
      // Mostrar alerta de sucesso após salvar
      setAlertMessage('Configurações de impressão salvas com sucesso!')
      setShowWarningAlert(true)
      // Aguardar alguns segundos antes de fechar o modal para que o alerta seja visível
      setTimeout(() => {
        setShowWarningAlert(false) // Fechar o alerta antes de fechar o modal
        setShowModal(false) // Fechar o modal após o tempo definido
      }, 3000) // 3 segundos de atraso
    } catch (error) {
      console.error('Erro ao salvar as configurações de impressão:', error)
    }
  }

  const fetchCortes = async () => {
    setIsLoadingCortes(true)

    try {
      // Primeiro, chamamos CortesPendentes para definir os cortes no estado
      await CortesPendentes(null, tenantId, setCortes, setIsLoadingCortes, -1) // -1 significa que nao ira considerar o paramentro valorDocorte

      // Agora que temos os cortes, vamos buscar as configurações personalizadas para cada cliente
      setCortes(async cortesAtuais => {
        // Array para armazenar todas as promessas de requisição dos campos personalizados dos clientes

        const requisicoesConfiguracoes = cortesAtuais.map(async corte => {
          const dados = {
            tenantId,
            rota_base: RotaBase,
            clienteId: corte.id_cliente,
            requisicao: 'lerCamposImpressaoCorteCliente'
          }

          const url = `${config.host}/configuracoesGerais`

          try {
            // Fazendo a requisição para buscar se o cliente tem campos personalizados
            const response = await fetchData(url, dados)

            const temConfiguracaoPersonalizada =
              response && response.camposSelecionados && response.camposSelecionados.length > 0

            return {
              ...corte,
              temConfiguracaoPersonalizada,
              camposPersonalizados: temConfiguracaoPersonalizada ? response.camposSelecionados : [],
              camposTabela
            }
          } catch (error) {
            console.error(
              `Erro ao buscar as configurações personalizadas para o cliente ${corte.id_cliente}:`,
              error
            )
            return {
              ...corte,
              temConfiguracaoPersonalizada: false,
              camposPersonalizados: [],
              camposTabela
            }
          }
        })

        // Esperar todas as promessas serem resolvidas e, então, atualizar o estado
        const cortesAtualizados = await Promise.all(requisicoesConfiguracoes)

        // Log para verificar o resultado final dos cortes atualizados

        // Atualizar o estado com os cortes resolvidos e atualizados
        setCortes(cortesAtualizados)
      })
    } catch (error) {
      console.error('Erro ao buscar os cortes pendentes:', error)
    } finally {
      setIsLoadingCortes(false)
    }
  }

  useEffect(() => {
    const fetchValorEmbalagemCorte = async () => {
      try {
        const dados = {
          tenantId: tenantId,
          rota_base: RotaBase,
          requisicao: 'getValorEmbalagemCorte'
        }
        const url = config.host + '/configuracoesGerais'
        const response = await fetchData(url, dados)
      } catch (error) {
        console.error('Erro ao buscar o valor da embalagem do corte:', error)
      }
    }

    fetchValorEmbalagemCorte()
  }, [tenantId, RotaBase])

  useEffect(() => {
    fetchCortes()
  }, [])
  // Função que determina a largura específica para cada campo
  const getWidthForField = campo => {
    switch (campo) {
      case 'id':
        return '50px'
      case 'data_corte':
        return '100px'
      case 'data':
        return '100px'
      case 'entregar':
        return '100px'
      default:
        return 'auto'
    }
  }
  // Aplicação da função no estilo
  const estiloCampo = campo => ({
    width: getWidthForField(campo)
  })

  // Definir uma ordem específica para os campos
  const ordemPreferencial = [
    'id', // Primeiro o campo 'id'
    'data_corte', // Depois 'data_corte'
    'cliente', // Depois 'cliente'
    'descricao' // Depois 'descricao'
    // Adicione mais campos conforme necessário na ordem que desejar
  ]
  const ordenarCamposPorPreferencia = (campos, ordemPreferencial) => {
    return [...campos].sort((a, b) => {
      const indexA = ordemPreferencial.indexOf(a)
      const indexB = ordemPreferencial.indexOf(b)

      // Se o campo não está na ordem preferencial, coloque-o no final
      const posA = indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA
      const posB = indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB

      return posA - posB
    })
  }

  // Organizar campos: Primeiro os definidos na ordem preferencial, depois os demais campos
  const camposOrdenados = [
    ...ordemPreferencial,
    ...Object.keys(camposParaImpressao).filter(
      campo => !ordemPreferencial.includes(campo) && !deveExcluirCampo(campo)
    )
  ]

  const configurarCampos = async corte => {
    try {
      // Definir clienteId do corte selecionado
      const clienteId = corte.id_cliente
      setClienteSelecionado(corte)
      setIsConfiguracaoCliente(true)

      // Buscar a configuração do cliente selecionado
      const dados = {
        tenantId,
        rota_base: RotaBase,
        clienteId,
        requisicao: 'lerCamposImpressaoCorteCliente'
      }

      const url = `${config.host}/configuracoesGerais` // URL para ler os campos de impressão do cliente
      const response = await fetchData(url, dados)

      let camposIniciais = {}
      if (cortes.length > 0) {
        // Se houver cortes, adiciona todos os campos disponíveis no objeto `corte`
        camposIniciais = Object.keys(cortes[0]).reduce((acc, campo) => {
          if (!deveExcluirCampo(campo)) {
            acc[campo] = false // Inicializa todos como não selecionados
          }
          return acc
        }, {})
      }

      if (response?.camposSelecionados && response.camposSelecionados.length > 0) {
        // Marca os campos retornados pela resposta do servidor
        response.camposSelecionados.forEach(campo => {
          if (!deveExcluirCampo(campo)) {
            camposIniciais[campo] = true // Marca como selecionado
          }
        })
      } else {
        // Se nenhuma configuração do cliente for encontrada, marque os campos padrão
        camposPadraoSelecionados.forEach(campo => {
          if (!deveExcluirCampo(campo)) {
            camposIniciais[campo] = true // Marca como selecionado
          }
        })
      }

      setCamposParaImpressao(camposIniciais)
      setShowModal(true) // Abre o modal para configuração
    } catch (error) {
      console.error('Erro ao configurar os campos de impressão para o cliente:', error)
    }
  }

  const deepEqual = (a, b) => {
    if (a === b) return true

    if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
      return false
    }

    const keysA = Object.keys(a)
    const keysB = Object.keys(b)

    if (keysA.length !== keysB.length) return false

    for (let key of keysA) {
      if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
        return false
      }
    }

    return true
  }

  useEffect(() => {
    if (Array.isArray(cortes)) {
      const cortesComCamposDiferentes = cortes.map(corte => {
        const camposDiferentes = corte.camposPersonalizados
          ? corte.camposPersonalizados.filter(campo => !camposTabela.includes(campo))
          : []

        const temConfiguracaoPersonalizada = camposDiferentes.length > 0
        return {
          ...corte,
          camposDiferentes,
          temConfiguracaoPersonalizada
        }
      })

      // Verificar se `cortesComCamposDiferentes` é igual ao estado atual `cortes`
      if (!deepEqual(cortes, cortesComCamposDiferentes)) {
        setCortes(cortesComCamposDiferentes)
      }
    }
  }, [cortes, camposTabela])
  // Função para definir as medidas da etiqueta
  function definirMedidasEtiqueta() {
    return {
      largura: '566px', // Largura da etiqueta
      altura: '378px', // Altura da etiqueta
      larguraTotal: 200 // Largura total do componente impresso (tabela)
    }
  }
  return (
    <div>
      {/* Cabeçalho da Página */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
          padding: '10px',
          borderBottom: '1px solid #ccc'
        }}
      >
        <h2 style={{ margin: 0 }}>Impressão Etiqueta de Cortes</h2>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* Botão para Configurar Campos de Impressão */}
          <Button
            variant="link"
            onClick={async () => {
              setIsConfiguracaoCliente(false) // Definindo como configuração geral
              await fetchCamposImpressaoCortePadrao() // Carrega as configurações dos campos padrões do banco de dados
              setShowModal(true) // Abre o modal após carregar as configurações
            }}
            style={{
              marginRight: '10px',
              padding: '4px 8px',
              fontSize: '0.9em',
              fontWeight: 'normal',
              color: '#6c757d',
              textDecoration: 'none',
              border: 'none',
              backgroundColor: 'transparent',
              boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)', // Sombra leve para criar um efeito 3D
              transition: 'box-shadow 0.3s ease, transform 0.3s ease' // Animação suave para a sombra e transformação
            }}
            onMouseEnter={e => {
              e.target.style.boxShadow = '4px 4px 8px rgba(0, 0, 0, 0.2)' // Sombra aumentada no hover
              e.target.style.transform = 'translateY(-2px)' // Pequena elevação para o efeito 3D
            }}
            onMouseLeave={e => {
              e.target.style.boxShadow = '2px 2px 5px rgba(0, 0, 0, 0.1)' // Retorna a sombra original
              e.target.style.transform = 'translateY(0)' // Volta à posição original
            }}
          >
            Config. Padrão Campos de Impressão
          </Button>

          {/* Botão de Impressão */}
          <Button
            variant="success"
            onClick={() =>
              imprimirTabela(
                cortes,
                linhasSelecionadas,
                quantidadesImpressao,
                definirMedidasEtiqueta
              )
            }
            style={{ padding: '8px 16px' }}
          >
            Imprimir Etiquetas
          </Button>
        </div>
      </div>

      {/* Modal para Configuração dos Campos */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isConfiguracaoCliente && clienteSelecionado
              ? `Configurar Campos de Impressão para o cliente ${clienteSelecionado.cliente}`
              : 'Configurar Campos de Impressão'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.keys(camposParaImpressao)
            .filter(campo => !deveExcluirCampo(campo))
            .map(campo => (
              <div key={campo} className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={`checkbox-${campo}`}
                  checked={camposParaImpressao[campo]}
                  onChange={() =>
                    setCamposParaImpressao(prev => ({
                      ...prev,
                      [campo]: !prev[campo]
                    }))
                  }
                />
                <label className="form-check-label" htmlFor={`checkbox-${campo}`}>
                  {renameField(campo)}
                </label>
              </div>
            ))}
          {/* Alerta de aviso dentro do modal */}
          {showWarningAlert && (
            <Alert
              variant="warning"
              onClose={() => setShowWarningAlert(false)}
              dismissible
              style={{ marginLeft: '20px', minWidth: '250px', position: 'relative' }}
            >
              {alertMessage}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleModalSave}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Tabela de Cortes */}
      <div style={{ overflow: 'auto', maxHeight: '600px', fontSize: '0.8em', marginTop: '20px' }}>
        <Table striped bordered hover>
          <thead style={{ position: 'sticky', top: '0', background: '#f8f9fa' }}>
            <tr>
              {camposTabela
                .filter(campo => !deveExcluirCampo(campo))
                .map(
                  campo =>
                    camposParaImpressao[campo] && (
                      <th key={campo} style={estiloCampo(campo)}>
                        {renameField(campo)}
                      </th>
                    )
                )}
              <th style={{ width: '5%' }}>
                {/* Checkbox de Seleção Global (Opcional) */}
                <input
                  type="checkbox"
                  onChange={e => {
                    const isChecked = e.target.checked
                    const todasLinhas = {}
                    cortes.forEach(corte => {
                      todasLinhas[corte._id] = isChecked
                    })
                    setLinhasSelecionadas(todasLinhas)
                  }}
                />
              </th>
              <th style={{ width: '5%' }}>Impressões</th>
              <th style={{ width: '8%' }}>Ações</th>
            </tr>
          </thead>

          <tbody>
            {Array.isArray(cortes) &&
              cortes.map((corte, index) => (
                <tr key={index}>
                  {camposTabela
                    .filter(campo => !deveExcluirCampo(campo))
                    .map(campo => (
                      <td key={campo}>
                        {campo === 'cliente' ? (
                          <>
                            {corte[campo]}
                            {corte.temConfiguracaoPersonalizada && (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-${corte._id}`}>
                                    {(corte.camposDiferentes ?? []).length > 0 ? (
                                      <div>
                                        <strong>CAMPOS PERSONALIZADOS:</strong>
                                        <ul style={{ margin: 0, paddingLeft: '20px' }}>
                                          {ordenarCamposPorPreferencia(
                                            corte.camposDiferentes ?? [],
                                            ordemPreferencial
                                          ).map((campoPersonalizado, idx) => (
                                            <li key={idx} style={{ listStyleType: 'disc' }}>
                                              {renameField(campoPersonalizado)}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    ) : null}
                                  </Tooltip>
                                }
                              >
                                <Badge
                                  pill
                                  variant="warning"
                                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                                >
                                  Personalizado
                                </Badge>
                              </OverlayTrigger>
                            )}
                          </>
                        ) : campo === 'tamanhos' ? (
                          corte.tamanhos && typeof corte.tamanhos === 'object' ? (
                            Object.entries(corte.tamanhos)
                              .filter(([tamanho, quantidade]) => quantidade > 0)
                              .map(([tamanho, quantidade], idx, array) => (
                                <span key={tamanho}>
                                  {tamanho}: {quantidade}
                                  {idx < array.length - 1 ? ', ' : ''}
                                </span>
                              )).length > 0 ? (
                              // Exibe os tamanhos com quantidade > 0
                              Object.entries(corte.tamanhos)
                                .filter(([tamanho, quantidade]) => quantidade > 0)
                                .map(([tamanho, quantidade], idx, array) => (
                                  <span key={tamanho}>
                                    {tamanho}: {quantidade}
                                    {idx < array.length - 1 ? ', ' : ''}
                                  </span>
                                ))
                            ) : (
                              <span>N/A</span>
                            )
                          ) : (
                            <span>{corte.tamanhos || ''}</span>
                          )
                        ) : campo === 'data_corte' || campo === 'data' || campo === 'entregar' ? (
                          formatDate(corte[campo])
                        ) : (
                          corte[campo]
                        )}
                      </td>
                    ))}
                  <td>
                    <input
                      type="checkbox"
                      checked={linhasSelecionadas[corte._id] || false}
                      onChange={e => {
                        const isChecked = e.target.checked
                        setLinhasSelecionadas(prev => ({
                          ...prev,
                          [corte._id]: isChecked
                        }))
                      }}
                    />
                  </td>
                  {/* Coluna para quantidade de impressões */}
                  <td>
                    <Form.Control
                      type="number"
                      value={quantidadesImpressao[corte._id] || 1}
                      min="1"
                      onChange={e =>
                        setQuantidadesImpressao(prev => ({
                          ...prev,
                          [corte._id]: parseInt(e.target.value, 10)
                        }))
                      }
                      style={{ width: '100%' }}
                      className="editable-field"
                    />
                  </td>
                  {/* Coluna para o botão "Config. Campos" */}
                  <td style={{ textAlign: 'center' }}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-config-campos`}>
                          Configurar outros Campos para esse cliente
                        </Tooltip>
                      }
                    >
                      <Button
                        style={{
                          width: 'auto',
                          padding: '6px 12px',
                          backgroundColor: '#5bc0de',
                          borderColor: '#5bc0de'
                        }}
                        onClick={() => configurarCampos(corte)}
                      >
                        Campos
                      </Button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default ImpressaoEtiquetaCorte
