// NavbarModelagemcompleta.jsx
import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Navbar, Nav } from 'react-bootstrap'
import { AuthContext } from '../../../context/AuthContext'
// import { ShowAlertGeneric } from '/src/utils/form-utils.jsx'
import logoTopoModelagemCompleta from '../Usuarios/login/logo_topo.jpg'
import logoModaNegocios360 from '../Usuarios/login/4.png'
import nomeModaNegocios360 from '../Usuarios/login/6.png'
import Banner from '../CadastroGeral/Banner'
import { fetchData } from '../../../backend/model' // Importação de fetchData
import { config } from '../../../backend/configConexaoGeral' // Importação de config
//import CustomTooltip from './CustomTooltip' // Supondo que CustomTooltip esteja em um arquivo separado

const CustomTooltip = ({ children, setShowAlertMessage, setAlertMessage }) => {
  const [show, setShow] = useState(false)

  const [ip_atual, set_ip_atual] = useState('')
  const [ips, setIps] = useState([])

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()
  const authContextValues = useContext(AuthContext)
  const { RotaBase, tenantId } = authContextValues

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json')
        const data = await response.json()

        set_ip_atual(data.ip)
      } catch (error) {
        console.error('Ocorreu um erro ao obter o IP:', error)
      }
    }

    fetchIP()
  }, [])

  // Função para carregar os IPs permitidos
  const loadIps = async () => {
    try {
      let dados = {
        requisicao: 'RetornarTodosIps',
        rota_base: RotaBase,
        tenantId: tenantId
      }
      let url = config.host + '/liberar_ips'
      let response = await fetchData(url, dados)

      return response // Retorna os IPs obtidos
    } catch (error) {
      console.error('Ocorreu um erro ao carregar os IPs:', error)
      return []
    }
  }

  // Dependências vazias indicam que isso acontece apenas na montagem do componente
  const handleSubmit = async (
    username,
    password,
    ips,
    ip_atual,
    navigate,
    contextValues // Adiciona os valores do contexto como parâmetro
  ) => {
    const {
      login,
      logout,
      setIdNivelAcesso,
      setNivelAcesso,
      setUser,
      setSenha,
      setIsAuthenticated,
      setSuperUser,
      RotaBase,
      tenantId,
      setTenantId,
      setAcessoModulos,
      setNomeCliente,
      setEmail,
      setIdentificacaoFiscal
    } = contextValues // Desestrutura os valores necessários do contexto

    try {
      const url = `${config.host}/login`

      const dados = {
        username: username.trim(),
        password: password,
        rota_base: RotaBase,
        requisicao: 'login'
      }

      const log = await fetchData(url, dados)

      if (log && log.success) {
        // Processamento em caso de sucesso
        setTenantId(log.user.tenantId)
        setIdNivelAcesso(log.user.id_nivel_acesso)
        setNivelAcesso(log.user.nivel_acesso)
        setUser(log.user.nome_usuario)
        setSenha(log.user.email)
        setAcessoModulos(log.user.acessoModulos)
        setNomeCliente(log.user.nome)
        setEmail(log.user.email)
        setIdentificacaoFiscal(log.user.indentificacaoFiscal)
        login()

        // Após configurar o tenantId, carrega os IPs permitidos
        const loadedIps = await loadIps()

        setIps(loadedIps)

        // Verifica se o IP atual está permitido
        const ipPermitido = loadedIps.some(ip => ip.ip_liberado === ip_atual)
        if (
          !ipPermitido &&
          log.user.nivel_acesso !== 'Administrador' &&
          log.user.nivel_acesso !== 'owner'
        ) {
          setAlertMessage('IP SEM PERMISSÃO DE ACESSO!')
          setShowAlertMessage(true)
          logout() // Desloga o usuário em caso de falha de IP permitido
          return
        }

        // Caso tudo esteja certo, redireciona o usuário para a página inicial
        setErrorMessage('')
        navigate('/inicio-mc')
      } else if (log && !log.success) {
        // Processamento em caso de falha específica
        setErrorMessage(log.message || 'Falha no login')
        setAlertMessage(log.message || 'Falha no login')
        setShowAlertMessage(true)
      } else {
        // Caso a resposta não esteja no formato esperado
        throw new Error('Falha no login')
      }
    } catch (error) {
      // Lógica para tratar erros genéricos
      setUser('')
      setSenha('')
      logout()
      setErrorMessage('Erro ao tentar se conectar ao servidor.')
      if (error.message) {
        console.error('Erro na autenticação:', error)
        setAlertMessage(error.message)
        setShowAlertMessage(true)
      }
    }
  }

  const showTip = () => {
    setShow(true)
  }

  const hideTip = () => {
    setTimeout(() => {
      setShow(false)
    }, 2000)
  }

  const handleFormSubmit = event => {
    event.preventDefault()

    handleSubmit(
      username,
      password,
      ips,
      ip_atual,
      navigate,

      authContextValues // Passa os valores do contexto como argumento para a função
    )
  }
  return (
    <div
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      style={{
        position: 'relative',
        display: 'inline-block'
      }}
    >
      {children}
      {show && (
        <div className="login-container-homeMC">
          <form className="login-formMC">
            <input
              className="inputsLoginMC"
              type="text"
              placeholder="Usuário ou e-mail"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <input
              className="inputsLoginMC"
              type="password"
              placeholder="Senha"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <a href="#" className="forgot-password">
              Esqueceu sua senha?
            </a>
            <button type="submit" onClick={handleFormSubmit} className="login-button">
              Entrar
            </button>
          </form>
          {/* <div class='social-login'>
                <button class='facebook-login'>
                  <img src='facebook-icon.png' alt='Facebook' /> Facebook
                </button>
                <button class='google-login'>
                  <img src='google-icon.png' alt='Google' /> Google
                </button>
              </div> */}
        </div>
      )}
    </div>
  )
}
export const NavbarModelagemcompleta = ({ setShowAlertMessage, setAlertMessage }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const hostname = window.location.hostname
  const pathname = location.pathname

  const isModaNegocios360 =
    (hostname && hostname.includes('moda.negocios360')) ||
    (pathname && pathname.includes('moda.negocios360'))
  // console.log('isModaNegocios360', isModaNegocios360)
  const logoTopo = isModaNegocios360 ? logoModaNegocios360 : logoTopoModelagemCompleta
  const titleContent = isModaNegocios360 ? (
    <img src={nomeModaNegocios360} alt="Moda Negócios 360" style={{ height: '150px' }} />
  ) : (
    'Modelagem Completa'
  )

  // Definindo alturas com base na condição
  const logoTopoHeight = isModaNegocios360 ? '55px' : '60px' // Ajuste os valores conforme necessário
  const logoTopoWidth = isModaNegocios360 ? '65px' : '70px' // Ajuste os valores conforme necessário
  const titleHeight = isModaNegocios360 ? '60px' : undefined // Mantém a altura somente se for uma imagem

  const renderTitle = () => {
    if (React.isValidElement(titleContent)) {
      const style = titleHeight ? { height: titleHeight } : {}
      return React.cloneElement(titleContent, {
        style: { ...titleContent.props.style, ...style }
      })
    }
    return (
      <Banner
        title={titleContent}
        fontFamily="Josefin Sans"
        color="#102a43"
        width="100%"
        fontSize="30px"
        classEfeito3dMouseOver=""
      />
    )
  }

  return (
    <Navbar bg="light" expand="lg" className="index-top-header-homeMC">
      <div className="d-flex align-items-center">
        <Navbar.Brand href="" className="d-flex align-items-center">
          <div onClick={() => navigate('/home')}>
            <img
              src={logoTopo}
              alt="Logo"
              className="logo-topo-homePF"
              style={{ height: logoTopoHeight, width: logoTopoWidth }} // Aplica a altura e largura do logoTopo
            />
          </div>
        </Navbar.Brand>
        <div className="custom-title-image">{renderTitle()}</div>
      </div>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto menu-items-container">
          <Nav.Link onClick={() => navigate('/funcionalidades')} id="index-link-nav-link">
            Funcionalidades
          </Nav.Link>
          <Nav.Link onClick={() => navigate('/planos-e-precos-moda')} id="index-link-nav-link">
            Planos sob consulta
          </Nav.Link>
          {/* <Nav.Link href="#afiliado" id="index-link-nav-link">
            Seja um Afiliado
          </Nav.Link> */}
          <Nav.Link onClick={() => navigate('/fale-conosco')} id="index-link-nav-link">
            Fale conosco
          </Nav.Link>
          <Nav.Link onClick={() => navigate('/cadastro-tenant')} className="experimente-gratis-btn">
            Experimente
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <CustomTooltip setShowAlertMessage={setShowAlertMessage} setAlertMessage={setAlertMessage}>
        <div className="entrar-link" onClick={() => navigate('/login')}>
          Entrar
        </div>
      </CustomTooltip>
    </Navbar>
  )
}
