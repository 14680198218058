import { useState } from 'react'
import PropTypes from 'prop-types'
import { fetchData } from '../../../backend/model'
import { config } from '../../../backend/configConexaoGeral'
import { ShowAlertGeneric } from '../../../utils/form-utils.jsx'
import { NavbarModelagemcompleta } from '../Home/NavbarModelagemcompleta.jsx'
import { CustomField } from '../../CustomField/custom-field'
import { CPFUtils } from '../../../utils/cpf-utils'
import { CNPJUtils } from '../../../utils/cnpj-utils'
import bcrypt from 'bcryptjs'
import DOMPurify from 'dompurify'
import logoTopoPrecoFacilMarket from '../../../../PRECO_FACIL_MARKET/src/components/pages/Usuarios/login/logo_topo.jpg'
import logoTopoModelagemCompleta from '../Usuarios/login/logo_topo.jpg'
import sheetsConfig from '../../../../src/config/sheetsConfig'
import {
  SalvarCategoria,
  SalvarAssociacaoServicoCategoria
} from '../../../../src/components/pages/CadastroCategoria/cadastro-categoria.jsx'
const CadastroTenant = ({ sistema, titulo, descricao1, descricao2 }) => {
  // Definição das PropTypes
  console.log('sistema', sistema)
  CadastroTenant.propTypes = {
    sistema: PropTypes.string.isRequired,
    titulo: PropTypes.string.isRequired,
    descricao1: PropTypes.string.isRequired,
    descricao2: PropTypes.string.isRequired
  }

  // Estado principal do componente
  const [tenantInfo, setTenantInfo] = useState({
    showAlertMessage: false,
    alertMessage: '',
    alertVariant: 'success',
    nome: '',
    tipoEntidade: 'Pessoa Física',
    identificacaoFiscal: '',
    designacaoSocial: '',
    endereco: '',
    telefone: '',
    etapa: 1,
    usuario: '',
    senha: '',
    email: '',
    rota_base: sistema, // obs: sistema deve ser sempre o nome da rota_base
    // Novo campo para módulos
    modulos: {
      corte: false,
      modelagem: false,
      plotagem: false
    }
  })

  const [showAlert, setShowAlert] = useState(false)
  const [isValidCpf, setIsValidCpf] = useState(true)
  const [isValidCnpj, setIsValidCnpj] = useState(true)

  // Estado para alertas específicos
  const [alertInfo, setAlertInfo] = useState({
    usuario: { showAlert: false, alertMessage: '' },
    email: { showAlert: false, alertMessage: '', emailVazio: false },
    senha: { showAlert: false, alertMessage: '' },
    cpf: { showAlert: false, alertMessage: '' },
    cnpj: { showAlert: false, alertMessage: '' },
    modulos: { showAlert: false, alertMessage: '' }, // Novo alerta para módulos
    designacaoSocial: { showAlert: false, alertMessage: '' }, // Novo alerta para designacaoSocial
    identificacaoFiscal: { showAlert: false, alertMessage: '' } // Novo alerta para identificacaoFiscal
  })

  // Função para verificar e atualizar alerta de email
  const verificarEAtualizarAlertaEmail = () => {
    const emailAlert = alertInfo.email.alertMessage
    const emailAlertExists = alertInfo.email.showAlert

    if (emailAlertExists && emailAlert.includes('Este e-mail já está vinculado a uma conta')) {
      setAlertInfo(prev => ({
        ...prev,
        email: {
          ...prev.email,
          showAlert: true,
          alertMessage: emailAlert
        }
      }))
      return true // Impede avançar
    }
    return false // Permite avançar
  }

  // Função para verificar e atualizar alerta de usuário
  const verificarEAtualizarAlertaUsuario = () => {
    const usuarioAlert = alertInfo.usuario.alertMessage
    const usuarioAlertExists = alertInfo.usuario.showAlert

    if (usuarioAlertExists && usuarioAlert.includes('não está disponível!')) {
      setAlertInfo(prev => ({
        ...prev,
        usuario: {
          ...prev.usuario,
          showAlert: true,
          alertMessage: usuarioAlert
        }
      }))
      return true // Impede avançar
    }
    return false // Permite avançar
  }

  // Função para avançar para a segunda etapa
  const avancarParaEtapa2 = e => {
    e.preventDefault()

    // Reset alertInfo para não mostrar alertas anteriores
    const newAlertInfo = {
      usuario: { showAlert: false, alertMessage: '' },
      email: { showAlert: false, alertMessage: '', emailVazio: false },
      senha: { showAlert: false, alertMessage: '' },
      cpf: { showAlert: false, alertMessage: '' },
      cnpj: { showAlert: false, alertMessage: '' },
      modulos: { showAlert: false, alertMessage: '' }, // Reset alerta de módulos
      designacaoSocial: { showAlert: false, alertMessage: '' }, // Reset alerta de designacaoSocial
      identificacaoFiscal: { showAlert: false, alertMessage: '' } // Reset alerta de identificacaoFiscal
    }

    let isValid = true

    // Validação do campo usuário
    if (!tenantInfo.usuario || tenantInfo.usuario.length < 3 || tenantInfo.usuario.length > 15) {
      newAlertInfo.usuario = {
        showAlert: true,
        alertMessage: 'Mínimo 3 e no máximo 15 caracteres'
      }
      isValid = false
      console.log('Validação do usuário falhou.')
    }

    // Validação do campo email
    if (!tenantInfo.email) {
      newAlertInfo.email = {
        showAlert: true,
        alertMessage: 'Preencha o campo e-mail',
        emailVazio: true
      }
      isValid = false
      console.log('Validação do email falhou.')
    }

    // Validação do campo senha
    if (!tenantInfo.senha || tenantInfo.senha.length < 8) {
      newAlertInfo.senha = {
        showAlert: true,
        alertMessage: 'A senha deve ter no mínimo 8 caracteres'
      }
      isValid = false
      console.log('Validação da senha falhou.')
    }

    // Atualiza o estado de alertInfo com os novos valores
    setAlertInfo(newAlertInfo)

    // Verifica se há problemas com email ou usuário
    let deveInterromper = verificarEAtualizarAlertaEmail()
    if (deveInterromper) {
      console.log('Interrompendo avanço devido a problemas com o email.')
      return
    }

    deveInterromper = verificarEAtualizarAlertaUsuario()
    if (deveInterromper) {
      console.log('Interrompendo avanço devido a problemas com o usuário.')
      return
    }

    // Se todos os campos forem válidos, avança para a próxima etapa
    if (isValid) {
      setTenantInfo(prevState => ({
        ...prevState,
        etapa: 2
      }))
    }
  }

  // Função para consultar o locatário no backend
  const ConsultarLocatario = async (campo_consulta, valor_consulta) => {
    try {
      const url = `${config.host}/tenants`

      const dados = {
        campoConsulta: campo_consulta,
        valorConsulta: valor_consulta,
        rota_base: sistema,
        requisicao: 'consultar_locatario'
      }

      const response = await fetchData(url, dados)

      if (response && response.length > 0) {
        return response
      } else {
        return null
      }
    } catch (error) {
      console.error('Erro ao consultar o tenant:', error)
      return null
    }
  }

  // Função para manipular a submissão do formulário
  const handleSubmit = async event => {
    event.preventDefault()
    // Validação de seleção de módulos
    const newAlertInfo = {
      usuario: { showAlert: false, alertMessage: '' },
      email: { showAlert: false, alertMessage: '', emailVazio: false },
      senha: { showAlert: false, alertMessage: '' },
      cpf: { showAlert: false, alertMessage: '' },
      cnpj: { showAlert: false, alertMessage: '' },
      modulos: { showAlert: false, alertMessage: '' }, // Reset alerta de módulos
      designacaoSocial: { showAlert: false, alertMessage: '' }, // Reset alerta de designacaoSocial
      identificacaoFiscal: { showAlert: false, alertMessage: '' } // Reset alerta de identificacaoFiscal
    }

    let isValid = true

    // Validação de Módulos
    const { corte, modelagem, plotagem } = tenantInfo.modulos
    if (!corte && !modelagem && !plotagem) {
      newAlertInfo.modulos = {
        showAlert: true,
        alertMessage: 'Selecione pelo menos um módulo para continuar.'
      }

      // Atualiza o estado de alertInfo com os novos valores
      setAlertInfo(newAlertInfo)

      // Adicione as seguintes linhas para atualizar tenantInfo.alertMessage e showAlert
      setTenantInfo(prev => ({
        ...prev,
        showAlert: true,
        alertMessage: newAlertInfo.modulos.alertMessage,
        alertVariant: 'warning'
      }))
      isValid = false
      console.log('Validação dos módulos falhou.')
    }

    // Validação do campo Identificação Fiscal
    if (!tenantInfo.identificacaoFiscal) {
      newAlertInfo.identificacaoFiscal = {
        showAlert: true,
        alertMessage: 'Preencha o campo Identificação Fiscal.'
      }

      setAlertInfo(newAlertInfo)

      setTenantInfo(prev => ({
        ...prev,
        showAlert: true,
        alertMessage: newAlertInfo.identificacaoFiscal.alertMessage,
        alertVariant: 'warning'
      }))

      isValid = false
      console.log('Identificação Fiscal não preenchida.')
    } else {
      // Validação do CPF ou CNPJ
      if (tenantInfo.tipoEntidade === 'Pessoa Física') {
        if (!isValidCpf) {
          newAlertInfo.cpf = {
            showAlert: true,
            alertMessage: 'CPF inválido. Por favor, corrija antes de submeter.'
          }

          setAlertInfo(newAlertInfo)

          setTenantInfo(prev => ({
            ...prev,
            showAlert: true,
            alertMessage: newAlertInfo.cpf.alertMessage,
            alertVariant: 'warning'
          }))
          isValid = false
          console.log('CPF inválido.')
        }
      } else {
        if (!isValidCnpj) {
          newAlertInfo.cnpj = {
            showAlert: true,
            alertMessage: 'CNPJ inválido. Por favor, corrija antes de submeter.'
          }

          setAlertInfo(newAlertInfo)

          setTenantInfo(prev => ({
            ...prev,
            showAlert: true,
            alertMessage: newAlertInfo.cnpj.alertMessage,
            alertVariant: 'warning'
          }))
          isValid = false
          console.log('CNPJ inválido.')
        }
      }
    }

    // Validação do campo Nome Completo ou Razão Social
    if (!tenantInfo.designacaoSocial.trim()) {
      newAlertInfo.designacaoSocial = {
        showAlert: true,
        alertMessage: 'Preencha o campo Nome Completo/Razão Social.'
      }
      isValid = false
      setAlertInfo(newAlertInfo)

      setTenantInfo(prev => ({
        ...prev,
        showAlert: true,
        alertMessage: newAlertInfo.designacaoSocial.alertMessage,
        alertVariant: 'warning'
      }))
      console.log('Nome Completo/Razão Social não preenchido.')
    }

    // Atualiza o estado de alertInfo com os novos valores

    setAlertInfo(newAlertInfo)

    if (!isValid) {
      setShowAlert(true)
      return // Não prossegue com o submit
    }

    // Encriptação da senha
    const saltRounds = 10
    const hashPassword = async senha => {
      const salt = await bcrypt.genSalt(saltRounds)
      return bcrypt.hash(senha, salt)
    }
    const senhaEncriptada = await hashPassword(tenantInfo.senha)

    try {
      const url = `${config.host}/tenants`
      const selectedModules = Object.keys(tenantInfo.modulos).filter(
        modulo => tenantInfo.modulos[modulo]
      )

      const dados = {
        nome: tenantInfo.designacaoSocial.trim(),
        usuario: tenantInfo.usuario.trim(),
        tipoLocatario: tenantInfo.tipoEntidade,
        identificacaoFiscal: tenantInfo.identificacaoFiscal,
        endereco: tenantInfo.endereco,
        telefone: tenantInfo.telefone,
        email: tenantInfo.email,
        senha: senhaEncriptada,
        rota_base: tenantInfo.rota_base,
        modulos: selectedModules, // Inclui os módulos selecionados
        requisicao: 'cadastro_tenant'
      }
      let response
      response = await fetchData(url, dados)

      if (response) {
        // Reset do estado após sucesso
        setTenantInfo({
          showAlertMessage: true,
          alertMessage: 'Valide seu Cadastro no email que foi enviado pra você!',
          nome: '',
          tipoEntidade: 'Pessoa Física',
          identificacaoFiscal: '',
          designacaoSocial: '',
          endereco: '',
          telefone: '',
          etapa: 1,
          usuario: '',
          senha: '',
          email: '',
          rota_base: sistema,
          modulos: {
            corte: false,
            modelagem: false,
            plotagem: false
          }
        })
        setShowAlert(true)
        // Se a rota base for "moda.negocios360", inserir a categoria "TECIDO"
        if (tenantInfo.rota_base === 'moda.negocios360') {
          await inserirCategoriaTecido(response.tenantIdTemp, 'moda.negocios360') // Assume que o backend retorna o tenantId recém-criado
        }
      } else {
        // Exibe uma mensagem de erro
        if (response.message) {
          setTenantInfo(prev => ({
            ...prev,
            showAlertMessage: true,
            alertMessage: response.message
          }))
        }
      }
    } catch (error) {
      console.error('Erro ao cadastrar o tenant:', error)
      setTenantInfo(prev => ({
        ...prev,
        showAlertMessage: true,
        alertMessage: 'Erro ao cadastrar o tenant. Por favor, tente novamente.'
      }))
    }
  }

  // Função para manipular mudanças nos campos de input
  const handleChange = e => {
    const { name, value, type, checked } = e.target

    // Verifica se é um checkbox para módulos
    if (type === 'checkbox' && name in tenantInfo.modulos) {
      setTenantInfo(prevState => {
        const updatedModulos = { ...prevState.modulos, [name]: checked }
        const anyModuleSelected = Object.values(updatedModulos).some(Boolean)

        return {
          ...prevState,
          modulos: updatedModulos,
          // Se algum módulo estiver selecionado, esconda o alerta
          showAlert: anyModuleSelected ? false : prevState.showAlert,
          alertMessage: anyModuleSelected ? '' : prevState.alertMessage,
          // Opcional: resetar alertVariant se necessário
          alertVariant: anyModuleSelected ? 'success' : prevState.alertVariant
        }
      })
      setShowAlert(false)
    } else {
      setTenantInfo(prevState => ({
        ...prevState,
        [name]: value
      }))
    }
  }

  // Função para manipular o blur nos campos de input
  const handleBlur = async (campo_consulta, valor_consulta) => {
    if (valor_consulta !== '') {
      const existe = await ConsultarLocatario(campo_consulta, valor_consulta)

      let mensagem = ''
      if (campo_consulta === 'usuario' && existe) {
        mensagem = `<strong>${DOMPurify.sanitize(valor_consulta)}</strong> não está disponível!`
      } else if (campo_consulta === 'email' && existe) {
        mensagem =
          'Este e-mail já está vinculado a uma conta. Caso este seja o seu endereço, você gostaria de recuperar a sua senha?'
      }
      const sanitizedMessage = sanitizeHTML(mensagem)

      setAlertInfo(prev => ({
        ...prev,
        [campo_consulta]: {
          showAlert: existe,
          alertMessage: sanitizedMessage
        }
      }))
    }
  }

  // Função para limpar campos de input
  const handleClear = fieldName => {
    setTenantInfo(prevState => ({
      ...prevState,
      [fieldName]: ''
    }))
    // Limpa alertas específicos se necessário
    setAlertInfo(prev => ({
      ...prev,
      [fieldName]: {
        showAlert: false,
        alertMessage: ''
      }
    }))
  }

  // Função para voltar para a primeira etapa
  const voltarParaEtapa1 = () => {
    setTenantInfo(prevState => ({
      ...prevState,
      etapa: 1
    }))
  }

  // Função para sanitizar HTML
  const sanitizeHTML = htmlContent => {
    return DOMPurify.sanitize(htmlContent, { USE_PROFILES: { html: true } })
  }

  // Manipulação de CPF/CNPJ
  const handleCpfCnpjChange = e => {
    const { value } = e.target
    if (tenantInfo.tipoEntidade === 'Pessoa Física') {
      const formattedCPF = CPFUtils.formatCPF(value)
      setTenantInfo(prevState => ({
        ...prevState,
        identificacaoFiscal: formattedCPF
      }))
    } else {
      const formattedCNPJ = CNPJUtils.formatCNPJ(value)
      setTenantInfo(prevState => ({
        ...prevState,
        identificacaoFiscal: formattedCNPJ
      }))
    }
  }

  const handleCpfCnpjBlur = () => {
    const value = tenantInfo.identificacaoFiscal
    if (tenantInfo.tipoEntidade === 'Pessoa Física') {
      const isValid = CPFUtils.validarCPF(value)

      setIsValidCpf(isValid)
      setAlertInfo(prev => ({
        ...prev,
        cpf: {
          showAlert: !isValid,
          alertMessage: isValid ? '' : 'CPF inválido.'
        }
      }))
    } else {
      const isValid = CNPJUtils.validarCNPJ(value)
      setIsValidCnpj(isValid)
      setAlertInfo(prev => ({
        ...prev,
        cnpj: {
          showAlert: !isValid,
          alertMessage: isValid ? '' : 'CNPJ inválido.'
        }
      }))
    }
  }

  return (
    <div>
      <NavbarModelagemcompleta />
      <ShowAlertGeneric
        showAlert={showAlert}
        message={tenantInfo.alertMessage}
        isBlurred={true}
        setShowAlert={setShowAlert}
        variant={tenantInfo.alertVariant}
        className="show-alert-genericPF" // Mantém o prefixo "PF"
        fontSize="1.5rem"
        dismissible={true}
        timeOut={tenantInfo.alertVariant === 'warning' ? 3000 : 1000000}
      />
      <div className={getClassName('main-container', sistema)}>
        <h1 className={getClassName('main-title', sistema)}>{titulo}</h1>
        <h3>Faça seu cadastro</h3>
        <div className={getClassName('content', sistema)}>
          <div className={getClassName('text-container', sistema)}>
            <div className={getClassName('text-group', sistema)}>
              <h2 className={getClassName('subtitle', sistema)}>{descricao1}</h2>
              <p className={getClassName('description-text', sistema)}>{descricao2}</p>
            </div>
          </div>
          <div className={getClassName('form-container', sistema)}>
            <form onSubmit={tenantInfo.etapa === 1 ? avancarParaEtapa2 : handleSubmit}>
              {/* Etapa 1: Informações Básicas */}
              {tenantInfo.etapa === 1 && (
                <div className={getClassName('signup-form-card', sistema)}>
                  {/* Campo de Usuário */}
                  <div className="relative-container">
                    <CustomField
                      buttonTop="0px"
                      buttonLeft="-2px"
                      label="Usuário"
                      labelVisible={true}
                      placeholder="Digite o nome para o seu usuário"
                      name="usuario"
                      value={tenantInfo.usuario}
                      onChange={handleChange}
                      onBlur={() => handleBlur('usuario', tenantInfo.usuario)}
                      onClear={() => handleClear('usuario')}
                    />
                    <div
                      className={getClassName('user-alert-container', sistema)}
                      style={{ minHeight: '10px' }}
                    >
                      {alertInfo.usuario.showAlert && (
                        <div
                          className={getClassName('user-alert-container', sistema)}
                          dangerouslySetInnerHTML={{
                            __html: alertInfo.usuario.alertMessage
                          }}
                        />
                      )}
                    </div>
                  </div>

                  {/* Campo de E-mail */}
                  <div className="relative-container">
                    <CustomField
                      buttonTop="0px"
                      buttonLeft="-2px"
                      label="Seu melhor e-mail"
                      labelVisible={true}
                      placeholder="Digite o seu e-mail"
                      name="email"
                      value={tenantInfo.email}
                      onChange={handleChange}
                      onBlur={() => handleBlur('email', tenantInfo.email)}
                      onClear={() => handleClear('email')}
                      required
                    />
                    <div className={getClassName('email-alert-container', sistema)}>
                      {alertInfo.email.showAlert && (
                        <div
                          className={
                            alertInfo.email.emailVazio
                              ? `${getClassName(
                                  'email-alert-containerBase',
                                  sistema
                                )} ${getClassName('emailVazio-alert-container', sistema)}`
                              : getClassName('email-alert-container', sistema)
                          }
                        >
                          <div>{alertInfo.email.alertMessage}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Campo de Senha */}
                  <div className="relative-container">
                    <CustomField
                      buttonTop="0px"
                      buttonLeft="-2px"
                      label="Senha"
                      labelVisible={true}
                      placeholder="8 ou mais caracteres"
                      name="senha"
                      value={tenantInfo.senha}
                      onChange={handleChange}
                      onClear={() => handleClear('senha')}
                      required
                    />
                    <div className={getClassName('email-alert-container', sistema)}>
                      {alertInfo.senha.showAlert && (
                        <div
                          className={`${getClassName(
                            'email-alert-containerBase',
                            sistema
                          )} ${getClassName('emailVazio-alert-container', sistema)}`}
                        >
                          <div>{alertInfo.senha.alertMessage}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Botão Avançar */}
                  <button
                    type="submit"
                    className={`btn btn-primary ${getClassName('avancar-btn', sistema)}`}
                  >
                    Avançar
                  </button>
                </div>
              )}

              {/* Etapa 2: Informações Fiscais e Seleção de Módulos */}
              {tenantInfo.etapa === 2 && (
                <div className={getClassName('signup-form-card', sistema)}>
                  {/* Seleção do Tipo de Entidade */}
                  <div className={getClassName('button-group', sistema)}>
                    <label className={getClassName('radio-label', sistema)}>
                      <input
                        type="radio"
                        value="Pessoa Física"
                        name="tipoEntidade"
                        checked={tenantInfo.tipoEntidade === 'Pessoa Física'}
                        onChange={handleChange}
                      />
                      Pessoa Física
                    </label>
                    <label className={getClassName('radio-label', sistema)}>
                      <input
                        type="radio"
                        value="Pessoa Jurídica"
                        name="tipoEntidade"
                        checked={tenantInfo.tipoEntidade === 'Pessoa Jurídica'}
                        onChange={handleChange}
                      />
                      Pessoa Jurídica
                    </label>
                  </div>

                  {/* Campo de Identificação Fiscal */}
                  <div className="relative-container">
                    <CustomField
                      buttonTop="-8px"
                      buttonLeft="-6px"
                      label="Identificação Fiscal"
                      labelVisible={true}
                      name="identificacaoFiscal"
                      placeholder={tenantInfo.tipoEntidade === 'Pessoa Física' ? 'CPF' : 'CNPJ'}
                      value={tenantInfo.identificacaoFiscal}
                      onClear={() => handleClear('identificacaoFiscal')}
                      onChange={handleCpfCnpjChange}
                      onBlur={handleCpfCnpjBlur}
                    />
                    <div
                      className={`${getClassName(
                        'email-alert-containerBase',
                        sistema
                      )} ${getClassName(
                        tenantInfo.tipoEntidade === 'Pessoa Física'
                          ? 'CPF-alert-contaner'
                          : 'CNPJ-alert-contaner',
                        sistema
                      )}`}
                    >
                      {tenantInfo.tipoEntidade === 'Pessoa Física' &&
                        alertInfo.cpf.showAlert &&
                        alertInfo.cpf.alertMessage}
                      {tenantInfo.tipoEntidade === 'Pessoa Jurídica' &&
                        alertInfo.cnpj.showAlert &&
                        alertInfo.cnpj.alertMessage}
                      {alertInfo.identificacaoFiscal.showAlert &&
                        alertInfo.identificacaoFiscal.alertMessage}
                    </div>
                  </div>

                  {/* Campo de Nome Completo ou Razão Social */}
                  <div className="relative-container">
                    <CustomField
                      buttonTop="-8px"
                      buttonLeft="-6px"
                      label={
                        tenantInfo.tipoEntidade === 'Pessoa Física'
                          ? 'Nome Completo'
                          : 'Razão Social'
                      }
                      labelVisible={true}
                      placeholder={
                        tenantInfo.tipoEntidade === 'Pessoa Física'
                          ? 'Digite o Nome Completo'
                          : 'Digite a Razão Social'
                      }
                      name="designacaoSocial"
                      value={tenantInfo.designacaoSocial}
                      onChange={handleChange}
                      onClear={() => handleClear('designacaoSocial')}
                    />
                    <div className={getClassName('designacaoSocial-alert-container', sistema)}>
                      {alertInfo.designacaoSocial.showAlert && (
                        <div className={getClassName('designacaoSocial-alert', sistema)}>
                          {alertInfo.designacaoSocial.alertMessage}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Seleção de Módulos */}
                  <div className={getClassName('module-selection', sistema)}>
                    <h4>Marque os módulos que deseja ativar:</h4>
                    <div className={getClassName('checkbox-group', sistema)}>
                      {/* Checkbox Corte */}
                      <label className={getClassName('checkbox-label', sistema)}>
                        <input
                          type="checkbox"
                          name="corte"
                          checked={tenantInfo.modulos.corte}
                          onChange={handleChange}
                        />
                        <span className="custom-checkboxMC corteMC"></span>
                        <span className="checkbox-textMC corteMC">Corte</span>{' '}
                        {/* Classe específica adicionada */}
                        <span className="tooltip-textMC">
                          Gerencie seu setor de Corte de forma eficiente. Assinatura mensal:
                          R$129,00
                        </span>
                      </label>

                      {/* Checkbox Modelagem */}
                      <label className={getClassName('checkbox-label', sistema)}>
                        <input
                          type="checkbox"
                          name="modelagem"
                          checked={tenantInfo.modulos.modelagem}
                          onChange={handleChange}
                        />
                        <span className="custom-checkboxMC modelagemMC"></span>
                        <span className="checkbox-textMC modelagemMC">Modelagem</span>{' '}
                        {/* Classe específica adicionada */}
                        <span className="tooltip-textMC">
                          Gerencie seu setor de Modelagem de forma eficiente. Assinatura mensal:
                          R$59,90
                        </span>
                      </label>

                      {/* Checkbox Plotagem */}
                      <label className={getClassName('checkbox-label', sistema)}>
                        <input
                          type="checkbox"
                          name="plotagem"
                          checked={tenantInfo.modulos.plotagem}
                          onChange={handleChange}
                        />
                        <span className="custom-checkboxMC plotagemMC"></span>
                        <span className="checkbox-textMC plotagemMC">Plotagem</span>{' '}
                        {/* Classe específica adicionada */}
                        <span className="tooltip-textMC">
                          Gerencie seu setor de Plotagem de forma eficiente. Assinatura mensal:
                          R$59,90
                        </span>
                      </label>
                    </div>
                    {alertInfo.modulos.showAlert && (
                      <div className={getClassName('module-alert-container', sistema)}>
                        <div>{alertInfo.modulos.alertMessage}</div>
                      </div>
                    )}
                  </div>

                  {/* Botões Voltar e Cadastrar */}
                  <div className={getClassName('button-group', sistema)}>
                    <button
                      type="button"
                      className={`btn btn-secondary ${getClassName('voltar-btn', sistema)}`}
                      onClick={voltarParaEtapa1}
                    >
                      Voltar
                    </button>
                    <button
                      type="submit"
                      className={`btn btn-success ${getClassName('submit-btn', sistema)}`}
                    >
                      Cadastrar
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CadastroTenant

export const getLogoTopo = sistema => {
  return sistema === 'modelagemcompleta' || sistema === 'moda.negocios360'
    ? logoTopoModelagemCompleta
    : logoTopoPrecoFacilMarket
}

export const getTitle = sistema => {
  return sistema === 'modelagemcompleta' || sistema === 'moda.negocios360'
    ? 'Modelagem Completa'
    : 'Preço Fácil Market'
}

export const getClassName = (baseClassName, sistema) => {
  return `${baseClassName}${
    sistema === 'modelagemcompleta' || sistema === 'moda.negocios360' ? 'MC' : 'PF'
  }`
}

const inserirCategoriaTecido = async (tenantId, RotaBase) => {
  try {
    // Definir os dados da categoria "TECIDO"
    const cadastroDados = {
      informacoes: {
        Nome_Categoria: 'TECIDO',
        ID_Categoria_Pai: null,
        Descricao: 'Categoria automática para gerenciamento de tecidos',
        ATIVO: true,
        rota_base: RotaBase,
        tenantId: tenantId
      },
      rota: 'funcoesGenericas',
      requisicao: 'enviar',
      pagina: sheetsConfig['cadastroCategoria'].tabs.categorias
    }
    console.log('cadastroDados', cadastroDados)
    // Parâmetros para a função SalvarCategoria
    const params = {
      cadastroDados: cadastroDados,
      clienteId: tenantId,
      clienteData: null // Não temos dados adicionais do cliente aqui
    }

    // Chamar a função para salvar a nova categoria
    const idCategoria = await SalvarCategoria(false, params)
    if (idCategoria) {
      console.log('Categoria "TECIDO" criada com sucesso!')

      // Após criar a categoria, associar ao serviço "Corte" (ID 3)
      const associacaoResponse = await SalvarAssociacaoServicoCategoria(
        3,
        idCategoria,
        tenantId,
        RotaBase
      )
      if (associacaoResponse) {
        console.log('Categoria "TECIDO" associada ao serviço "Corte" com sucesso!')
      } else {
        console.error('Erro ao associar a categoria "TECIDO" ao serviço "Corte".')
      }
    } else {
      console.error('Erro ao criar a categoria "TECIDO".')
    }
  } catch (error) {
    console.error('Erro ao inserir a categoria "TECIDO" e associá-la ao serviço:', error)
  }
}
