import { Alert } from 'react-bootstrap'

export const CustomAlert = ({ showAlert, message, setShowAlert }) => {
  if (!showAlert) return null

  return (
    <Alert
      variant="danger"
      onClose={() => setShowAlert(false)}
      dismissible
      style={{ width: '500px' }}
      className="d-flex justify-content-center"
    >
      {message}
    </Alert>
  )
}
