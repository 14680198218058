//routePrecoFacilMarket
import { useEffect, useContext } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Home from '../../PRECO_FACIL_MARKET/src/components/pages/Home/index'
import Inicio from '../../PRECO_FACIL_MARKET/src/components/pages/Inicio/inicio'
import UserRegistration from '../components/pages/Usuarios/usuarios'
import ListaProdutosMercadoLivre from '../components/pages/Marketplaces/MercadoLivre/Produtos/listagem'
import CadastroProduto from '../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CadastroProduto/cadastro-produto'
import EditarProduto from '../../PRECO_FACIL_MARKET/src/components/pages/Produtos/EdicaoProduto/edicao-produto'
import CadastroCategoria from '../../PRECO_FACIL_MARKET/src/components/pages/Categorias/CadastroCategoria/cadastro-categoria'
import EditarCategoria from '../../PRECO_FACIL_MARKET/src/components/pages/Categorias/EdicaoCategoria/edicao-categoria'
import AccessLevelRegistration from '../components/pages/Usuarios/NivelAcesso/nivel_acesso'
import IpRegistration from '../components/pages/Usuarios/login/GerenciadorDeIPs'
import ConfiguracaoUsuarioPrecoFacilMarket from '../../PRECO_FACIL_MARKET/src/components/pages/Usuarios/NivelAcesso/config_niveis_acesso'
import { AuthContext } from '../context/AuthContext'
import AcessoNegado from '../components/pages/Usuarios/login/AcessoNegado'
import CadastroSubSistema from '../components/pages/CadastroSubSistema/cadastro-sub-sistema'
import PrecoFacilMarketLogin from '../../PRECO_FACIL_MARKET/src/components/pages/Usuarios/login/login'
import PrecoFacilMarketHome from '../../PRECO_FACIL_MARKET/src/components/pages/Home/index'
import CadastroMarketPlace from '../../PRECO_FACIL_MARKET/src/components/pages/Marketplaces/CadastroMarketplace/cadastro-marketplace'
import EditarMarketplace from '../../PRECO_FACIL_MARKET/src/components/pages/Marketplaces/EdicaoMarketplace/edicao-marketplace'
import CadastroRegrasDescontoFrete from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/regra-frete'
import CadastroRegrasPesoFrete from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/regras-peso-frete'
import RegraMagalu from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraMagalu/regra-magalu'
import CadastroRegrasFreteAmVv from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/cadastro-regras-freteAmVv'
import ModelagemCompletaTenants from '../components/pages/Tenant/tenant'
import CalculoPrecoPF from '../../PRECO_FACIL_MARKET/src/components/pages/Produtos/CalculoPreco/calculo-precoPF.jsx'
import CadastroRegraDescontoFreteML from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/cadastro-regras-freteML'
import ConfiguracaoRegrasFreteMercadoLivre from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegrasMercadoLivre/config_regras_frete_ML'
import SelecionarGrupoVendedor from '../../PRECO_FACIL_MARKET/src/components/pages/Regras/RegraFrete/ConfigRegraAmericanas/config-regra-americanas'
import CadastroValorImposto from '../../PRECO_FACIL_MARKET/src/components/pages/CadastroImposto/cadastro-imposto'
import TenantMarketplacesConfig from '../../PRECO_FACIL_MARKET/src/components/pages/Marketplaces/TenantMarktplaceConfig/tenant-marketplace-config'
import PlanosDetalhesPF from '../../PRECO_FACIL_MARKET/src/components/pages/Home/plano-detalhes'
import PropTypes from 'prop-types'
import SelectPlan from '../components/pages/Payments/SelectPlan.jsx'
import PaymentSuccess from '../components/pages/Payments/PaymentSuccess.jsx'
import PaymentFailure from '../components/pages/Payments/PaymentFailure.jsx'
import PaymentPending from '../components/pages/Payments/PaymentPending.jsx'
import EmailClient from '../components/pages/EmailClient/email-client.jsx'
export const PrivateRoute = ({ children, tela }) => {
  const {
    isAuthenticated,
    isLoading,
    checkAuth,
    idNivelAcesso,
    nivel_acesso,
    telasPermissao,

    RotaBase,
    superUser
  } = useContext(AuthContext)

  const location = useLocation()

  useEffect(() => {
    if (!superUser) {
      checkAuth() // Apenas para garantir que a verificação ocorra na montagem
    }
  }, [checkAuth, superUser])

  if (isLoading) {
    return <div>Carregando...</div>
  }

  if (!isAuthenticated && !isLoading) {
    // if (RotaBase === 'modelagemcompleta') {
    //   return <Navigate to="/modelagemcompleta" />
    // } else if (RotaBase === 'precofacilmarket') {
    //   return <Navigate to="/inicio-pf" />
    // }

    const searchParams = new URLSearchParams(location.search)

    const from = searchParams.get('from')
    const token = searchParams.get('token')
    let url = `/login${from ? `?from=${from}` : ''}`
    if (token) {
      url += `&token=${token}`
    }

    return <Navigate to={url} />
  }
  // Verifica se o nível de acesso atual tem permissão para acessar a tela

  if (telasPermissao && tela) {
    let podeAcessar
    if (nivel_acesso == 'Administrador') podeAcessar = true
    else
      podeAcessar = telasPermissao.some(permissao => {
        // Se quiser manter o log, certifique-se de que ele não esteja na expressão de retorno

        return permissao.tela === tela && permissao.id_nivel === idNivelAcesso
      })

    if (!podeAcessar) {
      // Se o usuário tambem nao for o c e a senha nao for o email do claudio ele nao vai conseguir acessar: obs: isso é uma ganbiarra para o claudio conseguir acessar tudo, depois tem que tirar, usar criptografia
      return <Navigate to="/acesso-negado" />
    }
  }

  return children
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
  tela: PropTypes.string.isRequired
}
// Em seguida, use o PrivateRoute em suas definições de rota

//------------------------------------------------------------
function AppRoutes() {
  return (
    <Routes>
      {/* Rotas públicas */}

      <Route path="/acesso-negado" element={<AcessoNegado />} />
      <Route path="/login" element={<PrecoFacilMarketLogin />} />
      <Route
        path="/cadastro-tenant"
        element={
          <ModelagemCompletaTenants
            sistema="precofacilmarket"
            titulo="Maximize seus lucros com precificação inteligente!"
            descricao1="Defina os preços ideais para impulsionar suas vendas em diferentes plataformas."
            descricao2="Nosso sistema analítico coloca a estratégia de precificação inteligente a seu alcance, impulsionando o crescimento de seu negócio em várias plataformas de vendas online."
          />
        }
      />
      <Route path="/" element={<PrecoFacilMarketHome />} />
      <Route path="/home" element={<PrecoFacilMarketHome />} />
      <Route path="/precofacilmarket" element={<PrecoFacilMarketHome />} />

      {/* Rotas protegidas */}

      <Route
        path="/cadastro-sub-sistema"
        element={
          <PrivateRoute tela="Cadastro de Sub Sistemas">
            <CadastroSubSistema />
          </PrivateRoute>
        }
      />

      <Route
        path="/configuracao-usuario-precofacilmarket"
        element={
          <PrivateRoute tela="Configuração de Níveis de Acesso">
            <ConfiguracaoUsuarioPrecoFacilMarket />
          </PrivateRoute>
        }
      />

      <Route
        path="/gerenciador-de-ips"
        element={
          <PrivateRoute tela="Gerenciar IPs de Acesso">
            <IpRegistration />
          </PrivateRoute>
        }
      />

      <Route
        path="*"
        element={
          <PrivateRoute tela="Início">
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        path="/inicio-pf"
        element={
          <PrivateRoute tela="Início">
            <Inicio />
          </PrivateRoute>
        }
      />
      <Route
        path="/"
        element={
          <PrivateRoute tela="Início">
            <Inicio />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-nivel-acesso"
        element={
          <PrivateRoute tela="Cadastro de Níveis de Acesso">
            <AccessLevelRegistration />
          </PrivateRoute>
        }
      />

      <Route
        path="/cadastro-usuario"
        element={
          <PrivateRoute tela="Cadastro de Usuário">
            <UserRegistration />
          </PrivateRoute>
        }
      />

      <Route
        path="/cadastro-categoria"
        element={
          <PrivateRoute tela="Cadastro de Categoria">
            <CadastroCategoria />
          </PrivateRoute>
        }
      />
      <Route
        path="/edicao-categoria"
        element={
          <PrivateRoute tela="Editar Categoria">
            <EditarCategoria />
          </PrivateRoute>
        }
      />

      <Route
        path="/listagem-produtos-mercadolivre"
        element={
          <PrivateRoute tela="Listagem Produtos Mercado Livre">
            <ListaProdutosMercadoLivre />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-regras-freteML"
        element={
          // <PrivateRoute tela="Cadastro de Regras de Frete Mercado Livre">
          <CadastroRegraDescontoFreteML />
          // </PrivateRoute>
        }
      />
      <Route path="/config-regras-frete-ML" element={<ConfiguracaoRegrasFreteMercadoLivre />} />

      <Route
        path="/calculo-precoPF"
        element={
          <PrivateRoute tela="Calculo de Preço">
            <CalculoPrecoPF />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-produto"
        element={
          <PrivateRoute tela="Cadastro de Produto">
            <CadastroProduto />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-produto"
        element={
          <PrivateRoute tela="Editar Produto">
            <EditarProduto />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-marketplace"
        element={
          <PrivateRoute tela="Cadastro de Marketplace">
            <CadastroMarketPlace />
          </PrivateRoute>
        }
      />
      <Route
        path="/editar-marketplace"
        element={
          <PrivateRoute tela="Editar Marketplace">
            <EditarMarketplace />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-regra-preco-frete"
        element={
          <PrivateRoute tela="Cadastro de Regra">
            {/* referente a MAGALU */}
            <CadastroRegrasDescontoFrete />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-regra-peso-frete/:marketplace"
        // path='/cadastro-regra-peso-frete'
        element={
          <PrivateRoute tela="Cadastro de Regra">
            {/* referente a MAGALU */}
            <CadastroRegrasPesoFrete />
          </PrivateRoute>
        }
      />

      <Route
        path="/regra-magalu"
        element={
          <PrivateRoute tela="Regra Magalu">
            <RegraMagalu />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-regra-freteAmVv/:marketplace"
        element={
          <PrivateRoute tela="Cadastro de Regra">
            <CadastroRegrasFreteAmVv />
          </PrivateRoute>
        }
      />
      <Route
        path="/config-regras-frete-americanas/:marketplace"
        element={
          <PrivateRoute tela="Configuração de Regras Americanas">
            <SelecionarGrupoVendedor />
          </PrivateRoute>
        }
      />
      <Route
        path="/cadastro-imposto"
        element={
          // <PrivateRoute tela="Cadastro de Imposto">
          <CadastroValorImposto />
          // </PrivateRoute>
        }
      />
      <Route
        path="/tenant-marketplaces-config"
        element={
          // <PrivateRoute tela="Configuração de Marketplaces">
          <TenantMarketplacesConfig />
          // </PrivateRoute>
        }
      />

      <Route
        path="/planos-detalhes-pf"
        element={
          // <PrivateRoute tela="Planos e Preços">
          <PlanosDetalhesPF />
          // </PrivateRoute>
        }
      />
      <Route
        path="/assinar-plano"
        element={
          <PrivateRoute tela="Assinar Plano">
            <SelectPlan />
          </PrivateRoute>
        }
      />

      <Route
        path="/precofacil/payment-success"
        element={<PaymentSuccess systemName="Preço Fácil Market" />}
      />
      <Route
        path="/precofacil/payment-failure"
        element={<PaymentFailure systemName="Preço Fácil Market" />}
      />
      <Route
        path="/precofacil/payment-pending"
        element={<PaymentPending systemName="Preço Fácil Market" />}
      />
      <Route path="/email-client" element={<EmailClient />} />
    </Routes>
  )
}

export default AppRoutes
