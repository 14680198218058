import React, { useContext, useEffect, useState, useRef } from 'react'
import { FormControl } from 'react-bootstrap'
import Select from 'react-select'

import { masks } from '../../../../utils/masks'
import { MyContext } from '../cadastro-geral-context'
import { CPFUtils } from '../../../../utils/cpf-utils'
import { CNPJUtils } from '../../../../utils/cnpj-utils'
import { useParams } from 'react-router-dom'
import { addFieldsExtra } from '../../../../backend/model'

import PropTypes from 'prop-types'
import { is } from 'date-fns/locale'
//import { useSelector, useDispatch } from 'react-redux'
//import { setTipoPessoa } from '../../../../utils/actions'

export function Uset() {
  const [nome, setNome] = useState('')
  const [sobrenome, setSobrenome] = useState('')
  const [cpf, setCpf] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [isValidCpf, setisValidCpf] = useState(true)
  const [tipoPessoa, setTipoPessoa] = useState('')
  //const setTipoPessoaRedux = novoValor => dispatch(setTipoPessoa(novoValor))
  //const dispatch = useDispatch()
  //const [tipoPessoa, setTipoPessoa] = useState('');
  const [categoria, setCategoria] = useState('')
  const [sexo, setSexo] = useState('')
  const [telefone, setTelefone] = useState('')
  const [email, setEmail] = useState('')
  const [originalEmail, setOriginalEmail] = useState('')
  const [isValidEmail, setisValidEmail] = useState(true)
  const [cep, setCep] = useState('')
  const [isValidCep, setisValidCep] = useState(true)
  const [endereco, setEndereco] = useState('')
  const [numero, setNumero] = useState('')
  const [complemento, setComplemento] = useState('')
  const [bairro, setBairro] = useState('')
  const [cidade, setCidade] = useState({ cidade: '', source: null })
  const [uf, setUf] = useState('')
  const [ufs, setUfs] = useState([])
  const [isBlurred, setisBlurred] = useState(false)

  const [showAlert_cpf, setShowAlertCPF] = useState(false)
  const [showAlert_cep, setShowAlertCep] = useState(false)
  const [showAlert_email, setShowAlertEmail] = useState(false)
  const [showAlert_tel, setShowAlertTel] = useState(false)
  const [buscarCepAtivo, setBuscarCepAtivo] = useState(false)
  const [Municipios, setMunicipios] = useState([])
  const [codigoUf, setCodigoUf] = useState('')
  const [todosMunicipios, setTodosMunicipios] = useState([])
  const [isValidTel, setisValidTel] = useState(true)
  const [MunicipiosParaOSelectSource, setMunicipiosParaOSelectSource] = useState([])
  const [title, setTitle] = useState('Escolha a cidade')
  const [pegarCep, setpegarCep] = useState(false)
  const [cidadeSelecionada, setCidadeSelecionada] = useState('')
  const [searchType, setsearchType] = useState('')
  const [CepUpdateCounter, setCepUpdateCounter] = useState(0)
  const [selectedSector, setSelectedSector] = useState('')
  const [isValidCnpj, setisValidCnpj] = useState(true)
  const [showAlert_cnpj, setShowAlertCNPJ] = useState(false)
  const [nomeEmpresa, setNomeEmpresa] = useState('')
  const [clienteEditando, setClienteEditando] = useState(null)
  const [showAlertGravando, setShowAlertGravando] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const { clienteId, clienteData } = useParams()

  const contextValue = React.useMemo(
    () => ({
      nome,
      setNome,
      sobrenome,
      setSobrenome,
      cpf,
      setCpf,
      isValidCpf,
      setisValidCpf,
      cnpj,
      setCnpj,
      tipoPessoa,
      setTipoPessoa,
      categoria,
      setCategoria,
      sexo,
      setSexo,
      telefone,
      setTelefone,
      email,
      setEmail,
      originalEmail,
      setOriginalEmail,
      isValidEmail,
      setisValidEmail,
      pegarCep,
      setpegarCep,
      cep,
      setCep,
      isValidCep,
      setisValidCep,
      endereco,
      setEndereco,
      numero,
      setNumero,
      complemento,
      setComplemento,
      bairro,
      setBairro,
      cidade,
      setCidade,
      uf,
      setUf,
      ufs,
      setUfs,
      isBlurred,
      setisBlurred,
      showAlert_cpf,
      setShowAlertCPF,
      showAlert_cnpj,
      setShowAlertCNPJ,
      showAlert_cep,
      setShowAlertCep,
      showAlert_email,
      setShowAlertEmail,
      showAlert_tel,
      setShowAlertTel,
      buscarCepAtivo,
      setBuscarCepAtivo,
      Municipios,
      setMunicipios,
      codigoUf,
      setCodigoUf,
      todosMunicipios,
      setTodosMunicipios,
      isValidTel,
      setisValidTel,
      MunicipiosParaOSelectSource,
      setMunicipiosParaOSelectSource,
      title,
      setTitle,
      cidadeSelecionada,
      setCidadeSelecionada,
      searchType,
      setsearchType,
      setCepUpdateCounter,
      CepUpdateCounter,
      selectedSector,
      setSelectedSector,
      isValidCnpj,
      setisValidCnpj,
      nomeEmpresa,
      setNomeEmpresa,
      showAlertGravando,
      setShowAlertGravando,
      clienteEditando,
      setClienteEditando,
      clienteId,
      clienteData,
      showAlert,
      setShowAlert
    }),
    [
      nome,
      setNome,
      sobrenome,
      setSobrenome,
      cpf,
      setCpf,
      isValidCpf,
      setisValidCpf,
      cnpj,
      setCnpj,
      tipoPessoa,
      setTipoPessoa,
      categoria,
      setCategoria,
      sexo,
      setSexo,
      telefone,
      setTelefone,
      email,
      setEmail,
      originalEmail,
      setOriginalEmail,
      isValidEmail,
      setisValidEmail,
      pegarCep,
      setpegarCep,
      cep,
      setCep,
      isValidCep,
      setisValidCep,
      endereco,
      setEndereco,
      numero,
      setNumero,
      complemento,
      setComplemento,
      bairro,
      setBairro,
      cidade,
      setCidade,
      uf,
      setUf,
      ufs,
      setUfs,
      isBlurred,
      setisBlurred,
      showAlert_cpf,
      setShowAlertCPF,
      showAlert_cnpj,
      setShowAlertCNPJ,
      showAlert_cep,
      setShowAlertCep,
      showAlert_email,
      setShowAlertEmail,
      showAlert_tel,
      setShowAlertTel,
      buscarCepAtivo,
      setBuscarCepAtivo,
      Municipios,
      setMunicipios,
      codigoUf,
      setCodigoUf,
      todosMunicipios,
      setTodosMunicipios,
      isValidTel,
      setisValidTel,
      MunicipiosParaOSelectSource,
      setMunicipiosParaOSelectSource,
      title,
      setTitle,
      cidadeSelecionada,
      setCidadeSelecionada,
      searchType,
      setsearchType,
      setCepUpdateCounter,
      CepUpdateCounter,
      selectedSector,
      setSelectedSector,
      isValidCnpj,
      setisValidCnpj,
      nomeEmpresa,
      setNomeEmpresa,
      showAlertGravando,
      setShowAlertGravando,
      clienteEditando,
      setClienteEditando,
      clienteId,
      clienteData,
      showAlert,
      setShowAlert
    ]
  )

  return {
    Get_contextValue: contextValue,
    variaveisState: {
      nome,
      setNome,
      sobrenome,
      setSobrenome,
      cpf,
      setCpf,
      cnpj,
      setCnpj,
      isValidCpf,
      setisValidCpf,
      tipoPessoa,
      setTipoPessoa,
      categoria,
      setCategoria,
      sexo,
      setSexo,
      telefone,
      setTelefone,
      email,
      setEmail,
      originalEmail,
      setOriginalEmail,
      isValidEmail,
      setisValidEmail,
      cep,
      setCep,
      isValidCep,
      setisValidCep,
      endereco,
      setEndereco,
      numero,
      setNumero,
      complemento,
      setComplemento,
      bairro,
      setBairro,
      cidade,
      setCidade,
      uf,
      setUf,
      ufs,
      setUfs,
      isBlurred,
      setisBlurred,
      showAlert_cpf,
      setShowAlertCPF,
      showAlert_cep,
      setShowAlertCep,
      showAlert_email,
      setShowAlertEmail,
      showAlert_tel,
      setShowAlertTel,
      buscarCepAtivo,
      setBuscarCepAtivo,
      Municipios,
      setMunicipios,
      codigoUf,
      setCodigoUf,
      todosMunicipios,
      setTodosMunicipios,
      isValidTel,
      setisValidTel,
      MunicipiosParaOSelectSource,
      setMunicipiosParaOSelectSource,
      title,
      setTitle,
      pegarCep,
      setpegarCep,
      cidadeSelecionada,
      setCidadeSelecionada,
      searchType,
      setsearchType,
      CepUpdateCounter,
      setCepUpdateCounter,
      selectedSector,
      setSelectedSector,
      isValidCnpj,
      setisValidCnpj,
      showAlert_cnpj,
      setShowAlertCNPJ,
      nomeEmpresa,
      setNomeEmpresa,
      clienteEditando,
      setClienteEditando,
      clienteId,
      clienteData,
      showAlertGravando,
      setShowAlertGravando,
      showAlert,
      setShowAlert
    }
  }
}

export function SelectSexo({ initialValue, sexo, setSexo, disabled }) {
  SelectSexo.propTypes = {
    initialValue: PropTypes.string,
    sexo: PropTypes.string.isRequired,
    setSexo: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  const sexoOptions = [
    { value: 'feminino', label: 'Feminino' },
    { value: 'masculino', label: 'Masculino' }
  ]

  const [selectedSexoOption, setSelectedSexoOption] = useState(null)

  useEffect(() => {
    if (initialValue) {
      const selectedOption = sexoOptions.find(option => option.label === initialValue)

      setSelectedSexoOption(selectedOption)
      setSexo(initialValue)
    } else {
      const selectedOption = sexoOptions.find(option => option.value === sexo)
      setSelectedSexoOption(selectedOption)
    }
  }, [initialValue, sexo, setSexo])

  useEffect(() => {
    const selectedOption = sexoOptions.find(option => option.label === sexo)
    setSelectedSexoOption(selectedOption)
  }, [sexo])

  const handleSexoChange = selectedOption => {
    if (selectedOption) {
      setSexo(selectedOption.value)
      setSelectedSexoOption(selectedOption)
    } else {
      setSexo('')
      setSelectedSexoOption(null)
    }
  }
  const customStyles = disabled => ({
    control: base => ({
      ...base,
      minWidth: '250px',
      backgroundColor: disabled ? '#e0e0e0' : 'white', // Aplica cor de fundo com base no estado `disabled`
      cursor: disabled ? 'not-allowed' : 'default' // Cursor diferenciado se estiver desabilitado
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'blue' : 'white'
    })
  })
  return (
    <Select
      id="select-sexo"
      className="basic-single"
      classNamePrefix="select"
      isClearable
      placeholder="Escolha o sexo..."
      options={disabled ? [] : sexoOptions}
      value={selectedSexoOption}
      onChange={handleSexoChange}
      styles={customStyles(disabled)}
    />
  )
}

export function SelectTipo({ initialValue, tipoPessoa, setTipoPessoa, disabled }) {
  SelectTipo.propTypes = {
    initialValue: PropTypes.string,
    tipoPessoa: PropTypes.string.isRequired,
    setTipoPessoa: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  const tipoPessoaOptions = [
    { value: 'Pessoa Física', label: 'Pessoa Física' },
    { value: 'Pessoa Jurídica', label: 'Pessoa Jurídica' }
  ]

  const [selectedTipoOption, setSelectedTipoOption] = useState(null)

  useEffect(() => {
    const selectedOption = initialValue
      ? tipoPessoaOptions.find(option => option.value === initialValue)
      : tipoPessoaOptions.find(option => option.value === tipoPessoa)

    setSelectedTipoOption(selectedOption)
  }, [initialValue, tipoPessoa])

  useEffect(() => {
    if (initialValue) {
      setTipoPessoa(initialValue)
    }
  }, [initialValue, setTipoPessoa])

  useEffect(() => {
    const selectedOption = tipoPessoaOptions.find(option => option.value === tipoPessoa)
    setSelectedTipoOption(selectedOption || null)
  }, [tipoPessoa])

  const handleTipoPessoaChange = selectedOption => {
    if (selectedOption) {
      setTipoPessoa(selectedOption.value)
      setSelectedTipoOption(selectedOption)
    } else {
      setTipoPessoa('')
      setSelectedTipoOption(null)
    }
  }
  const customStyles = disabled => ({
    control: base => ({
      ...base,
      minWidth: '250px',
      backgroundColor: disabled ? '#e0e0e0' : 'white', // Aplica cor de fundo com base no estado `disabled`
      cursor: disabled ? 'not-allowed' : 'default' // Cursor diferenciado se estiver desabilitado
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'blue' : 'white'
    })
  })
  return (
    <Select
      id="select-tipo"
      options={disabled ? [] : tipoPessoaOptions}
      value={selectedTipoOption}
      onChange={handleTipoPessoaChange}
      isClearable={true}
      placeholder="Escolha o tipo..."
      className="basic-single"
      classNamePrefix="select"
      styles={customStyles(disabled)}
    />
  )
}

export function SelectCategoria({ initialValue, categoria, setCategoria, disabled }) {
  SelectCategoria.propTypes = {
    initialValue: PropTypes.string,
    categoria: PropTypes.string.isRequired,
    setCategoria: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  const handleCategoriaChange = selectedOption => {
    if (selectedOption) {
      setCategoria(selectedOption.value)
    } else {
      setCategoria('')
    }
  }

  const categoriaOptions = [
    { value: 'cliente', label: 'Cliente' },
    { value: 'fornecedor', label: 'Fornecedor' },
    { value: 'funcionario', label: 'Funcionário' }
  ]

  const [selectedCategoriaOption, setSelectedCategoriaOption] = useState(null)

  useEffect(() => {
    if (initialValue) {
      const selectedOption = categoriaOptions.find(option => option.label === initialValue)
      setSelectedCategoriaOption(selectedOption)
      setCategoria(initialValue)
    } else {
      const selectedOption = categoriaOptions.find(option => option.value === categoria)
      setSelectedCategoriaOption(selectedOption)
    }
  }, [initialValue, categoria, setCategoria])

  const [isClearable, setIsClearable] = useState(true)

  useEffect(() => {
    const selectedOption = categoriaOptions.find(option => option.value === categoria)
    setSelectedCategoriaOption(selectedOption || null)
  }, [categoria])
  const customStyles = disabled => ({
    control: base => ({
      ...base,
      minWidth: '300px',
      marginRight: '40px',
      backgroundColor: disabled ? '#e0e0e0' : 'white', // Se `disabled` for true, aplica fundo cinza
      cursor: disabled ? 'not-allowed' : 'default' // Cursor de "not-allowed" se estiver desabilitado
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'blue' : 'white'
    })
  })
  return (
    <Select
      id="select-categoria"
      value={selectedCategoriaOption}
      options={categoriaOptions}
      placeholder="Escolha a Categoria..."
      isClearable={isClearable}
      onChange={handleCategoriaChange}
      styles={customStyles(disabled)}
    />
  )
}

export function ImputName({ initialValue, nome, setNome, setSobrenome, setSexo, disabled }) {
  ImputName.propTypes = {
    initialValue: PropTypes.string,
    nome: PropTypes.string.isRequired,
    setNome: PropTypes.func.isRequired,
    setSobrenome: PropTypes.func.isRequired,
    setSexo: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  useEffect(() => {
    if (initialValue) {
      setNome(initialValue)
    }
  }, [initialValue, setNome])

  function divideNomeComposto(nome) {
    const partesNome = nome.split(' ')

    if (partesNome.length > 1) {
      const nome = partesNome[0]
      const sobrenome = partesNome.slice(1).join(' ')

      return [nome, sobrenome]
    }

    return [nome, null]
  }

  async function checkGender(nome) {
    const response = await fetch('https://api.genderize.io?name=' + nome)

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await response.json()

    return data.gender
  }

  const handleNomeBlur = async () => {
    let nomeParaVerificar = nome
    if (nome.includes(' ')) {
      const [novoNome, novoSobrenome] = divideNomeComposto(nome)
      setNome(novoNome)
      setSobrenome(novoSobrenome)
      nomeParaVerificar = novoNome
    }
    try {
      const gender = await checkGender(nomeParaVerificar)

      if (gender) {
        let sexoDescoberto = gender === 'male' ? 'Masculino' : 'Feminino'

        setSexo(sexoDescoberto)
      }
    } catch (error) {
      console.error('Houve um erro ao verificar o sexo:', error)
    }
  }

  const handleNomeChange = event => {
    setNome(event.target.value)
  }

  return (
    <FormControl
      id="input-name"
      className="custom-placeholder"
      placeholder="Nome"
      value={nome}
      onChange={handleNomeChange}
      onBlur={handleNomeBlur}
      disabled={disabled}
    />
  )
}

export function SobrenomeImput({ initialValue, sobrenome, setSobrenome, disabled }) {
  SobrenomeImput.propTypes = {
    initialValue: PropTypes.string,
    sobrenome: PropTypes.string.isRequired,
    setSobrenome: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  useEffect(() => {
    if (initialValue) {
      setSobrenome(initialValue)
    }
  }, [initialValue, setSobrenome])

  const handleSobrenomeChange = event => {
    setSobrenome(event.target.value)
  }

  return (
    <FormControl
      id="input-sname"
      placeholder="Sobrenome"
      value={sobrenome}
      onChange={handleSobrenomeChange}
      className="custom-placeholder"
      disabled={disabled}
    />
  )
}

export function NomeEmpresaInput({ initialValue, nomeEmpresa, setNomeEmpresa, disabled }) {
  NomeEmpresaInput.propTypes = {
    initialValue: PropTypes.string,
    nomeEmpresa: PropTypes.string.isRequired,
    setNomeEmpresa: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  useEffect(() => {
    if (initialValue) {
      setNomeEmpresa(initialValue)
    }
  }, [initialValue, setNomeEmpresa])

  const handleNomeEmpresaChange = e => {
    setNomeEmpresa(e.target.value)
  }

  return (
    <FormControl
      id="input-nome-empresa"
      placeholder="Nome da Empresa"
      value={nomeEmpresa}
      onChange={handleNomeEmpresaChange}
      className="custom-placeholder"
      disabled={disabled}
    />
  )
}

export function InputCPForCNPJ({
  initialValue,
  cpf,
  setCpf,
  cnpj,
  setCnpj,
  isValidCpf,
  setisValidCpf,
  isValidCnpj,
  setisValidCnpj,
  isBlurred,
  setisBlurred,
  setShowAlertCPF,
  showAlert_cpf,
  setShowAlertCNPJ,
  showAlert_cnpj,
  tipoPessoa,
  disabled
}) {
  InputCPForCNPJ.propTypes = {
    initialValue: PropTypes.string,
    cpf: PropTypes.string.isRequired,
    setCpf: PropTypes.func.isRequired,
    cnpj: PropTypes.string.isRequired,
    setCnpj: PropTypes.func.isRequired,
    isValidCpf: PropTypes.bool.isRequired,
    setisValidCpf: PropTypes.func.isRequired,
    isValidCnpj: PropTypes.bool.isRequired,
    setisValidCnpj: PropTypes.func.isRequired,
    isBlurred: PropTypes.bool.isRequired,
    setisBlurred: PropTypes.func.isRequired,
    setShowAlertCPF: PropTypes.func.isRequired,
    showAlert_cpf: PropTypes.bool.isRequired,
    setShowAlertCNPJ: PropTypes.func.isRequired,
    showAlert_cnpj: PropTypes.bool.isRequired,
    tipoPessoa: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  }

  const handleCpfCnpjChange = e => {
    const value = e.target.value
    if (tipoPessoa === 'Pessoa Física') {
      setCpf(CPFUtils.formatCPF(value))
    } else {
      setCnpj(CNPJUtils.formatCNPJ(value))
    }
  }

  const handleCpfCnpjBlur = e => {
    const value = e.target.value
    setisBlurred(true)
    if (tipoPessoa === 'Pessoa Física') {
      if (value !== '') {
        setisValidCpf(CPFUtils.validarCPF(value))
        setShowAlertCPF(!CPFUtils.validarCPF(value))
      } else {
        setisValidCpf(true)
        setShowAlertCPF(false)
      }
    } else {
      if (value !== '') {
        setisValidCnpj(CNPJUtils.validarCNPJ(value))
        setShowAlertCNPJ(!CNPJUtils.validarCNPJ(value))
      } else {
        setisValidCnpj(true)
        setShowAlertCNPJ(false)
      }
    }
  }

  const isValidField = tipoPessoa === 'Pessoa Física' ? isValidCpf : isValidCnpj
  const className = `custom-placeholder ${isValidField ? '' : 'invalid-filed'}`

  useEffect(() => {
    if (initialValue) {
      if (tipoPessoa === 'Pessoa Física') {
        setCpf(initialValue)
      } else {
        setCnpj(initialValue)
      }
    }
  }, [initialValue, tipoPessoa, setCpf, setCnpj])

  return (
    <FormControl
      id="input-cpf-cnpj"
      placeholder={tipoPessoa === 'Pessoa Física' ? 'CPF' : 'CNPJ'}
      value={tipoPessoa === 'Pessoa Física' ? cpf : cnpj}
      onChange={handleCpfCnpjChange}
      onBlur={handleCpfCnpjBlur}
      className={className}
      disabled={disabled}
    />
  )
}

export function TelefoneImput({
  initialValue,
  telefone,
  setTelefone,
  isValidTel,
  setisValidTel,
  setisBlurred,
  setShowAlertTel,
  disabled
}) {
  TelefoneImput.propTypes = {
    initialValue: PropTypes.string,
    telefone: PropTypes.string.isRequired,
    setTelefone: PropTypes.func.isRequired,
    isValidTel: PropTypes.bool.isRequired,
    setisValidTel: PropTypes.func.isRequired,
    setisBlurred: PropTypes.func.isRequired,
    setShowAlertTel: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  const { handleTelefoneChange, handleTelefoneBlur } = EventosTelefone(
    setTelefone,
    setisValidTel,
    setisBlurred,
    setShowAlertTel
  )

  useEffect(() => {
    if (initialValue) {
      setTelefone(initialValue)
    }
  }, [initialValue, setTelefone])

  return (
    <FormControl
      noValidate={true}
      id="cd-fone"
      placeholder="(XX) XXXX-XXXX"
      type="tel"
      pattern="\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}"
      value={telefone}
      onChange={handleTelefoneChange}
      onBlur={handleTelefoneBlur}
      className={`custom-placeholder ${isValidTel ? '' : 'invalid-filed'}`}
      disabled={disabled}
    />
  )
}

function EventosTelefone(setTelefone, setisValidTel, setisBlurred, setShowAlertTel) {
  const handleTelefoneChange = event => {
    const value = event.target.value
    const maskedValue = masks.phone(value)
    setTelefone(maskedValue || value)
    setisValidTel(maskedValue && maskedValue.length === 15)
  }

  const handleTelefoneBlur = event => {
    const value = event.target.value
    const maskedValue = masks.phone(value)

    setisBlurred(true)
    if (maskedValue && maskedValue.length === 15) {
      setTelefone(maskedValue)
      setisValidTel(true)
      setShowAlertTel(false)
    } else if (value.length > 0) {
      setisValidTel(false)
      setShowAlertTel(true)
    }
  }
  return { handleTelefoneChange, handleTelefoneBlur }
}

export function EmailImput({
  initialValue,
  email,
  setEmail,
  isValidEmail,
  setisValidEmail,
  setisBlurred,
  setShowAlertEmail,
  originalEmail,
  setOriginalEmail,
  disabled
}) {
  EmailImput.propTypes = {
    initialValue: PropTypes.string,
    email: PropTypes.string.isRequired,
    setEmail: PropTypes.func.isRequired,
    isValidEmail: PropTypes.bool.isRequired,
    setisValidEmail: PropTypes.func.isRequired,
    setisBlurred: PropTypes.func.isRequired,
    setShowAlertEmail: PropTypes.func.isRequired,
    originalEmail: PropTypes.string.isRequired,
    setOriginalEmail: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  const { handleEmailBlur, handleEmailFocus } = EventosEmail(
    setisBlurred,
    setisValidEmail,
    setShowAlertEmail,
    setEmail,
    setOriginalEmail,
    originalEmail
  )

  useEffect(() => {
    if (initialValue) {
      setEmail(initialValue)
      setOriginalEmail(initialValue)
    }
  }, [initialValue, setEmail, setOriginalEmail])

  return (
    <FormControl
      type="email"
      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
      id="input-email"
      placeholder="E-mail"
      value={email}
      onChange={event => setEmail(event.target.value)}
      onBlur={handleEmailBlur}
      onFocus={handleEmailFocus}
      className={`custom-placeholder ${isValidEmail ? '' : 'invalid-filed'}`}
      disabled={disabled}
    />
  )
}

function EventosEmail(
  setisBlurred,
  setisValidEmail,
  setShowAlertEmail,
  setEmail,
  setOriginalEmail,
  originalEmail
) {
  const handleEmailBlur = event => {
    const value = event.target.value
    const maskedValue = masks.email(value)
    setisBlurred(true)
    if (!maskedValue && value.length > 0) {
      setisValidEmail(false)
      setShowAlertEmail(true)
    } else {
      setEmail(maskedValue)
      setisValidEmail(true)
      setShowAlertEmail(false)
    }

    setOriginalEmail(value)
  }

  const handleEmailFocus = event => {
    const value = originalEmail

    setOriginalEmail(value)
  }
  return { handleEmailBlur, handleEmailFocus }
}

export function CepImput({
  initialValue,
  cep,
  setCep,
  isValidCep,
  setisValidCep,
  setShowAlertCep,
  showAlert_cep,
  setisBlurred,
  isBlurred,
  setpegarCep,
  setCepUpdateCounter,
  setAddressUpdateStatus,
  setsearchType,
  searchType,
  disabled
}) {
  CepImput.propTypes = {
    initialValue: PropTypes.string,
    cep: PropTypes.string.isRequired,
    setCep: PropTypes.func.isRequired,
    isValidCep: PropTypes.bool.isRequired,
    setisValidCep: PropTypes.func.isRequired,
    setShowAlertCep: PropTypes.func.isRequired,
    showAlert_cep: PropTypes.bool.isRequired,
    setisBlurred: PropTypes.func.isRequired,
    isBlurred: PropTypes.bool.isRequired,
    setpegarCep: PropTypes.func.isRequired,
    setCepUpdateCounter: PropTypes.func.isRequired,
    setAddressUpdateStatus: PropTypes.func.isRequired,
    setsearchType: PropTypes.func.isRequired,
    searchType: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  }
  const { handleCepChange, handleCepBlur } = EventosCep(
    setCep,
    setisValidCep,
    setisBlurred,
    setShowAlertCep,
    showAlert_cep,
    isBlurred,
    setpegarCep,
    setCepUpdateCounter,
    setAddressUpdateStatus,
    setsearchType,
    searchType
  )

  useEffect(() => {
    if (initialValue) {
      setCep(initialValue)
    }
  }, [initialValue, setCep])

  return (
    <FormControl
      id="input-cep"
      placeholder="Cep"
      pattern="[0-9]{8}"
      value={cep}
      onChange={handleCepChange}
      onBlur={handleCepBlur}
      className={`custom-placeholder ${isValidCep ? '' : 'invalid-filed'}`}
      style={{ marginRight: '10px' }}
      disabled={disabled}
    />
  )
}

function EventosCep(
  setCep,
  setisValidCep,
  setisBlurred,
  setShowAlertCep,
  showAlert_cep,
  isBlurred,
  setpegarCep,
  // setAddressUpdateCounter,
  setCepUpdateCounter,
  setAddressUpdateStatus,
  setsearchType,
  searchType
) {
  const handleCepChange = event => {
    setsearchType('cep')

    const value = event.target.value

    const maskedValue = masks.cep(value)

    setCep(maskedValue || value)

    setisValidCep(maskedValue && maskedValue.length === 9)
  }

  const handleCepBlur = event => {
    const value = event.target.value
    const maskedValue = masks.cep(value)

    setisBlurred(true)

    if (maskedValue && maskedValue.length === 9) {
      setCep(maskedValue)
      setisValidCep(true)

      setShowAlertCep(false)
      setCepUpdateCounter(prevCounter => prevCounter + 1)
    } else if (value.length > 0) {
      setisValidCep(false)

      setShowAlertCep(true)
    }
  }

  return { handleCepChange, handleCepBlur }
}

export function EnderecoImput({
  initialValue,
  endereco,
  setEndereco,
  setUf,
  setCep,
  setpegarCep,
  setAddressUpdateStatus,
  setsearchType,
  disabled
}) {
  EnderecoImput.propTypes = {
    initialValue: PropTypes.string,
    endereco: PropTypes.string.isRequired,
    setEndereco: PropTypes.func.isRequired,
    setUf: PropTypes.func.isRequired,
    setCep: PropTypes.func.isRequired,
    setpegarCep: PropTypes.func.isRequired,
    setAddressUpdateStatus: PropTypes.func.isRequired,
    setsearchType: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  const handleEnderecoChange = event => {
    setCep('')
    setsearchType('address')
    setEndereco(event.target.value)
  }

  useEffect(() => {
    if (initialValue) {
      setEndereco(initialValue)
    }
  }, [initialValue, setEndereco])

  return (
    <FormControl
      id="end"
      placeholder="Endereço"
      value={endereco}
      onChange={handleEnderecoChange}
      className="custom-placeholder"
      disabled={disabled}
    />
  )
}

export function NumeroImput({ initialValue, numero, setNumero, disabled }) {
  NumeroImput.propTypes = {
    initialValue: PropTypes.string,
    numero: PropTypes.string.isRequired,
    setNumero: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  const handleNumeroChange = event => {
    setNumero(event.target.value)
  }

  useEffect(() => {
    if (initialValue) {
      setNumero(initialValue)
    }
  }, [initialValue, setNumero])

  return (
    <FormControl
      id="numero"
      placeholder="N°"
      value={numero}
      onChange={handleNumeroChange}
      className="custom-placeholder"
      disabled={disabled}
    />
  )
}

export function ComplementoImput({ initialValue, complemento, setComplemento, disabled }) {
  ComplementoImput.propTypes = {
    initialValue: PropTypes.string,
    complemento: PropTypes.string.isRequired,
    setComplemento: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  const handleComplementoChange = event => {
    setComplemento(event.target.value)
  }

  useEffect(() => {
    if (initialValue) {
      setComplemento(initialValue)
    }
  }, [initialValue, setComplemento])

  return (
    <FormControl
      id="complemento"
      placeholder="Complemento"
      value={complemento}
      onChange={handleComplementoChange}
      className="custom-placeholder"
      disabled={disabled}
    />
  )
}

export function BairroImput({ initialValue, bairro, setBairro, disabled }) {
  BairroImput.propTypes = {
    initialValue: PropTypes.string,
    bairro: PropTypes.string.isRequired,
    setBairro: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }

  const handleBairroChange = event => {
    setBairro(event.target.value)
  }

  useEffect(() => {
    if (initialValue) {
      setBairro(initialValue)
    }
  }, [initialValue, setBairro])

  return (
    <FormControl
      id="bairro"
      placeholder="Bairro"
      style={{ width: '105%' }}
      value={bairro}
      onChange={handleBairroChange}
      className="custom-placeholder"
      disabled={disabled}
    />
  )
}

export function MunicipiosSelect({
  setCidadeSelecionada,
  initialValue,
  Municipios,
  title,
  setTitle,
  cidadeState,
  MunicipiosParaOSelectSource,
  setMunicipiosParaOSelectSource,
  cidadeSelecionada,
  setCidade,
  pegarCep,
  disabled
}) {
  MunicipiosSelect.propTypes = {
    setCidadeSelecionada: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    Municipios: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    setTitle: PropTypes.func.isRequired,
    cidadeState: PropTypes.object,
    MunicipiosParaOSelectSource: PropTypes.array.isRequired,
    setMunicipiosParaOSelectSource: PropTypes.func.isRequired,
    cidadeSelecionada: PropTypes.string,
    setCidade: PropTypes.func.isRequired,
    pegarCep: PropTypes.bool.isRequired,
    disabled: PropTypes.bool
  }

  const [formattedMunicipios, setFormattedMunicipios] = useState([])
  const [selectedMunicipioOption, setSelectedMunicipioOption] = useState(null)

  // Atualizar a seleção inicial com base no valor passado (`initialValue`)
  useEffect(() => {
    if (initialValue && formattedMunicipios.length > 0) {
      const matchingOption = formattedMunicipios.find(option => option.value === initialValue)
      if (matchingOption) {
        setSelectedMunicipioOption(matchingOption)
        setCidade(matchingOption.value)
      } else {
        // Caso a opção não seja encontrada, ainda podemos definir a cidade manualmente
        setSelectedMunicipioOption({ value: initialValue, label: initialValue })
        setCidade(initialValue)
      }
    }
  }, [initialValue, formattedMunicipios, setCidade])

  // Formatar os municípios para o Select sempre que MunicipiosParaOSelectSource mudar
  useEffect(() => {
    if (MunicipiosParaOSelectSource?.length > 0) {
      const formatted = MunicipiosParaOSelectSource.map(municipio => ({
        value: municipio.value, // Use a propriedade correta 'value'
        label: municipio.label // Use a propriedade correta 'label'
      }))

      setFormattedMunicipios(formatted)
    }
  }, [MunicipiosParaOSelectSource])

  // Manter a cidade sincronizada quando o valor do Select mudar
  const handleSelectChange = selectedOption => {
    if (selectedOption) {
      setCidade(selectedOption.label)
      setSelectedMunicipioOption(selectedOption)
    } else {
      setCidade(null)
      setSelectedMunicipioOption(null)
    }
  }

  // Estilos personalizados para o componente Select
  const customStyles = disabled => ({
    control: base => ({
      ...base,
      minWidth: '300px',
      marginRight: '40px',
      backgroundColor: disabled ? '#e0e0e0' : 'white', // Se `disabled` for true, aplica fundo cinza
      cursor: disabled ? 'not-allowed' : 'default' // Cursor de "not-allowed" se estiver desabilitado
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'blue' : 'white'
    })
  })

  return (
    <Select
      title={title}
      isClearable={true}
      options={disabled ? [] : formattedMunicipios}
      placeholder="Escolha a cidade"
      maxMenuHeight={235}
      classNamePrefix="classDropdownToggle"
      isLoading={pegarCep}
      onChange={handleSelectChange}
      value={selectedMunicipioOption}
      styles={customStyles(disabled)}
    />
  )
}

export function EstadosSelect({
  initialValue,
  ufs,
  uf,
  setUf,
  Municipios,
  setMunicipios,
  setTitle,
  todosMunicipios,
  cidade,
  setpegarCep,
  setCidadeSelecionada,
  disabled
}) {
  EstadosSelect.propTypes = {
    initialValue: PropTypes.string,
    ufs: PropTypes.array.isRequired,
    uf: PropTypes.string,
    setUf: PropTypes.func.isRequired,
    Municipios: PropTypes.array.isRequired,
    setMunicipios: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    todosMunicipios: PropTypes.array.isRequired,
    // cidade: PropTypes.object.isRequired,
    setpegarCep: PropTypes.func.isRequired,
    setCidadeSelecionada: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  }
  console.log('disabled', disabled)
  const handleUfChange = EventosEstados(todosMunicipios)
  const [isClearable, setIsClearable] = useState(true)
  const [selectedUfOption, setSelectedUfOption] = useState(null)

  const customStyles = disabled => ({
    control: base => ({
      ...base,
      minWidth: '280px',
      backgroundColor: disabled ? '#e0e0e0' : 'white', // Aplica cor de fundo com base no estado `disabled`
      cursor: disabled ? 'not-allowed' : 'default' // Cursor diferenciado se estiver desabilitado
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'blue' : 'white'
    })
  })

  useEffect(() => {
    if (initialValue && !uf) {
      const selectedOption = ufs.find(option => option.value === initialValue)
      setSelectedUfOption(selectedOption)
      handleUfChange(
        selectedOption,
        setUf,
        ufs,
        setMunicipios,
        setTitle,
        cidade,
        setpegarCep,
        setCidadeSelecionada,
        setSelectedUfOption
      )
    } else if (uf) {
      const selectedOption = ufs.find(option => option.label === uf)
      setSelectedUfOption(selectedOption)
    }
  }, [uf, initialValue, ufs])

  return (
    <Select
      id="uf"
      classNamePrefix="select"
      isClearable={isClearable}
      options={disabled ? [] : ufs}
      value={selectedUfOption}
      styles={customStyles(disabled)}
      placeholder="Escolha o estado"
      onChange={selectedOption => {
        if (!disabled) {
          handleUfChange(
            selectedOption,
            setUf,
            ufs,
            setMunicipios,
            setTitle,
            cidade,
            setpegarCep,
            setCidadeSelecionada,
            setSelectedUfOption
          )
        }
      }}
    />
  )
}

export function EventosEstados(todosMunicipios) {
  return (
    selectedOption,
    setUf,
    ufs,
    setMunicipios,
    setTitle,
    cidade,
    setpegarCep,
    setCidadeSelecionada,
    setSelectedUfOption
  ) => {
    setCidadeSelecionada('')

    if (!selectedOption) {
      setUf('')
      setMunicipios([])
      setTitle('Escolha a cidade')
      setSelectedUfOption(null)
      return
    }

    const newUf = selectedOption.label ? selectedOption.label : selectedOption

    setUf(newUf)
    setSelectedUfOption(selectedOption)

    const municipiosDaCidade = filterMunicipiosByCidade(todosMunicipios, newUf, ufs)

    setMunicipios(municipiosDaCidade) // aqui eu seto os municipios para o componente MunicipiosSelect

    // setTitle(titulo_padrao_selectsourch)
  }
}

//--------------------funções auxiliares-------------------------------------------------------------------------------
function getCodigoMunicipioByCidade(cidadeSelecionada, ufs) {
  const ufsSelecionadas = ufs.filter(uf => uf.label === cidadeSelecionada)

  if (ufsSelecionadas.length > 0) {
    return ufsSelecionadas[0].codigo
  } else {
    return null
  }
}
function getCodigoMunicipioByUF(cidadeSelecionada, ufs) {
  const ufsSelecionadas = ufs.filter(uf => uf.value === cidadeSelecionada)

  if (ufsSelecionadas.length > 0) {
    return ufsSelecionadas[0].codigo
  } else {
    return null
  }
}
// função que filtra os municipios de acordo com a cidade selecionada
export function filterMunicipiosByCidade(todosMunicipios, cidadeSelecionada, ufs) {
  // Primeiro, tenta obter o código do município

  const codigoMunicipio = getCodigoMunicipioByCidade(cidadeSelecionada, ufs)

  // Se o código do município for encontrado, faz a filtragem com base no código
  if (codigoMunicipio) {
    return todosMunicipios.filter(municipio => municipio.codigo_uf === codigoMunicipio)
  }

  // Verificação para garantir que `ufs` não é nulo, indefinido, e é um array válido
  if (ufs && Array.isArray(ufs) && ufs.length > 0) {
    const ufSelecionado = ufs[0]

    // Verificação para garantir que `ufSelecionado` é uma string válida antes de chamar `toLowerCase`
    if (typeof ufSelecionado === 'string') {
      const municipiosDoEstado = todosMunicipios.filter(municipio => {
        // Adicionando um log para ver o que há dentro de `municipio`

        // Agora fazendo a comparação como antes
        return municipio.codigo_uf === codigoMunicipio
      })

      return municipiosDoEstado
    } else {
      console.error('UF selecionado não é uma string válida:', ufSelecionado)
    }
  } else {
    console.error('UFs fornecidos são inválidos ou não foram especificados:', ufs)
  }

  // Se nada for encontrado, retorna uma lista vazia
  console.error('Não foi possível encontrar nenhum município para a cidade ou UF fornecidos.')
  return []
}
export function filterMunicipiosByUF(todosMunicipios, cidadeSelecionada, ufs) {
  // Primeiro, tenta obter o código do município

  const codigoMunicipio = getCodigoMunicipioByUF(cidadeSelecionada, ufs)

  // Se o código do município for encontrado, faz a filtragem com base no código
  if (codigoMunicipio) {
    return todosMunicipios.filter(municipio => municipio.codigo_uf === codigoMunicipio)
  }

  // Verificação para garantir que `ufs` não é nulo, indefinido, e é um array válido
  if (ufs && Array.isArray(ufs) && ufs.length > 0) {
    const ufSelecionado = ufs[0]

    // Verificação para garantir que `ufSelecionado` é uma string válida antes de chamar `toLowerCase`
    if (typeof ufSelecionado === 'string') {
      const municipiosDoEstado = todosMunicipios.filter(municipio => {
        // Agora fazendo a comparação como antes
        return municipio.codigo_uf === codigoMunicipio
      })

      return municipiosDoEstado
    } else {
      console.error('UF selecionado não é uma string válida:', ufSelecionado)
    }
  } else {
    console.error('UFs fornecidos são inválidos ou não foram especificados:', ufs)
  }

  // Se nada for encontrado, retorna uma lista vazia
  console.error('Não foi possível encontrar nenhum município para a cidade ou UF fornecidos.')
  return []
}

//--------------------funções auxiliares-------------------------------------------------------------------------------
function adicionarIdEData(cadastroDados, id, data) {
  return addFieldsExtra(cadastroDados, {
    data: data,
    id: id
  })
}
