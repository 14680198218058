const PlotagemDescription = () => {
  return (
    <div className="plotagem-description">
      <h2 className="plotagem-title">Detalhes do Módulo de Plotagem</h2>

      {/* Seção de Cadastro e Gerenciamento de Plotagens */}
      <section className="plotagem-section">
        <h3>Cadastro e Gerenciamento de Plotagens</h3>
        <p>
          O módulo de plotagem oferece uma interface completa para cadastrar e gerenciar os serviços
          de plotagem, permitindo o controle detalhado de cada pedido, desde a seleção do cliente
          até a definição de valores.
        </p>
        <ul className="plotagem-list">
          <li>Seleção de Clientes para cada plotagem</li>
          <li>Cadastro da metragem total para plotagem</li>
          <li>Gerenciamento de valores pendentes e pagos</li>
          <li>Controle de múltiplos pedidos de plotagem</li>
          <li>Validação dos dados de plotagem antes do cadastro</li>
        </ul>
      </section>

      {/* Seção de Resumo Financeiro */}
      <section className="plotagem-section">
        <h3>Resumo Financeiro</h3>
        <p>
          O módulo permite a visualização de um resumo financeiro dos serviços de plotagem, com
          destaque para os valores pagos, pendentes e totais de cada cliente. É possível verificar
          de forma detalhada os pagamentos realizados e os valores a pagar.
        </p>
        <ul className="plotagem-list">
          <li>Visualização do valor total da plotagem</li>
          <li>Controle de pagamentos pendentes</li>
          <li>Cálculo automático do valor da plotagem com base na metragem</li>
          <li>Resumo dos valores pagos e a pagar</li>
        </ul>
      </section>

      {/* Seção de Integração com Outros Módulos */}
      <section className="plotagem-section">
        <h3>Integração com Outros Módulos</h3>
        <p>
          O módulo de plotagem está totalmente integrado com outros módulos do sistema, como o
          módulo financeiro e o cadastro de clientes, facilitando o controle unificado das
          informações.
        </p>
        <ul className="plotagem-list">
          <li>Sincronização de dados financeiros com o módulo financeiro</li>
          <li>Acesso aos dados de clientes de outros módulos</li>
          <li>Gerenciamento unificado dos valores pendentes</li>
        </ul>
      </section>

      {/* Seção de Edição e Suporte */}
      <section className="plotagem-section">
        <h3>Edição e Suporte</h3>
        <p>
          O sistema permite a edição de dados de plotagem conforme necessário. Para mais
          informações, os usuários podem acessar a central de ajuda ou entrar em contato com o
          suporte.
        </p>
      </section>
    </div>
  )
}

export default PlotagemDescription
