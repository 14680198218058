import { useState, useEffect } from 'react'
import { Modal, Button, Form, Alert } from 'react-bootstrap'
import { fetchData } from '../../../../src/backend/model.js'
import { config } from '../../../../src/backend/configConexaoGeral'

const HorarioFuncionarioModal = ({ show, onHide, funcionario, selectedDate }) => {
  const [entradaPadrao, setEntradaPadrao] = useState(true)
  const [saidaPadrao, setSaidaPadrao] = useState(true)
  const [horaEntrada, setHoraEntrada] = useState('')
  const [horaSaida, setHoraSaida] = useState('')
  const [horasExtras, setHorasExtras] = useState(0)
  const [minutosExtras, setMinutosExtras] = useState(0)
  const [totalHorasPadrao, setTotalHorasPadrao] = useState(0)
  const [aviso, setAviso] = useState('')
  const [horaEntradaAnterior, setHoraEntradaAnterior] = useState('')
  const [horaSaidaAnterior, setHoraSaidaAnterior] = useState('')

  useEffect(() => {
    if (show && funcionario) {
      verificarHorariosFuncionario()
      verificarHorasExtrasFuncionario()
      obterHorasTrabalhoPadraoFuncionario()
    }
  }, [show, funcionario])

  const obterHorasTrabalhoPadraoFuncionario = async () => {
    try {
      const url = `${config.host}/gerenciarHorarios` // URL para rota da função no servidor
      const response = await fetchData(url, {
        funcionarioId: funcionario._id,
        requisicao: 'obterHorasTrabalhoPadraoFuncionario'
      })

      if (response) {
        setTotalHorasPadrao(response.totalHorasTrabalhadas)
      }
    } catch (error) {
      console.error('Erro ao obter horas padrão para o cargo:', error)
    }
  }

  const verificarHorariosFuncionario = async () => {
    try {
      const url = `${config.host}/horariosFuncionarios`
      const horariosResponse = await fetchData(url, {
        funcionarioId: funcionario._id,
        requisicao: 'listarHorarioPorFuncionario',
        data: selectedDate
      })

      if (horariosResponse) {
        const { horaEntrada, horaSaida } = horariosResponse

        setHoraEntrada(horaEntrada || '')
        setHoraSaida(horaSaida || '')
        setEntradaPadrao(false)
        setSaidaPadrao(false)
      } else {
        buscarHorariosPadrao()
      }
    } catch (error) {
      console.error('Erro ao verificar horários do funcionário:', error)
    }
  }

  // Função para converter horas decimais para horas e minutos

  const verificarHorasExtrasFuncionario = async () => {
    try {
      const url = `${config.host}/horarioExtraFuncionarios`
      const horasExtrasResponse = await fetchData(url, {
        funcionarioId: funcionario._id,
        requisicao: 'listarHorasExtrasPorFuncionario',
        data: selectedDate
      })
      console.log('selectedDate', selectedDate)
      if (horasExtrasResponse) {
        console.log('horasExtrasResponse', horasExtrasResponse)
        const { horas, minutos } = converterHorasParaHorasEMinutos(
          horasExtrasResponse[0]?.horasExtras
        )
        setHorasExtras(horas)
        setMinutosExtras(minutos)
      } else {
        setHorasExtras(0)
        setMinutosExtras(0)
      }
    } catch (error) {
      console.error('Erro ao verificar horas extras do funcionário:', error)
    }
  }

  const buscarHorariosPadrao = async () => {
    try {
      const url = `${config.host}/gerenciarHorarios`
      const horariosResponse = await fetchData(url, {
        cargoId: funcionario.cargoId,
        requisicao: 'listarHorariosPorCargo'
      })

      if (horariosResponse && horariosResponse.length > 0) {
        const { horaEntrada, horaSaida } = horariosResponse[0]
        setHoraEntrada(horaEntrada || '')
        setHoraSaida(horaSaida || '')
      }
    } catch (error) {
      console.error('Erro ao buscar horários padrão:', error)
    }
  }

  const handleEntradaPadraoChange = () => {
    const novoValorEntradaPadrao = !entradaPadrao
    setEntradaPadrao(novoValorEntradaPadrao)
    if (novoValorEntradaPadrao) {
      buscarHorariosPadrao()
    }
  }

  const handleSaidaPadraoChange = () => {
    const novoValorSaidaPadrao = !saidaPadrao
    setSaidaPadrao(novoValorSaidaPadrao)
    if (novoValorSaidaPadrao) {
      buscarHorariosPadrao()
    }
  }

  const handleHoraEntradaChange = e => {
    const novaHoraEntrada = e.target.value
    setHoraEntradaAnterior(horaEntrada) // Armazena o valor anterior
    setHoraEntrada(novaHoraEntrada)
    if (novaHoraEntrada) {
      setEntradaPadrao(false)
      verificarHorasExcedentes(novaHoraEntrada, horaSaida)
    }
  }

  const handleHoraSaidaChange = e => {
    const novaHoraSaida = e.target.value
    setHoraSaidaAnterior(horaSaida) // Armazena o valor anterior
    setHoraSaida(novaHoraSaida)
    if (novaHoraSaida) {
      setSaidaPadrao(false)
      verificarHorasExcedentes(horaEntrada, novaHoraSaida)
    }
  }
  //----------------------------------------------------------------
  const verificarHorasExcedentes = (entrada, saida) => {
    if (!entrada || !saida) return

    const [horaEntradaHoras, horaEntradaMinutos] = entrada.split(':').map(Number)
    const [horaSaidaHoras, horaSaidaMinutos] = saida.split(':').map(Number)

    const totalHorasConfiguradas =
      (horaSaidaHoras * 60 + horaSaidaMinutos - (horaEntradaHoras * 60 + horaEntradaMinutos)) / 60

    if (totalHorasConfiguradas > totalHorasPadrao) {
      setAviso(
        'O horário configurado excede o horário padrão. Cadastre as horas adicionais na seção de horas extras.'
      )
      setHoraEntrada(horaEntradaAnterior) // Voltar ao valor anterior se exceder
      setHoraSaida(horaSaidaAnterior) // Voltar ao valor anterior se exceder

      // Exibir o aviso por 4 segundos
      setTimeout(() => setAviso(''), 8000)
    } else {
      setAviso('')
    }
  }

  const handleSave = async () => {
    try {
      const urlHorario = `${config.host}/horariosFuncionarios`
      const bodyHorario = {
        funcionarioId: funcionario._id,
        horaEntrada,
        horaSaida,
        requisicao: 'salvarOuAtualizarHorarioFuncionario',
        data: selectedDate
      }

      await fetchData(urlHorario, bodyHorario)

      // Garantir que horasExtras e minutosExtras tenham valores válidos
      const safeHorasExtras = isNaN(horasExtras) || !horasExtras ? 0 : horasExtras
      const safeMinutosExtras = isNaN(minutosExtras) || !minutosExtras ? 0 : minutosExtras

      // Calcula o total de horas extras
      const totalHorasExtras = safeHorasExtras + safeMinutosExtras / 60

      // Cria o corpo da requisição para as horas extras
      const urlHorasExtras = `${config.host}/horarioExtraFuncionarios`
      const bodyHorasExtras = {
        funcionarioId: funcionario._id,
        horasExtras: totalHorasExtras === 0 ? 0 : totalHorasExtras,
        requisicao: 'salvarOuAtualizarHorasExtrasFuncionario',
        data: selectedDate
      }

      await fetchData(urlHorasExtras, bodyHorasExtras)

      onHide()
    } catch (error) {
      console.error('Erro ao salvar horários do funcionário:', error)
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Configurar Horários para {funcionario?.Nome}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {aviso && (
            <Alert variant="warning" onClose={() => setAviso('')} dismissible>
              {aviso}
            </Alert>
          )}

          <Form.Group controlId="formEntradaPadrao">
            <Form.Check
              type="checkbox"
              label="Entrada Padrão"
              checked={entradaPadrao}
              onChange={handleEntradaPadraoChange}
            />
            <Form.Control
              type="time"
              value={horaEntrada}
              onChange={handleHoraEntradaChange}
              disabled={entradaPadrao}
              placeholder="Hora de Entrada"
            />
          </Form.Group>
          <Form.Group controlId="formSaidaPadrao" className="mt-3">
            <Form.Check
              type="checkbox"
              label="Saída Padrão"
              checked={saidaPadrao}
              onChange={handleSaidaPadraoChange}
            />
            <Form.Control
              type="time"
              value={horaSaida}
              onChange={handleHoraSaidaChange}
              disabled={saidaPadrao}
              placeholder="Hora de Saída"
            />
            <Form.Group controlId="formHorasExtras" className="mt-3">
              <Form.Label>Horas Extras</Form.Label>
              <HourAdjuster value={horasExtras} onChange={setHorasExtras} />
            </Form.Group>

            <Form.Group controlId="formMinutosExtras" className="mt-3">
              <Form.Label>Minutos Extras</Form.Label>
              <MinuteAdjuster value={minutosExtras} onChange={setMinutosExtras} />
            </Form.Group>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default HorarioFuncionarioModal

// Componente para os botões de incremento/decremento das horas
const HourAdjuster = ({ value, onChange }) => {
  const increment = amount => {
    // Verifica se value é NaN ou undefined ou está vazio e define como 0
    if (!value || isNaN(value)) {
      value = 0
    }

    onChange(value + amount)
  }

  const decrement = amount => {
    const newValue = value - amount
    if (newValue >= 0) {
      onChange(newValue)
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {[1, 2, 3].map(amt => (
        <Button
          key={`hour-increment-${amt}`}
          variant="outline-primary"
          style={{ margin: '0 5px', padding: '5px' }}
          onClick={() => increment(amt)}
        >
          +{amt}
        </Button>
      ))}
      <Form.Control
        type="number"
        value={value}
        onChange={e => onChange(parseInt(e.target.value))}
        style={{ width: '60px', textAlign: 'center' }}
      />
      {[1, 2, 3].map(amt => (
        <Button
          key={`hour-decrement-${amt}`}
          variant="outline-danger"
          style={{ margin: '0 5px', padding: '5px' }}
          onClick={() => decrement(amt)}
        >
          -{amt}
        </Button>
      ))}
    </div>
  )
}

// Componente para os botões de incremento/decremento dos minutos
const MinuteAdjuster = ({ value, onChange }) => {
  const increment = amount => {
    // Verifica se value é NaN, undefined ou uma string vazia e define como 0
    const safeValue = !value || isNaN(value) ? 0 : value

    onChange(safeValue + amount)
  }

  const decrement = amount => {
    const newValue = value - amount
    if (newValue >= 0) {
      onChange(newValue)
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {[1, 10, 30].map(amt => (
        <Button
          key={`minute-increment-${amt}`}
          variant="outline-success"
          style={{ margin: '0 5px', padding: '5px' }}
          onClick={() => increment(amt)}
        >
          +{amt}
        </Button>
      ))}
      <Form.Control
        type="number"
        value={value}
        onChange={e => onChange(parseInt(e.target.value))}
        style={{ width: '60px', textAlign: 'center' }}
      />
      {[1, 10, 30].map(amt => (
        <Button
          key={`minute-decrement-${amt}`}
          variant="outline-warning"
          style={{ margin: '0 5px', padding: '5px' }}
          onClick={() => decrement(amt)}
        >
          -{amt}
        </Button>
      ))}
    </div>
  )
}

export function converterHorasParaHorasEMinutos(decimalHours) {
  console.log('decimalHours', decimalHours)

  // Verifica se decimalHours é undefined, null ou NaN, e define como 0 nesses casos
  if (decimalHours === undefined || decimalHours === null || isNaN(decimalHours)) {
    return { horas: 0, minutos: 0 }
  }

  const horas = Math.floor(decimalHours) // Pega a parte inteira das horas
  const minutos = Math.round((decimalHours - horas) * 60) // Converte o restante em minutos

  return { horas, minutos }
}
